define('laterally/initializers/view', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = {

    name: 'views',

    initialize: function initialize() {
      _ember['default'].View.reopen({

        bodyClassNames: [],
        concatenatedProperties: ['bodyClassNames'],

        initializeTooltips: (function () {
          if (this.tagName !== '') {
            this.$('[data-toggle=tooltip]').tooltip({ container: 'body' });
          }
        }).on('didInsertElement'),

        addBodyClasses: (function () {
          var names = this.get('bodyClassNames');
          _ember['default'].$('body').addClass(names.join(' '));
        }).on('didInsertElement'),

        removeBodyClasses: (function () {
          var names = this.get('bodyClassNames');
          _ember['default'].$('body').removeClass(names.join(' '));
        }).on('willDestroyElement')

      });
    }

  };
});