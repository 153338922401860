define('laterally/initializers/bus', ['exports'], function (exports) {
  exports['default'] = {
    name: 'bus',
    before: 'session',

    initialize: function initialize(container) {
      container.injection('adapter', 'bus', 'service:bus');
      container.injection('service:pusher', 'bus', 'service:bus');
      container.injection('service:session', 'bus', 'service:bus');
    }

  };
});