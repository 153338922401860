define('laterally/routes/external-jobs/index', ['exports', 'ember', 'ember-cli-js-cookie'], function (exports, _ember, _emberCliJsCookie) {
  var Route = _ember['default'].Route;
  var RSVP = _ember['default'].RSVP;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  exports['default'] = Route.extend({
    queryParams: { page: { refreshModel: true } },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.initLocations();

      set(controller, 'isLoading', false);
    },

    beforeModel: function beforeModel() {
      var cont = this.controllerFor('external-jobs/index');
      var paids = this.cookieGet('practice-area-ids');
      if (paids) {
        this.cookieDelete('practice-area-ids');
      }
      var lids = this.cookieGet('location-ids');
      if (lids) {
        cont.set('locationIds', lids);
      }
      var start = this.cookieGet('start-experience');
      if (start) {
        cont.set('startExperience', start);
      }
      var end = this.cookieGet('end-experience');
      if (end) {
        cont.set('endExperience', end);
      }
      var keywords = this.cookieGet('keywords');
      if (keywords) {
        cont.set('keywords', keywords);
      }

      set(cont, 'kind', this.cookieGet('kind') || 'all');
    },

    model: function model() {
      var cont = this.controllerFor('external-jobs/index');
      var kind = get(cont, 'kind');
      var sort = cont.get('sort');
      var jobs;

      if (kind === 'saved') {
        var jobs = this.store.find('job', {
          state: kind,
          sort: sort
        });
      } else {
        var startExperience = cont.get('startExperience');
        var endExperience = cont.get('endExperience');
        var locationIds = cont.get('locationIds');
        var keywords = cont.get('keywords');
        var page = cont.get('page');
        var pageSize = cont.get('pageSize');

        jobs = this.store.find('job', {
          state: kind,
          experience_min: startExperience || undefined,
          experience_max: this.normalizeEndExperience(endExperience) || undefined,
          location_ids: locationIds,
          keywords: keywords,
          sort: sort,
          page: page,
          page_size: pageSize
        });
      }

      return RSVP.hash({
        jobs: jobs,
        jobSaves: this.store.find('jobSave')
      });
    },

    afterModel: function afterModel() {
      return get(this.controllerFor('external-jobs/index'), 'filterOptions');
    },

    normalizeEndExperience: function normalizeEndExperience(exp) {
      if (exp > get(this.controllerFor('external-jobs/index'), 'maxExperience')) {
        return null;
      } else {
        return exp;
      }
    },

    cookieGet: function cookieGet(key) {
      var value = _emberCliJsCookie['default'].getJSON(key);
      return value;
    },

    cookieDelete: function cookieDelete(key) {
      _emberCliJsCookie['default'].remove(key);
    },

    actions: {
      loading: function loading() {
        return set(this.controllerFor('external-jobs/index'), 'isLoading', true);
      },

      timeSelected: function timeSelected(times) {
        this.analytics.track('In-House Jobs: Changed time period filter');
        this.controller.useTimes(times);
      },

      experienceSelected: function experienceSelected(times) {
        this.analytics.track('In-House Jobs: Changed experience filter');
        this.controller.useExperiences(times);
      },

      locationsSelected: function locationsSelected(locations) {
        this.analytics.track('In-House Jobs: Changed locations filter');
        this.controller.useLocations(locations);
      },

      locationRemoved: function locationRemoved(location) {
        this.analytics.track('In-House Jobs: Removed location from filter');
        this.controller.dropLocation(location);
      },

      keywordsChanged: function keywordsChanged(keywords) {
        this.analytics.track('In-House Jobs: Changed keywords', { keywords: keywords });
        this.controller.useKeywords(keywords);
      },

      track: function track(name, data) {
        this.analytics.track(name, data);
      },

      hideJob: function hideJob(job) {},

      saveJob: function saveJob(job, delay) {
        var _this = this;

        var save = get(job, 'jobSave');

        if (save) {
          save.destroyRecord();

          if (get(this.controller, 'kind') === 'saved') {
            run.later(function () {
              get(_this.controller, 'model.jobs').removeRecord(get(save, 'job'));
            }, delay || 0);
          }

          this.analytics.track('In-House Jobs: Removed job', { job: get(job, 'id') });
        } else {
          var lawyer = get(this.session, 'userable');
          var record = this.store.createRecord('job-save', { job: job, lawyer: lawyer });
          record.save();

          this.analytics.track('In-House Jobs: Saved job', { job: get(job, 'id') });
        }
      },

      kindSelected: function kindSelected(kind) {
        this.controller.useKind(kind);
      },

      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});