define('laterally/components/explore-share-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['explore-connect--header-bar-share-container'],

    initializeBootstrapDropdown: function initializeBootstrapDropdown() {
      $('.dropdown-toggle').dropdown();
    },

    setupSelectOnOpen: function setupSelectOnOpen() {
      var urlInput = this.$('input')[0];
      var _this = this;
      this.$().on('shown.bs.dropdown', function () {
        _this.get('analytics').track('Explore Connect: Showing Share URL');
        urlInput.focus();
        urlInput.select();
      });
    },

    actions: {
      share: function share() {
        this.initializeBootstrapDropdown();
        this.setupSelectOnOpen();
      }
    }
  });
});