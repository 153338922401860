define("laterally/utils/computed/store-locally", ["exports"], function (exports) {
  var store = window.store;

  exports["default"] = function (storeKey, d) {
    var defaultValue = d;

    if (store.enabled && !store.has(storeKey)) {
      store.set(storeKey, defaultValue);
    }

    return (function (key, value) {
      if (arguments.length > 1) {
        if (store.enabled) {
          store.set(storeKey, value);
        } else {
          defaultValue = value;
        }
      }

      if (store.enabled) {
        return store.get(storeKey);
      } else {
        return defaultValue;
      }
    }).property();
  };
});