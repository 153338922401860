define('laterally/models/concerns/stateful', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Mixin.create({

    state: _emberData['default'].attr('string'),

    step: (function () {
      return this.constructor.STEPS[this.get('state')];
    }).property('state'),

    steps: (function () {
      var properties = {};
      var STEPS = this.constructor.STEPS;
      var recordStep = this.get('step');

      _ember['default'].keys(STEPS).forEach(function (step) {
        properties[step] = recordStep >= STEPS[step];
      });

      return properties;
    }).property('step'),

    states: (function () {
      var properties = {};
      var STATES = this.constructor.STATES;
      var recordState = this.get('state');

      STATES.forEach(function (state) {
        properties[state] = recordState === state;
      });
      return _ember['default'].Object.createWithMixins(properties);
    }).property('state')

  });
});