define("laterally/utils/computed/year", ["exports"], function (exports) {
  exports["default"] = function (property) {
    return (function (_, value) {
      if (arguments.length === 2) {
        value = value ? new Date(value, 1, 1) : value;
        this.set(property, value);
      }
      value = this.get(property);
      value = value ? value.getFullYear() : value;
      return value;
    }).property(property);
  };
});