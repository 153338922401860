define('laterally/models/filter-set', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    name: _emberData['default'].attr('string'),
    filters: _emberData['default'].attr('object', { defaultValue: function defaultValue() {
        return {};
      } }),

    exploreeGroup: _emberData['default'].belongsTo('exploreeGroup'),
    campaign: _ember['default'].computed.alias('exploreeGroup')

  });
});