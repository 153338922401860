define('laterally/controllers/candidates', ['exports', 'ember'], function (exports, _ember) {

  var gt = _ember['default'].computed.gt;
  var alias = _ember['default'].computed.alias;
  var equal = _ember['default'].computed.equal;

  exports['default'] = _ember['default'].ArrayController.extend({

    itemController: 'candidates/firmLocation',

    queryParams: ['state'],

    loading: false,

    state: 'all',

    stateAll: equal('state', 'all'),
    stateHidden: equal('state', 'hidden'),

    stateAgreed: alias('session.user.userable.states.AGREED'),

    // Using the 'filterBy' computed property macro caused problems.
    visibleFirmLocations: (function () {
      return this.filterBy('visible');
    }).property('@each.visible'),

    anyVisibleFirmLocations: gt('visibleFirmLocations.length', 0),

    actions: {

      showTermsOfService: (function () {
        this.send("openModal", "application/termsOfServiceModal");
      }).track('Shown Terms of Service'),

      filterState: function filterState(state) {
        this.set('state', state);
        this.get('analytics').track('Filtering Candidates by state', { state: state });
      }

    }

  });
});