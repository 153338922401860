define('laterally/controllers/jobs-list/firm-location-job', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({

    jobsListController: alias('parentController.parentController.jobsListController'),
    lawyer: alias('jobsListController.lawyer'),
    openDrawer: alias('jobsListController.openDrawer'),
    removable: alias('jobsListController.removable'),

    closed: alias('defunct'),
    removePending: false,

    displayDrawer: (function () {
      return this.get('openDrawer') === this;
    }).property('openDrawer'),

    jobSelection: (function () {
      return this.get('lawyer.jobSelections').findBy('firmLocationJob', this.get('model'));
    }).property('lawyer.jobSelections.@each.firmLocationJob', 'model'),

    status: (function () {
      if (!!this.get('jobSelection.hiddenFromLawyers')) {
        return 'hidden';
      }
      if (!!this.get('jobSelection.hiddenFromFirms')) {
        return 'passed';
      }
      if (!!this.get('model.job.defunct')) {
        return 'closed';
      }
      if (!!this.get('jobSelection.withdrawnByLawyerAt')) {
        return 'withdrawn';
      }
      return 'open';
    }).property('closed', 'defunct', 'model.job.defunct', 'jobSelection.passConfirmed', 'jobSelection.hiddenFromFirms', 'jobSelection.withdrawnByLawyerAt'),

    actions: {

      // remove: function() {
      //   this.set('removing', true);
      // },

      remove: function remove() {
        var _this = this;

        this.set('removePending', true);
        var jobSelection = this.get('jobSelection');
        if (jobSelection.get('submittedAt')) {
          jobSelection.withdrawByLawyer().then(function () {
            _this.get('jobsListController').send('reload');
            _this.set('removePending', false);
          });
        } else {
          this.set('removing', true);
        }
      },

      removed: function removed() {
        this.set('removing', false);
        this.get('jobSelection').destroyRecord();
        this.set('removePending', false);
      },

      toggleDrawer: function toggleDrawer() {
        if (this.get('displayDrawer')) {
          this.set('openDrawer', null);
        } else {
          this.set('openDrawer', this);
        }
      }

    }

  });
});