define('laterally/controllers/explorees/connect', ['exports', 'ember', 'laterally/controllers/concerns/paged', 'laterally/controllers/concerns/ordered', 'laterally/models/exploree-group-membership', 'laterally/utils/deferred-send-action', 'laterally/utils/deferred-receive-action'], function (exports, _ember, _laterallyControllersConcernsPaged, _laterallyControllersConcernsOrdered, _laterallyModelsExploreeGroupMembership, _laterallyUtilsDeferredSendAction, _laterallyUtilsDeferredReceiveAction) {
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;

  var alias = computed.alias;
  var not = computed.not;

  exports['default'] = _ember['default'].ArrayController.extend(_laterallyControllersConcernsPaged['default'], _laterallyControllersConcernsOrdered['default'], _laterallyModelsExploreeGroupMembership.stateProperties, {

    // modal params
    connectableToExplain: null,
    someExploreesForConnectable: null,
    exploreesForConnectableCount: null,
    connectableExplanationDeferred: null,

    itemController: 'explorees/exploree',

    firmUser: alias('session.userable'),

    displayLastMove: false,

    queryParams: ['state'],

    connections: [],

    // This is automatically set in the route.
    group: null,

    state: 'saved',
    orderDirection: 'asc',
    orderColumn: 'firms.name',
    showConnectAll: true,

    scrollToTopObserver: (function () {
      if (!this.get('loading')) {
        _ember['default'].run.schedule('afterRender', function () {
          var results = _ember['default'].$('.explore-results');

          if (results.length) {
            var offset = results.offset().top;
            if (offset < _ember['default'].$(window).scrollTop()) {
              _ember['default'].$('html, body').animate({
                scrollTop: offset
              }, 500);
            }
          }
        });
      }
    }).observes('loading'),

    cannotConnect: not('group.job.content.id'),

    actions: {

      connectWithMessageChanged: function connectWithMessageChanged() {
        var _this = this;

        _ember['default'].run.once(function () {
          _this.toggleProperty('group.connectWithMessage');
          var includeMessage = _this.get('group.connectWithMessage');
          _this.analytics.track('Explore Connect: toggled "Include a message?" to ' + includeMessage);
          get(_this, 'group').save();
        });
      },

      connectAll: (0, _laterallyUtilsDeferredReceiveAction['default'])(function () {
        var _this2 = this;

        this.analytics.track('Explore Connect: Clicked Connect/Share All');
        if (get(this, 'cannotConnect')) {
          return RSVP.reject();
        }

        var batch = this.store.createRecord('connectionBatch', {
          campaign: get(this, 'group'),
          introducer: get(this, 'firmUser')
        });

        if (get(this, 'group.connectWithMessage')) {
          set(batch, 'introduction', get(this, 'group.defaultMessage'));
        }

        var explorees = get(this, 'model');
        var connections = get(this, 'connections');
        var unconnectedExplorees = explorees.filter(function (exploree) {
          return connections.mapBy('lawyerId').indexOf(get(exploree, 'lawyerId')) === -1;
        });

        if (get(unconnectedExplorees, 'length')) {
          var exploreesCount = get(explorees, 'meta.total') - get(connections, 'length');
          var promise = (0, _laterallyUtilsDeferredSendAction['default'])(this, 'explainConnectable', batch, unconnectedExplorees, exploreesCount);

          return promise.then(function (batch) {
            return batch.save().then(function () {
              get(_this2, 'connections').pushObjects(get(batch, 'connections').toArray());
            });
          });
        } else {
          return RSVP.reject();
        }
      }),

      drawerOpened: function drawerOpened(component /*, animation, progress, remaining */) {
        this.analytics.track('Explore Connect: Opened Drawer');
        var element = component.$().parent();
        var offset = element.offset().top;
        var scroll = _ember['default'].$(window).scrollTop();
        if (scroll > 100 && scroll > offset) {
          _ember['default'].$(window).scrollTop(offset);
        }
      },

      showJob: function showJob(exploreeGroup) {
        this.transitionToRoute('explore-jobs.edit', exploreeGroup.get('id'));
      },

      editMessage: function editMessage() {
        this.analytics.track('Explore Connect: Clicked [Edit Connect Message]');
        this.send('openModal', 'explorees/message', {
          model: this.get('group')
        });
      }

    }

  });
});