define('laterally/routes/authenticated', ['exports', 'ember', 'ember-keyboard-shortcuts/mixins/route', 'laterally/utils/deferred-receive-action'], function (exports, _ember, _emberKeyboardShortcutsMixinsRoute, _laterallyUtilsDeferredReceiveAction) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Route.extend(_emberKeyboardShortcutsMixinsRoute['default'], {

    activate: function activate() {
      if (this.pusher && this.pusher != undefined) {
        this.pusher.subscribe('message');
      }
    },

    deactivate: function deactivate() {
      if (this.pusher && this.pusher != undefined) {
        this.pusher.unsubscribe('message');
      }
    },

    beforeModel: function beforeModel() {
      if (!get(this.session, 'token')) {
        if (this.router.location.getURL() === '/') {
          this.transitionTo('landing');
        } else {
          this.transitionTo('login');
        }
      }
    },

    model: function model() {
      return this.session.get('user');
    },

    afterModel: function afterModel() {
      var _this = this;

      this.loadConversations();
      this.openPusher();

      this.store.findQuery('campaign', { limit: 6, sort: '-created_at' }).then(function (campaigns) {
        set(_this.controllerFor('campaigns'), 'model', campaigns);
      });
    },

    loadConversations: function loadConversations() {
      var user = this.session.get('user');
      if (user && user.get('userable.canViewConversations')) {
        var conversations = this.store.filter('conversation', { unread: true }, function (conversation) {
          return get(conversation, 'unread') === true;
        });
        this.controllerFor('conversations').set('model', conversations);
      }
    },

    openPusher: function openPusher() {
      var pusher = this.get('pusher');
      var user = this.session.get('user');
      if (user) {
        pusher.open(user.get('id'));
        pusher.subscribe('user');
      } else {
        pusher.unsubscribe('user');
        pusher.close();
      }
    },

    actions: {

      openIntercom: function openIntercom() {
        var _this2 = this;

        this.analytics.track('Opening Intercom');
        if (window.Intercom) {
          window.Intercom('onShow', function () {
            _this2.analytics.track('Showed Intercom sidebar');
          });
          window.Intercom('show');
          window.Intercom('showNewMessage');
        }
      },

      renewSession: function renewSession(token, resolve, reject) {
        return this.session.open(token).then(resolve, reject);
      },

      claimUser: (0, _laterallyUtilsDeferredReceiveAction['default'])(function (options) {
        var user = get(this.controllerFor('authenticated'), 'user');

        if (get(user, 'claimed')) {
          return RSVP.resolve(false); // let callee know the user was already claimed
        } else {
            var deferred = _ember['default'].RSVP.defer();
            var promise = deferred.promise;

            setProperties(this.controllerFor('authenticated'), {
              userToClaim: user,
              userClaimDeferred: deferred,
              userClaimOptions: options
            });

            return promise.then(function () {
              return RSVP.resolve(true);
            });
          }
      }),

      selectLocations: function selectLocations(locations, selections) {
        setProperties(this.controller, {
          locations: locations,
          locationSelections: selections
        });
      },

      selectPracticeAreas: function selectPracticeAreas(categories, selections) {
        setProperties(this.controller, {
          practiceAreaCategories: categories,
          practiceAreaSelections: selections
        });
      },

      requestToPublish: function requestToPublish(lawyer) {
        var _this3 = this;

        lawyer.submitForApproval().then(function () {
          _this3.transitionTo('conversations');
        });
      },

      highlightProgressErrors: function highlightProgressErrors() {
        this.controllerFor('lawyer').set('errorsFlag', new Date().getTime());
      }
    },

    keyboardShortcuts: {

      'ctrl+alt+p': 'openPossessModal'

    }

  });
});