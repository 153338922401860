define('laterally/services/messenger', ['exports', 'ember', 'laterally/models/lawyer', 'laterally/models/exploree'], function (exports, _ember, _laterallyModelsLawyer, _laterallyModelsExploree) {

  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    firmUser: _ember['default'].computed.oneWay('session.user.userable'),

    state: null,
    conversation: null,

    // contact modal uses state, and clears it when it closes
    clearState: function clearState() {
      this.set('state', null);
      this.set('conversation', null);
    },

    trackConversation: function trackConversation(contactee) {
      var data = {};
      data[contactee.constructor.typeKey] = {
        id: contactee.get('id'),
        name: contactee.get('name')
      };
      this.analytics.track('Sent message', data);
    },

    // We must manually remove new messages still in the conversation object
    // because ember data does not know that the messages that came from the
    // server are actually the messages we sent in the POST payload. Similar
    // issue in the lawyer controller for embedded records.
    deleteNewMessages: function deleteNewMessages(conversation) {
      var newMessages = conversation.get('messages').filterBy('isNew');
      newMessages.forEach(function (record) {
        record.deleteRecord();
      });
    },

    replaceMacros: function replaceMacros(message, sendTo) {
      return message.replace('{{first_name}}', sendTo.get('firstName'));
    },

    sendMessage: function sendMessage(body, sendTo, exploreeGroup) {
      var conversation = this.store.createRecord('conversation');
      set(this, 'conversation', conversation);

      var message = this.replaceMacros(body, sendTo);
      conversation.get('messages').createRecord({
        body: message, createdAt: window.moment()
      });
      conversation.get('firmUsers').addObject(this.get('firmUser'));
      if (sendTo instanceof _laterallyModelsLawyer['default']) {
        conversation.set('lawyerId', sendTo.get('id'));
      } else if (sendTo instanceof _laterallyModelsExploree['default']) {
        conversation.set('lawyerId', sendTo.get('lawyerId'));
      }

      if (exploreeGroup) {
        conversation.set('exploreeGroup', exploreeGroup);
      }

      var promise = conversation.save();
      this.set('state', 'inFlight');
      promise.then((function () {
        this.set('state', 'Sent');
        this.trackConversation(sendTo);
        this.deleteNewMessages(conversation);
      }).bind(this));

      return promise;
    }

  });
});