define('laterally/components/sorted-objects/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var sort = computed.sort;
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    objects: null,
    sortKey: null,
    sortDesc: false,

    objectSorting: computed('sortKey', 'sortDesc', function () {
      if (get(this, 'sortDesc')) {
        return [[get(this, 'sortKey'), 'desc'].join(':')];
      } else {
        return [get(this, 'sortKey')];
      }
    }),

    sortedObjects: sort('objects', 'objectSorting')
  });
});