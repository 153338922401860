define('laterally/controllers/selectable-firms', ['exports', 'ember', 'laterally/utils/computed/in-groups'], function (exports, _ember, _laterallyUtilsComputedInGroups) {
  exports['default'] = _ember['default'].ArrayController.extend({
    // controller is rendered under ApplicationRoute,
    // so using action hashes won't be helpful
    onSave: null,

    selections: _ember['default'].A(),

    groupedContent: (0, _laterallyUtilsComputedInGroups['default'])('content', 3),

    actions: {
      save: function save() {
        var onSave = this.get('onSave');

        if (onSave) {
          onSave(this.get('selections'));
        }
      },

      modalClosed: function modalClosed() {
        this.send('save');
        return true;
      }
    }
  });
});