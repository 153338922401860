define('laterally/routes/concerns/on-start-step', ['exports', 'ember', 'laterally/routes/concerns/require-user', 'laterally/models/firm-user'], function (exports, _ember, _laterallyRoutesConcernsRequireUser, _laterallyModelsFirmUser) {
  exports['default'] = _ember['default'].Mixin.create(_laterallyRoutesConcernsRequireUser['default'], {

    user: null,

    getUser: (function (user) {
      this.user = user;
    }).on('requiredUser'),

    showModal: function showModal(model, transition) {
      if (this.user.get('userable.step') === _laterallyModelsFirmUser['default'].STEPS.START) {
        _ember['default'].run.schedule("afterRender", this, function () {
          transition.send("openModal", "application/firstTimeModal");
        });
      }
    }

  });
});