define('laterally/utils/computed/group', ['exports', 'ember', 'laterally/utils/normalize-group-dependent-key'], function (exports, _ember, _laterallyUtilsNormalizeGroupDependentKey) {
  var get = _ember['default'].get;

  exports['default'] = function (dependentKey, groupCallback) {
    dependentKey = (0, _laterallyUtilsNormalizeGroupDependentKey['default'])(dependentKey);
    return (function () {
      var groups = [];

      (this.get(dependentKey) || []).forEach(function (item) {
        var res = groupCallback.call(this, groups, item);
        var groupKey;
        var groupMixin;

        if (!get(res, 'key')) {
          groupKey = res;
          groupMixin = { key: groupKey };
        } else {
          groupMixin = res;
        }

        var foundGroup = groups.findProperty('key', groupKey);

        if (!foundGroup) {
          foundGroup = groups.pushObject(_ember['default'].ArrayProxy.createWithMixins(groupMixin, {
            content: []
          }));
        }

        foundGroup.get('content').pushObject(item);
      }, this);

      return groups;
    }).property(dependentKey);
  };
});