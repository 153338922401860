define('laterally/routes/ex3', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  exports['default'] = Route.extend({

    model: function model(params) {
      var _this = this;

      this.analytics.track('Visited ex3 (explore step 3 - "Connect") link', { claimToken: params.token });

      return this.session.openWithSingleAccessToken(params.token).then(function () {
        if (params.exploreeGroupId) {
          _this.store.find('exploreeGroup', params.exploreeGroupId).then(function (exploreeGroup) {
            _this.transitionTo('explorees.connect', exploreeGroup.get('id'));
          });
        }
      }, function () {
        _this.transitionTo('login');
      });
    }

  });
});