define('laterally/routes/sign-up', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      var user = this.get('session.user');

      if (user) {
        if (user.get('userable')) {
          this.transitionTo('profile');
        } else {
          this.transitionTo('onboarding');
        }
      }
    },

    model: function model() {
      return this.store.createRecord('user');
    },

    actions: {
      signedUp: function signedUp(user, forEmployer) {
        if (forEmployer) {
          this.transitionTo('welcome.employers');
        } else {
          this.transitionTo('welcome.lawyers');
        }
      }
    }

  });
});