define('laterally/utils/rest-action', ['exports', 'ember', 'laterally/utils/underscore-keys', 'laterally/utils/filter-falsy-keys'], function (exports, _ember, _laterallyUtilsUnderscoreKeys, _laterallyUtilsFilterFalsyKeys) {
  exports['default'] = restAction;

  var Promise = _ember['default'].RSVP.Promise;

  function restAction(path) {
    return function () {
      var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      params = (0, _laterallyUtilsFilterFalsyKeys['default'])((0, _laterallyUtilsUnderscoreKeys['default'])(params));
      var model = this;
      var store = this.store;
      var type = this.constructor;
      var typeKey = type.typeKey;
      var adapter = store.adapterFor(typeKey);
      var serializer = store.serializerFor(typeKey);
      var url = adapter.buildURL(typeKey, this.get('id'), this);
      return new Promise(function (resolve, reject) {
        model.set('isSending', true);

        var settings = {};
        settings.type = 'POST';
        settings.data = params;
        var token = model.container.lookup('service:session').get('token.value');

        if (token) {
          settings.headers = {};
          settings.headers['Authorization'] = ['Bearer', token].join(' ');
        }

        _ember['default'].$.ajax(url + '/' + path, settings).done(function (data) {
          if (data) {
            var record = serializer.extract(store, type, data, null, 'single');
            record = store.push(typeKey, record);
            resolve(record);
          } else {
            resolve();
          }
        }).fail(function (data) {
          if (data.responseJSON) {
            var errors = data.responseJSON.errors;
            model.set('serverErrors', errors);
            if (reject && typeof reject === "function") {
              reject(data);
            }
          } else {
            reject(data);
          }
        }).always(function () {
          model.set('isSending', false);
        });
      });
    };
  }
});