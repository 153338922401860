define('laterally/models/campaign', ['exports', 'ember-data', 'laterally/utils/computed/moment-unix'], function (exports, _emberData, _laterallyUtilsComputedMomentUnix) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    exploreeGroup: belongsTo('exploreeGroup', { async: true }),

    name: attr(),

    isLive: attr('boolean'),
    isClosed: attr('boolean'),

    savedMembershipsCount: attr('number'),
    createdAt: attr('moment'),
    createdAtUnix: (0, _laterallyUtilsComputedMomentUnix['default'])('createdAt')
  });
});