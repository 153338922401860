define('laterally/routes/lawyer/index', ['exports', 'ember', 'laterally/utils/has-many-committer'], function (exports, _ember, _laterallyUtilsHasManyCommitter) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, model) {
      set(controller, 'model', model);
      set(controller, 'canEdit', model === get(this, 'session.userable'));
    },

    model: function model() {
      return this.modelFor('lawyer');
    },

    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'login') {
          return true;
        }

        var controller = this.controllerFor('lawyer.index');
        var lawyer = get(controller, 'lawyer');
        var pertinentKeys = [].concat('currentLocation firmName schoolName'.w(), 'questionMoving questionProudest questionOtherCareer questionBesidesResume'.w(), 'barAdmissions'.w(), 'email phone contactNotes'.w());

        if (transition.targetName.indexOf('lawyer.') !== 0 && lawyer.didChange(pertinentKeys)) {
          var committer = _ember['default'].Object.createWithMixins(_laterallyUtilsHasManyCommitter['default'], {
            records: get(lawyer, 'barAdmissions')
          });

          transition.abort();

          _ember['default'].RSVP.all([lawyer.save(), committer.saveRecords()]).then(function () {
            transition.retry();
          });
        }
      }
    }
  });
});