define('laterally/models/job-summary', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _emberData['default'].Model.extend({
    title: attr(),
    description: attr(),

    firmName: attr(),
    firmProfilePicture: attr(),

    locationNames: attr(),
    firmGroupMembers: hasMany('firmGroupMember')
  });
});