define('laterally/components/explorees/result-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    saveComment: 'saveComment',
    identifyUser: 'identifyUser',
    toggleOpen: null,
    drawerComponentName: 'campaign/result-drawer',
    exploree: null,

    actions: {
      toggleOpen: function toggleOpen() {
        this.sendAction('toggleOpen');
      },

      saveComment: function saveComment(comment, exploree) {
        this.sendAction('saveComment', comment, exploree);
      },

      identifyUser: function identifyUser(fn) {
        this.sendAction('identifyUser', fn);
      }
    }

  });
});