define('laterally/serializers/conversation', ['exports', 'ember-data', 'laterally/serializers/application'], function (exports, _emberData, _laterallySerializersApplication) {
  exports['default'] = _laterallySerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {

    attrs: {
      messages: {
        serialize: 'records'
      },
      firmUsers: {
        serialize: 'ids'
      },
      lawyers: {
        serialize: 'ids'
      },
      explorees: {
        serialize: 'ids'
      }
    }

  });
});