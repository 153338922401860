define('laterally/components/edit-buttons', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    disabled: false,
    editing: false,
    canEdit: false,
    isSaving: false,

    save: 'save',
    edit: 'edit',
    cancel: 'cancel',

    buttonSize: 'xs',

    buttonSizeClass: (function () {
      return 'btn-' + this.get('buttonSize');
    }).property('buttonSize'),

    sendUnlessDisabled: function sendUnlessDisabled(action) {
      if (!this.get('disabled')) {
        this.sendAction(action);
      }
    },

    actions: {

      save: function save() {
        this.sendUnlessDisabled('save');
      },

      edit: function edit() {
        this.sendUnlessDisabled('edit');
      },

      cancel: function cancel() {
        this.sendUnlessDisabled('cancel');
      }

    }

  });
});