define('laterally/models/job-selection', ['exports', 'ember', 'ember-data', 'laterally/utils/rest-action'], function (exports, _ember, _emberData, _laterallyUtilsRestAction) {

  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _emberData['default'].Model.extend({

    notes: attr('string'),
    createdAt: attr('date'),
    madeAvailableAt: attr('date'),
    hiddenReason: attr('string'),
    hiddenNotes: attr('string'),
    hiddenAt: attr('moment'),
    hiddenFromFirms: attr('boolean'),
    hiddenFromLawyers: attr('boolean'),
    withdrawnByLawyerAt: attr('moment'),
    submittedAt: attr('moment'),

    lawyer: belongsTo('lawyer'),
    firmLocationJob: belongsTo('firmLocationJob'),

    hide: (0, _laterallyUtilsRestAction['default'])('hide'),
    unhide: (0, _laterallyUtilsRestAction['default'])('unhide'),
    withdrawByLawyer: (0, _laterallyUtilsRestAction['default'])('withdraw_by_lawyer'),

    passConfirmed: computed('hiddenFromFirms', function () {
      return get(this, 'hiddenFromFirms');
    })

  });
});