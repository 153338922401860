define('laterally/controllers/concerns/ordered', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    queryParams: ['orderColumn', 'orderDirection'],

    orderDirection: null,
    orderColumn: null,

    actions: {

      orderBy: function orderBy(column, direction) {
        this.setProperties({
          orderColumn: column,
          orderDirection: direction
        });
      }

    }

  });
});