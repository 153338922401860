define('laterally/controllers/authenticated', ['exports', 'ember'], function (exports, _ember) {
  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({
    user: alias('session.user.content'),

    willRenewSession: false,
    newToken: null,

    userToClaim: null,
    userClaimOptions: null,
    claimUserDeferred: null,

    locationSelections: null,
    practiceAreaSelections: null,

    preventLegacyClaimModal: false
  });
});