define('laterally/components/form-selectable-input/component', ['exports', 'ember', 'laterally/components/form-for/select'], function (exports, _ember, _laterallyComponentsFormForSelect) {
  exports['default'] = _ember['default'].Component.extend(_laterallyComponentsFormForSelect['default'], {

    classNames: ['form-for--selectable-input'],
    'on-enter': 'on-enter',

    actions: {
      selectedDenormalized: function selectedDenormalized(value) {
        this.sendAction('selectedDenormalized', value);
      },

      'on-enter': function onEnter() {
        this.sendAction('on-enter');
      }
    }

  });
});