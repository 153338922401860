define('laterally/components/onboarding-form/for-lawyers/component', ['exports', 'ember', 'laterally/utils/track-property'], function (exports, _ember, _laterallyUtilsTrackProperty) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var mapBy = _ember['default'].computed.mapBy;

  exports['default'] = _ember['default'].Component.extend({

    // attrs
    lawyer: null,
    schools: null,
    firms: null,
    next: null,

    agreed: true,

    schoolNames: mapBy('schools', 'name'),
    firmNames: mapBy('firms', 'name'),

    trackFirm: (0, _laterallyUtilsTrackProperty['default'])('lawyer.firm', 'Entered firm'),
    trackSchool: (0, _laterallyUtilsTrackProperty['default'])('lawyer.school', 'Entered school'),
    trackGraduationYear: (0, _laterallyUtilsTrackProperty['default'])('lawyer.graduationYearOnly', 'Entered graduation year'),

    actions: {

      toggleAgreed: function toggleAgreed() {
        this.toggleProperty('agreed');
      },

      enteredUnknownFirmName: function enteredUnknownFirmName(firmName) {
        this.analytics.track('Entered unknown firm name', { firm: firmName });
      },

      enteredUnknownSchoolName: function enteredUnknownSchoolName(schoolName) {
        this.analytics.track('Entered unknown school name', { school: schoolName });
      },

      submit: function submit() {
        var _this = this;

        if (get(this, 'agreed')) {
          if (get(this, 'studentMode')) {
            set(this, 'model.student', true);
          }
          get(this, 'model').save().then(function () {
            _this.analytics.track('Lawyer Registered');
            _this.sendAction('next');
          }, function (error) {
            _this.analytics.track('Lawyer Registered Error', error);
            _ember['default'].RSVP.rethrow(error);
          });
        }
      }

    }

  });
});