define('laterally/components/job-profile/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['job-container'],

    job: null,
    backToEdit: null,
    respond: null,

    showActions: true,

    actions: {
      backToEdit: function backToEdit() {
        this.sendAction('backToEdit');
      },

      respond: function respond() {
        this.sendAction('respond');
      }
    }

  });
});