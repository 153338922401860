define('laterally/components/conversation-job-box/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['message-list-item', 'conversations-job-info'],

    job: alias('conversation.exploreeGroup.job')

  });
});