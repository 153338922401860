define('laterally/components/move-tracker/firm-row/component', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var equal = computed.equal;
  var not = computed.not;
  var alias = computed.alias;
  var PromiseObject = _emberData['default'].PromiseObject;

  var service = _ember['default'].inject.service;

  exports['default'] = _ember['default'].Component.extend({
    isInHouseVersion: false,

    summary: null,
    store: service(),
    expandedFirm: null,

    departures: null,
    arrivals: null,
    unsetExpandedFirm: 'unsetExpandedFirm',
    setExpandedFirm: 'setExpandedFirm',
    tabSelected: 'drawerTabSelected',

    firm: alias('summary.firm'),
    // collapsed: true,
    collapsed: not('expanded'),

    expanded: (function () {
      return get(this, 'expandedFirm.id') == get(this, 'firm.id');
    }).property('expandedFirm.id', 'firm.id'),

    collapsible: computed('summary.{arrivalCount,departureCount}', function () {
      return get(this, 'summary.arrivalCount') || get(this, 'summary.departureCount');
    }),

    categoryDepartureRate: computed('summary', 'category', function () {
      var category = get(this, 'category') === 'inhouse' ? 'inHouse' : get(this, 'category');
      return get(get(this, 'summary'), [category, "DepartureRate"].join(''));
    }),

    breakdown: computed(function () {
      var promise = get(this, 'store').find('move-breakdown', {
        firm_id: get(this, 'summary.id'),
        location_id: get(this, 'locationId'),
        practice_area_ids: get(this, 'practiceAreaIds'),
        date_start: get(this, 'startTime') ? moment(get(this, 'startTime')).add('days', 1).format('YYYY-MM-DD') : undefined,
        date_end: get(this, 'endTime') ? moment(get(this, 'endTime')).add('days', 1).endOf('year').format('YYYY-MM-DD') : undefined,
        experience_min: get(this, 'experienceMin'),
        experience_max: get(this, 'experienceMax')
      }).then(function (breakdowns) {
        return get(breakdowns, 'firstObject');
      });

      return PromiseObject.create({
        promise: promise
      });
    }),

    arrivals: computed(function () {
      return this.getPagerForMoves('arrival');
    }),

    departures: computed(function () {
      return this.getPagerForMoves('departure');
    }),

    getPagerForMoves: function getPagerForMoves(kind) {
      var firmId = get(this, 'summary.id');
      var store = get(this, 'store');

      var startTime = get(this, 'startTime');
      var endTime = get(this, 'endTime');

      var experienceMin = get(this, 'experienceMin');
      var experienceMax = get(this, 'experienceMax');

      if (startTime) {
        startTime = moment(startTime).add('days', 1).format('YYYY-MM-DD');
      }

      if (endTime) {
        endTime = moment(endTime).add('days', 1).endOf('year').format('YYYY-MM-DD');
      }

      var params = {
        firm_id: firmId,
        category: get(this, 'category'),
        location_id: get(this, 'locationId'),
        practice_area_ids: get(this, 'practiceAreaIds'),
        date_start: startTime,
        date_end: endTime,
        experience_min: experienceMin,
        experience_max: experienceMax,

        kind: kind,
        type: get(this, 'isInHouseVersion') ? 'in-house' : null
      };

      var totalRecords = get(this, 'summary.' + kind + 'Count');

      var store = get(this, 'store');
      var query = store.find('move', params);
      var pager = this.container.lookup('service:pager').create({ firstPage: query, totalRecords: totalRecords });

      return pager;
    },

    actions: {
      toggleCollapsed: function toggleCollapsed() {
        if (get(this, 'fakeOpen')) {
          return;
        }

        if (!get(this, 'collapsible')) {
          return;
        }

        var firmName = get(this, 'firm.name');
        if (get(this, 'collapsed')) {
          this.analytics.track('Move Tracker: opened firm drawer', { firm: firmName });
        } else {
          this.analytics.track('Move Tracker: closed firm drawer', { firm: firmName });
        }
        this.toggleProperty('collapsed');

        if (get(this, 'expanded')) {
          this.sendAction('unsetExpandedFirm');
        } else {
          this.sendAction('setExpandedFirm', get(this, 'firm'));
        }
      },

      tabSelected: function tabSelected(selectedTab) {
        this.analytics.track('Move Tracker: In-drawer tab selected', { tab: selectedTab });
      }
    }
  });
});