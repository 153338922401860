define('laterally/components/move-tracker/arrivals-table/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    tagName: 'table',
    classNames: 'table move-tracker__moves-table'.w(),

    didInsertElement: (function () {
      var _this = this;

      if (!get(this, 'arrivals.firstObject.isFulfilled')) {
        return;
      }

      this.$().parents('.move-tracker__firm-drawyer').removeClass('-floated');

      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.$().floatThead();
        _this.$().on('floatThead', function (e, isFloated, $floatContainer) {
          if (isFloated) {
            $floatContainer.parents('.move-tracker__firm-drawyer').addClass('-floated');
          } else {
            $floatContainer.parents('.move-tracker__firm-drawyer').removeClass('-floated');
          }
        });
      });
    }).observes('arrivals.firstObject.isFulfilled')
  });
});