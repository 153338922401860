define('laterally/app', ['exports', 'ember', 'ember-data', 'ember/resolver', 'ember/load-initializers', 'laterally/config/environment', 'laterally/utils/xdomain-slaves'], function (exports, _ember, _emberData, _emberResolver, _emberLoadInitializers, _laterallyConfigEnvironment, _laterallyUtilsXdomainSlaves) {

  var App = _ember['default'].Application.extend({
    modulePrefix: _laterallyConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _laterallyConfigEnvironment['default'].podModulePrefix,
    Resolver: _emberResolver['default'],
    customEvents: {
      paste: "paste"
    }
  });

  (0, _emberLoadInitializers['default'])(App, _laterallyConfigEnvironment['default'].modulePrefix);

  _ember['default'].computed.join = function () /* ...properties */{
    var properties = [].slice.call(arguments);
    return func.property.apply(func, properties);
    function func() {
      return properties.map(this.get, this).join(', ');
    }
  };

  _ember['default'].computed.joinBy = function (collection, property) {
    return func.property(collection + '.@each.' + property);
    function func() {
      return this.get(collection).mapBy(property).join(', ');
    }
  };

  _ember['default'].computed.truncate = function (property, length) {
    if (!length) {
      length = 30;
    }
    return (function () {
      var str = this.get(property);
      return str ? str.slice(0, length) : '';
    }).property(property);
  };

  _ember['default'].computed.sumBy = function (collection, property) {
    var dependentKey = collection + (collection ? '.' : '') + '@each.' + property;
    return (function () {
      return this.get(collection).mapBy(property).reduce(function (sum, item) {
        return sum + item;
      }, 0);
    }).property(dependentKey);
  };

  _ember['default'].computed.findBy = function (collection, key, value) {
    if (arguments.length === 2) {
      value = true;
    }
    return (function () {
      return this.get(collection).findBy(key, value);
    }).property(collection + '.@each.' + key);
  };

  _ember['default'].Route.reopen(_ember['default'].Evented);

  _ember['default'].Route.reopen({

    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (_ember['default'].isNone(value)) {
        return value;
      }
      return this._super(value, urlKey, defaultValueType);
    },

    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      var options = _ember['default'].get(this.queryParams, urlKey);
      if (options) {
        var type = _ember['default'].get(options, 'type');
        if (type) {
          return type(value).valueOf();
        }
      }
      return this._super(value, urlKey, defaultValueType);
    }

  });

  (function () {

    var methods = {

      titleize: function titleize(str) {
        return _ember['default'].String.humanize(str).replace(/(^\w| \w)/g, function (match) {
          return match ? match.toUpperCase() : "";
        });
      },

      humanize: function humanize(str) {
        return _ember['default'].String.underscore(str).replace(/_/g, " ");
      },

      pluralize: function pluralize(str) {
        return str.replace(/y$/, "ie") + "s";
      },

      singularize: function singularize(str) {
        return str.replace(/s$/, '');
      },

      capitalize: function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      },

      decapitalize: function decapitalize(str) {
        return str.charAt(0).toLowerCase() + str.slice(1);
      }

    };

    _ember['default'].merge(_ember['default'].String, methods);

    _ember['default'].keys(methods).forEach(function (method) {
      String.prototype[method] = function () {
        return _ember['default'].String[method](this);
      };
    });

    _ember['default'].keys(_ember['default'].String).forEach(function (method) {

      _ember['default'].computed[method] = function (dependentKey) {
        return (function () {
          var value = this.get(dependentKey);
          if (value) {
            return _ember['default'].String[method](value);
          }
        }).property(dependentKey);
      };
    });
  })();

  Number.prototype.mod = function (n) {
    return (this % n + n) % n;
  };

  // Observing Enumerables (i.e., DS.Errors) doesn't seem to work anymore
  // so we'll have to observe `hasErrors` if we want to listen for error changes

  _emberData['default'].Model.reopen({
    hasErrors: false,

    setHasErrors: (function () {
      _ember['default'].run(this, function () {
        this.set('hasErrors', this.get('errors.length') > 1);
      });
    }).on('becameValid', 'becameInvalid')
  });

  //
  // ember-data sucks at polymorphism
  var oldAssert = _ember['default'].assert;

  _ember['default'].assert = function (desc, test) {
    if (desc.match && !desc.match(/You can only add a '\w+' record to this relationship/)) {
      oldAssert(desc, test);
    }
  };
  //

  _ember['default'].$.ajaxSetup({
    headers: { 'X-Requested-With': 'XMLHttpRequest' }
  });

  //

  exports['default'] = App;
});