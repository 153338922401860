define('laterally/routes/move-tracker/index', ['exports', 'ember', 'laterally/routes/concerns/paged'], function (exports, _ember, _laterallyRoutesConcernsPaged) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var moment = window.moment;
  exports['default'] = Route.extend(_laterallyRoutesConcernsPaged['default'], {
    queryParams: {
      category: { refreshModel: true },
      firmId: { refreshModel: true },
      locationId: { refreshModel: true },
      practiceAreaIds: { refreshModel: true },
      experienceMin: { refreshModel: true },
      experienceMax: { refreshModel: true },
      startTime: { refreshModel: true },
      endTime: { refreshModel: true }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.initLocations();
      controller.initPracticeAreas();

      if (!model.mapBy('firm.id').contains(get(controller, 'expandedFirm.id'))) {
        set(controller, 'expandedFirm', null);
      }
    },

    model: function model(params) {
      return this.store.findQuery('move-summary', this.generateQuery(params));
    },

    afterModel: function afterModel(model) {
      return get(this.controllerFor('move-tracker/index'), 'filterOptions');
    },

    generateQuery: function generateQuery(params) {
      return {
        category: params.category || undefined,
        page: params.page,
        sort: params.sort,
        firm_id: params.firmId || undefined,
        location_id: params.locationId,
        practice_area_ids: params.practiceAreaIds,
        experience_min: params.experienceMin,
        experience_max: params.experienceMax,
        date_start: this.getStartDateFromStartTime(params.startTime),
        date_end: this.getEndDateFromEndTime(params.endTime)
      };
    },

    getStartDateFromStartTime: function getStartDateFromStartTime(time) {
      if (time) {
        return moment(time).add('days', 1).format('YYYY-MM-DD');
      } else {
        return undefined;
      }
    },

    getEndDateFromEndTime: function getEndDateFromEndTime(time) {
      if (time) {
        return moment(time).add('days', 1).endOf('year').format('YYYY-MM-DD');
      } else {
        return undefined;
      }
    },

    actions: {
      sortCallback: function sortCallback(sortedColumn) {
        this.analytics.track('Move Tracker: sorted by ' + sortedColumn);
        this.refresh();
      },

      practiceAreasSelected: function practiceAreasSelected(practiceAreas) {
        this.analytics.track('Move Tracker: Changed practice areas filter');
        this.controller.usePracticeAreas(practiceAreas);
      },

      removePracticeArea: function removePracticeArea(practiceArea) {
        this.analytics.track('Move Tracker: Removed practice area from filter');
        this.controller.dropPracticeArea(practiceArea);
      },

      willTransition: function willTransition() {
        this.controller.set('nowLoading', true);
      },

      didTransition: function didTransition() {
        this.controller.set('nowLoading', false);
      }

    }
  });
});