define('laterally/components/selectivity-label/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    classNames: ['selectivity-label'],
    classNameBindings: ['type'],

    selectivity: null,

    title: computed('selectivity', function () {
      return this.constructor.TITLES.objectAt(get(this, 'selectivity')) || this.constructor.TITLES[0];
    }),

    type: computed('title', function () {
      return get(this, 'title').replace(' ', '-').toLowerCase();
    })
  }).reopenClass({
    TITLES: ['Not Ranked', 'Variably Selec.', 'Selective', 'Highly Selective', 'Most Selective']
  });
});