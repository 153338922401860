define('laterally/components/jobs-history/component', ['exports', 'ember', 'laterally/mixins/inaccessible'], function (exports, _ember, _laterallyMixinsInaccessible) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var alias = computed.alias;
  var sumBy = computed.sumBy;
  var oneWay = computed.oneWay;
  exports['default'] = Component.extend(_laterallyMixinsInaccessible['default'], {
    classNames: ['jobs-history'],

    groups: null,

    minTime: null,
    maxTime: null,
    minExperience: null,
    maxExperience: null,
    experienceThreshold: null,
    locationOptions: null,
    practiceAreaOptions: null,
    practiceAreaCategoryOptions: null,
    openIntercom: 'openIntercom',
    track: 'track',

    startTime: null,
    endTime: null,
    startExperience: null,
    endExperience: null,
    locations: [],
    practiceAreas: [],

    jobsCount: sumBy('groups', 'jobsCount'),
    firmsCount: alias('groups.length'),

    adjustedAvgMarketTime: computed('groups.@each.{jobsCount,adjustedJobsMarketTime}', function () {
      var sum = get(this, 'groups').mapBy('jobsCount').reduce(function (memo, count) {
        return memo + count;
      }, 0);
      var avg = get(this, 'groups').reduce(function (memo, group) {
        return memo + get(group, 'adjustedJobsMarketTime') * get(group, 'jobsCount');
      }, 0) / sum;

      return avg;
    }),

    avgMarketTime: computed('groups.@each.{jobsCount,jobsMarketTime}', function () {
      var sum = get(this, 'groups').mapBy('jobsCount').reduce(function (memo, count) {
        return memo + count;
      }, 0);
      var avg = get(this, 'groups').reduce(function (memo, group) {
        return memo + get(group, 'jobsMarketTime') * get(group, 'jobsCount');
      }, 0) / sum;

      return avg;
    }),

    userableAllowed: computed('userable.{state,stage}', function () {
      return !get(this, 'userable.steps.SELECTING');
    }),

    actions: {

      timeSelected: function timeSelected(values) {
        this.sendAction('timeSelected', values);
      },

      experienceSelected: function experienceSelected(values) {
        this.sendAction('experienceSelected', values);
      },

      selectLocations: function selectLocations() {
        this.sendAction('selectLocations', get(this, 'locationOptions'), get(this, 'locations'));
      },

      selectPracticeAreas: function selectPracticeAreas() {
        this.sendAction('selectPracticeAreas', get(this, 'practiceAreaCategoryOptions'), get(this, 'practiceAreas'));
      },

      removeLocation: function removeLocation(location) {
        this.sendAction('locationRemoved', location);
      },

      removePracticeArea: function removePracticeArea(practiceArea) {
        this.sendAction('practiceAreaRemoved', practiceArea);
      },

      openIntercom: function openIntercom() {
        this.sendAction('openIntercom');
      },

      track: function track(name) {
        this.sendAction('track', name);
      }
    }
  });
});