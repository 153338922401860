define('laterally/initializers/messenger', ['exports'], function (exports) {
  exports['default'] = {

    name: 'messenger',

    initialize: function initialize(container) {
      container.injection('service:messenger', 'store', 'service:store');
      container.injection('service:messenger', 'analytics', 'service:analytics');
    }

  };
});