define('laterally/adapters/v2', ['exports', 'laterally/config/environment', 'ember', 'ember-data'], function (exports, _laterallyConfigEnvironment, _ember, _emberData) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].ActiveModelAdapter.extend({
    host: _laterallyConfigEnvironment['default'].APP.API_HOST,
    namespace: 'api/v2',

    coalesceFindRequests: true,

    headers: computed('session.accessToken', function () {
      var accessToken = get(this.session, 'accessToken');

      if (accessToken) {
        return { 'Authorization': ['Bearer', accessToken].join(' ') };
      } else {
        return {};
      }
    })

  });
});