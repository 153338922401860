define('laterally/components/connection-banner/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['connection-banner'],
    classNameBindings: ['squished:connection-banner--squished', 'striped:connection-banner--striped'],

    squished: false,

    actions: {
      dismiss: function dismiss() {
        this.sendAction('dismiss');
      }
    }
  });
});