define("laterally/models/employer-application", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({

    email: _emberData["default"].attr("string"),
    position: _emberData["default"].attr("string"),
    name: _emberData["default"].attr("string"),
    phone: _emberData["default"].attr("string"),
    firm: _emberData["default"].attr("string"),
    password: _emberData["default"].attr("string")

  });
});