define('laterally/initializers/pusher-initializer', ['exports'], function (exports) {
  var PusherInitializer = {
    name: 'PusherInitializer',
    initialize: function initialize(container) {
      container.injection('service:pusher', 'store', 'store:main');
      container.injection('route', 'pusher', 'service:pusher');
    }
  };

  exports['default'] = PusherInitializer;
});