define('laterally/components/form-for/select', ['exports', 'ember', 'laterally/components/form-for/input'], function (exports, _ember, _laterallyComponentsFormForInput) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Mixin.create(_laterallyComponentsFormForInput['default'], {

    // attrs
    content: null,
    collection: null,
    prompt: computed('form.hasLabelPlaceholder', 'label', function () {
      if (get(this, 'form.hasLabelPlaceholder')) {
        return get(this, 'label');
      }
    })

  });
});