define('laterally/controllers/applications', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  exports['default'] = Controller.extend({
    user: alias('session.user'),

    actions: {
      requestToPublish: function requestToPublish() {
        if (this.get('user.userable.canPublish')) {
          this.send('openModal', 'pending-message');
        }
      }
    }
  });
});