define('laterally/views/application/claim-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    classNames: "claim-modal",

    focusInput: (function () {
      _ember['default'].run.later(this.$('input:enabled'), 'focus', 500);
    }).on('didInsertElement')

  });
});