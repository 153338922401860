define('laterally/controllers/explorees/result/connect-actions', ['exports', 'ember', 'laterally/utils/deferred-send-action', 'laterally/utils/deferred-receive-action'], function (exports, _ember, _laterallyUtilsDeferredSendAction, _laterallyUtilsDeferredReceiveAction) {

  var computed = _ember['default'].computed;
  var alias = computed.alias;

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  function ensureHasAccess(fn) {
    return function ensuringHasAccess() {
      if (this.get('hasAccess')) {
        return fn.call(this);
      }
      this.send('showTermsOfService');
    };
  }

  exports['default'] = _ember['default'].Controller.extend({

    needs: ['explorees', 'explorees/connect', 'explorees/message'],
    messenger: _ember['default'].inject.service(),
    firmUser: alias('session.userable'),
    exploree: alias('model'),
    hasAccess: alias('firmUser.states.AGREED'),
    hidExploree: false,

    state: alias('controllers.explorees/connect.state'),
    group: alias('controllers.explorees/connect.group'),
    connections: alias('controllers.explorees/connect.connections'),
    cannotConnect: alias('controllers.explorees/connect.cannotConnect'),

    connection: computed('connections.@each.lawyerId', 'model.lawyerId', function () {
      var lawyerId = get(this, 'model.lawyerId');

      return get(this, 'connections').find(function (connection) {
        return get(connection, 'lawyerId') === lawyerId;
      });
    }),

    // Only show undo buttons when there is a membeship and we didn't just hide
    // the exploree.
    showUndoButtons: (function () {
      return this.get('membership') && !this.get('hidExploree');
    }).property('membership', 'hidExploree'),

    membership: (function () {
      var group = this.get('group');
      if (group) {
        return this.get('model.memberships').find(function (membership) {
          // Must filter out deleted memberships because they can float around
          // in the association for some reason.
          return !membership.get('isDeleted') && membership.get('exploreeGroup') === group;
        });
      }
    }).property('model.memberships.[]', 'group'),

    defaultMessage: function defaultMessage() {
      var messageController = this.get('controllers.explorees/message');
      return this.get('group.message') || messageController.get('defaultMessage');
    },

    createMembership: function createMembership(options) {
      var group = options.group || this.get('group');
      var state = options.state;
      if (group) {
        if (state === 'hidden') {
          this.send('hidExploree');
        }

        var membership = this.get('membership');

        if (membership) {
          membership.set('state', state);
          membership.set('exploreeGroup', group);
          return membership.save();
        } else {
          return this.get('model.memberships').createRecord({
            state: state, exploreeGroup: group
          }).save();
        }
      }
    },

    showVoteStats: (function () {
      return this.get('exploree.yesCount') + this.get('exploree.noCount') + this.get('exploree.commentsCount');
    }).property('exploree.yesCount', 'exploree.noCount', 'exploree.commentsCount'),

    actions: {

      viewConnection: (0, _laterallyUtilsDeferredReceiveAction['default'])(function (connection) {
        var _this = this;

        return get(connection, 'conversation').then(function (conversation) {
          return _this.transitionToRoute('conversations.show', conversation);
        });
      }),

      selectGroup: function selectGroup(group) {
        this.createMembership({ group: group });
        return true;
      },

      hidExploree: function hidExploree() {
        if (this.get('state') !== 'hidden') {
          this.set('hidExploree', true);
          return true;
        }
      },

      connect: ensureHasAccess(function () {
        var _this2 = this;

        this.send('track', 'Clicked Connect/Share Job');
        if (!get(this, 'cannotConnect')) {
          if (!get(this, 'connection')) {
            var connection = this.store.createRecord('connection', {
              lawyerId: get(this, 'exploree.lawyerId'),
              campaign: get(this, 'group'),
              introducer: get(this, 'firmUser'),
              introduction: get(this, 'group.connectWithMessage') ? get(this, 'group.defaultMessage') : null
            });

            var promise = (0, _laterallyUtilsDeferredSendAction['default'])(this, 'explainConnectable', connection, [get(this, 'exploree')]);

            promise.then(function (connection) {
              set(_this2, 'connection', connection);
              connection.save();
            });
          }
        }
      }),

      save: ensureHasAccess(function () {
        if (this.createMembership({ state: 'saved' })) {
          this.send('track', 'Saved exploree');
        } else {
          this.send('track', 'Tried to save Exploree without an ExploreeGroup selected');
        }
      }),

      hide: ensureHasAccess(function () {
        if (this.createMembership({ state: 'hidden' })) {
          this.send('track', 'Hid exploree');
        } else {
          this.send('track', 'Tried to hide Exploree without an ExploreeGroup selected');
        }
      }),

      unsave: function unsave() {
        this.get('membership').destroyRecord();
        this.get('group').reload();
        this.send('track', 'Unsaved exploree');
      },

      unhide: function unhide() {
        this.get('membership').destroyRecord();
        this.send('track', 'Unhide exploree');
      }

    }

  });
});