define('laterally/routes/reset-password/change-password', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, params) {
      controller.set("perishableToken", params.perishable_token);
    },

    beforeModel: function beforeModel() {
      if (get(this, 'session.user')) {
        return this.transitionTo('settings.change_password');
      }
    },

    afterModel: (function () {}).track("On Change Password Page")

  });
});