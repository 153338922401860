define('laterally/controllers/jobs/index', ['exports', 'ember', 'laterally/utils/computed/store-find'], function (exports, _ember, _laterallyUtilsComputedStoreFind) {
  var Controller = _ember['default'].Controller;
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var oneWay = computed.oneWay;
  var alias = computed.alias;
  var moment = window.moment;
  exports['default'] = Controller.extend({
    needs: ['application'],

    queryParams: [{
      startTime: 'start',
      endTime: 'end',
      startExperience: 'experience_min',
      endExperience: 'experience_max',
      locationIds: 'locations',
      practiceAreaIds: 'practiceAreas'
    }, 'sort'],

    startTime: oneWay('minTime'),
    endTime: oneWay('maxTime'),
    startExperience: oneWay('minExperience'),
    endExperience: oneWay('maxExperience'),
    locationIds: [],
    practiceAreaIds: [],
    sort: '-jobs_count',

    isLoading: false,

    minTime: 'Q3-2014',
    maxTime: computed(function () {
      var end = moment().add(3, 'months');
      return end.format('[Q]Q-YYYY');
    }),

    minExperience: 1,
    maxExperience: 100,
    experienceThreshold: 10,

    regions: alias('controllers.application.regions'),
    practiceAreaCategories: alias('controllers.application.practiceAreaCategories'),

    allLocations: (0, _laterallyUtilsComputedStoreFind['default'])('location'),
    locationOptions: computed('allLocations.isFulfilled', function () {
      if (get(this, 'allLocations.isFulfilled')) {
        return get(this, 'allLocations').slice();
      } else {
        return [];
      }
    }),

    allPracticeAreaCategories: (0, _laterallyUtilsComputedStoreFind['default'])('practiceAreaCategory'),

    practiceAreaCategoryOptions: computed('allPracticeAreaCategories.isFulfilled', function () {
      if (get(this, 'allPracticeAreaCategories.isFulfilled')) {
        return get(this, 'allPracticeAreaCategories').slice();
      } else {
        return [];
      }
    }),

    practiceAreaOptions: computed('allPracticeAreaCategories.isFulfilled', function () {
      if (get(this, 'allPracticeAreaCategories.isFulfilled')) {
        return [].concat.apply([], get(this, 'allPracticeAreaCategories').mapBy('practiceAreas')).uniq();
      } else {
        return [];
      }
    }),

    initLocations: function initLocations() {
      var locationIds = get(this, 'locationIds');

      set(this, 'locations', get(this, 'locationOptions').filter(function (location) {
        return locationIds.contains(get(location, 'id'));
      }));
    },

    initPracticeAreas: function initPracticeAreas() {
      var practiceAreaIds = get(this, 'practiceAreaIds');

      set(this, 'practiceAreas', get(this, 'practiceAreaOptions').filter(function (practiceArea) {
        return practiceAreaIds.contains(get(practiceArea, 'id'));
      }));
    },

    useTimes: function useTimes(times) {
      set(this, 'startTime', times[0]);
      set(this, 'endTime', times[1]);
    },

    useExperiences: function useExperiences(experiences) {
      set(this, 'startExperience', experiences[0]);
      set(this, 'endExperience', experiences[1]);
    },

    useLocations: function useLocations(locations) {
      set(this, 'locations', locations);
      set(this, 'locationIds', locations.mapBy('id'));
    },

    usePracticeAreas: function usePracticeAreas(practiceAreas) {
      set(this, 'practiceAreas', practiceAreas);
      set(this, 'practiceAreaIds', practiceAreas.mapBy('id'));
    },

    dropLocation: function dropLocation(location) {
      get(this, 'locations').removeObject(location);
      get(this, 'locationIds').removeObject(get(location, 'id'));
    },

    dropPracticeArea: function dropPracticeArea(practiceArea) {
      get(this, 'practiceAreas').removeObject(practiceArea);
      get(this, 'practiceAreaIds').removeObject(get(practiceArea, 'id'));
    },

    filterOptions: computed(function () {
      return RSVP.all([get(this, 'allLocations'), get(this, 'allPracticeAreaCategories')]);
    })
  });
});