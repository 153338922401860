define('laterally/components/jobs-list', ['exports', 'ember', 'laterally/utils/computed/array-controller'], function (exports, _ember, _laterallyUtilsComputedArrayController) {
  exports['default'] = _ember['default'].Component.extend({

    classNameBindings: ['showOnPage:jobs-list'],

    lawyer: undefined,

    removable: false,
    reload: 'reload',
    hideSectionHeaders: false,
    showOnPage: _ember['default'].computed.or('showingOpen', 'hasData'),
    hasData: _ember['default'].computed.not('noData'),
    noData: _ember['default'].computed.empty('filteredFirms'),

    openDrawer: null,

    firms: (0, _laterallyUtilsComputedArrayController['default'])('lawyer.firms', 'jobs_list/firm'),
    statusFilter: 'open',

    showingOpen: (function () {
      return this.get('statusFilter') == 'open';
    }).property('statusFilter'),

    showingClosed: (function () {
      return this.get('statusFilter') == 'closed';
    }).property('statusFilter'),

    showingPassed: (function () {
      return this.get('statusFilter') == 'passed';
    }).property('statusFilter'),

    showingWithdrawn: (function () {
      return this.get('statusFilter') == 'withdrawn';
    }).property('statusFilter'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.callContinuously(this.updatePassConfirms);

      // HACK:
      // Reload firms to kill weird disappearing scrollbar bug on chrome osx
      if (this.get('hackScrollbar')) {
        if (this.get('firms.length')) {
          this.set('_firms', this.get('firms'));
          this.set('firms', []);

          _ember['default'].run.next(function () {
            _this.set('firms', _this.get('_firms'));
          });
        }
      }
    },

    nonOpenJobs: (function () {
      return this.get('closedCount') + this.get('passedCount') + this.get('withdrawnCount') > 0;
    }).property('closedCount', 'passedCount', 'withdrawnCount'),

    willDestroyElement: function willDestroyElement() {
      this.stopCalling();
    },

    callContinuously: function callContinuously(method) {
      method.call(this);

      this.set('runLater', _ember['default'].run.later(this, function () {
        method.call(this);
        this.callContinuously(method);
      }, 1000 * 60));
    },

    stopCalling: function stopCalling() {
      _ember['default'].run.cancel(this.get('runLater'));
    },

    // Update volatile CP that is dependent upon current moment()
    // TODO: Abstract run.later boilerplate into a helper?
    updatePassConfirms: function updatePassConfirms() {
      this.get('firms').forEach(function (firm) {
        firm.notifyPropertyChange('allPassConfirmed');

        firm.get('firmLocationJobs').forEach(function (firmLocationJob) {
          firmLocationJob.get('jobSelection').notifyPropertyChange('passConfirmed');
        });
      });
    },

    filteredFirms: (function () {
      var firms = this.get('firms').filter(function (firm) {
        return !!firm.get('model.name');
      });
      var status = this.get('statusFilter');

      var filtered = firms.filter(function (firm) {
        var fljc = firm.get('filteredFirmLocationJobs.length');
        var includeFirm = !!firm.get('filteredFirmLocationJobs').find(function (flj) {
          return flj.get('status') == status;
        });
        if (fljc == 0) {
          includeFirm = false;
        }
        return includeFirm;
      });
      return filtered;
    }).property('firms.@each.filteredFirmLocationJobs', 'firms.@each.filteredFirmLocationJobs.length', 'statusFilter'),

    openCount: (function () {
      return this.countStatus('open');
    }).property('firms.@each.firmLocationJobs.@each.status'),

    closedCount: (function () {
      return this.countStatus('closed');
    }).property('firms.@each.firmLocationJobs.@each.status'),

    passedCount: (function () {
      return this.countStatus('passed');
    }).property('firms.@each.firmLocationJobs.@each.status'),

    withdrawnCount: (function () {
      return this.countStatus('withdrawn');
    }).property('firms.@each.firmLocationJobs.@each.status'),

    countStatus: function countStatus(status) {
      var firms = this.get('firms');
      var count = 0;
      firms.forEach(function (firm) {
        if (firm.get('firmLocationJobs')) {
          firm.get('firmLocationJobs').forEach(function (flj) {
            if (flj.get('status') == status) {
              count++;
            }
          });
        }
      });
      return count;
    },

    actions: {
      reload: function reload() {
        this.sendAction('reload');
      }
    }

  });
});