define('laterally/views/possess', ['exports', 'ember'], function (exports, _ember) {

  var on = _ember['default'].on;
  var observer = _ember['default'].observer;

  exports['default'] = _ember['default'].View.extend({

    classNames: ['possess'],

    focusSearch: function focusSearch() {
      var element = this.$('input');
      if (element) {
        element.focus();
      }
    },

    focusAfterInsert: on('didInsertElement', function () {
      this.focusSearch();
    }),

    focusAfterClear: observer('controller.query', function () {
      if (!this.get('controller.query')) {
        this.focusSearch();
      }
    })

  });
});