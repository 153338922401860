define('laterally/components/tab-nav/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    tabs: null,

    actions: {
      selectTab: function selectTab(name) {
        set(this, 'tabs.selectedTab', name);
      }
    }
  });
});