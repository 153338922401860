define('laterally/controllers/selectable-practice-areas', ['exports', 'ember', 'laterally/utils/computed/group'], function (exports, _ember, _laterallyUtilsComputedGroup) {

  var sort = _ember['default'].computed.sort;
  var equal = _ember['default'].computed.equal;

  exports['default'] = _ember['default'].ArrayController.extend({
    // controller is rendered under ApplicationRoute,
    // so using action hashes won't be helpful
    onSave: null,

    sorted: sort('model', 'sorting'),
    sorting: ['name'],

    selections: _ember['default'].A(), // overwrite instance variable for instant updates

    matchAll: equal('match', 'all'),

    actions: {

      save: function save() {
        var onSave = this.get('onSave');

        if (onSave) {
          onSave(this.get('selections'), this.get('match'));
        }
      },

      modalClosed: function modalClosed() {
        this.send('save');
        return true;
      },

      setMatch: function setMatch(match) {
        this.set('match', match);
      }

    }
  });
});