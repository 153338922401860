define('laterally/components/experiences-popover/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['experiences-popover'],
    placement: computed(function () {
      var $el = this.$();
      var $scrollParent = this.$(window);
      var parentScroll = $scrollParent.scrollTop();
      var parentHeight = $scrollParent.height();

      var offset = $el.offset().top;

      if (offset - parentHeight < parentHeight / 2) {
        return 'bottom';
      } else {
        return 'top';
      }
    }),

    store: service(),

    exploree: null,
    isShowing: false,

    experiences: computed(function () {
      return get(this, 'store').findQuery('experience', {
        exploree_id: get(this, 'exploree.id')
      });
    }),

    mouseEnter: function mouseEnter() {
      set(this, 'isShowing', true);
    },

    mouseLeave: function mouseLeave() {
      set(this, 'isShowing', false);
    }
  });
});