define('laterally/models/job', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {

  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  var attr = _emberData['default'].attr;

  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _emberData['default'].Model.extend({

    name: attr('string'),
    description: attr('string'),
    startYear: attr('number'),
    endYear: attr('number'),
    createdAt: attr('date'),
    defunct: attr('boolean'),
    defunctAt: attr('moment'),
    work: attr('string'),
    culture: attr('string'),
    exitOpportunities: attr('string'),
    billables: attr('string'),
    salaryLow: attr('string'),
    salaryHigh: attr('string'),
    bonuses: attr('string'),
    yearsToPartner: attr('string'),
    externallyHosted: attr('boolean'),
    externalLink: attr('string'),
    employerName: attr('string'),
    displayLocation: attr('string'),
    isPublic: attr('boolean'),
    laterallyId: attr('string'),
    updatedAt: attr('moment'),

    jobSave: belongsTo('jobSave'),

    startYearYear: computed(function (key, value) {
      if (arguments.length > 1) {
        if (value) {
          set(this, 'startYear', new Date().getFullYear() - value);
        } else {
          set(this, 'startYear', value);
        }

        return value;
      } else {
        return new Date().getFullYear() - get(this, 'startYear');
      }
    }),

    endYearYear: computed(function (key, value) {
      if (arguments.length > 1) {
        if (value) {
          set(this, 'endYear', new Date().getFullYear() - value);
        } else {
          set(this, 'endYear', value);
        }

        return value;
      } else {
        return new Date().getFullYear() - get(this, 'endYear');
      }
    }),

    practiceAreas: hasMany('practiceArea'),
    firmLocationJobs: hasMany('firmLocationJob'),
    firmLocations: hasMany('firmLocation'),
    firmGroupMembers: hasMany('firmGroupMember', { async: true }),
    firmUser: belongsTo('firmUser'),
    firm: belongsTo('firm'),
    exploreeGroup: belongsTo('exploreeGroup'),

    visibleFirmGroupMembers: _ember['default'].computed.filter('firmGroupMembers', function (member, index, array) {
      return !member.get('_destroy');
    }),

    // No pressing need to use a ticker refresh here
    // since we're using large time intervals (one week)
    createdWithinPastWeek: (function () {
      var age = window.moment().diff(this.get('createdAt'), 'days');
      return age < 7;
    }).property('createdAt').volatile(),

    yearRequirement: (function () {
      var startYear = this.get('startYear');
      var endYear = this.get('endYear');
      var ret;

      if (startYear) {
        ret = startYear;
        if (startYear !== endYear) {
          ret += endYear ? '-' + endYear : '+';
        }
        ret += ' year' + (!endYear && startYear === 1 ? '' : 's');
        return ret;
      } else if (endYear) {
        ret = 'up to ';
        ret += endYear;
        ret += ' year' + (endYear === 1 ? '' : 's');
        return ret;
      }
    }).property('startYear', 'endYear'),

    locationNames: (function () {
      var firmLocations = this.get('firmLocations');
      return firmLocations.map(function (fl) {
        return fl.get('location.shortName');
      }).join(', ');
    }).property('firmLocations'),

    locationDisplayNames: (function () {
      var firmLocations = this.get('firmLocations');
      return firmLocations.map(function (fl) {
        return fl.get('location.displayName') || fl.get('location.shortName');
      }).join(', ');
    }).property('firmLocations')

  });
});