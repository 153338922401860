define('laterally/routes/explorees', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    lastCampaign: null,

    beforeModel: function beforeModel(transition) {
      var _this = this;

      this.session.get('user').then(function (user) {
        if (user) {
          if (user.get('isFirm') && !user.get('userable.firm.canExplore')) {
            var qp = transition.queryParams;
            _this.transitionTo('candidates', { queryParams: qp });
            return;
          }
        } else {
          _this.transitionTo('login');
        }
      });

      if (this.get('lastCampaign') && ['explorees.connect', 'explorees.connect.index'].indexOf(transition.targetName) === -1) {
        this.transitionTo('explorees.campaign', this.get('lastCampaign.id'));
      }
    },

    actions: {
      filterDidChange: function filterDidChange() {
        // no-op
      },

      createGroup: function createGroup(name, resolve, reject) {
        if (name) {
          this.store.createRecord('exploreeGroup', {
            name: name,
            firmUser: this.session.get('userable')
          }).save().then((function (group) {
            resolve(group);
            this.analytics.track('Created ExploreeGroup', {
              exploreeGroup: group.getProperties('id', 'name')
            });
          }).bind(this), reject);
        } else {
          reject();
        }
      },

      visitedCampaign: function visitedCampaign(campaign) {
        this.set('lastCampaign', campaign);
      },

      messageSentFromCampaign: function messageSentFromCampaign(campaign) {
        campaign.set('isLive', true);
        campaign.save();
      },

      goToExplore: function goToExplore(exploreeGroup) {
        this.transitionTo('explorees.campaign', exploreeGroup.get('id'));
      }
    }
  });
});