define('laterally/models/historical-job', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var moment = window.moment;
  exports['default'] = Model.extend({
    firm: belongsTo('historicalFirm'),

    name: attr('string'),
    description: attr('string'),
    locationNames: attr('array'),

    openedAt: attr('moment'),
    closedAt: attr('moment'),

    onMarketHumanized: computed('openedAt', 'closedAt', function () {
      return moment.duration((get(this, 'closedAt') || moment()).diff(get(this, 'openedAt'))).humanize();
    })
  });
});