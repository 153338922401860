define('laterally/components/focus-target/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  var RSVP = _ember['default'].RSVP;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['focus-target'],

    // attrs
    name: null,
    'on-focus': null,
    'on-focus-target': null,
    skipScroll: null,

    pulse: true,
    isPulsing: false,

    offset: 35,

    focusedDidChange: (function () {
      var _this = this;

      if (get(this, 'name') === get(this, 'focusser.name')) {
        var pulse = get(this, 'pulse');

        this.scrollToTarget().then(function () {
          if (pulse) {
            set(_this, 'isPulsing', true);

            run.later(function () {
              set(_this, 'isPulsing', false);
            }, 250);
          }
        });

        this.sendFocusToTarget();
      }
    }).observes('name', 'focusser.name'),

    scrollToTarget: function scrollToTarget() {
      var defer = RSVP.defer();
      var offset = get(this, 'offset');
      var scrollTop = this.$().offset().top - offset;

      if (this.isInView() || this.get('skipScroll')) {
        defer.resolve();
      } else {
        $('html, body').animate({ scrollTop: scrollTop }, 500, function () {
          defer.resolve();
        });
      }

      return defer.promise;
    },

    isInView: function isInView() {
      return $(window).scrollTop() + $(window).height() * 0.66 > this.$().offset().top;
    },

    sendFocusToTarget: function sendFocusToTarget() {
      var onFocus = get(this, 'on-focus');

      if (onFocus) {
        get(this, 'on-focus-target').send(onFocus);
      }
    }
  });
});