define('laterally/views/application/header', ['exports', 'ember'], function (exports, _ember) {
  var View = _ember['default'].View;
  exports['default'] = View.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().on('click', '.navbar-collapse.in', function (e) {
        if (_this.$(e.target).is('a')) {
          _this.$('.navbar-collapse.in').collapse('hide');
        }
      });
    }
  });
});