define('laterally/components/one-way-radio/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var on = _ember['default'].on;

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    type: 'radio',

    // attrs
    property: null,
    checkedWhen: null,

    attributeBindings: ['type', 'checked'],

    didInsertElement: (function () {
      set(this, 'checked', get(this, 'property') === get(this, 'checkedWhen'));
    }).observes('property'),

    checked: false,

    elementValueDidChange: on('change', function () {
      this.sendAction('on-change');
    })

  });
});