define('laterally/components/tokens-editable', ['exports', 'ember', 'laterally/components/concerns/editable'], function (exports, _ember, _laterallyComponentsConcernsEditable) {
  exports['default'] = _ember['default'].Component.extend(_laterallyComponentsConcernsEditable['default'], {

    classNames: ['tokens-editable'],

    focusInput: function focusInput() {
      this.$('[contenteditable]:last').focus();
    },

    actions: {

      add: function add() {
        // Use pushObject, not addObject otherwise it will not add the blank
        // string if another item is also blank.
        this.get('value').pushObject('');
        _ember['default'].run.scheduleOnce('afterRender', this, 'focusInput');
      },

      remove: function remove(item) {
        this.get('value').removeObject(item);
      }

    }

  });
});