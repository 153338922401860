define('laterally/components/external-job-row/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['historical-group'],
    classNameBindings: ['isCollapsed'],

    isCollapsed: true,
    saveJob: null,

    isSelected: _ember['default'].computed.not('isCollapsed'),

    actions: {
      toggleCollapsed: function toggleCollapsed() {
        var job = get(this, 'group');
        this.analytics.track('In-House Jobs: Expanded/Collapsed Job', { laterally_id: job.get('laterallyId'), job_title: job.get('name') });
        this.toggleProperty('isCollapsed');
      },

      trackApply: function trackApply(job) {
        this.analytics.track('In-House Jobs: Clicked Apply', { laterally_id: job.get('laterallyId'), job_title: job.get('name') });
        return true;
      },

      save: function save(job, delay) {
        this.sendAction('saveJob', job, delay);
      }
    }
  });
});