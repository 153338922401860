define('laterally/components/mailto-link/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'a',

    attributeBindings: ['href'],

    href: computed('email', function () {
      return 'mailto:' + get(this, 'email');
    }),

    // attrs
    email: null

  });
});