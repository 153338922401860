define("laterally/templates/jobs/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.4",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks,
            get = hooks.get,
            inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        inline(env, morph0, context, "jobs-history", [], { "userable": get(env, context, "session.userable"), "regions": get(env, context, "regions"), "practiceAreaCategories": get(env, context, "practiceAreaCategories"), "groups": get(env, context, "model"), "isLoading": get(env, context, "isLoading"), "minTime": get(env, context, "minTime"), "maxTime": get(env, context, "maxTime"), "minExperience": get(env, context, "minExperience"), "maxExperience": get(env, context, "maxExperience"), "experienceThreshold": get(env, context, "experienceThreshold"), "locationOptions": get(env, context, "locationOptions"), "practiceAreaOptions": get(env, context, "practiceAreaOptions"), "practiceAreaCategoryOptions": get(env, context, "practiceAreaCategoryOptions"), "startTime": get(env, context, "startTime"), "endTime": get(env, context, "endTime"), "startExperience": get(env, context, "startExperience"), "endExperience": get(env, context, "endExperience"), "locations": get(env, context, "locations"), "practiceAreas": get(env, context, "practiceAreas"), "sort": get(env, context, "sort"), "timeSelected": "timeSelected", "experienceSelected": "experienceSelected", "selectLocations": "selectLocations", "selectPracticeAreas": "selectPracticeAreas", "locationRemoved": "locationRemoved", "practiceAreaRemoved": "practiceAreaRemoved" });
        return fragment;
      }
    };
  })());
});