define('laterally/components/first-objects/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    objects: null,
    limit: null,

    firstObjects: computed('objects.[]', function () {
      return get(this, 'objects').toArray().slice(0, get(this, 'limit') || undefined);
    }),

    otherObjects: computed('objects.[]', function () {
      return get(this, 'objects').toArray().slice(get(this, 'limit') || 0);
    })
  });
});