define('laterally/models/historical-group', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    firm: belongsTo('historicalFirm', { async: false }),
    jobs: hasMany('historicalJob', { async: true }),
    jobsCount: attr('number'),
    jobsMarketTime: attr('number'),
    adjustedJobsMarketTime: attr('number')
  });
});