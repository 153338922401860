define('laterally/components/edit-pencil/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',
    classNames: ['edit-pencil-component'],

    focusNextInput: (function () {
      this.$().closest('.edit-pencil-scope').find('.input').focus();
    }).on('click')

  });
});