define('laterally/initializers/focus', ['exports'], function (exports) {
  exports['default'] = {
    name: 'focus',

    initialize: function initialize(container) {
      container.injection('service:focus', 'router', 'router:main');

      container.injection('route:application', 'focusser', 'service:focus');
      container.injection('component:focus-target', 'focusser', 'service:focus');
    }

  };
});