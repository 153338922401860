define('laterally/components/firms/index/firm-location-job/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Component.extend({

    tagName: '',

    // attrs
    firmLocationJob: null,
    selectedState: null,
    hideJob: null,

    lawyer: alias('session.userable'),
    confirmAdd: false,

    jobSelections: computed('lawyer.jobSelections.[]', function () {
      var lawyer = get(this, 'lawyer');
      if (!lawyer) {
        return [];
      }
      var firm = get(this, 'firmLocationJob.firmLocation.firm');
      return get(lawyer, 'jobSelections').filterBy('firmLocationJob.firmLocation.firm', firm);
    }),

    added: computed('jobSelections', function () {
      var firmLocationJob = get(this, 'firmLocationJob');
      return get(this, 'jobSelections').findBy('firmLocationJob', firmLocationJob);
    }),

    track: function track(action) {
      var firm = this.get('firmLocationJob.firmLocation.firm.name');
      var job = get(this, 'firmLocationJob.job.name');
      this.get('analytics').track(action + ' job', { job: job, firm: firm });
    },

    createJobSelection: function createJobSelection(hiddenFromLawyers) {
      if (!get(this, 'added')) {
        this.track(hiddenFromLawyers ? 'Hid' : 'Added');
        var firmLocationJob = get(this, 'firmLocationJob');
        return get(this, 'lawyer.jobSelections').createRecord({
          firmLocationJob: firmLocationJob, hiddenFromLawyers: hiddenFromLawyers
        }).save();
      }
    },

    hidden: computed('added.hiddenFromLawyers', function () {
      var added = get(this, 'added');
      var hiddenSelection = added && get(added, 'hiddenFromLawyers');
      return !!(get(this, 'selectedState') === 'hidden' ^ hiddenSelection);
    }),

    actions: {

      save: function save() {
        this.createJobSelection(false);
      },

      hide: function hide() {
        this.createJobSelection(true);
        this.sendAction('hideJob', get(this, 'firmLocationJob'));
      },

      remove: function remove() {
        var record = get(this, 'added');
        if (record) {
          this.track(get(record, 'hiddenFromLawyers') ? 'Unhid' : 'Unadded');
          record.destroyRecord();
        }
      }

    }

  });
});