define('laterally/components/profile-picture-editable', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    added: null,

    classNames: ['profile-picture-editable'],

    uploading: false,

    style: "50x50",

    editable: false,

    buttonClassNames: "btn btn-file btn-link",
    buttonIconClassNames: "fa fa-camera fa-lg",

    openOnce: false,

    openOnceDidChange: (function () {
      if (this.get('openOnce')) {
        this.$('input[type=file]').click();
      }
    }).observes('openOnce'),

    actions: {
      addedFile: function addedFile() {
        this.sendAction('added');
      }
    }
  });
});