define('laterally/components/profit-partner/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',
    classNames: ['profit-partner'],

    profitPerPartner: null,

    displayProfit: (function () {
      var profit = get(this, 'profitPerPartner');
      if (profit) {
        return "$" + profit + "m";
      } else {
        return "–";
      }
    }).property('profitPerPartner')

  });
});