define('laterally/models/explore-vote', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    value: _emberData['default'].attr('string'),
    exploreeGroupShareToken: _emberData['default'].attr('string'),

    exploree: _emberData['default'].belongsTo('exploree'),
    exploreVoter: _emberData['default'].belongsTo('exploreVoter'),

    isYes: (function () {
      return this.get('value') == 'yes';
    }).property('value'),

    isNo: (function () {
      return this.get('value') == 'no';
    }).property('value')
  });
});