define('laterally/initializers/array', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var get = _ember['default'].get;

  var methods = {

    toSentence: function toSentence() {
      var length = get(this, 'length');
      switch (length) {
        case 0:
          return '';
        case 1:
          return '' + this.objectAt(0);
        case 2:
          return '' + this.objectAt(0) + ' and ' + this.objectAt(1);
        case 3:
          return this.slice(0, -1).join(', ') + ', and ' + this.objectAt(length - 1);
      }
    },

    inGroups: function inGroups(number) {
      var groups = [];
      var size = Math.ceil(get(this, 'length') / number);
      for (var i = 0; i < number; i++) {
        groups.push(this.slice(i * size, (i + 1) * size));
      }
      return groups;
    },

    group: function group(callback) {
      var groups = {};
      var key, item;
      for (var i = 0, len = get(this, 'length'); i < len; i++) {
        item = this.objectAt(i);
        key = callback(item);
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(item);
      }
      return groups;
    },

    groupBy: function groupBy(key) {
      return this.group(function (item) {
        return get(item, key);
      });
    },

    last: function last() {
      return this.objectAt(get(this, 'length') - 1);
    },

    maxBy: function maxBy(property) {
      return Math.max.apply(Math, _toConsumableArray(this.mapBy(property)));
    },

    minBy: function minBy(property) {
      return Math.min.apply(Math, _toConsumableArray(this.mapBy(property)));
    }

  };

  exports['default'] = {
    name: 'array',
    initialize: function initialize() {
      _ember['default'].Array.reopen(methods);
      _ember['default'].merge(Array.prototype, methods);
    }
  };
});