define('laterally/components/range-editable', ['exports', 'ember'], function (exports, _ember) {

  var FromRangeOption = _ember['default'].Object.extend({
    disabled: (function () {
      var toValue = this.get('rangeEditable.toValue');
      return !_ember['default'].isNone(toValue) && this.get('value') > toValue;
    }).property('rangeEditable.toValue')
  });

  var ToRangeOption = _ember['default'].Object.extend({
    disabled: (function () {
      var fromValue = this.get('rangeEditable.fromValue');
      return !_ember['default'].isNone(fromValue) && this.get('value') < fromValue;
    }).property('rangeEditable.fromValue')
  });

  function contentProperty(klass) {
    return (function () {
      var arr = [];
      for (var i = this.get('startAt'), end = this.get('endAt'); i <= end; i++) {
        arr.push(klass.create({
          value: i,
          rangeEditable: this
        }));
      }
      return arr;
    }).property('startAt', 'endAt');
  }

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['range-editable', 'editable'],

    fromLabel: 'From',
    toLabel: 'To',

    fromContent: contentProperty(FromRangeOption),
    toContent: contentProperty(ToRangeOption)

  });
});