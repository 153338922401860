define('laterally/models/invited-user', ['exports', 'ember-data'], function (exports, _emberData) {

  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  exports['default'] = _emberData['default'].Model.extend({
    inviteToken: belongsTo('inviteToken'),
    agreed: attr('boolean', { defaultValue: false }),

    email: attr('string'),
    password: attr('string')
  });
});