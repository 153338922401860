define('laterally/routes/connections/index', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  exports['default'] = Route.extend({

    model: function model() {
      return this.store.find('connection');
    },

    afterModel: function afterModel(connection) {
      return get(connection, 'job');
    }
  });
});