define('laterally/views/application/pdf-modal', ['exports', 'ember', 'laterally/views/concerns/modal'], function (exports, _ember, _laterallyViewsConcernsModal) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].View.extend(_laterallyViewsConcernsModal['default'], {
    layoutName: "application/pdf_modal_layout",
    height: (function () {
      return "height: " + ($(window).height() - 160) + "px;";
    }).property()
  });
});