define('laterally/models/candidate', ['exports', 'ember', 'ember-data', 'laterally/models/concerns/stateful', 'laterally/utils/rest-action'], function (exports, _ember, _emberData, _laterallyModelsConcernsStateful, _laterallyUtilsRestAction) {
  exports['default'] = _emberData['default'].Model.extend(_laterallyModelsConcernsStateful['default'], {

    lawyer: _emberData['default'].belongsTo('lawyer'),
    firm: _emberData['default'].belongsTo('firm'),
    madeAvailableAt: _emberData['default'].attr('date'),
    madeAvailableAtString: (function () {
      var date = this.get("madeAvailableAt");
      if (!date) {
        return '';
      }

      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var time = date.toTimeString().split(":");
      var timezone = time.pop();
      time = time.join(".");
      timezone = timezone.split(" ").slice(1).join(" ");

      return [month, day, year].join("/") + " " + [time, timezone].join(" ");
    }).property('madeAvailableAt'),

    featured: _emberData['default'].attr('boolean'),
    saved: _ember['default'].computed.alias("steps.SAVED"),

    save: (0, _laterallyUtilsRestAction['default'])('save'),
    unsave: (0, _laterallyUtilsRestAction['default'])('unsave'),
    hide: (0, _laterallyUtilsRestAction['default'])('hide'),
    unhide: (0, _laterallyUtilsRestAction['default'])('unhide')

  }).reopenClass({
    STEPS: { WAITING: 0, PROPOSED: 1, SAVED: 2, HIDDEN: 3 },
    STATES: ['WAITING', 'PROPOSED', 'SAVED', 'HIDDEN']
  });
});