define('laterally/routes/concerns/attrs', ['exports', 'ember'], function (exports, _ember) {

  var hash = _ember['default'].RSVP.hash;

  exports['default'] = _ember['default'].Mixin.create({

    model: function model(params, transition) {
      var parent = {};
      if (transition.resolveIndex > 0) {
        var infos = transition.state.handlerInfos;
        parent = infos[transition.resolveIndex - 1].context;
      }
      var attrs = this.attrs ? this.attrs(params) : parent;
      return hash(attrs);
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    }

  });
});