define('laterally/controllers/conversations/show/message', ['exports', 'ember'], function (exports, _ember) {

  var gt = _ember['default'].computed.gt;
  var and = _ember['default'].computed.and;

  exports['default'] = _ember['default'].Controller.extend({

    isDeleteable: and('enoughTime', 'isCurrentUser'),

    enoughTime: gt('timeRemaining', 0),

    isCurrentUser: (function () {
      return this.get('session.userable') === this.get('model.sender');
    }).property('session.userable', 'model.sender'),

    timeRemaining: (function () {
      var deadline = this.get('model.deleteableUntil').unix();
      return (deadline - window.moment().unix()) * 1000;
    }).property('model.deleteableUntil').volatile(),

    updateIsDeletable: (function () {
      if (this.get('isDeleteable')) {
        _ember['default'].run.later(this, function () {
          this.notifyPropertyChange('timeRemaining');
        }, this.get('timeRemaining'));
      }
    }).on('init')

  });
});