define('laterally/controllers/april-fools', ['exports', 'ember'], function (exports, _ember) {

  var equal = _ember['default'].computed.equal;

  exports['default'] = _ember['default'].Controller.extend({

    tab: 'lunch-budget',

    tabLunchBudget: equal('tab', 'lunch-budget'),
    tabSummerHomes: equal('tab', 'summer-homes'),
    tabSnackScore: equal('tab', 'snack-score')

  });
});