define('laterally/utils/deferred-send-action', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;

  exports['default'] = function (context, action) {
    var deferred = RSVP.defer();

    for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      args[_key - 2] = arguments[_key];
    }

    args.unshift(action);
    args.push(deferred);

    if (context.sendAction) {
      context.sendAction.apply(context, args);
    } else {
      context.send.apply(context, args);
    }

    return deferred.promise;
  };
});