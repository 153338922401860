define('laterally/controllers/reset-password/form', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var $ = _ember['default'].$;
  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Controller.extend({

    email: null,

    actions: {

      submit: function submit() {
        var _this = this;

        var email = get(this, 'email');

        this.get('analytics').track('Attempted to reset password', { email: email });

        $.post(_laterallyConfigEnvironment['default'].APP.API_HOST + '/api/user_passwords/reset', { email: email }, function (response) {
          _this.handleSubmit(response);
        });
      }

    },

    handleSubmit: (function () {
      this.transitionToRoute("reset_password.thanks");
    }).track("Resetting Password", function (response) {
      return response;
    })

  });
});