define('laterally/controllers/connections/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  var filterBy = computed.filterBy;
  var sort = computed.sort;
  exports['default'] = _ember['default'].Controller.extend({
    lawyer: alias('session.user.userable'),
    connections: alias('model'),

    connectionSorting: ['createdAtUnix'],
    sortedConnections: sort('connections', 'connectionSorting'),

    archivedConnections: filterBy('sortedConnections', 'archived', true),
    unarchivedConnections: filterBy('sortedConnections', 'archived', false)
  });
});