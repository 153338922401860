define('laterally/controllers/application/header', ['exports', 'ember', 'laterally/config/environment', 'laterally/utils/computed/store-locally', 'laterally/utils/deferred-receive-action'], function (exports, _ember, _laterallyConfigEnvironment, _laterallyUtilsComputedStoreLocally, _laterallyUtilsDeferredReceiveAction) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  var equal = computed.equal;

  exports['default'] = _ember['default'].Controller.extend({

    needs: ['application', 'campaigns'],
    recentCampaigns: alias('controllers.campaigns.model'),

    oldAdminURL: _laterallyConfigEnvironment['default'].APP.ADMIN_V1_URL,

    currentUser: alias('session.user'),
    currentRouteName: alias('controllers.application.currentRouteName'),
    showMenu: alias('currentUser.userable.steps.PENDING'),
    isLawyer: alias('currentUser.isLawyer'),
    steps: alias("currentUser.userable.steps"),

    onSignInPage: equal('controllers.application.currentRouteName', 'login'),
    onLandingPage: computed('controllers.application.currentRouteName', function () {
      return ['landing.index', 'landing-move-tracker.index'].includes(get(this, 'controllers.application.currentRouteName'));
    }),

    showBonusMessage: false,
    showBonusMessageNotification: (0, _laterallyUtilsComputedStoreLocally['default'])('header.showBonusMessageNotification', true),

    showMessages: alias('steps.APPROVED'),
    showNav: true,

    showDirections: (function () {
      var path = this.get('currentRouteName');
      return this.get('isLawyer') && !this.get('showMenu') && (path.indexOf('firms.') === 0 || path === 'status' || path.indexOf('lawyer.') === 0);
    }).property('showMenu', 'currentRouteName'),

    showMenuOrDraftBar: (function () {
      var onApplyPath = this.get("currentRouteName") === "join.apply";
      return this.get("isLawyer") && !onApplyPath;
    }).property('currentRouteName', 'isLawyer'),

    navbarStyles: (function () {
      var route = this.get('currentRouteName');

      if (route.indexOf('landing') === 0) {
        return 'navbar-default navbar-bright';
      } else if (route === 'employer') {
        return 'navbar-default navbar-muted';
      } else {
        return 'navbar-inverse';
      }

      return route.indexOf('landing') !== 0 && route !== 'employer';
    }).property('currentRouteName'),

    canPossess: alias('session.canPossess'),

    unreadTotal: (function () {
      var conversations = this.get('currentUser.conversations');

      if (conversations) {
        return conversations.filter(function (conversation) {
          return conversation.get('unreadCount') > 0;
        }).get('length');
      }

      return 0;
    }).property('currentUser.conversations.@each.unreadCount'),

    actions: {

      toggleBonusMessage: function toggleBonusMessage() {
        this.toggleProperty('showBonusMessage');
        this.set('showBonusMessageNotification', false);
      },

      createCampaign: (0, _laterallyUtilsDeferredReceiveAction['default'])(function (name) {
        var _this = this;

        return this.store.createRecord('exploreeGroup', {
          name: name,
          firmUser: this.session.get('userable')
        }).save().then(function (group) {
          _this.analytics.track('Created ExploreeGroup', {
            exploreeGroup: group.getProperties('id', 'name')
          });

          get(_this, 'recentCampaigns').unshiftObject(group);
          _this.transitionToRoute('explorees.campaign', get(group, 'id'));
        });
      })

    }

  });
});