define('laterally/controllers/firms/selected', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({

    needs: ['firms'],

    userable: alias('controllers.firms.userable')

  });
});