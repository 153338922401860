define('laterally/routes/application', ['exports', 'ember', 'laterally/mixins/timeoutable', 'laterally/config/environment'], function (exports, _ember, _laterallyMixinsTimeoutable, _laterallyConfigEnvironment) {
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Route.extend(_laterallyMixinsTimeoutable.RouteMixin, {

    queryParams: {
      accessToken: { replace: true }
    },

    beforeModel: function beforeModel(transition) {
      var _this = this;

      if (transition.targetName === 'logout') {
        return null;
      } else {
        return this.session.useTokenValue(transition.queryParams.access_token).then(function () {
          return get(_this.session, 'user');
        });
      }
    },

    renderTemplate: function renderTemplate() {
      this.render();
      this.render('application/header', {
        into: 'application',
        outlet: 'header'
      });
      this.render('application/footer', {
        into: 'application',
        outlet: 'footer'
      });
    },

    openModal: function openModal(name, options) {
      if (!options) {
        options = {};
      }
      // The true argument specifies not to throw an error if a controller
      // could not be found for 'name'.
      var controller = this.controllerFor(name, true);
      if (!controller) {
        controller = this.generateController(name, options.model);
      }
      controller.setProperties(options);
      this.render(name, {
        into: 'application',
        outlet: 'modal',
        controller: controller
      });
    },

    actions: {

      flash: function flash(message) {
        var controller = this.controllerFor('application');
        controller.set('flashMessage', message);

        run.later(function () {
          controller.set('flashMessage', null);
        }, 5000);
      },

      willRenewSession: function willRenewSession() {
        var _this2 = this;

        this.session.get('user').then(function (user) {
          _this2.controllerFor('authenticated').setProperties({
            willRenewSession: true,
            newToken: _this2.store.createRecord('accessToken', {
              userEmail: get(user, 'email')
            })
          });
        });
      },

      openPossessModal: function openPossessModal() {
        if (get(this, 'session.canPossess')) {
          this.send('openModal', 'possess');
        }
      },

      modalClosed: function modalClosed() {
        this.send('disconnectModal');
      },

      logIn: function logIn(token) {
        var _this3 = this;

        var session = this.session;

        session.open(token).then(function () {
          _this3.transitionTo('authenticated');
        });
      },

      logOut: function logOut(always) {
        return this.transitionTo('logout').then(always, always);
      },

      /*
        'openModal' is used to display modals. It renders the view into the
        'modal' outlet in 'application.hbs' template. The first parameter
        defines the name of the template and controller. If a controller
        does not exist under the name, one will be generated for you. The
        second param specifies properties that should be defined on the
        controller. To specify the content of the controller, you must pass
        a 'model' in the second parameter, not 'content'.
      */
      openModal: function openModal(name, options) {
        this.openModal(name, options);
      },

      closeModal: function closeModal() {
        var view = this.get('lastModalView');
        if (view) {
          view.send('close');
        }
      },

      disconnectModal: function disconnectModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },

      userSignedUp: function userSignedUp(user, employer, credentials) {
        var _this4 = this;

        this.session.openWithCredentials(credentials).then(function () {
          _this4.analytics.track('User Signed Up');
          var route = 'onboarding.';
          route += employer ? 'employers' : 'lawyers';
          _this4.transitionTo(route);
        });
      },

      userLoggedIn: function userLoggedIn(accessToken, failureCb) {
        var _this5 = this;

        this.session.open(accessToken).then(function () {
          _this5.transitionTo('profile');
        })['catch'](function () {
          failureCb();
        });
      },

      focusOn: function focusOn(focusTargetName, routeName, routeParams) {
        get(this, 'focusser').focusOn(focusTargetName, routeName, routeParams);
      },

      renderReferredModal: function renderReferredModal() {
        var referralToken = this.referralManager.get('token');

        if (referralToken) {
          // Have to run this 'next' otherwise it doesn't work.
          _ember['default'].run.next(this, 'openModal', 'application/referredModal', {
            referralToken: referralToken
          });
        }
      },

      afterSessionExpiry: function afterSessionExpiry() {
        this.send('flash', "Your session has expired");
        this.transitionTo('login');
      },

      error: function error(_error, transition) {
        if (_laterallyConfigEnvironment['default'].environment === 'development') {
          console.log(_error);
          console.log(_error.stack);
        }
      },

      filterDidChange: function filterDidChange() {
        // no-op
      }

    }

  });
});