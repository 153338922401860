define('laterally/components/radio-button-element/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var on = _ember['default'].on;
  var observer = _ember['default'].observer;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',

    classNames: ['radio-button-element'],
    classNameBindings: ['checked:is-checked:is-unchecked'],

    // attrs
    checked: false,

    elementValueChanged: on('change', function () {
      set(this, 'checked', this.$('input').prop('checked'));
    }),

    componentValueChanged: observer('checked', function () {
      this.$('input').prop('checked', get(this, 'checked'));
    })

  });
});