define('laterally/helpers/pretty-number', ['exports', 'ember'], function (exports, _ember) {

  var htmlSafe = _ember['default'].String.htmlSafe;

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(function (value) {
    if (value) {
      value = value.toString();
      value = value.replace(/,/g, '');
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return htmlSafe(value);
    }
  });
});