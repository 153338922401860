define('laterally/routes/conversations/show', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      var _this = this;

      return this.store.find('conversation', params.conversation_id)['catch'](function (error) {
        if (error.status == 404) {
          _this.transitionTo('conversations.index');
          return;
        }
      });
    }

  });
});