define('laterally/models/concerns/attachment', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Mixin.create({

    deleteAttachment: function deleteAttachment(name, session) {
      return $.ajax({
        type: "POST",
        url: _laterallyConfigEnvironment['default'].APP.API_HOST + '/api/lawyers/' + this.get('id') + '/delete_' + name.underscore(),
        headers: this.ajaxHeaders(session) }).then((function () {
        this.uploadedAttachment(name, null);
      }).bind(this));
    },

    uploadedAttachment: function uploadedAttachment(name, data) {
      var model = this.constructor;
      var attrs = { id: this.get('id') };
      var key = this.store.serializerFor(model).keyForAttribute(name);
      attrs[_ember['default'].String.camelize(key)] = data;
      this.store.update(model, attrs);
    },

    ajaxHeaders: function ajaxHeaders(session) {
      var headers = {};
      headers['Authorization'] = ['Bearer', session.get('token.value')].join(' ');

      return headers;
    }

  });
});