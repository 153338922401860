define('laterally/components/move-tracker/explanation/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    showExplanation: false,

    actions: {
      toggleShowExplanation: function toggleShowExplanation() {
        this.toggleProperty('showExplanation');
      }
    }
  });
});