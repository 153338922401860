define('laterally/controllers/lawyer/index/locations', ['exports', 'ember', 'laterally/controllers/lawyer/index/selections'], function (exports, _ember, _laterallyControllersLawyerIndexSelections) {

  var alias = _ember['default'].computed.alias;

  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Controller.extend(_laterallyControllersLawyerIndexSelections['default'], {

    selections: alias('session.userable.desiredLocations'),

    regions: alias('controllers.application.regions'),

    actions: {
      saveAndReload: function saveAndReload() {
        var _this = this;

        var def = _ember['default'].RSVP.defer();

        this.send('save', def);

        def.promise.then(function () {
          _this.send('reload');
        });
      }
    }

  });
});