define('laterally/routes/landing', ['exports', 'ember'], function (exports, _ember) {
  var RSVP = _ember['default'].RSVP;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      if (get(this.session, 'user') && !get(this.session, 'user.isDeveloper')) {
        this.transitionTo('profile');
      }
    },

    model: function model() {
      var _this = this;

      return this.store.find('featuredJob').then(function (jobs) {
        return RSVP.hash({
          inHouseJobs: jobs.filterBy('externallyHosted', true).slice(0, 7),
          lawFirmJobs: jobs.filterBy('externallyHosted', false).slice(0, 7),
          moves: _this.store.findQuery('move-summary', { limit: 7 })
        });
      });
    },

    goToSignupOrJobsPage: function goToSignupOrJobsPage(externallyHosted, useSignupModal) {
      if (get(this.session, 'user')) {
        if (externallyHosted) {
          this.transitionTo('external-jobs');
        } else {
          this.transitionTo('firms');
        }
      } else {
        if (useSignupModal) {
          this.showSignupModal();
        } else {
          this.transitionTo('sign-up');
        }
      }
    },

    goToSignupOrMovesPage: function goToSignupOrMovesPage() {
      if (get(this.session, 'user')) {
        this.transitionTo('move-tracker');
      } else {
        this.showSignupModal(true);
      }
    },

    showSignupModal: function showSignupModal() {
      var forMoveTracker = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      setProperties(this.controller, {
        shouldAuthenticate: true,
        token: this.session.prepareToken(),
        user: this.store.createRecord('user'),
        forMoveTracker: forMoveTracker
      });
    },

    actions: {
      readPledge: function readPledge() {
        this.analytics.track('Clicked Read Our Pledge button');
        set(this.controller, 'willReadPledge', true);
      },

      signUp: function signUp() {
        this.analytics.track('Clicked Sign Up');
        this.goToSignupOrJobsPage(null, false);
      },

      seeJob: function seeJob(job) {
        this.analytics.track('Clicked on featured job', { jobId: get(job, 'id') });
        this.goToSignupOrJobsPage(get(job, 'externallyHosted'), true);
      },

      seeMove: function seeMove(move) {
        this.analytics.track('Clicked on firm moves', { firmId: get(move, 'firm.idid'), firmName: get(move, 'firmName') });
        this.goToSignupOrMovesPage();
      },

      seeMoreJobs: function seeMoreJobs(externallyHosted) {
        if (externallyHosted) {
          this.analytics.track('Clicked View All In-House Jobs');
        } else {
          this.analytics.track('Clicked View All Law Firm Jobs');
        }

        this.goToSignupOrJobsPage(externallyHosted, true);
      },

      seeMoreMoves: function seeMoreMoves() {
        this.analytics.track('Clicked View All Moves');
        this.goToSignupOrMovesPage();
      }
    }

  });
});