define('laterally/routes/firm-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    afterModel: (function (model) {
      var user = this.session.get('user');
      if (user) {
        var userable = user.get('userable');
        if (userable !== model && !user.get('isDeveloper')) {
          this.transitionTo('profile');
        }

        if (userable.get('firm.canExplore')) {
          this.transitionTo('explorees');
        } else {
          this.transitionTo('candidates');
        }
      } else {
        this.transitionTo('login');
      }
    }).track("Firm::Profile")

  });
});