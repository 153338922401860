define('laterally/components/cards-list/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['cards-list-component'],

    grouper: function grouper(object) {
      if (get(object, 'isClosed')) {
        return 'Closed Searches';
      } else if (get(object, 'isLive')) {
        return 'Live Searches';
      } else {
        return 'Open Searches';
      }
    },

    groupSorter: function groupSorter(a, b) {
      var keys = ['Live Searches', 'Open Searches', 'Closed Searches'];

      return keys.indexOf(a) > keys.indexOf(b) ? 1 : -1;
    },

    grouperDependentKeys: ['isLive', 'isClosed']
  });
});