define('laterally/controllers/explorees/save-all', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;
  var equal = _ember['default'].computed.equal;
  var gt = _ember['default'].computed.gt;

  exports['default'] = _ember['default'].Controller.extend({

    needs: ['explorees/campaign'],

    state: 'unsaved',
    stateUnsaved: equal('state', 'unsaved'),
    stateSaving: equal('state', 'saving'),
    stateSaved: equal('state', 'saved'),

    group: alias('controllers.explorees/campaign.group'),
    results: alias('controllers.explorees/campaign.model'),
    open: false,

    total: alias('controllers.explorees/campaign.total'),
    overLimit: gt('total', 500),
    needsConfirm: gt('total', 10),
    confirmed: false,

    confirm: (function () {
      return !this.get('confirmed') && this.get('needsConfirm');
    }).property('needsConfirm', 'confirmed'),

    stateResetter: (function () {
      this.set('state', 'unsaved');
    }).observes('controllers.explorees/campaign.model'),

    confirmedResetter: (function () {
      if (!this.get('open')) {
        this.set('confirmed', false);
      }
    }).observes('open'),

    saveAll: function saveAll(group) {
      this.send('close');
      this.set('state', 'saving');
      var explorees = this.get('controllers.explorees/campaign');
      var params = explorees.get('filters');
      params = explorees.getProperties(params);
      return group.saveAll({ params: params }).then((function () {
        this.set('state', 'saved');
      }).bind(this));
    },

    track: function track(message) {
      var group = this.get('controllers.explorees/campaign.group');
      this.analytics.track(message, {
        exploreeGroup: group.getProperties('id', 'name')
      });
    },

    actions: {

      saveAll: function saveAll() {
        if (this.get('overLimit')) {
          this.set('open', true);
          this.track('Tried to "Save All" too many explorees');
        } else if (!this.get('group')) {
          this.set('open', true);
          this.track('Tried to "Save All" without an ExploreeGroup selected');
        } else if (this.get('confirm')) {
          this.set('open', true);
          this.track('Opened confirm dialog for "Save All"');
        } else {
          this.saveAll(this.get('group'));
          this.track('Saved All');
        }
      },

      confirm: function confirm() {
        this.set('confirmed', true);
        this.send('saveAll');
      },

      selectGroup: function selectGroup(group) {
        this.saveAll(group).then((function () {
          this.get('target').send('selectGroup', group);
        }).bind(this));
      },

      close: function close() {
        this.set('open', false);
      }

    }

  });
});