define('laterally/controllers/move-tracker/index', ['exports', 'ember', 'laterally/controllers/concerns/paged', 'laterally/utils/computed/store-find', 'laterally/mixins/inaccessible'], function (exports, _ember, _laterallyControllersConcernsPaged, _laterallyUtilsComputedStoreFind, _laterallyMixinsInaccessible) {
  var RSVP = _ember['default'].RSVP;
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var oneWay = _Ember$computed.oneWay;
  var alias = _Ember$computed.alias;
  var equal = _Ember$computed.equal;
  var or = _Ember$computed.or;
  var bool = _Ember$computed.bool;
  var moment = window.moment;
  var _ = window._;
  exports['default'] = _ember['default'].Controller.extend(_laterallyMixinsInaccessible['default'], _laterallyControllersConcernsPaged['default'], {
    needs: ['application'],

    queryParams: ['category', 'sort', {
      firmId: 'firm',
      locationId: 'location',
      practiceAreaIds: 'practiceAreas',
      experienceMin: 'experience_min',
      experienceMax: 'experience_max',
      startTime: 'start',
      endTime: 'end'
    }],

    version: null,
    isInHouseVersion: equal('version', 'inHouse'),

    _defaultTab: null,
    defaultTab: computed(function (key, value) {
      if (arguments.length > 1) {
        set(this, '_defaultTab', value);
        return value;
      } else {
        return get(this, '_defaultTab') || (get(this, 'isInHouseVersion') ? 'arrivals' : 'arrivals');
      }
    }),

    nonFirmFilterEngaged: or('locationId', 'practiceAreaIds.length', 'experienceMin', 'experienceMax', 'startTime', 'endTime'),
    locationFilterEngaged: bool('locationId'),

    category: null,

    perPage: 20,
    sort: '-ppp',
    expandedFirm: null,

    firmId: null,
    locationId: null,
    practiceAreaIds: [],

    location: null,
    practiceAreas: null,

    experienceMin: null,
    experienceMax: null,

    experienceRange: computed(function (key, value) {
      if (arguments.length > 1) {
        if (value) {
          set(this, 'experienceMin', value.split(',')[0]);
          set(this, 'experienceMax', value.split(',')[1]);
        } else {
          set(this, 'experienceMin', null);
          set(this, 'experienceMax', null);
        }

        return value;
      } else {
        if (get(this, 'experienceMin') || get(this, 'experienceMax')) {
          return [get(this, 'experienceMin'), get(this, 'experienceMax')].join(',');
        } else {
          return null;
        }
      }
    }),

    categoryOptions: computed(function () {
      return [{
        value: 'firm',
        label: 'Firm <-> Firm'
      }, {
        value: 'inhouse',
        label: 'Firm <-> In-House'
      }, {
        value: 'government',
        label: 'Firm <-> Government'
      }, {
        value: 'clerkship',
        label: 'Firm <-> Clerkships'
      }, {
        value: 'other',
        label: 'Firm <-> Other'
      }];
    }),

    categoryDepartureRateKey: computed('category', function () {
      if (get(this, 'category')) {
        return [get(this, 'category').replace('inhouse', 'in_house'), 'departure_rate'].join('_');
      }
    }),

    regions: alias('controllers.application.regions'),
    practiceAreaCategories: alias('controllers.application.practiceAreaCategories'),

    initLocations: function initLocations() {
      set(this, 'locations', get(this, 'locationOptions').findBy('id', get(this, 'locationId')));
    },

    initPracticeAreas: function initPracticeAreas() {
      var practiceAreaIds = get(this, 'practiceAreaIds');

      set(this, 'practiceAreas', get(this, 'practiceAreaOptions').filter(function (practiceArea) {
        return practiceAreaIds.contains(get(practiceArea, 'id'));
      }));
    },

    allFirms: (0, _laterallyUtilsComputedStoreFind['default'])('firm', {
      move_trackable: true
    }),

    firmOptions: computed('allFirms.isFulfilled', function () {
      if (get(this, 'allFirms.isFulfilled')) {
        var sliced = get(this, 'allFirms').filterBy('category', 'law_firm');
        sliced.unshiftObject({ id: null, name: null });

        return sliced;
      } else {
        return [];
      }
    }),

    companyOptions: computed('allFirms.isFulfilled', function () {
      if (get(this, 'allFirms.isFulfilled')) {
        var sliced = get(this, 'allFirms').filterBy('category', 'company');
        sliced.unshiftObject({ id: null, name: null });

        return sliced;
      } else {
        return [];
      }
    }),

    allLocations: (0, _laterallyUtilsComputedStoreFind['default'])('location', {
      with_explorees: true
    }),

    locationOptions: computed('allLocations.isFulfilled', function () {
      if (get(this, 'allLocations.isFulfilled')) {
        return get(this, 'allLocations').slice().map(function (location) {
          return getProperties(location, ['id', 'name']);
        });
      } else {
        return [];
      }
    }),

    allPracticeAreaCategories: (0, _laterallyUtilsComputedStoreFind['default'])('practiceAreaCategory'),

    practiceAreaCategoryOptions: computed('allPracticeAreaCategories.isFulfilled', function () {
      if (get(this, 'allPracticeAreaCategories.isFulfilled')) {
        return get(this, 'allPracticeAreaCategories').slice();
      } else {
        return [];
      }
    }),

    practiceAreaOptions: computed('allPracticeAreaCategories.isFulfilled', function () {
      if (get(this, 'allPracticeAreaCategories.isFulfilled')) {
        return [].concat.apply([], get(this, 'allPracticeAreaCategories').mapBy('practiceAreas')).uniq();
      } else {
        return [];
      }
    }),

    filterOptions: computed(function () {
      return RSVP.all([get(this, 'allLocations'), get(this, 'allPracticeAreaCategories'), get(this, 'allFirms')]);
    }),

    years: computed(function () {
      var years = [];

      for (var i = 2010; i < new Date().getFullYear() + 1; i++) {
        years.push(i.toString());
      }

      return years;
    }),

    yearOptions: computed('years', function () {
      return get(this, 'years').map(function (year) {
        return { value: year, label: year };
      });
    }),

    experienceOptions: computed(function () {
      return [{
        value: '0,24',
        label: 'Junior (1 - 2)'
      }, {
        value: '24,48',
        label: 'Mid (3 - 4)'
      }, {
        value: '48,72',
        label: 'Late Mid (5 - 6)'
      }, {
        value: '72,1200',
        label: 'Senior (7+)'
      }];
    }),

    usePracticeAreas: function usePracticeAreas(practiceAreas) {
      set(this, 'practiceAreas', practiceAreas);
      set(this, 'practiceAreaIds', practiceAreas.mapBy('id'));
    },

    dropPracticeArea: function dropPracticeArea(practiceArea) {
      get(this, 'practiceAreas').removeObject(practiceArea);
      get(this, 'practiceAreaIds').removeObject(get(practiceArea, 'id'));
    },

    useTimes: function useTimes(times) {
      set(this, 'startTime', times[0]);
      set(this, 'endTime', times[1]);
    },

    firmChanged: _ember['default'].observer('firmId', function () {
      var firmId = get(this, 'firmId');
      var firm = get(this, 'allFirms').filterBy('id', firmId).get('firstObject');
      var params = { firmId: firmId };
      if (firm) {
        params['firmName'] = get(firm, 'name');
      }
      this.analytics.track('Move Tracker: Changed firm filter', params);
    }),

    locationChanged: _ember['default'].observer('locationId', function () {
      var locationId = get(this, 'locationId');
      var location = get(this, 'allLocations').filterBy('id', locationId).get('firstObject');
      var params = { locationId: locationId };
      if (location) {
        params['locationName'] = get(location, 'name');
      }
      this.analytics.track('Move Tracker: Changed location filter', params);
    }),

    yearsChanged: _ember['default'].observer('startTime', 'endTime', function () {
      this.analytics.track('Move Tracker: Changed start/end time filters');
    }),

    experienceLevelChanged: _ember['default'].observer('experienceMin', 'experienceMax', function () {
      this.analytics.track('Move Tracker: Changed experience filter');
    }),

    categoryChanged: _ember['default'].observer('category', function () {
      this.analytics.track('Move Tracker: Changed category filter');
    }),

    actions: {
      setExpandedFirm: function setExpandedFirm(firm) {
        set(this, 'expandedFirm', firm);
      },

      unsetExpandedFirm: function unsetExpandedFirm() {
        set(this, 'expandedFirm', null);
      },

      clearPracticeAreas: function clearPracticeAreas() {
        this.usePracticeAreas([]);
      },

      firmTabSelected: function firmTabSelected(tab) {
        set(this, 'lastTab', tab);
      }
    }
  });
});