define('laterally/controllers/application/confirm-email-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    lawyer: _ember['default'].computed.alias('session.user.userable'),

    resent: false,

    actions: {
      resend: function resend() {
        this.set("resent", false);
        if (this.get("lawyer.states.SELECTING")) {
          this.set("confirmed", true);
        } else {
          this.get("lawyer").resendConfirmationEmail().then((function () {
            if (this.get("lawyer.states.SELECTING")) {
              this.set("confirmed", true);
            } else {
              this.set("resent", true);
            }
          }).bind(this));
        }
      }
    }

  });
});