define('laterally/models/transaction', ['exports', 'ember', 'ember-data', 'laterally/utils/computed/not-any'], function (exports, _ember, _emberData, _laterallyUtilsComputedNotAny) {
  exports['default'] = _emberData['default'].Model.extend({

    isBlank: (0, _laterallyUtilsComputedNotAny['default'])("client", "title", "description"),
    notBlank: _ember['default'].computed.not('isBlank'),

    lawyer: _emberData['default'].belongsTo('transactor', { polymorphic: true }),
    client: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    order: _emberData['default'].attr('number')

  });
});