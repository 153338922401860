define('laterally/models/location', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var computed = _ember['default'].computed;
  var bool = computed.bool;
  exports['default'] = _emberData['default'].Model.extend({

    name: _emberData['default'].attr('string'),
    region: _emberData['default'].belongsTo('region'),

    shortName: (function () {
      var name = this.get('name');
      return name.replace('Area', '').replace('Metro', '').trim();
    }).property('name'),

    displayName: _emberData['default'].attr('string'),
    isPrimary: _emberData['default'].attr('boolean'),
    state: _emberData['default'].attr('string'),

    hasState: bool('state'),
    order: _emberData['default'].attr('number')

  });
});