define('laterally/routes/possess', ['exports', 'ember', 'laterally/utils/promise-ajax', 'laterally/config/environment'], function (exports, _ember, _laterallyUtilsPromiseAjax, _laterallyConfigEnvironment) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Route.extend({
    model: function model(params, transition) {
      var _this = this;

      if (!get(this.session, 'canPossess')) {
        transition.abort();
        return;
      }

      var store = this.store;
      var session = this.session;

      (0, _laterallyUtilsPromiseAjax['default'])({
        url: _laterallyConfigEnvironment['default'].APP.API_HOST + '/possession',
        headers: get(this, 'ajaxHeaders'),
        type: 'POST',
        data: { user_id: params.userId }
      }).then(function (data) {
        _this.get('analytics').track('Possessed', { userId: params.userId });
        store.pushPayload('accessToken', data);
        set(session, 'token', store.all('accessToken').get('lastObject'));

        _this.referrerManager.clearReferrer();
        window.location.href = '/';
      });
    },

    ajaxHeaders: (function () {
      var headers = {};
      headers['Authorization'] = ['Bearer', get(this.session, 'token.value')].join(' ');

      return headers;
    }).property('session.token')
  });
});