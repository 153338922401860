define('laterally/controllers/firms/index', ['exports', 'ember', 'laterally/controllers/concerns/paged', 'laterally/controllers/concerns/ordered', 'laterally/mixins/inaccessible', 'laterally/utils/deferred-receive-action'], function (exports, _ember, _laterallyControllersConcernsPaged, _laterallyControllersConcernsOrdered, _laterallyMixinsInaccessible, _laterallyUtilsDeferredReceiveAction) {

  var alias = _ember['default'].computed.alias;
  var set = _ember['default'].set;
  var observer = _ember['default'].observer;
  var Promise = _ember['default'].RSVP.Promise;
  var run = _ember['default'].run;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].ArrayController.extend(_laterallyMixinsInaccessible['default'], _laterallyControllersConcernsPaged['default'], _laterallyControllersConcernsOrdered['default'], {

    needs: ['application', 'application/header'],

    interceptClicksForModal: false,

    user: alias('session.user'),
    userable: alias('user.userable'),

    separatorOption: { name: ' --- ', disabled: true, id: 0 },
    editPracticeAreasText: '&#9998; Edit Practice Areas'.htmlSafe(),
    editLocationsText: '&#9998; Edit Locations'.htmlSafe(),

    practiceAreas: (function () {
      var arr = (this.get('userable.desiredPracticeAreas') || []).sortBy('name');
      arr.push(this.get('separatorOption'));
      arr.push({ id: 'edit-practice-areas', name: this.get('editPracticeAreasText') });
      return arr;
    }).property('userable.desiredPracticeAreas.[]'),

    locations: (function () {
      var arr = this.get('userable.desiredLocations') || [];
      arr = arr ? arr.sortBy('name') : [];
      arr.push(this.get('separatorOption'));
      arr.push({ id: 'edit-locations', name: this.get('editLocationsText') });
      return arr;
    }).property('userable.desiredLocations.[]'),

    regions: alias('controllers.application.regions'),
    practiceAreaCategories: alias('controllers.application.practiceAreaCategories'),

    queryParams: ['practiceAreaId', 'locationId', 'state'],

    orderDirection: 'asc',
    orderColumn: 'prestige_rank',
    locationId: undefined,
    practiceAreaId: undefined,
    state: null,

    isFakeLoading: false,

    selectedFirm: null,

    clearSelectedFirm: observer('model', function () {
      set(this, 'selectedFirm', null);
    }),

    fakeLoading: function fakeLoading() {
      var _this = this;

      return new Promise(function (resolve) {
        set(_this, 'isFakeLoading', true);
        run.later(_this, function () {
          set(this, 'isFakeLoading', false);
          resolve();
        }, 2200);
      });
    },

    practiceAreaIdDidChange: (function () {
      var value = this.get('practiceAreaId');
      if (value === 'edit-practice-areas') {
        this.send('openPracticeAreasModal');
        this.set('practiceAreaId', undefined);
      } else {
        this.set('practiceAreaId', value);
      }
    }).observesImmediately('practiceAreaId'),

    locationIdDidChange: (function () {
      var value = this.get('locationId');
      if (value === 'edit-locations') {
        this.send('openLocationsModal');
        this.set('locationId', undefined);
      } else {
        this.set('locationId', value);
      }
    }).observesImmediately('locationId'),

    trackLocationFiltering: (function () {
      var id = this.get('locationId');
      if (id) {
        var name = this.get('locations').findBy('id', id).get('name');
        this.get('analytics').track('Filtering by location: ' + name);
      }
    }).observes('locationId'),

    trackPracticeAreaFiltering: (function () {
      var id = this.get('practiceAreaId');
      if (id) {
        var name = this.get('practiceAreas').findBy('id', id).get('name');
        this.get('analytics').track('Filtering by practice area: ' + name);
      }
    }).observes('practiceAreaId'),

    trackOrdering: (function () {
      this.get('analytics').track('Sorted firms by ' + this.get('orderColumn').capitalize());
    }).observes('orderColumn'),

    addedCount: computed('userable.jobSelections.@each.hiddenFromLawyers', function () {
      if (this.get('user.isDeveloper')) {
        return 0;
      }
      var jobSelections = get(this, 'userable.jobSelections');
      jobSelections = jobSelections.filterBy('hiddenFromLawyers', false);
      return get(jobSelections, 'length');
    }),

    actions: {
      requestStageBump: (0, _laterallyUtilsDeferredReceiveAction['default'])(function (lawyer) {
        return lawyer.requestStageBump();
      }),

      goToPage: function goToPage(number) {
        if (this.get('userable.onboarded')) {
          this._super(number);
        } else {
          this.startOnboarding();
        }
      },

      orderBy: function orderBy(column, direction) {
        if (!this.get('userable.onboarded') || !this.get('userable.steps.SELECTING') || this.get('userable.states.REJECTED')) {
          this.startOnboarding();
        } else {
          this._super(column, direction);
        }
      },

      openedFilter: function openedFilter(e) {
        if (!this.get('userable.onboarded') || !this.get('userable.steps.SELECTING') || this.get('userable.states.REJECTED')) {
          e.preventDefault();
          this.startOnboarding();
        }
      },

      select: function select(firm) {
        if (this.get('userable.onboarded') && this.session.get('userable.steps.SELECTING') && !this.session.get('userable.states.REJECTED')) {
          if (this.get('selectedFirm') === firm) {
            this.set('selectedFirm', null);
            this.get('analytics').track("Closed drawer for firm", { firm: firm.get('name') });
          } else {
            this.set('selectedFirm', firm);
            this.get('analytics').track("Opened drawer for firm", { firm: firm.get('name') });
          }
        } else {
          this.startOnboarding();
        }
      },

      hideFirm: function hideFirm(firm) {
        var updatedFirms = this.get('content').without(firm);
        this.set('content', updatedFirms);
      }

    }

  });
});