define('laterally/models/user-session', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    email: _emberData['default'].attr('string'),
    password: _emberData['default'].attr('string'),

    user: _emberData['default'].belongsTo('user'),

    possessed: _emberData['default'].attr('boolean'),

    claimToken: _emberData['default'].attr('string')

  });
});