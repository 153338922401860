define('laterally/views/landing/alert', ['exports', 'ember'], function (exports, _ember) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].View.extend({

    classNames: ['alert-refer'],

    templateName: 'landing/alert',

    animateIn: (function () {
      var element;

      if ($(window).width() > 480) {
        element = this.$();
        if (element) {
          element.css({ top: '37px' });
        }

        _ember['default'].run.later(this, function () {
          if (element) {
            this.$().animate({ top: '62px' }, 600);
          }
        }, 1250);
      }
    }).on('didInsertElement'),

    actions: {

      close: function close() {
        this.$().slideUp(250);
      }

    }

  });
});