define('laterally/views/application/order-by', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    tagName: 'a',

    classNames: ['order-by'],
    classNameBindings: ['isActive'],

    attributeBindings: ['href'],
    href: '',

    layoutName: 'application/order_by',

    direction: 'asc',

    isActive: (function () {
      return this.get('column') === this.get('controller.orderColumn');
    }).property('column', 'controller.orderColumn'),

    arrowDown: (function () {
      return !this.get('isActive') || this.get('controller.orderDirection') === 'desc';
    }).property('isActive', 'controller.orderDirection'),

    order: (function (event) {
      var direction = this.get('direction');
      if (this.get('isActive')) {
        var controllerDirection = this.get('controller.orderDirection');
        direction = controllerDirection === 'asc' ? 'desc' : 'asc';
      }
      this.get('controller').send('orderBy', this.get('column'), direction);
      event.preventDefault();
    }).on('click')

  });
});