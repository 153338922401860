define('laterally/mixins/connectable', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var Mixin = _ember['default'].Mixin;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Mixin.create({
    introduction: attr('string'),
    introducer: belongsTo('firmRep'),

    campaign: belongsTo('exploreeGroup', { async: true })
  });
});