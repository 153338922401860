define('laterally/components/lawyer-nav/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['nav', 'navbar-nav', 'lawyer-navbar'],
    lawyer: null,

    didInsertElement: function didInsertElement() {
      $('li.dropdown').hover(function () {
        $(this).find('.dropdown-menu').stop(true, true).fadeIn(50);
      }, function () {
        $(this).find('.dropdown-menu').stop(true, true).fadeOut(50);
      });
    }
  });
});