define('laterally/components/truncated-text/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    text: '',
    limit: 20,

    truncated: (function () {
      var text = this.get('text');
      var limit = this.get('limit');
      return (text || '').slice(0, limit).trim();
    }).property('text', 'limit'),

    ellipse: (function () {
      var length = this.get('text.length');
      var limit = this.get('limit');
      if (length > limit) {
        return '&hellip;';
      }
    }).property('text.length', 'limit')

  });
});