define('laterally/helpers/formatted-moment', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Handlebars.registerBoundHelper(function (mo, options) {
    var format, formatted, wrapped;

    if (options) {
      format = options.hash.format;
    }

    if (mo) {
      formatted = mo.format(format);
      wrapped = ['<time ', 'datetime="', mo.format(), '"', '>', formatted, '</time>'].join('');

      return new _ember['default'].Handlebars.SafeString(wrapped);
    } else {
      return '';
    }
  });
});