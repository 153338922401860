define('laterally/routes/vote', ['exports', 'ember', 'laterally/routes/concerns/paged', 'laterally/routes/concerns/ordered', 'laterally/utils/computed/store-locally'], function (exports, _ember, _laterallyRoutesConcernsPaged, _laterallyRoutesConcernsOrdered, _laterallyUtilsComputedStoreLocally) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Route.extend(_laterallyRoutesConcernsPaged['default'], _laterallyRoutesConcernsOrdered['default'], {

    queryParams: {
      state: { refreshModel: true }
    },

    currentExploreVoterId: (0, _laterallyUtilsComputedStoreLocally['default'])('exploreVote.voterId'),
    slack: _ember['default'].inject.service('slack'),
    currentUser: _ember['default'].computed.alias('session.user'),

    setupController: function setupController(controller, hash) {
      controller.setProperties(hash);
    },

    model: function model(params) {
      var _this = this;

      params.state = 'saved';
      var query = params;

      return this.store.find('exploreShare', query).then(function (exploreShares) {
        var exploreShare = exploreShares.get('firstObject');
        var exploreeGroup = exploreShare.get('exploreeGroup');
        query.groupId = exploreeGroup.get('id');

        if (_this.get('currentExploreVoterId')) {
          var currentVoter = _this.store.find('exploreVoter', _this.get('currentExploreVoterId'));
          _this.set('session.exploreVoter', currentVoter);
        }

        var explorees = exploreShare.get('explorees');
        explorees.set('meta', exploreShares.get('meta'));
        return new _ember['default'].RSVP.hash({
          model: explorees,
          exploreShare: exploreShare,
          group: exploreeGroup,
          voters: exploreShare.get('exploreVoters'),
          votes: exploreShare.get('exploreVotes'),
          comments: exploreShare.get('exploreeComments'),
          newVoter: _this.store.createRecord('exploreVoter')
        });
      }, function (reason) {
        _this.transitionTo('landing');
      });
    },

    afterModel: function afterModel(model, transition) {
      this.analytics.track('Visited a Poll (use this)', {
        "Exploree Group": model.group.get('id'),
        "ExploreeGroupName": model.group.get('name'),
        "Firm User": model.group.get('firmUser.name')
      });
      transition.send('slackifyActivity', model.group, 'visited');
    },

    selectGroupIdAndState: function selectGroupIdAndState(groupId, state) {
      var qp = this.get('defaults');
      qp.state = state;

      this.transitionTo('explorees.connect', groupId, { queryParams: qp }).then(function () {});
    },

    actions: {

      slackifyActivity: function slackifyActivity(exploreeGroup, actionName) {
        var firmUserName = exploreeGroup.get('firmUser.name');
        var campaignName = exploreeGroup.get('name');
        var shareUrl = exploreeGroup.get('shareUrl');
        var user = this.get('currentUser');
        var voter = this.get('session.exploreVoter');
        var who = 'Someone';
        if (voter) {
          who = voter.get('name') + ' (a non-user voter) ';
        }
        if (user) {
          var possessor_string = '';
          if (this.get('session.possessed')) {
            possessor_string = ' [POSSESSED BY: user ' + this.get('session.possessingUserId') + '] ';
          }
          who = user.get('prettyName') + possessor_string + ' (user: <https://admin-v1.laterally.com/user/' + user.get('id') + '|' + user.get('id') + '>) ';
        }
        this.get('slack').notify(who + " " + actionName + " " + firmUserName + "'s <" + shareUrl + "|poll> for " + campaignName + ". View in <https://mixpanel.com/report/388013/live/|mixpanel>");
      },

      selectGroup: function selectGroup(group) {
        this.send('selectGroupAndState', group, '');
      },

      selectGroupAndState: function selectGroupAndState(group, state) {
        this.selectGroupIdAndState(group.get('id'), state);
        this.analytics.track('Selected explore group and state', {
          exploreeGroup: group.getProperties('id', 'name'),
          state: state
        });
      },

      loading: function loading() {
        this.controllerFor('vote').set('loading', true);
        var current = this.controllerFor('application').get('currentRouteName');
        // Bubble the event only if we're transitioning from another route because
        // even catching this event doesn't make the template render faster so we
        // might as well hit some global loading template.
        return current !== this.get('routeName');
      },

      didTransition: function didTransition() {
        this.controllerFor('vote').set('loading', false);

        return true;
      },

      setCurrentVoter: function setCurrentVoter(voter) {
        this.set('session.exploreVoter', voter);
        this.set('currentExploreVoterId', voter.get('id'));
      }

    }

  });
});