define('laterally/components/liquid-child', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['liquid-child'],

    updateElementVisibility: (function () {
      var visible = this.get('visible');
      var $container = this.$();

      if ($container && $container.length) {
        $container.css('visibility', visible ? 'visible' : 'hidden');
      }
    }).on('willInsertElement').observes('visible'),

    tellContainerWeRendered: _ember['default'].on('didInsertElement', function () {
      this.sendAction('didRender', this);
    })
  });
});