define('laterally/views/landing/firefox', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    animateIn: (function () {
      _ember['default'].run.later(this.$().hide(), 'slideDown', 750, 1250);
    }).on('didInsertElement'),

    actions: {

      close: function close() {
        this.$().slideUp(250);
      }

    }

  });
});