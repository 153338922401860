define('laterally/services/analytics', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {
	var get = _ember['default'].get;

	function delegate(name) {
		return function delegator() {
			if (window.analytics && !this.session.get('possessed')) {
				var _window$analytics;

				(_window$analytics = window.analytics)[name].apply(_window$analytics, arguments);
			} else {
				if (name == 'track') {
					console.log(name);
					console.log.apply(console, arguments);
				}
			}
		};
	}

	exports['default'] = _ember['default'].Object.extend(_ember['default'].Evented, {

		initSegment: (function () {
			var _this = this;

			if (_laterallyConfigEnvironment['default'].APP.SEGMENT_WRITE_KEY) {
				window.analytics.load(_laterallyConfigEnvironment['default'].APP.SEGMENT_WRITE_KEY);
				window.analytics.ready(function () {
					_this.trigger('ready');
				});
			} else {
				this.trigger('ready');
			}
		}).on('init'),

		watchSessionToken: (function () {
			var _this2 = this;

			var user = get(this.session, 'actingUser');

			if (user) {
				if (window.olark) {
					window.olark('api.box.hide');
				}

				user.then(function (user) {
					_this2.identify(user.get('id'), user.get('traits'));

					_this2.rebootIntercom(user);
				});
			} else {
				if (window.olark) {
					window.olark('api.box.show');
				}

				if (window.analytics.user().id()) {
					this.track('Logout');
					window.analytics.reset();
					this.rebootIntercom();
				}
			}
		}).observes('session.token').on('ready'),

		rebootIntercom: function rebootIntercom(user) {
			var intercom = window.Intercom;

			if (intercom) {
				var settings = { app_id: _laterallyConfigEnvironment['default'].APP.INTERCOM_APP_ID };
				if (user) {
					settings.user_id = get(user, 'id');
				}

				intercom('shutdown');
				intercom('boot', settings);
			}
		},

		identify: delegate('identify'),
		alias: delegate('alias'),
		track: delegate('track'),
		page: delegate('page')

	});
});