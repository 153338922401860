define('laterally/components/explorees/search-creator/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var Promise = _ember['default'].RSVP.Promise;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['search-creator-component'],

    formIsShown: false,
    searchName: null,
    isSaving: false,

    createCampaign: null,
    selectCampaign: null,

    searchNameIsValid: computed('searchName', function () {
      return !!get(this, 'searchName');
    }),

    keyDown: function keyDown(event) {
      if (event.keyCode === 27) {
        set(this, 'searchName', null);
        set(this, 'formIsShown', false);
      }
    },

    actions: {
      showForm: function showForm() {
        var _this = this;

        set(this, 'formIsShown', true);
        _ember['default'].run.next(function () {
          _this.$('input').focus();
        });
      },

      submitForm: function submitForm() {
        var _this2 = this;

        if (get(this, 'searchNameIsValid') && !get(this, 'isSaving')) {
          new Promise(function (resolve, reject) {
            set(_this2, 'isSaving', true);
            _this2.sendAction('createCampaign', get(_this2, 'searchName'), resolve, reject);
          }).then(function (campaign) {
            set(_this2, 'searchName', null);
            set(_this2, 'formIsShown', false);

            _this2.sendAction('selectCampaign', campaign);
          }, function () {
            set(_this2, 'isSaving', false);
          });
        }
      }
    }
  });
});