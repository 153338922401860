define('laterally/components/explorees/step-breadcrumbs/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['step-breadcrumbs-component'],

    exploreeGroup: null,
    goToExplore: null,
    showJob: null,
    goToConnect: null,
    step: null,
    back: null,
    next: null,

    step1: _ember['default'].computed.equal('step', 1),
    step2: _ember['default'].computed.equal('step', 2),
    step3: _ember['default'].computed.equal('step', 3),

    track: function track(message, props) {
      if (!props) {
        props = {};
      }
      var exploreeGroup = this.get('exploreeGroup');
      props.exploreeGroup = exploreeGroup ? exploreeGroup.getProperties('id', 'name') : exploreeGroup;
      this.analytics.track(message, props);
    },

    actions: {
      goToExplore: function goToExplore() {
        this.track('Clicked Find Lawyers in 1-2-3 bar');
        this.sendAction('goToExplore', this.get('exploreeGroup'));
      },

      viewJobProfile: function viewJobProfile() {
        this.track('Clicked Job Profile in 1-2-3 bar');
        this.sendAction('showJob', this.get('exploreeGroup'));
      },

      goToConnect: function goToConnect() {
        this.track('Clicked Connect in 1-2-3 bar');
        this.sendAction('goToConnect');
      },

      back: function back() {
        this.sendAction('back');
      },

      next: function next() {
        this.sendAction('next');
      }
    }

  });
});