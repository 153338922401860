define('laterally/initializers/checkbox', ['exports', 'ember'], function (exports, _ember) {

  var not = _ember['default'].computed.not;

  var initialize = function initialize() {
    _ember['default'].Checkbox.reopen({
      disabled: not('enabled'),
      enabled: true
    });
  };

  exports.initialize = initialize;
  exports['default'] = {
    name: 'checkbox',
    initialize: initialize
  };
});