define('laterally/routes/onboarding', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      if (!get(this, 'session.user')) {
        this.transitionTo('landing');
      }

      if (get(this.session, 'userable') || get(this.session, 'user.employerApplicationId')) {
        this.transitionTo('profile');
      }

      if (get(this.controllerFor('onboarding'), 'toStudents')) {
        this.transitionTo('onboarding.students');
      }
    }

  });
});