define('laterally/controllers/conversations/show/group', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;
  var any = _ember['default'].computed.any;

  exports['default'] = _ember['default'].ArrayController.extend({

    currentUserable: alias('session.userable'),

    sender: any('firstObject.sender', 'currentUserable'),
    isIntroduction: alias('firstObject.isIntroduction')

  });
});