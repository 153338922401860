define('laterally/models/exploree-group', ['exports', 'ember', 'ember-data', 'laterally/config/environment', 'laterally/utils/rest-action'], function (exports, _ember, _emberData, _laterallyConfigEnvironment, _laterallyUtilsRestAction) {

  var alias = _ember['default'].computed.alias;
  var filterBy = _ember['default'].computed.filterBy;

  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;

  exports['default'] = _emberData['default'].Model.extend({

    name: attr('string'),

    message: attr('string'),
    defaultMessage: alias('message'),

    connectWithMessage: attr('boolean'),
    createdAt: attr('date'),
    shareToken: attr('string'),
    firmUser: belongsTo('firmUser'),
    job: belongsTo('job', { async: true }),
    firm: _ember['default'].computed.alias('firmUser.firm'),

    memberships: hasMany('exploreeGroupMemberships'),
    conversations: hasMany('conversations', { async: true }),

    savedMemberships: filterBy('memberships', 'isSaved'),
    hiddenMemberships: filterBy('memberships', 'isHidden'),

    savedMembershipsCount: attr('number'),

    saveAll: (0, _laterallyUtilsRestAction['default'])('save_all'),
    copy: (0, _laterallyUtilsRestAction['default'])('copy'),

    isLive: attr('boolean'),
    isClosed: attr('boolean'),

    filterSets: hasMany('filterSet'),

    connections: hasMany('connection', { async: true }),

    shareUrl: (function () {
      return _laterallyConfigEnvironment['default'].HOST + '/vote/' + this.get('shareToken');
    }).property('shareToken')
  });
});