define('laterally/models/firm-group-member', ['exports', 'ember-data', 'laterally/utils/computed/not-any', 'laterally/models/concerns/profile-picture'], function (exports, _emberData, _laterallyUtilsComputedNotAny, _laterallyModelsConcernsProfilePicture) {
  exports['default'] = _emberData['default'].Model.extend(_laterallyModelsConcernsProfilePicture['default'], {

    job: _emberData['default'].belongsTo('job'),

    name: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    location: _emberData['default'].attr('string'),
    url: _emberData['default'].attr('string'),

    isBlank: false,
    notBlank: Ember.computed.not('isBlank')

  });
});