define('laterally/components/file-input', ['exports', 'ember'], function (exports, _ember) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Component.extend({

    buttonClassNames: 'btn btn-file',
    buttonIconClassNames: '',

    allowedTypesArePDF: _ember['default'].computed.equal('allowedTypes', 'application/pdf'),

    allowedTypes: null,
    sizeLimit: 5242880, //5*1024*1024 = 5MB
    sizeLimitString: (function () {
      return String(this.get('sizeLimit')).split('').slice(0, -6).join('') + ' MB';
    }).property('sizeLimit'),

    name: _ember['default'].computed.underscore("attachment"),
    trackAs: _ember['default'].computed.capitalize("attachment"),

    uploaded: false,
    uploadMessage: 'Upload',

    ajaxHeaders: (function () {
      var headers = {};
      headers['Authorization'] = ['Bearer', this.get('session.token.value')].join(' ');

      return headers;
    }).property('session.token'),

    upload: (function () {
      var el;
      if (this.get("allowedTypes")) {
        el = this.$('input[name="' + this.get("name") + '"]')[0];
        var fileType = el && el.files && el.files[0] && el.files[0].type;
        var types = this.get("allowedTypes").split(",");
        var abort = fileType && !types.contains(fileType);
        if (abort) {
          this.set("error", { fileType: true });
          this.get('analytics').track("Aborted Uploading " + this.get("trackAs"));
          return;
        }
      }
      if (this.get('sizeLimit')) {
        el = this.$('input[name="' + this.get("name") + '"]')[0];
        var fileSize = el && el.files && el.files[0] && el.files[0].size;
        var sizeLimit = this.get("sizeLimit");
        if (fileSize > sizeLimit) {
          this.set("error", { fileSize: true });
          this.get('analytics').track("File Too Large when Uploading " + this.get("trackAs"));
          return;
        }
      }
      this.set("loading", true);
      this.get('analytics').track("Started Uploading " + this.get("trackAs"));
      var xhr;
      if (window.FormData) {
        var formData = new FormData();
        formData.append(this.get('name'), this.$('input[name="' + this.get('name') + '"]')[0].files[0]);
        xhr = $.ajax(this.get('url'), {
          type: 'POST',
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          headers: this.get('ajaxHeaders')
        });
      } else {
        xhr = $.ajax(this.get('url'), {
          files: this.$('input[name="' + this.get('name') + '"]'),
          iframe: true,
          dataType: "json",
          headers: this.get('ajaxHeaders')
        });
      }
      xhr.always((function () {
        this.set("loading", false);
      }).bind(this)).done((function (data) {
        this.set('uploaded', true);
        this.get('model').uploadedAttachment(this.get('attachment'), data);
        this.set('value', data);
        this.get('analytics').track("Uploaded " + this.get("trackAs"));
        this.sendAction('uploadedFile');
      }).bind(this));
    }).on('change', 'input'),

    url: (function () {
      var model = this.get('model');
      var typeKey = model.constructor.typeKey;

      var store = model.get('store');
      var adapter = store.adapterFor(typeKey);

      return [adapter.buildURL(typeKey, model.get('id')), this.get('attachment').underscore()].join('/');
    }).property('model', 'attachment')

  });
});