define('laterally/components/lawyer-request-to-publish', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['lawyer-request-to-publish'],
    classNameBindings: ['lawyer.isPending:is-published'],

    // attrs
    lawyer: null,
    'on-request': null,

    publishDisabledMessage: computed('lawyer.{isPending,isEditing,canPublish,hasProfileForPublishRequest,firms.length}', function () {
      if (get(this, 'lawyer.isPending')) {
        return "You've requested to apply - we're working to get you approved";
      }

      if (get(this, 'lawyer.isEditing')) {
        return "We'll check that you're a match for our partner firms and get back to you soon";
      }

      if (!get(this, 'lawyer.canPublish')) {
        var msg = "Before applying to employers, you must ";
        var x = [];

        if (!get(this, 'lawyer.firms.length')) {
          x.push("select some jobs");
        }

        if (!get(this, 'lawyer.hasProfileForPublishRequest')) {
          x.push("complete your profile");
        }

        msg += x.join(" and ");

        return msg;
      }
    }),

    actions: {

      request: function request() {
        this.sendAction('on-request', get(this, 'lawyer'));
      }

    }

  });
});