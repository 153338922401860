define('laterally/components/checkbox-in-array/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var on = _ember['default'].on;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',

    classNames: ['checkbox-in-array'],
    classNameBindings: ['checked:is-checked:is-unchecked', 'disabled:is-disabled:is-enabled'],

    // attrs
    array: [],
    value: null,
    max: null,
    disabled: computed('checked', 'canAdd', function (_, value) {
      return !get(this, 'canAdd') && !get(this, 'checked');
    }),
    checked: computed(function (_, checked) {
      var array = get(this, 'array');
      var value = get(this, 'value');
      if (arguments.length === 2 && typeof checked === 'boolean') {
        array[checked ? 'addObject' : 'removeObject'](value);
      }
      return array.contains(value);
    }),

    // If we don't do this and someone passes in a `checked` attr, then it won't
    // reflect the correct value upstream.
    setUpstream: on('init', function () {
      this.notifyPropertyChange('checked');
      this.notifyPropertyChange('disabled');
    }),

    canAdd: computed('max', 'array.length', function () {
      var max = get(this, 'max');
      return typeof max !== 'number' || get(this, 'array.length') < max;
    })

  });
});