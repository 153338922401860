define('laterally/components/messages-list/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    groupedMessages: _ember['default'].A(),

    classNames: ['message-list'],

    showLastMessage: (function () {
      var element = this.$();
      element.scrollTop(element[0].scrollHeight);
    }).on('didInsertElement'),

    scrollMessageList: (function () {
      var component = this;
      var element = this.$();
      if (element) {
        var was = this.get('_wasScrolledToBottom');
        _ember['default'].run.schedule('afterRender', function () {
          if (element[0].scrollHeight === element.outerHeight()) {
            component.sendAction('scrolledToBottom', get(this, 'conversation'));
          }
          if (was) {
            element.scrollTop(999999);
          }
        });
      }
    }).observes('groupedMessages.lastObject.[]').on('didInsertElement'),

    attachMessageListScrollObserver: (function () {
      var component = this;

      this.$().on('scroll', function () {
        _ember['default'].run(function () {
          component._setWasScrolledToBottom();

          if (component.get('_wasScrolledToBottom')) {
            component.sendAction('scrolledToBottom', get(this, 'conversation'));
          }
        });
      });
    }).on('didInsertElement'),

    removeMessageListScrollObserver: (function () {
      this.$().off('scroll');
    }).on('willDestroyElement'),

    _wasScrolledToBottom: false,

    _setWasScrolledToBottom: function _setWasScrolledToBottom() {
      var $el = this.$();
      var ret = false;

      if ($el && $el.children().length) {
        ret = Math.round($el[0].scrollHeight - $el.scrollTop()) === Math.round($el.outerHeight());
      }

      this.set('_wasScrolledToBottom', ret);
    }
  });
});