define('laterally/controllers/possess', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var observer = _ember['default'].observer;

  function ajax(args) {
    return _ember['default'].RSVP.Promise.cast(_ember['default'].$.ajax(args));
  }

  exports['default'] = _ember['default'].Controller.extend({

    query: '',

    isLoading: false,
    showUserDetails: false,

    ajaxHeaders: (function () {
      var headers = {};
      headers['Authorization'] = ['Bearer', this.get('session.token.value')].join(' ');

      return headers;
    }).property('session.token'),

    queryObserver: observer('query', function () {
      _ember['default'].run.debounce(this, this.resultsLoader, 200);
    }),

    resultsLoader: function resultsLoader() {
      var _this = this;

      if (this.get('query')) {
        this.set('isLoading', true);
        ajax({
          url: _laterallyConfigEnvironment['default'].APP.API_HOST + '/possession/search',
          headers: this.get('ajaxHeaders'),
          data: { query: this.get('query') },
          dataType: 'json' }).then(function (results) {
          return _this.set('results', results);
        })['finally'](function () {
          return _this.set('isLoading', false);
        });
      } else {
        this.set('results', []);
      }
    },

    actions: {

      possess: function possess(id) {
        var _this2 = this;

        var store = this.store;
        var session = this.session;

        ajax({
          url: _laterallyConfigEnvironment['default'].APP.API_HOST + '/possession',
          headers: this.get('ajaxHeaders'),
          type: 'POST',
          data: { user_id: id }
        }).then(function (data) {
          _this2.get('analytics').track('Possessed', { userId: id });
          store.pushPayload('accessToken', data);
          session.set('token', store.all('accessToken').get('lastObject'));

          _this2.referrerManager.clearReferrer();
          _this2.send('close');
          _this2.send('clearQuery');
          _this2.send('refresh');
        });
      },

      unpossess: function unpossess() {
        var _this3 = this;

        var store = this.store;
        var session = this.session;

        ajax({
          url: _laterallyConfigEnvironment['default'].APP.API_HOST + '/possession',
          type: 'DELETE',
          headers: this.get('ajaxHeaders')
        }).then(function (data) {
          _this3.get('analytics').track('Unpossessed');
          store.pushPayload('accessToken', data);
          session.set('token', store.all('accessToken').get('lastObject'));

          _this3.referrerManager.clearReferrer();
          _this3.send('refresh');
        });
      },

      refresh: function refresh() {
        this.send('disconnectModal');
        window.location = '/';
      },

      clearQuery: function clearQuery() {
        this.set('query', '');
      },

      close: function close() {
        this.send('disconnectModal');
      },

      toggleUserDetails: function toggleUserDetails() {
        this.toggleProperty('showUserDetails');
      }

    }

  });
});