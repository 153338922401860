define('laterally/controllers/lawyer', ['exports', 'ember', 'laterally/utils/track-properties'], function (exports, _ember, _laterallyUtilsTrackProperties) {

  var get = _ember['default'].get;
  var alias = _ember['default'].computed.alias;
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Controller.extend((0, _laterallyUtilsTrackProperties['default'])({
    lawyer: {
      currentLocation: 'Edited current location',
      firm: 'Edited firm',
      school: 'Edited school',
      graduationYear: 'Edited graduation year',
      questionMoving: 'Question Moving edited',
      questionProudest: 'Question Proudest edited',
      questionOtherCareer: 'Question Other Career edited',
      questionBesidesResume: 'Question Besides Resume edited',
      email: 'Email edited',
      phone: 'Phone Number edited',
      contactNotes: 'Contact Notes edited'
    }

  }), {
    currentUser: alias('session.user'),

    // attrs
    model: null,
    lawyer: alias('model'),
    canEdit: false,
    isEditing: false,

    trackingEnabled: false,

    progressFlag: '',
    errorsFlag: '',
    showErrorsFor: {},

    isMine: (function () {
      return this.get('lawyer.id') === this.get('currentUser.userable.id');
    }).property('lawyer.id', 'currentUser.userable.id'),

    shownToCount: computed('lawyer.state', 'lawyer.firms', function () {
      return get(this, 'lawyer.state') !== 'APPROVED' ? 0 : get(this, 'lawyer.firms').compact().get('length');
    }),

    actions: {

      getProfileHelp: function getProfileHelp() {
        var lawyer = this.session.get('user.userable');
        lawyer.getHelp();

        this.analytics.track('Clicked get help');
        this.send('openIntercom');
      },

      showContactModal: function showContactModal() {
        var lawyer = get(this, 'lawyer');
        this.send('openModal', 'application/contactModal', { sendTo: lawyer });
      },

      mustCompleteProfile: function mustCompleteProfile() {
        var controller = this;
        var lawyer = get(this, 'model');
        var keys = get(lawyer, 'requirementKeys');

        controller.set('progressFlag', new Date().getTime());

        keys.forEach(function (key) {
          controller.set(['showErrorsFor', key.split('.')[0]].join('.'), true);
        });
      }

    }

  });
});