define('laterally/controllers/invite-token', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      submit: function submit() {
        var _this = this;

        var user = get(this, 'user');
        var analytics = get(this, 'analytics');

        if (!get(user, 'password')) {
          get(user, 'errors').add('password', "can't be blank");
        }

        if (!get(user, 'agreed')) {
          get(user, 'errors').add('agreed', "must be accepted");
        }

        if (get(user, 'errors.isEmpty')) {
          user.save().then(function () {
            analytics.track('Onboarded as Firm', user.getProperties('id name email'));

            _this.session.openWithCredentials({
              email: get(user, 'email'),
              password: get(user, 'password')
            }).then(function () {
              _this.transitionToRoute('profile');
            });
          });
        }
      }
    }

  });
});