define('laterally/initializers/referrer-manager', ['exports'], function (exports) {
  exports['default'] = {
    name: 'referrer-manager',

    initialize: function initialize(container) {
      container.injection('service:referrer-manager', 'store', 'store:main');

      container.injection('route', 'referrerManager', 'service:referrer-manager');
      container.injection('controller', 'referrerManager', 'service:referrer-manager');
    }

  };
});