define('laterally/components/lawyer-state-progress/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['lawyer-state-progress'],

    // attrs
    lawyer: null,
    mustCompleteProfile: null,
    requestedToPublish: null,

    showProfileCompletionPrompt: false,
    showJobSelectionPrompt: false,

    promptProfileCompletion: function promptProfileCompletion() {
      if (get(this, 'showProfileCompletionPrompt') && !get(this, 'mustCompleteProfile')) {
        set(this, 'shakePrompts', true);
      }

      set(this, 'showProfileCompletionPrompt', true);
    },

    promptJobSelection: function promptJobSelection() {
      if (get(this, 'showJobSelectionPrompt')) {
        set(this, 'shakePrompts', true);
      }

      set(this, 'showJobSelectionPrompt', true);
    },

    hidePrompts: function hidePrompts() {
      set(this, 'showProfileCompletionPrompt', false);
      set(this, 'showJobSelectionPrompt', false);
      set(this, 'shakePrompts', false);
    },

    firmsCount: computed('lawyer.jobSelections.[]', function () {
      return get(this, 'lawyer.jobSelections').filterBy('hiddenFromLawyers', false).mapBy('firmLocationJob.firmLocation.firm').uniq().length;
    }),

    shakePrompts: false,

    shakePromptsDidChange: (function () {
      if (get(this, 'shakePrompts')) {
        run.later(this, function () {
          if (!get(this, 'isDestroying') && !get(this, 'isDestroyed')) {
            set(this, 'shakePrompts', false);
          }
        }, 1000);
      }
    }).observes('shakePrompts'),

    errorDidChange: (function () {
      if (get(this, 'lawyer.hasProfileForPublishRequest')) {
        set(this, 'showProfileCompletionPrompt', false);
      }

      if (get(this, 'lawyer.jobSelections.length')) {
        set(this, 'showJobSelectionPrompt', false);
      }
    }).observes('lawyer.hasProfileForPublishRequest', 'lawyer.jobSelections.length'),

    actions: {

      requestToPublish: function requestToPublish() {
        var lawyer = get(this, 'lawyer');
        var hasProfile = get(lawyer, 'hasProfileForPublishRequest');
        var hasJobSelections = get(lawyer, 'jobSelections.length');

        if (hasProfile && hasJobSelections) {
          this.hidePrompts();
          this.sendAction('requestedToPublish', get(this, 'lawyer'));
        } else {
          if (!hasProfile) {
            if (get(this, 'mustCompleteProfile')) {
              this.sendAction('mustCompleteProfile');
            }
            this.promptProfileCompletion();
          }
          if (!hasJobSelections) {
            this.promptJobSelection();
          }
        }
      }

    }
  });
});