define('laterally/models/firm-rep', ['exports', 'ember-data', 'laterally/models/concerns/profile-picture'], function (exports, _emberData, _laterallyModelsConcernsProfilePicture) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend(_laterallyModelsConcernsProfilePicture['default'], {
    firstName: attr(),
    lastName: attr(),
    fullName: attr(),
    phoneNumber: attr(),
    email: attr(),
    title: attr(),
    contact: attr(),
    firmName: attr(),

    firmUser: belongsTo('firmUser', { async: true })
  });
});