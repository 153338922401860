define('laterally/models/experience', ['exports', 'ember-data'], function (exports, _emberData) {
  var _Ember = Ember;
  var get = _Ember.get;
  var set = _Ember.set;
  var computed = _Ember.computed;
  var moment = window.moment;
  exports['default'] = _emberData['default'].Model.extend({
    category: _emberData['default'].attr('string'),
    employer: _emberData['default'].attr('string'),
    position: _emberData['default'].attr('string'),
    dateStart: _emberData['default'].attr('date'),
    dateEnd: _emberData['default'].attr('date'),
    nextEmployer: _emberData['default'].attr('string'),
    previousEmployer: _emberData['default'].attr('string'),
    nextFirmName: _emberData['default'].attr('string'),
    previousFirmName: _emberData['default'].attr('string'),
    nextCategory: _emberData['default'].attr('string'),
    previousCategory: _emberData['default'].attr('string'),
    nextPosition: _emberData['default'].attr('string'),
    previousPosition: _emberData['default'].attr('string'),
    previousExperienceId: _emberData['default'].attr('string'),
    nextExperienceId: _emberData['default'].attr('string'),
    locationName: _emberData['default'].attr('string'),
    previousLocationName: _emberData['default'].attr('string'),
    nextLocationName: _emberData['default'].attr('string'),
    experienceLevelAtHire: _emberData['default'].attr('string'),
    experienceLevelAtDeparture: _emberData['default'].attr('string'),

    lawyer: _emberData['default'].belongsTo('lawyer'),
    exploree: _emberData['default'].belongsTo('exploree'),
    experiencer: _emberData['default'].belongsTo('experiencer', { polymorphic: true }),
    nextFirm: _emberData['default'].belongsTo('firm'),
    previousFirm: _emberData['default'].belongsTo('firm'),

    practiceAreas: _emberData['default'].attr('string'),
    nextPracticeAreas: _emberData['default'].attr('string'),
    recentFirmPracticeAreas: _emberData['default'].attr('string'),
    recentFirmPracticeAreasDiffed: _emberData['default'].attr('boolean'),

    range: (function () {
      var start = this.get('dateStart') || new Date();
      var end = this.get('dateEnd') || new Date();
      return window.moment.duration(window.moment(end).diff(start));
    }).property('dateStart', 'dateEnd'),

    years: (function () {
      return this.get('range').years();
    }).property('range'),

    months: (function () {
      return this.get('range').months();
    }).property('range'),

    nextCategoryPretty: (function () {
      var nextCategory = get(this, 'nextCategory');
      if (nextCategory == 'firm') {
        return 'Law Firm';
      }
      if (nextCategory) {
        return capitalizeFirstLetter(nextCategory);
      }
    }).property('nextCategory'),

    previousCategoryPretty: (function () {
      var previousCategory = get(this, 'previousCategory');
      if (previousCategory == 'firm') {
        return 'Law Firm';
      }
      if (previousCategory) {
        return capitalizeFirstLetter(get(this, 'previousCategory'));
      }
    }).property('previousCategory'),

    startMoment: computed('dateStart', function () {
      var date = get(this, 'dateStart');

      if (date) {
        return moment(date);
      } else {
        return null;
      }
    }),

    endMoment: computed('dateEnd', function () {
      var date = get(this, 'dateEnd');

      if (date) {
        return moment(date);
      } else {
        return null;
      }
    })
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
});