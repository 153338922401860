define('laterally/models/firm-user', ['exports', 'ember', 'ember-data', 'laterally/models/concerns/userable', 'laterally/models/concerns/stateful', 'laterally/models/concerns/conversation-member', 'laterally/utils/rest-action', 'laterally/config/environment'], function (exports, _ember, _emberData, _laterallyModelsConcernsUserable, _laterallyModelsConcernsStateful, _laterallyModelsConcernsConversationMember, _laterallyUtilsRestAction, _laterallyConfigEnvironment) {

  var mapBy = _ember['default'].computed.mapBy;

  exports['default'] = _emberData['default'].Model.extend(_laterallyModelsConcernsUserable['default'], _laterallyModelsConcernsStateful['default'], _laterallyModelsConcernsConversationMember['default'], {

    isFirm: true,
    isFirmRep: true,

    traits: (function () {
      return { "Firm": this.get("firmName") };
    }).property().volatile(),

    firm: _emberData['default'].belongsTo('firm'),

    firmLocationUsers: _emberData['default'].hasMany('firmLocationUser', { async: true }),
    firmLocations: mapBy('firmLocationUsers', 'firmLocation'),
    firmLocationJobs: mapBy('firmLocations', 'firmLocationJobs'),

    title: _emberData['default'].attr("string"),

    startDemo: (0, _laterallyUtilsRestAction['default'])('start_demo'),
    shownToS: (0, _laterallyUtilsRestAction['default'])('shown_tos'),
    acceptTerms: (0, _laterallyUtilsRestAction['default'])('accept_terms'),
    join: (0, _laterallyUtilsRestAction['default'])('join'),

    canViewConversations: _ember['default'].computed.bool('steps.AGREED'),

    inviteEmail: _emberData['default'].attr('string'),

    ex3Url: (function () {
      return _laterallyConfigEnvironment['default'].HOST + '/ex3/' + this.get('user.singleAccessToken') + '/';
    }).property('user.singleAccessToken')

  }).reopenClass({
    STEPS: { START: 0, DEMOING: 1, APPROVED: 2, WAITING: 3, AGREED: 4 },
    STATES: ['START', 'DEMOING', 'APPROVED', 'WAITING', 'AGREED']
  });
});