define('laterally/views/jobs-list/firm-location-job', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    slider: (function () {
      if (this.get('controller.removing')) {
        this.$().slideUp(250, (function () {
          this.get('controller').send('removed');
        }).bind(this));
      }
    }).observes('controller.removing')

  });
});