define("laterally/templates/candidates", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          View Conversations ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-arrow-right");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      })();
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.4",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks,
              block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, null);
          dom.insertBoundary(fragment, 0);
          block(env, morph0, context, "link-to", ["conversations"], { "class": "btn btn-success-inverse" }, child0, null);
          return fragment;
        }
      };
    })();
    var child1 = (function () {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.4",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "id", "review-terms");
          dom.setAttribute(el1, "href", "");
          dom.setAttribute(el1, "class", "btn btn-success-inverse");
          var el2 = dom.createTextNode("\n          Review Terms and See Real Candidates ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-arrow-right");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks,
              element = hooks.element;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element12 = dom.childAt(fragment, [1]);
          element(env, element12, context, "action", ["showTermsOfService"], {});
          return fragment;
        }
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks,
                    content = hooks.content;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment, 1, 1, contextualElement);
                content(env, morph0, context, "firmLocation.model.location.name");
                return fragment;
              }
            };
          })();
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.4",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks,
                  get = hooks.get,
                  block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, null);
              dom.insertBoundary(fragment, 0);
              block(env, morph0, context, "scroll-to", [], { "anchor": get(env, context, "firmLocation.locationId") }, child0, null);
              return fragment;
            }
          };
        })();
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 1,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement, blockArguments) {
            var dom = env.dom;
            var hooks = env.hooks,
                set = hooks.set,
                get = hooks.get,
                block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            set(env, context, "firmLocation", blockArguments[0]);
            block(env, morph0, context, "slide-toggle", [], { "open": get(env, context, "firmLocation.visible"), "direction": "horizontal", "tagName": "span" }, child0, null);
            return fragment;
          }
        };
      })();
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.4",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "location-nav");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks,
              get = hooks.get,
              block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          block(env, morph0, context, "each", [get(env, context, "controller")], {}, child0, null);
          return fragment;
        }
      };
    })();
    var child3 = (function () {
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.4",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "no-candidates-message");
          var el2 = dom.createTextNode("No Candidates");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          return fragment;
        }
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "location-header");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "location-anchor");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "location-name");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks,
                get = hooks.get,
                subexpr = hooks.subexpr,
                concat = hooks.concat,
                attribute = hooks.attribute,
                inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element10 = dom.childAt(fragment, [1]);
            var element11 = dom.childAt(element10, [1]);
            var attrMorph0 = dom.createAttrMorph(element11, 'id');
            var morph0 = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
            attribute(env, attrMorph0, element11, "id", concat(env, [subexpr(env, context, "unbound", [get(env, context, "firmLocation.locationId")], {})]));
            inline(env, morph0, context, "unbound", [get(env, context, "firmLocation.model.location.name")], {});
            return fragment;
          }
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "job-year-requirement");
                var el2 = dom.createTextNode("\n                  (");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(")\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks,
                    get = hooks.get,
                    inline = hooks.inline;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                inline(env, morph0, context, "unbound", [get(env, context, "firmLocationJob.model.job.yearRequirement")], {});
                return fragment;
              }
            };
          })();
          var child1 = (function () {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "job-closed");
                var el2 = dom.createElement("p");
                dom.setAttribute(el2, "class", "label label-danger");
                var el3 = dom.createTextNode("Closed");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                return fragment;
              }
            };
          })();
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.4",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "job-header");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              dom.setAttribute(el2, "class", "job-name");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("hr");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks,
                  get = hooks.get,
                  inline = hooks.inline,
                  block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element9 = dom.childAt(fragment, [1]);
              var morph0 = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
              var morph1 = dom.createMorphAt(element9, 3, 3);
              var morph2 = dom.createMorphAt(element9, 4, 4);
              inline(env, morph0, context, "unbound", [get(env, context, "firmLocationJob.model.job.name")], {});
              block(env, morph1, context, "if", [get(env, context, "firmLocationJob.model.job.yearRequirement")], {}, child0, null);
              block(env, morph2, context, "if", [get(env, context, "firmLocationJob.model.job.defunct")], {}, child1, null);
              return fragment;
            }
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  isHTMLBars: true,
                  revision: "Ember@1.11.4",
                  blockParams: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  build: function build(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "class", "lawyer-action lawyer-action-unhide");
                    var el2 = dom.createTextNode("\n                            Unhide\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  render: function render(context, env, contextualElement) {
                    var dom = env.dom;
                    var hooks = env.hooks,
                        element = hooks.element;
                    dom.detectNamespace(contextualElement);
                    var fragment;
                    if (env.useFragmentCache && dom.canClone) {
                      if (this.cachedFragment === null) {
                        fragment = this.build(dom);
                        if (this.hasRendered) {
                          this.cachedFragment = fragment;
                        } else {
                          this.hasRendered = true;
                        }
                      }
                      if (this.cachedFragment) {
                        fragment = dom.cloneNode(this.cachedFragment, true);
                      }
                    } else {
                      fragment = this.build(dom);
                    }
                    var element6 = dom.childAt(fragment, [1]);
                    element(env, element6, context, "action", ["unhide"], { "bubbles": false });
                    return fragment;
                  }
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    isHTMLBars: true,
                    revision: "Ember@1.11.4",
                    blockParams: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    build: function build(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                See messages\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    render: function render(context, env, contextualElement) {
                      var dom = env.dom;
                      dom.detectNamespace(contextualElement);
                      var fragment;
                      if (env.useFragmentCache && dom.canClone) {
                        if (this.cachedFragment === null) {
                          fragment = this.build(dom);
                          if (this.hasRendered) {
                            this.cachedFragment = fragment;
                          } else {
                            this.hasRendered = true;
                          }
                        }
                        if (this.cachedFragment) {
                          fragment = dom.cloneNode(this.cachedFragment, true);
                        }
                      } else {
                        fragment = this.build(dom);
                      }
                      return fragment;
                    }
                  };
                })();
                var child1 = (function () {
                  return {
                    isHTMLBars: true,
                    revision: "Ember@1.11.4",
                    blockParams: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    build: function build(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                Contact\n                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.setAttribute(el1, "class", "fa fa-comment");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    render: function render(context, env, contextualElement) {
                      var dom = env.dom;
                      dom.detectNamespace(contextualElement);
                      var fragment;
                      if (env.useFragmentCache && dom.canClone) {
                        if (this.cachedFragment === null) {
                          fragment = this.build(dom);
                          if (this.hasRendered) {
                            this.cachedFragment = fragment;
                          } else {
                            this.hasRendered = true;
                          }
                        }
                        if (this.cachedFragment) {
                          fragment = dom.cloneNode(this.cachedFragment, true);
                        }
                      } else {
                        fragment = this.build(dom);
                      }
                      return fragment;
                    }
                  };
                })();
                return {
                  isHTMLBars: true,
                  revision: "Ember@1.11.4",
                  blockParams: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  build: function build(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "btn-group");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("button");
                    dom.setAttribute(el2, "class", "lawyer-action lawyer-action-contact");
                    var el3 = dom.createTextNode("\n");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("button");
                    dom.setAttribute(el2, "href", "");
                    dom.setAttribute(el2, "class", "lawyer-action lawyer-action-hide");
                    var el3 = dom.createTextNode("\n                              Pass\n                            ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                          ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  render: function render(context, env, contextualElement) {
                    var dom = env.dom;
                    var hooks = env.hooks,
                        get = hooks.get,
                        element = hooks.element,
                        block = hooks.block;
                    dom.detectNamespace(contextualElement);
                    var fragment;
                    if (env.useFragmentCache && dom.canClone) {
                      if (this.cachedFragment === null) {
                        fragment = this.build(dom);
                        if (this.hasRendered) {
                          this.cachedFragment = fragment;
                        } else {
                          this.hasRendered = true;
                        }
                      }
                      if (this.cachedFragment) {
                        fragment = dom.cloneNode(this.cachedFragment, true);
                      }
                    } else {
                      fragment = this.build(dom);
                    }
                    var element3 = dom.childAt(fragment, [1]);
                    var element4 = dom.childAt(element3, [1]);
                    var element5 = dom.childAt(element3, [3]);
                    var morph0 = dom.createMorphAt(element4, 1, 1);
                    element(env, element4, context, "action", ["contact", get(env, context, "jobSelection.conversation")], { "bubbles": false });
                    block(env, morph0, context, "if", [get(env, context, "jobSelection.conversation")], {}, child0, child1);
                    element(env, element5, context, "action", ["confirmPass"], { "bubbles": false });
                    return fragment;
                  }
                };
              })();
              var child2 = (function () {
                return {
                  isHTMLBars: true,
                  revision: "Ember@1.11.4",
                  blockParams: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  build: function build(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "job-selection-notes");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "job-selection-notes-header");
                    var el3 = dom.createTextNode("Note from Lateral.ly:");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  render: function render(context, env, contextualElement) {
                    var dom = env.dom;
                    var hooks = env.hooks,
                        get = hooks.get,
                        inline = hooks.inline;
                    dom.detectNamespace(contextualElement);
                    var fragment;
                    if (env.useFragmentCache && dom.canClone) {
                      if (this.cachedFragment === null) {
                        fragment = this.build(dom);
                        if (this.hasRendered) {
                          this.cachedFragment = fragment;
                        } else {
                          this.hasRendered = true;
                        }
                      }
                      if (this.cachedFragment) {
                        fragment = dom.cloneNode(this.cachedFragment, true);
                      }
                    } else {
                      fragment = this.build(dom);
                    }
                    var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
                    inline(env, morph0, context, "view-more", [], { "content": get(env, context, "jobSelection.model.notes"), "length": 300 });
                    return fragment;
                  }
                };
              })();
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.4",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-md-5");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "lawyer-profile-picture-container");
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "lawyer-name");
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "lawyer-looking-for");
                  var el5 = dom.createTextNode("\n                          Current location: ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                      ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-md-2 col-xs-4 lawyer-cell");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "lawyer-info-column");
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("span");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                      ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-md-2 col-xs-4 lawyer-cell");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "lawyer-info-column");
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("span");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                      ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-md-1 col-xs-4 lawyer-cell");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "lawyer-info-column");
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("span");
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                      ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "col-md-2 lawyer-cell");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "lawyer-actions");
                  var el4 = dom.createTextNode("\n");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("                      ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks,
                      get = hooks.get,
                      inline = hooks.inline,
                      block = hooks.block;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element7 = dom.childAt(fragment, [1]);
                  var element8 = dom.childAt(element7, [1, 1]);
                  var morph0 = dom.createMorphAt(element8, 1, 1);
                  var morph1 = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
                  var morph2 = dom.createMorphAt(dom.childAt(element8, [5]), 1, 1);
                  var morph3 = dom.createMorphAt(dom.childAt(element7, [3, 1, 1]), 0, 0);
                  var morph4 = dom.createMorphAt(dom.childAt(element7, [5, 1, 1]), 0, 0);
                  var morph5 = dom.createMorphAt(dom.childAt(element7, [7, 1, 1]), 0, 0);
                  var morph6 = dom.createMorphAt(dom.childAt(element7, [9, 1]), 1, 1);
                  var morph7 = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  dom.insertBoundary(fragment, null);
                  inline(env, morph0, context, "profile-picture", [], { "model": get(env, context, "jobSelection.model.lawyer"), "style": "75x75" });
                  inline(env, morph1, context, "unbound", [get(env, context, "jobSelection.model.lawyer.name")], {});
                  inline(env, morph2, context, "unbound", [get(env, context, "jobSelection.model.lawyer.currentLocation")], {});
                  inline(env, morph3, context, "unbound", [get(env, context, "jobSelection.model.lawyer.firmName")], {});
                  inline(env, morph4, context, "unbound", [get(env, context, "jobSelection.model.lawyer.schoolName")], {});
                  inline(env, morph5, context, "unbound", [get(env, context, "jobSelection.model.lawyer.graduationYear")], {});
                  block(env, morph6, context, "if", [get(env, context, "jobSelection.hidden")], {}, child0, child1);
                  block(env, morph7, context, "if", [get(env, context, "jobSelection.model.notes")], {}, child2, null);
                  return fragment;
                }
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  isHTMLBars: true,
                  revision: "Ember@1.11.4",
                  blockParams: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  build: function build(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          Confirming ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "fa fa-spinner fa-spin");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  render: function render(context, env, contextualElement) {
                    var dom = env.dom;
                    dom.detectNamespace(contextualElement);
                    var fragment;
                    if (env.useFragmentCache && dom.canClone) {
                      if (this.cachedFragment === null) {
                        fragment = this.build(dom);
                        if (this.hasRendered) {
                          this.cachedFragment = fragment;
                        } else {
                          this.hasRendered = true;
                        }
                      }
                      if (this.cachedFragment) {
                        fragment = dom.cloneNode(this.cachedFragment, true);
                      }
                    } else {
                      fragment = this.build(dom);
                    }
                    return fragment;
                  }
                };
              })();
              var child1 = (function () {
                return {
                  isHTMLBars: true,
                  revision: "Ember@1.11.4",
                  blockParams: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  build: function build(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          Confirm Pass\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  render: function render(context, env, contextualElement) {
                    var dom = env.dom;
                    dom.detectNamespace(contextualElement);
                    var fragment;
                    if (env.useFragmentCache && dom.canClone) {
                      if (this.cachedFragment === null) {
                        fragment = this.build(dom);
                        if (this.hasRendered) {
                          this.cachedFragment = fragment;
                        } else {
                          this.hasRendered = true;
                        }
                      }
                      if (this.cachedFragment) {
                        fragment = dom.cloneNode(this.cachedFragment, true);
                      }
                    } else {
                      fragment = this.build(dom);
                    }
                    return fragment;
                  }
                };
              })();
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.4",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "popover bottom");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "arrow");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "popover-title");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("h5");
                  var el4 = dom.createTextNode("Tell Lateral.ly your reason for passing:");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "popover-content");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("form");
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "radio");
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("label");
                  var el6 = dom.createTextNode("\n                            ");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createTextNode("\n                            Competitive candidate pool\n                          ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "radio");
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("label");
                  var el6 = dom.createTextNode("\n                            ");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createTextNode("\n                            Type of experience\n                          ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "radio");
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("label");
                  var el6 = dom.createTextNode("\n                            ");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createTextNode("\n                            Years of experience\n                          ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "radio");
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("label");
                  var el6 = dom.createTextNode("\n                            ");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createTextNode("\n                            Timing of application\n                          ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "radio");
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("label");
                  var el6 = dom.createTextNode("\n                            ");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createTextNode("\n                            Previously submitted (recruiter)\n                          ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "radio");
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("label");
                  var el6 = dom.createTextNode("\n                            ");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createTextNode("\n                            Previously submitted (self)\n                          ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "radio");
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("label");
                  var el6 = dom.createTextNode("\n                            ");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createComment("");
                  dom.appendChild(el5, el6);
                  var el6 = dom.createTextNode("\n                            No reason stated\n                          ");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "form-group");
                  var el5 = dom.createTextNode("\n                          ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n                        ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                      ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "modal-footer popover-title");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("button");
                  dom.setAttribute(el3, "class", "btn btn-primary pull-right");
                  var el4 = dom.createTextNode("\n");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("                      ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks,
                      get = hooks.get,
                      inline = hooks.inline,
                      element = hooks.element,
                      block = hooks.block;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var element0 = dom.childAt(fragment, [1]);
                  var element1 = dom.childAt(element0, [5, 1]);
                  var element2 = dom.childAt(element0, [7, 1]);
                  var morph0 = dom.createMorphAt(dom.childAt(element1, [1, 1]), 1, 1);
                  var morph1 = dom.createMorphAt(dom.childAt(element1, [3, 1]), 1, 1);
                  var morph2 = dom.createMorphAt(dom.childAt(element1, [5, 1]), 1, 1);
                  var morph3 = dom.createMorphAt(dom.childAt(element1, [7, 1]), 1, 1);
                  var morph4 = dom.createMorphAt(dom.childAt(element1, [9, 1]), 1, 1);
                  var morph5 = dom.createMorphAt(dom.childAt(element1, [11, 1]), 1, 1);
                  var morph6 = dom.createMorphAt(dom.childAt(element1, [13, 1]), 1, 1);
                  var morph7 = dom.createMorphAt(dom.childAt(element1, [15]), 1, 1);
                  var morph8 = dom.createMorphAt(element2, 1, 1);
                  inline(env, morph0, context, "radio-button", [], { "name": get(env, context, "jobSelection.model.hiddenReason"), "value": "competition" });
                  inline(env, morph1, context, "radio-button", [], { "name": get(env, context, "jobSelection.model.hiddenReason"), "value": "experience-needs" });
                  inline(env, morph2, context, "radio-button", [], { "name": get(env, context, "jobSelection.model.hiddenReason"), "value": "experience-years" });
                  inline(env, morph3, context, "radio-button", [], { "name": get(env, context, "jobSelection.model.hiddenReason"), "value": "timing-of-application" });
                  inline(env, morph4, context, "radio-button", [], { "name": get(env, context, "jobSelection.model.hiddenReason"), "value": "previously-submitted-recruiter" });
                  inline(env, morph5, context, "radio-button", [], { "name": get(env, context, "jobSelection.model.hiddenReason"), "value": "previously-submitted-self" });
                  inline(env, morph6, context, "radio-button", [], { "name": get(env, context, "jobSelection.model.hiddenReason") });
                  inline(env, morph7, context, "textarea", [], { "value": get(env, context, "jobSelection.model.hiddenNotes"), "class": "form-control", "placeholder": "Optional feedback to Lateral.ly about the candidate and the position's requirements.", "rows": 3 });
                  element(env, element2, context, "action", ["saveHidden"], {});
                  element(env, element2, context, "bind-attr", [], { "disabled": get(env, context, "jobSelection.model.isSaving") });
                  block(env, morph8, context, "if", [get(env, context, "jobSelection.model.isSaving")], {}, child0, child1);
                  return fragment;
                }
              };
            })();
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks,
                    get = hooks.get,
                    block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
                var morph1 = dom.createMorphAt(fragment, 2, 2, contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "link-to", ["lawyer", get(env, context, "jobSelection.model.lawyer")], { "class": "lawyer" }, child0, null);
                block(env, morph1, context, "hide-on-blur", [], { "show": get(env, context, "jobSelection.confirmPass") }, child1, null);
                return fragment;
              }
            };
          })();
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.4",
            blockParams: 1,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement, blockArguments) {
              var dom = env.dom;
              var hooks = env.hooks,
                  set = hooks.set,
                  get = hooks.get,
                  block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, null);
              dom.insertBoundary(fragment, 0);
              set(env, context, "jobSelection", blockArguments[0]);
              block(env, morph0, context, "slide-toggle", [], { "open": get(env, context, "jobSelection.visible") }, child0, null);
              return fragment;
            }
          };
        })();
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 1,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "job-selections");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement, blockArguments) {
            var dom = env.dom;
            var hooks = env.hooks,
                set = hooks.set,
                get = hooks.get,
                block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
            var morph1 = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
            dom.insertBoundary(fragment, 0);
            set(env, context, "firmLocationJob", blockArguments[0]);
            block(env, morph0, context, "slide-toggle", [], { "open": get(env, context, "firmLocationJob.visible") }, child0, null);
            block(env, morph1, context, "each", [get(env, context, "firmLocationJob.jobSelections")], {}, child1, null);
            return fragment;
          }
        };
      })();
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.4",
        blockParams: 1,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "firm-location-jobs");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement, blockArguments) {
          var dom = env.dom;
          var hooks = env.hooks,
              set = hooks.set,
              get = hooks.get,
              block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
          var morph1 = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          dom.insertBoundary(fragment, 0);
          set(env, context, "firmLocation", blockArguments[0]);
          block(env, morph0, context, "slide-toggle", [], { "open": get(env, context, "firmLocation.visible") }, child0, null);
          block(env, morph1, context, "each", [get(env, context, "firmLocation.firmLocationJobs")], {}, child1, null);
          return fragment;
        }
      };
    })();
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.4",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "filters");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "");
        var el5 = dom.createTextNode("Candidates");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "separator");
        var el5 = dom.createTextNode("/");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "");
        var el5 = dom.createTextNode("Passed");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "continue");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "firm-locations");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks,
            element = hooks.element,
            get = hooks.get,
            block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element13 = dom.childAt(fragment, [0, 1]);
        var element14 = dom.childAt(element13, [1]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element14, [5]);
        var morph0 = dom.createMorphAt(dom.childAt(element13, [3]), 1, 1);
        var morph1 = dom.createMorphAt(fragment, 2, 2, contextualElement);
        var morph2 = dom.createMorphAt(fragment, 3, 3, contextualElement);
        var morph3 = dom.createMorphAt(dom.childAt(fragment, [4, 1]), 1, 1);
        element(env, element15, context, "bind-attr", [], { "class": "stateAll:active" });
        element(env, element15, context, "action", ["filterState", "all"], {});
        element(env, element16, context, "bind-attr", [], { "class": "stateHidden:active" });
        element(env, element16, context, "action", ["filterState", "hidden"], {});
        block(env, morph0, context, "if", [get(env, context, "stateAgreed")], {}, child0, child1);
        block(env, morph1, context, "slide-toggle", [], { "open": get(env, context, "anyVisibleFirmLocations") }, child2, null);
        block(env, morph2, context, "slide-toggle", [], { "closed": get(env, context, "anyVisibleFirmLocations") }, child3, null);
        block(env, morph3, context, "each", [get(env, context, "controller")], {}, child4, null);
        return fragment;
      }
    };
  })());
});