define('laterally/components/user-claimer/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['user-claimer'],

    user: null,
    deferred: null,
    options: null,

    termsAccepted: true,

    fulfilled: function fulfilled() {
      this.sendAction('dismiss');
      get(this, 'deferred').resolve();
    },

    aborted: function aborted() {
      this.sendAction('dismiss');
      get(this, 'deferred').reject();
    },

    actions: {
      submit: function submit() {
        var _this = this;

        if (get(this, 'termsAccepted')) {
          var user = get(this, 'user');

          user.save().then(function () {
            set(user, 'claimed', true);

            _this.analytics.track('Claimed profile', {
              user: _this.getProperties('id', 'name')
            });

            _this.fulfilled();
          });
        }
      },

      outsideClick: function outsideClick() {
        this.aborted();
      },

      escape: function escape() {
        this.aborted();
      },

      dismiss: function dismiss() {
        this.aborted();
      }
    }
  });
});