define('laterally/utils/computed/moment-unix', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;

  exports['default'] = function (key) {
    return computed(key, function () {
      var moment = get(this, key);

      if (moment) {
        return moment.unix();
      } else {
        return null;
      }
    });
  };
});