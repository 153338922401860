define('laterally/components/from-now', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    moment: null,
    fromNow: '',

    clock: (function () {
      if (this.get('isDestroyed')) {
        return;
      }
      var fromNow = window.moment(this.get('moment')).fromNow();
      var laterSeconds;
      if (window.moment().diff(this.get('moment', 'seconds')) < 60) {
        laterSeconds = 10;
      } else {
        laterSeconds = 60;
      }
      this.set('fromNow', fromNow);
      _ember['default'].run.later(this, this.clock, 1000 * laterSeconds);
    }).on('didInsertElement'),

    prettyDate: (function () {
      if (this.get('moment')) {
        return this.get('moment')._d;
      }
    }).property('moment')
  });
});