define('laterally/components/login-form/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    tagName: 'form',

    token: null,
    'on-submit': null,

    submit: function submit(e) {
      e.preventDefault();
      this.sendAction('on-submit', get(this, 'token'));
    }
  });
});