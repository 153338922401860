define('laterally/components/form-for/property', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  var bool = _ember['default'].computed.bool;
  var capitalize = _ember['default'].computed.capitalize;
  var Binding = _ember['default'].Binding;

  exports['default'] = _ember['default'].Mixin.create({

    classNames: ['form-for--property'],
    classNameBindings: ['hasError'],

    // attrs
    form: null,
    property: null,
    label: computed('property', function () {
      var parts = get(this, 'property').split('.');
      return parts.last().titleize();
    }),

    hasError: bool('error'),

    errorHint: capitalize('error'),

    errorBinder: on('init', function () {
      var prop = get(this, 'property');
      var error = 'form.model.errors.' + prop + '.firstObject.message';
      Binding.from(error).to('error').connect(this);
    })

  });
});