define('laterally/routes/concerns/modal-on-step', ['exports', 'ember', 'laterally/routes/concerns/require-user', 'laterally/models/firm-user'], function (exports, _ember, _laterallyRoutesConcernsRequireUser, _laterallyModelsFirmUser) {
  exports['default'] = _ember['default'].Mixin.create(_laterallyRoutesConcernsRequireUser['default'], {

    user: null,

    getUser: (function (user) {
      this.user = user;
    }).on('requiredUser'),

    showModal: function showModal(model, transition, options) {
      if (options && options.state && options.modal) {
        if (!options.modalContext) {
          options.modalContext = {};
        }
        if (this.user.get('userable.step') === _laterallyModelsFirmUser['default'].STEPS[options.state.toUpperCase()]) {
          _ember['default'].run.schedule("afterRender", this, function () {
            transition.send("openModal", options.modal, options.modalContext);
          });
        }
      } else {
        throw "showModal options must have a state and a modal defined";
      }
    }

  });
});