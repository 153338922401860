define('laterally/routes/concerns/require-userable', ['exports', 'ember', 'laterally/routes/concerns/require-user'], function (exports, _ember, _laterallyRoutesConcernsRequireUser) {
  exports['default'] = _ember['default'].Mixin.create(_laterallyRoutesConcernsRequireUser['default'], {

    verifyUserable: (function (user) {
      var userable = user.get('userable');
      if (userable) {
        this.trigger('requiredUserable', userable);
      } else {
        this.transitionTo('landing');
      }
    }).on('requiredUser')

  });
});