define('laterally/components/embed-pdf', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var htmlSafe = _ember['default'].String.htmlSafe;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['embed-pdf'],

    type: 'application/pdf',

    // attrs
    src: '',

    useGoogleDocsEmbed: (function () {
      return !!navigator.userAgent.match(/iPad|iPhone|iPod/);
    }).property(),

    url: (function () {
      var src = this.get('src');

      if (src) {
        if (this.get('useGoogleDocsEmbed')) {
          var protocol = window.location.protocol;
          var path = [_laterallyConfigEnvironment['default'].APP.API_HOST, this.get('src')].join('');
          return [protocol, '//docs.google.com/viewer?embedded=true&url=', encodeURIComponent(path)].join('');
        } else {
          return htmlSafe([_laterallyConfigEnvironment['default'].APP.API_HOST, this.get('src')].join(''));
        }
      } else {
        return '';
      }
    }).property('src')

  });
});