define('laterally/controllers/application/contact-modal', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({

    sendTo: null,
    exploreeGroup: null,

    messenger: _ember['default'].inject.service(),
    messageSent: _ember['default'].computed.notEmpty('messenger.state'),
    messageInFlight: _ember['default'].computed.equal('messenger.state', 'inFlight'),

    conversation: alias('messenger.conversation'),

    replacements: (function () {
      return {
        'first_name': this.get('sendTo.firstName')
      };
    }).property('sendTo'),

    actions: {

      sendMessage: function sendMessage(body) {
        var _this = this;

        this.get('messenger').sendMessage(body, this.get('sendTo'), this.get('exploreeGroup')).then(function () {
          if (_this.get('afterSend')) {
            _this.get('afterSend').apply(_this);
          }
        });
      },

      disconnectModal: function disconnectModal() {
        this.get('messenger').clearState();
        return true;
      }
    }

  });
});