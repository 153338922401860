define('laterally/models/exploree', ['exports', 'ember', 'ember-data', 'laterally/models/concerns/profile-picture', 'laterally/models/concerns/transactor', 'laterally/models/concerns/admittee', 'laterally/models/concerns/experiencer'], function (exports, _ember, _emberData, _laterallyModelsConcernsProfilePicture, _laterallyModelsConcernsTransactor, _laterallyModelsConcernsAdmittee, _laterallyModelsConcernsExperiencer) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;

  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;

  var alias = _ember['default'].computed.alias;

  exports['default'] = _emberData['default'].Model.extend(_laterallyModelsConcernsProfilePicture['default'], _laterallyModelsConcernsTransactor['default'], _laterallyModelsConcernsAdmittee['default'], _laterallyModelsConcernsExperiencer['default'], {

    isExploree: true,

    firstName: attr('string'),
    fullName: attr('string'),
    firmProfileUrl: attr('string'),
    position: attr('string'),
    positionType: attr('string'),
    bio: attr('string'),
    firm: attr('string'),

    name: alias('fullName'),

    lawyerId: attr('string'),
    user: alias('lawyer.user'),

    locations: hasMany('location'),
    practiceAreas: hasMany('practiceArea'),
    clerkships: hasMany('clerkship'),
    schoolAttendances: hasMany('schoolAttendances'),
    languages: hasMany('languages'),
    experiences: hasMany('experiences'),
    memberships: hasMany('exploreeGroupMemberships'),
    exploreVotes: hasMany('exploreVotes'),
    exploreeComments: hasMany('exploreeComments'),

    lawSchoolAttendance: (function () {
      var attendances = this.get('schoolAttendances').filterBy('school.lawSchool');
      return attendances.findBy('isJD') || attendances.sortBy('graduation').get('firstObject');
    }).property('schoolAttendances.@each.{school.lawSchool,isJD,graduation}'),

    yesCount: (function () {
      return this.get('yesVotes.length');
    }).property('yesVotes.length'),

    yesVotes: (function () {
      return this.get('exploreVotes').filterBy('value', 'yes');
    }).property('exploreVotes.@each.value'),

    noCount: (function () {
      return this.get('noVotes.length');
    }).property('noVotes.length'),

    commentsCount: (function () {
      return this.get('exploreeComments.length');
    }).property('exploreeComments.length'),

    noVotes: (function () {
      return this.get('exploreVotes').filterBy('value', 'no');
    }).property('exploreVotes.@each.value')

  });
});