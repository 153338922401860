define('laterally/models/school', ['exports', 'ember-data'], function (exports, _emberData) {

  var attr = _emberData['default'].attr;

  exports['default'] = _emberData['default'].Model.extend({

    name: attr('string'),
    lawSchool: attr('boolean')

  });
});