define('laterally/controllers/conversations/show/job', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({

    userable: alias('session.user.userable'),

    actions: {
      respond: function respond() {
        this.transitionToRoute('conversations.show', this.get('model'));
      }
    }

  });
});