define('laterally/components/editable-card/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['editable-card'],
    classNameBindings: ['isEditing'],

    // attrs
    model: null,

    isSaving: false,
    isEditing: false,

    actions: {

      save: function save() {
        var _this = this;

        set(this, 'isSaving', true);
        set(this, 'isEditing', false);
        get(this, 'model').save()['finally'](function () {
          set(_this, 'isSaving', false);
          _this.sendAction('savedOrCanceled');
        });
      },

      edit: function edit() {
        set(this, 'isEditing', true);
      },

      cancel: function cancel() {
        get(this, 'model').rollback();
        set(this, 'isEditing', false);
        this.sendAction('savedOrCanceled');
      }

    }

  });
});