define('laterally/controllers/candidates/job-selection', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({

    needs: ['candidates'],

    stateHidden: alias('controllers.candidates.stateHidden'),
    currentUserable: alias('session.user.userable'),

    confirmPass: false,

    visible: (function () {
      return this.get('stateHidden') === this.get('hidden');
    }).property('stateHidden', 'hidden'),

    hidden: (function () {
      return this.get('model.hiddenFromFirms');
    }).property(),

    firstOfJob: (function () {
      return this.get('parentController').indexOf(this) === 0;
    }).property('parentController.[]'),

    conversation: (function () {
      var userable = this.get('currentUserable');
      if (userable.get('isFirm')) {
        var lawyer = this.get('model.lawyer');
        return userable.get('conversations').find(function (conversation) {
          return conversation.get('lawyers').contains(lawyer);
        });
      }
    }).property('currentUserable', 'currentUserable.conversations.[]', 'lawyer'),

    track: function track(message) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var lawyer = this.get('model.lawyer');
      data.lawyer = lawyer.getProperties('id', 'name', 'email');
      this.analytics.track(message, data);
    },

    actions: {

      confirmPass: function confirmPass() {
        this.set('confirmPass', true);
      },

      contact: function contact(conversation) {
        if (conversation) {
          this.transitionToRoute('conversations.show', conversation.get('id'));
        } else {
          var lawyer = this.get('model.lawyer');
          this.send('openModal', 'application/contactModal', { sendTo: lawyer });
          this.track('Opened contact modal');
        }
      },

      unhide: function unhide() {
        this.set('hidden', false);
        this.get('model').unhide();
        this.track('Unhid candidate');
      },

      saveHidden: function saveHidden() {
        var _this = this;

        var jobSelection = this.get('model');
        var reason = jobSelection.get('hiddenReason');
        jobSelection.save().then(function () {
          _this.set('hidden', true);
          _this.track('Hid candidate', { reason: reason });
        });
      }
    }

  });
});