define('laterally/models/region', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var computed = _ember['default'].computed;
  var filterBy = computed.filterBy;
  var sort = computed.sort;
  exports['default'] = _emberData['default'].Model.extend({
    orderSorting: ['order'],

    name: _emberData['default'].attr('string'),
    isInternational: _emberData['default'].attr('boolean'),
    order: _emberData['default'].attr('number'),

    locations: _emberData['default'].hasMany('location'),
    sortedLocations: sort('locations', 'orderSorting'),

    primaryLocations: filterBy('locations', 'isPrimary', true),
    nonPrimaryLocations: filterBy('locations', 'isPrimary', false),

    sortedPrimaryLocations: sort('primaryLocations', 'orderSorting'),

    statedLocations: filterBy('locations', 'hasState', true),
    unstatedLocations: filterBy('locations', 'hasState', false),

    sortedStatedLocations: sort('statedLocations', 'orderSorting')

  });
});