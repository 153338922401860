define('laterally/components/keyboard-nav/component', ['exports', 'ember', 'laterally/components/keyboard-nav-target/component'], function (exports, _ember, _laterallyComponentsKeyboardNavTargetComponent) {

  var get = _ember['default'].get;
  var targetSelector = '.' + _laterallyComponentsKeyboardNavTargetComponent.targetClass;
  var on = _ember['default'].on;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Component.extend({

    focused: null,

    // attrs
    'on-select': null,
    'on-focus': null,
    value: null,

    dispatchKeyDown: on('keyDown', function (event) {
      switch (event.keyCode) {
        case 13:
          // enter
          var focused = this.get('focused');
          if (focused) {
            this.$(focused).trigger('mousedown');
          }
          break;
        case 38:
          // up arrow
          this.move(-1);break;
        case 40:
          // down arrow
          this.move(+1);break;
        default:
          return;
      }
      event.preventDefault();
    }),

    move: function move(direction) {
      var targets = this.$(targetSelector);
      var focused = this.get('focused');
      var index;
      if (focused) {
        index = (targets.index(focused) + direction).mod(targets.length);
      } else {
        index = direction === 1 ? 0 : targets.length - 1;
      }
      this.send('focus', targets[index]);
    },

    initializeFocus: function initializeFocus() {
      if (!get(this, 'isDestroying') && !get(this, 'isDestroyed')) {
        this.send('focus', this.$(targetSelector)[0]);
      }
    },

    scheduleFocus: function scheduleFocus() {
      run.scheduleOnce('afterRender', this, 'initializeFocus');
    },

    actions: {

      select: function select(value) {
        this.sendAction('on-select', value);
      },

      focus: function focus(element) {
        this.sendAction('on-focus');
        this.set('focused', element);
      },

      didInsertChild: function didInsertChild() {
        if (!this.get('focused')) {
          this.scheduleFocus();
        }
      },

      willDestroyChild: function willDestroyChild(element) {
        if (element === this.get('focused')) {
          this.set('focused', null);
          this.scheduleFocus();
        }
      }

    }

  });
});