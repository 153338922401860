define('laterally/routes/applications', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel(transition) {
      if (!get(this.session, 'user.userable.isLawyer')) {
        transition.abort();
      }
    },

    model: function model() {
      return get(this.session, 'user.userable');
    },

    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
});