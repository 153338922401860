define('laterally/components/editable-buttons/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['editable-buttons'],

    // attrs
    'on-save': 'save',
    'on-edit': 'edit',
    'on-cancel': 'cancel',
    isSaving: false,
    isEditing: false,
    isNew: false,
    neverShowEdit: false,
    editText: null,

    actions: {

      save: function save() {
        this.sendAction('on-save');
      },

      edit: function edit() {
        this.sendAction('on-edit');
      },

      cancel: function cancel() {
        this.sendAction('on-cancel');
      }

    }

  });
});