define('laterally/controllers/settings/change-password', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Controller.extend({

    password: null,
    current_password: null,

    errors: null,
    success: false,

    ajaxHeaders: (function () {
      var headers = {};
      headers['Authorization'] = ['Bearer', this.get('session.token.value')].join(' ');

      return headers;
    }).property('session.token'),

    actions: {

      submit: function submit() {
        var _this = this;

        this.set("errors", null);
        this.set("success", false);
        $.ajax(_laterallyConfigEnvironment['default'].APP.API_HOST + '/api/user_passwords/change', { type: 'POST', headers: this.get('ajaxHeaders'), data: {
            current_password: this.get("current_password"),
            password: this.get("password")
          } }).then(function (response) {
          _this.set('success', true);
          _this.analytics.track('Session::ChangePassword', response);
        }, function (data) {
          var errors = data.responseJSON.errors;
          _this.set("errors", errors);
        });
      }

    }

  });
});