define("laterally/utils/computed/not-any", ["exports"], function (exports) {
  exports["default"] = function () {
    var properties = [].slice.call(arguments);
    var func = function func() {
      return properties.every(function (property) {
        return !this.get(property);
      }, this);
    };
    return func.property.apply(func, properties);
  };
});