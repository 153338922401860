define('laterally/models/conversation', ['exports', 'ember', 'ember-data', 'laterally/utils/rest-action'], function (exports, _ember, _emberData, _laterallyUtilsRestAction) {

  var computed = _ember['default'].computed;
  var not = _ember['default'].computed.not;
  var alias = _ember['default'].computed.alias;
  var bool = _ember['default'].computed.bool;

  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;

  exports['default'] = _emberData['default'].Model.extend({

    messages: hasMany('message'),
    sortedMessages: computed('messages.[]', function () {
      return this.get('messages').sortBy('createdAtUnix');
    }),

    lawyers: hasMany('lawyer'),
    firmUsers: hasMany('firmUser'),
    exploreeGroup: belongsTo('exploreeGroup'),
    hasExploreeGroup: bool('exploreeGroup'),

    createdAt: attr('moment'),
    createdAtUnix: (function () {
      return this.get('createdAt').unix();
    }).property('createdAt'),

    // for creating
    lawyerId: attr('string'),

    job: alias('exploreeGroup.job'),
    contactee: alias('lawyers.firstObject'),

    unreadCount: attr('number'),
    unread: bool('unreadCount'),
    read: not('unreadCount'),

    connectionId: attr(),

    readLatest: function readLatest() {
      if (this.get('unreadCount') > 0) {
        this.store.push('conversation', {
          id: this.get('id'),
          unreadCount: 0
        });
        this.persistReadLatest();
      }
    },

    persistReadLatest: (0, _laterallyUtilsRestAction['default'])('read_latest'),

    dateForSorting: (function () {
      if (this.get('sortedMessages.lastObject.createdAtUnix')) {
        return this.get('sortedMessages.lastObject.createdAtUnix');
      }
      return this.get('createdAtUnix');
    }).property('sortedMessages.lastObject.createdAtUnix', 'createdAtUnix')

  });
});