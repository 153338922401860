define('laterally/components/jobs-history/experience-filter/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    minExperience: null,
    maxExperience: null,
    thresholdMax: null,

    startExperience: null,
    endExperience: null,

    experienceRange: computed('minExperience', 'thresholdMax', function () {
      return {
        min: get(this, 'minExperience'),
        max: get(this, 'thresholdMax') + 1 + 1
      };
    }),

    experienceDefaults: computed('startExperience', 'endExperience', function () {
      return [get(this, 'startExperience'), get(this, 'endExperience')];
    }),

    willInsertElement: function willInsertElement() {
      var _this = this;

      set(this, 'formatTo', function (value, idx) {
        if (idx === 0 && value > get(_this, 'thresholdMax')) {
          return get(_this, 'maxExperience');
        } else if (idx === 1 && value > get(_this, 'thresholdMax') + 1) {
          return get(_this, 'maxExperience');
        } else {
          if (idx === 1) {
            value--;
          }

          return value;
        }
      });
    },

    formatFrom: function formatFrom(value, idx) {
      if (idx === 1) {
        value++;
      }

      return value;
    },

    actions: {
      experienceSelected: function experienceSelected(values) {
        this.sendAction('experienceSelected', values);
      }
    }
  });
});