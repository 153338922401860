define('laterally/controllers/refer', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var $ = _ember['default'].$;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({
    user: alias('session.user'),

    showLoginForm: false,

    referrableURL: (function () {
      return [_laterallyConfigEnvironment['default'].REFERRAL_HOST, '/r/', this.get('model.token')].join('');
    }).property('model.token'),

    actions: {

      showLoginForm: function showLoginForm() {
        this.set('showLoginForm', true);
      },

      logIn: function logIn() {
        var _this = this;

        this.session.open(this.get('token')).then(function (token) {
          get(token, 'user').then(function (user) {
            set(get(_this, 'model'), 'email', get(user, 'email'));
            get(_this, 'model').setProperties(get(user, 'userable').getProperties('firstName lastName'.w()));

            if (get(_this, 'model.firstName') && get(_this, 'model.lastName')) {
              _this.send('createReferrer');
            }
          });
        });
      },

      createReferrer: function createReferrer() {
        var _this2 = this;

        if (!this.get('model.firstName') || !this.get('model.lastName')) {
          window.alert('You must provide a first and last name');
        } else {
          this.referrerManager.createReferrer(get(this, 'model')).then(function () {
            _this2.get('analytics').track('Joined as Referrer');
          });
        }
      },

      openDialog: function openDialog(vendor) {
        var url,
            options = {
          width: 500,
          height: 300,
          top: ($(window).height() - 300) / 2,
          left: ($(window).width() - 500) / 2,
          location: "no",
          directories: "no",
          menubar: "no",
          status: "no",
          titlebar: "no",
          toolbar: "no"
        };
        switch (vendor) {
          case "facebook":
            url = 'https://www.facebook.com/dialog/share?app_id=' + _laterallyConfigEnvironment['default'].APP.FACEBOOK_APP_ID + '&display=popup&href=';
            break;
          case "twitter":
            url = 'https://twitter.com/intent/tweet?text=';
            break;
        }

        url += encodeURIComponent(this.get('referrableURL'));
        options = _ember['default'].keys(options).map(function (key) {
          return key + "=" + options[key];
        }).join(",");
        this.get('analytics').track("Shared on " + _ember['default'].String.capitalize(vendor));
        window.open(url, "oauth_popup", options);
      }
    }

  });
});