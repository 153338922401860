define('laterally/components/jobs/firm-member-edit-card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['job-profile-group--member-container'],
    classNameBindings: ['deleteable', 'hideParent:hidden'],

    member: null,
    removeMember: null,
    deleteable: false,
    hiddenBySlide: false,
    hideParent: false,

    actions: {
      hideCard: function hideCard() {
        this.set('hiddenBySlide', true);
      },

      uponHiding: function uponHiding() {
        this.sendAction('removeMember', this.get('member'));
        this.set('hideParent', true);
      }
    },

    showDelete: (function () {
      this.set('deleteable', true);
    }).on('mouseEnter'),

    hideDelete: (function () {
      this.set('deleteable', false);
    }).on('mouseLeave')

  });
});