define('laterally/routes/connect', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  exports['default'] = Route.extend({

    model: function model(params) {
      var _this = this;

      this.analytics.track('Visited exploree claim link', { claimToken: params.claimToken });

      return this.session.openWithClaimToken(params.claimToken).then(function () {
        _this.store.find('connection').then(function (connections) {
          var unseenConnections = connections.filterBy('seenByLawyer', false);

          if (get(unseenConnections, 'length') === 1) {
            _this.transitionTo('connection', get(unseenConnections, 'firstObject'));
          } else {
            _this.transitionTo('connections.index');
          }
        });
      }, function () {
        _this.transitionTo('login');
      });
    }

  });
});