define('laterally/controllers/lawyer/index', ['exports', 'ember', 'laterally/models/lawyer'], function (exports, _ember, _laterallyModelsLawyer) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var run = _ember['default'].run;

  function showQuestion(question) {
    var showToNew = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

    return computed('canEdit', 'lawyer.' + question, function () {
      var lawyer = get(this, 'lawyer');
      var canEdit = get(this, 'canEdit');
      var hasAnswer = !!get(lawyer, question);
      var editedAnswer = lawyer && !!lawyer.changedAttributes()[question];
      return hasAnswer || canEdit && (editedAnswer || showToNew);
    });
  }

  exports['default'] = _ember['default'].Controller.extend({
    needs: ['lawyer', 'application'],
    showErrorsFor: computed.alias('controllers.lawyer.showErrorsFor'),

    // attrs
    model: null,
    lawyer: alias('model'),
    canEdit: false,
    isEditing: alias('controllers.lawyer.isEditing'),
    isMine: alias('controllers.lawyer.isMine'),

    lastJobSelectionNote: computed('session.userable', 'model.jobSelections.[]', function () {
      var userable = get(this, 'session.userable');
      if (userable && userable.get('isFirm')) {
        var firm = get(userable, 'firm');
        return this.get('model.jobSelections').toArray().reverse().filterProperty('firmLocationJob.firmLocation.firm', firm).filterProperty('notes').get('firstObject.notes');
      }
    }),

    showQuestionProudest: showQuestion('questionProudest'),
    showQuestionOtherCareer: showQuestion('questionOtherCareer'),
    showQuestionBesidesResume: showQuestion('questionBesidesResume', true),

    actions: {

      openPracticeAreasModal: function openPracticeAreasModal() {
        this.send('openModal', 'application/practice-areas', {
          modalTitle: 'Edit Target Practice Areas',
          max: _laterallyModelsLawyer['default'].maxPracticeAreas,
          selections: this.get('session.userable.desiredPracticeAreas'),
          practiceAreaCategories: this.get('controllers.application.practiceAreaCategories'),
          onSave: (function (selected, match, defer) {
            var selections = this.get('session.userable.desiredPracticeAreas');
            selections.setObjects(selected);
            this.get('session.userable').save().then(function () {
              defer.resolve();
            });
          }).bind(this)
        });
      },

      openLocationsModal: function openLocationsModal() {
        this.send('openModal', 'lawyer/index/locations');
      },

      ensureOneBarAdmission: function ensureOneBarAdmission() {
        var _this = this;

        // Run next to check length after user possibly
        // clicked 'add' button (which triggers edit mode)
        run.next(function () {
          var barAdmissions = get(_this, 'lawyer.barAdmissions');

          if (get(barAdmissions, 'length') === 0) {
            barAdmissions.createRecord();
          }
        });
      }

    }

  });
});