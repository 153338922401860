define('laterally/controllers/application/firm-steps', ['exports', 'ember', 'laterally/models/firm-user'], function (exports, _ember, _laterallyModelsFirmUser) {
  exports['default'] = _ember['default'].ArrayController.extend({

    itemController: 'application/header-step',

    model: (function () {
      return [{
        name: 'Complete Profile',
        routeName: 'firm-user',
        routeContext: this.get('session.user.userable'),
        step: _laterallyModelsFirmUser['default'].STEPS.START,
        after: _laterallyModelsFirmUser['default'].STEPS.START
      }, {
        name: 'View Candidates',
        routeName: 'candidates',
        step: _laterallyModelsFirmUser['default'].STEPS.START,
        after: _laterallyModelsFirmUser['default'].STEPS.START
      }, {
        name: 'Connect',
        routeName: 'offers',
        step: _laterallyModelsFirmUser['default'].STEPS.APPROVED,
        after: _laterallyModelsFirmUser['default'].STEPS.APPROVED
      }];
    }).property('session.user.userable')

  });
});