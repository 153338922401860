define('laterally/controllers/explore-jobs/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ['preview'],
    isEditing: false,
    firmLocationToAdd: null,
    viewingAsAssociate: false,

    // set in route
    campaign: null,
    committer: null,

    setEditingIfDirty: (function () {
      if (this.get('model.hasDirtyAttributes') && !this.get('model.isSaving')) {
        this.set('isEditing', true);
      }
      if (this.get('model.firmGroupMembers').isAny('isDirty', true)) {
        this.set('isEditing', true);
      }
    }).observes('model.hasDirtyAttributes', 'model.isSaving', 'model.firmGroupMembers.@each.isDirty'),

    addFirmLocation: (function () {
      this.track('Adding Firm Location');
      var firmLocation = this.get('firmLocationToAdd');
      if (!_ember['default'].isNone(firmLocation)) {
        var firmLocations = this.get('model.firmLocations');
        if (firmLocations.contains(firmLocation)) {
          firmLocations.removeObject(firmLocation);
        } else {
          firmLocations.addObject(firmLocation);
        }
        this.set('isEditing', true);
        this.set('firmLocationToAdd', null);
      }
    }).observes('firmLocationToAdd'),

    track: function track(message, props) {
      if (!props) {
        props = {};
      }
      var campaign = this.get('campaign');
      props.exploreeGroup = campaign ? campaign.getProperties('id', 'name') : campaign;
      this.analytics.track(message, props);
    },

    verifyJobExists: function verifyJobExists() {
      var promise;
      if (this.get('model.id')) {
        promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
          resolve();
        });
      } else {
        promise = this.get('model').save();
      }
      return promise;
    },

    saveJob: function saveJob(defer) {
      var _this = this;

      this.set('isEditing', false);
      return _ember['default'].RSVP.all([this.get('model').save(), this.get('committer').saveRecords()]).then(function () {
        if (defer) {
          defer.resolve();
        }
        _this.set('isEditing', false);
      }, function () {
        if (defer) {
          defer.reject();
        }
        _this.set('isEditing', true);
      });
    },

    actions: {
      save: function save(defer) {
        this.track('Saving Job Profile');
        this.saveJob(defer);
      },

      edit: function edit() {
        this.track('Editing Job Profile');
        this.send('focusOn', 'job-description', 'explore-jobs.edit', { id: this.get('campaign.id') });
        this.set('isEditing', true);
      },

      cancel: function cancel() {
        this.track('Cancelling Job Profile');
        this.get('model').rollback();
        this.set('isEditing', false);
        //this.send('savedOrCanceled');
      },

      addFirmGroupMember: function addFirmGroupMember() {
        var _this2 = this;

        this.track('Adding Team Member');
        this.verifyJobExists().then(function () {
          if (_this2.get('model.errors.length') == 0) {
            _this2.set('addMemberError', null);
            var newMember = _this2.store.createRecord('firmGroupMember');
            newMember.save();
            _this2.get('model.firmGroupMembers').addObject(newMember);
            _this2.set('isEditing', true);
          } else {
            _this2.set('addMemberError', "Please correct the errors in red then try again.");
          }
        }, function () {
          _this2.set('addMemberError', "Please correct the errors in red then try again.");
        });
      },

      removeFirmLocation: function removeFirmLocation(locationToRemove) {
        this.track('Removing Firm Location');
        this.get('model.firmLocations').removeObject(locationToRemove);
        this.set('isEditing', true);
      },

      viewAsAssociate: function viewAsAssociate() {
        this.track('Viewing Job Profile as Associate');
        this.toggleProperty('viewingAsAssociate');
      },

      backToEdit: function backToEdit() {
        this.track('Back to Edit Job Profile');
        this.set('viewingAsAssociate', false);
      },

      removeMember: function removeMember(member) {
        this.track('Removing Team Member');
        this.get('model.firmGroupMembers').removeObject(member);
        this.get('committer').removeRecord(member);
        this.set('isEditing', true);
      },

      next: function next() {
        this.track('Clicked on Next from Explore Step 2 (Job Profile)');
        this.transitionToRoute('explorees.connect', this.get('campaign.id'));
      },

      goToConnect: function goToConnect() {
        this.transitionToRoute('explorees.connect', this.get('campaign.id'));
      }
    }
  });
});