define('laterally/routes/chat', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      var _this = this;

      this.analytics.track('Visited exploree claim link', { claimToken: params.claimToken });

      return this.session.openWithClaimToken(params.claimToken).then(function () {
        _this.store.find('conversation').then(function (conversations) {
          var conversation = conversations.get('firstObject');

          if (conversation) {
            _this.transitionTo('conversations.show', conversation);
          } else {
            _this.transitionTo('conversations');
          }
        });
      }, function () {
        _this.transitionTo('login');
      });
    }

  });
});