define('laterally/components/draggable-profile-picture', ['exports', 'ember', 'laterally/config/environment', 'laterally/mixins/profile-picture'], function (exports, _ember, _laterallyConfigEnvironment, _laterallyMixinsProfilePicture) {
  exports['default'] = _ember['default'].Component.extend(_laterallyMixinsProfilePicture['default'], {

    classNameBindings: ["styleClass", "draggable"],

    src: (function () {
      return _laterallyConfigEnvironment['default'].APP.API_HOST + this.get('model.profilePicture.original');
    }).property('model.profilePicture.original'),
    noImage: true,

    draggableContainment: (function () {
      var $this = this.$('img');
      if (!$this) {
        return;
      }

      var $parent = this.$();
      if (!$parent) {
        return;
      }

      var offset = $parent.offset();
      var x1 = offset.left < 0 ? false : offset.left;
      var y1 = offset.top < 0 ? false : offset.top;
      var w = $parent.width();
      var h = $parent.height();

      var x2 = x1;
      if ($this.width() > w) {
        x1 = x1 + 150 - $this.width();
      }

      var y2 = y1;
      if ($this.height() > h) {
        y1 = y1 + 150 - $this.height();
      }

      return [x1, y1, x2, y2];
    }).property().volatile(),

    initializeDrag: function initializeDrag() {
      if (!this.get("initializedDrag")) {
        _ember['default'].run.later(this, function () {
          if (!this.$('img') || !this.$('img').width || !this.$('img').height) {
            return;
          }

          if (this.$('img').width() === 0 || this.$('img').height() === 0) {
            this.initializeDrag();
          }

          this.$('img').draggable({
            containment: this.get("draggableContainment"),
            stop: this.setPosition.bind(this)
          });
          this.set("initializedDrag", true);
        });
      }
    },

    updateContainment: (function () {
      if (!this.get("initializedDrag")) {
        this.initializeDrag();
      } else {
        _ember['default'].run.later(this, function () {
          if (!this.get('noImage') && this.$('img')) {
            this.$('img').draggable("option", "containment", this.get("draggableContainment"));
          }
        });
      }
    }).observes("model.profilePicture.original"),

    setPosition: function setPosition(event, ui) {
      this.get("model").setProperties({
        profilePictureTop: this.convertToRatio(ui.position.top),
        profilePictureLeft: this.convertToRatio(ui.position.left)
      }).save();
    },

    updatePosition: (function () {
      var top = this.get('model.profilePictureTop');
      var left = this.get('model.profilePictureLeft');
      if (top !== undefined && left !== undefined) {
        this.$('img').css({
          top: this.convertFromRatio(top),
          left: this.convertFromRatio(left)
        });
      }
    }).observes("model.profilePictureTop", "model.profilePictureLeft"),

    convertFromRatio: function convertFromRatio(value) {
      return -value * this.$('img').parent().width();
    },

    convertToRatio: function convertToRatio(value) {
      return -value / this.$('img').parent().height();
    },

    reset: (function () {
      if (this.get("noImage")) {
        this.set("noImage", false);
        _ember['default'].run.later(this, this.dragger);
      }
    }).observes("model.profilePicture.original"),

    resetOffset: function resetOffset() {
      var previous = this.get('previousImage');
      var previousName = previous.split("?").shift();
      var current = this.get('model.profilePicture.original');
      if (current == null) {
        return;
      }
      var currentName = current.split("?").shift();
      if (previousName !== currentName) {
        this.get('model').setProperties({
          profilePictureTop: 0,
          profilePictureLeft: 0
        });
        this.set('previousImage', current);
      }
    },

    dragger: (function () {
      if (this.get('isDestroyed')) {
        return;
      }
      this.set("previousImage", this.get("model.profilePicture.original"));
      this.updateStyles();
      this.updatePosition();
      this.$('img').on('load.resize', this.updateStyles.bind(this));
      this.$('img').on('load.resize', this.updatePosition.bind(this));
      this.$('img').on('load.resize', this.updateContainment.bind(this));
      this.$('img').on('load.resize', this.resetOffset.bind(this));
      if (this.$('img') && this.$('img').length > 0) {
        this.set("noImage", false);
      }
    }).on("didInsertElement")

  });
});