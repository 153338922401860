define('laterally/serializers/lawyer', ['exports', 'ember-data', 'laterally/serializers/application'], function (exports, _emberData, _laterallySerializersApplication) {
  exports['default'] = _laterallySerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {

    attrs: {
      desiredLocations: {
        serialize: 'ids'
      },
      desiredPracticeAreas: {
        serialize: 'ids'
      },
      barAdmissions: {
        deserialize: 'records'
      },
      transactions: {
        deserialize: 'records'
      }
    }

  });
});