define('laterally/components/form-for/input', ['exports', 'ember', 'laterally/components/form-for/property'], function (exports, _ember, _laterallyComponentsFormForProperty) {

  var get = _ember['default'].get;
  var on = _ember['default'].on;
  var Binding = _ember['default'].Binding;

  exports['default'] = _ember['default'].Mixin.create(_laterallyComponentsFormForProperty['default'], {

    classNames: ['form-for--input'],

    valueBinder: on('init', function () {
      var prop = get(this, 'property');
      var value = 'form.model.' + prop;
      Binding.from(value).to('value').connect(this);
    })

  });
});