define('laterally/components/lawyer-profile-progress-requirement/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'a',

    classNames: ['progression-requirement'],
    classNameBindings: ['isCompleted:is-completed:is-uncompleted', 'isError:is-error'],

    // attrs
    key: null,
    showErrorsKey: null,
    focusTarget: null,
    focusRoute: null,
    focusParams: null,
    'on-focus': 'focusOn',

    lawyer: alias('parentView.lawyer'),
    refreshErrorAt: alias('parentView.refreshProgressAt'),
    progressFlag: alias('parentView.progressFlag'),
    showErrorsFor: alias('parentView.showErrorsFor'),

    isCompleted: false,
    isShaking: false,

    isErrorDidChange: (function () {
      var _this = this;

      if (get(this, 'isError')) {
        set(this, 'isShaking', true);
        run.later(function () {
          if (!get(_this, 'isDestroying') && !get(_this, 'isDestroyed')) {
            set(_this, 'isShaking', false);
          }
        }, 1000);
      }
    }).observes('isError', 'progressFlag'),

    isError: (function () {
      return !get(this, 'isCompleted') && get(this, 'showErrorsFor.' + (get(this, 'showErrorsKey') || get(this, 'key')));
    }).property('isCompleted'),

    notifyIsErrorChange: function notifyIsErrorChange() {
      this.notifyPropertyChange('isError');
    },

    addErrorsObserver: (function () {
      _ember['default'].addObserver(this, 'showErrorsFor.' + (get(this, 'showErrorsKey') || get(this, 'key')), this, this.notifyIsErrorChange);
      _ember['default'].addObserver(this, 'lawyer.' + (get(this, 'observerKey') || get(this, 'key')), this, this.setStatusOnFlagChange);
    }).on('didInsertElement'),

    removeErrorsObserver: (function () {
      _ember['default'].removeObserver(this, 'showErrorsFor.' + (get(this, 'showErrorsKey') || get(this, 'key')), this, this.notifyIsErrorChange);
      _ember['default'].removeObserver(this, 'lawyer.' + (get(this, 'observerKey') || get(this, 'key')), this, this.setStatusOnFlagChange);
    }).on('willDestroyElement'),

    setStatusOnFlagChange: (function () {
      var field = get(this, 'lawyer.' + get(this, 'key'));

      // HACK: Checks .length for any fields that are hasManys
      if (field && field.toArray) {
        field = get(field, 'length');
      }

      set(this, 'isCompleted', !!field);
    }).observes('refreshErrorAt').on('init'),

    sendFocus: on('click', function () {
      if (get(this, 'focusTarget')) {
        this.sendAction('on-focus', get(this, 'focusTarget'), get(this, 'focusRoute'), get(this, 'focusParams'));
      }
    })

  });
});