define('laterally/services/pager', ['exports', 'ember'], function (exports, _ember) {
  var ArrayProxy = _ember['default'].ArrayProxy;
  var A = _ember['default'].A;
  var RSVP = _ember['default'].RSVP;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var filterBy = _Ember$computed.filterBy;
  var alias = _Ember$computed.alias;
  var not = _Ember$computed.not;
  var bool = _Ember$computed.bool;
  var merge = _ember['default'].merge;
  exports['default'] = ArrayProxy.extend({
    store: inject.service('store'),

    content: null,
    records: null,
    firstPage: null,

    page: 1,
    totalRecords: 0,

    isFetching: false,

    init: function init() {
      set(this, 'content', A());
      set(this, 'records', A());

      this.addFirstPage(get(this, 'firstPage'));
    },

    resolvePages: function resolvePages() {
      var _this = this;

      return RSVP.all(this.toArray()).then(function () {
        return _this;
      });
    },

    addFirstPage: function addFirstPage(page) {
      return this._addPage(page);
    },

    addLinkedPage: function addLinkedPage(page) {
      var _this2 = this;

      return this._addPage(page)['catch'](function (reason) {
        get(_this2, 'content').removeObject(page);
        set(_this2, 'content', get(_this2, 'content').toArray());

        RSVP.rethrow(reason);
      });
    },

    _addPage: function _addPage(page) {
      var _this3 = this;

      get(this, 'content').addObject(page);

      return page.then(function (records) {
        get(_this3, 'records').addObjects(records.toArray());
        return records;
      });
    },

    fulfilledPages: filterBy('[]', 'isFulfilled', true),
    lastFulfilledPage: alias('fulfilledPages.lastObject'),

    // BUG: Ember `uniq`s any duplicate records from the server and messes `records.length`
    // canFetchNext: computed('totalRecords', 'records.length', function() {
    //   return get(this, 'totalRecords') > get(this, 'records.length');
    // }),

    canFetchNext: computed('firstObject.isFulfilled', 'content.length', function () {
      if (get(this, 'firstObject.isFulfilled')) {
        return get(this, 'totalRecords') > get(this, 'firstObject.content.meta.size') && get(this, 'content.length') === 1;
      } else {
        return false;
      }
    }),

    fetchNext: function fetchNext() {
      var _this4 = this;

      if (!get(this, 'canFetchNext')) {
        return RSVP.reject();
      }

      var store = get(this, 'store');
      var promise;

      var lastFulfilledPage = get(this, 'lastFulfilledPage');
      var lastPage = get(this, 'lastObject');

      // Only do request if we're not already waiting on fetching (i.e., the next page)
      if (lastFulfilledPage === lastPage) {
        set(this, 'isFetching', true);

        promise = lastPage.then(function (page) {
          var typeKey = page.type.typeKey;
          var query = page.query;
          var nextPage = (query.page || 1) + 1;

          return _this4.addLinkedPage(store.find(typeKey, merge(query, { page: nextPage })));
        })['finally'](function () {
          set(_this4, 'isFetching', false);
        });
      } else {
        promise = lastPage;
      }

      return promise.then(function (records) {
        return records.toArray();
      });
    }
  }).reopenClass({
    isServiceFactory: true
  });
});