define('laterally/models/user', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _emberData['default'].Model.extend({

    claimed: _emberData['default'].attr('boolean'),
    email: _emberData['default'].attr('string'),
    password: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),

    userable: _emberData['default'].belongsTo('userable', { polymorphic: true }),
    profile: alias('userable'),

    employerApplicationId: _emberData['default'].attr('string'),

    isAdmin: _emberData['default'].attr('boolean'),

    isLawyer: alias('userable.isLawyer'),
    isExploree: alias('userable.isExploree'),
    isDeveloper: alias('isAdmin'),
    isFirm: alias('userable.isFirm'),

    referralToken: _emberData['default'].attr('string', { writeOnly: true }),
    singleAccessToken: _emberData['default'].attr('string'),

    traits: (function () {
      return _ember['default'].merge({
        name: this.get("profile.name"),
        email: this.get("email"),
        "__last_login": Date.now(),
        "Current State": this.get("userable.state"),
        "First Seen": this.get("createdAt"),
        "Last Updated": this.get("userable.updatedAt")
      }, this.get('userable.traits') || {});
    }).property().volatile(),

    prettyName: (function () {
      var name = this.get('userable.name');
      if (name == null) {
        name = this.get('email');
      }
      return name;
    }).property('userable.name', 'email'),

    userableType: (function () {
      if (this.get('isLawyer')) {
        return 'Lawyer';
      }
      if (this.get('isFirm')) {
        return 'FirmUser';
      }
    }).property('isLawyer', 'isFirm')

  });
});