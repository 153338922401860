define('laterally/components/lawyer-student-onboard-modal/component', ['exports', 'ember', 'laterally/models/lawyer'], function (exports, _ember, _laterallyModelsLawyer) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;

  var navigator = window.navigator;
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['lawyer-search-details'],
    classNameBindings: ['stepClass'],

    stepClass: computed('step', function () {
      return 'is-on-step-' + get(this, 'step');
    }),

    isFBIOS: computed(function () {
      return !!navigator.userAgent.match(/^(.+)(\[.+FBIOS.+\][ ]*)/);
    }),

    // attrs
    regions: [],
    lawyer: [],
    'on-finish': null,

    modalClosed: 'modalClosed',

    step: null,
    isSaving: false,

    verifyingEmail: false,
    verifyingCode: false,

    maxPracticeAreas: _laterallyModelsLawyer['default'].maxPracticeAreas,

    logOnboarding: on('didInsertElement', function () {
      var lawyer = get(this, 'lawyer');
      set(lawyer, 'startedOnboardingAt', new Date());
      lawyer.save();
    }),

    showAccessRequestedToBasicLawyers: on('didInsertElement', function () {
      if (get(this, 'lawyer.states.EDITING') || get(this, 'lawyer.states.REJECTED')) {
        set(this, 'step', 5);
      } else {
        var lawyer = get(this, 'lawyer');
        var step = 1;

        if (get(lawyer, 'desiredLocations.length')) {
          step = 2;
        }

        if (get(lawyer, 'desiredPracticeAreas.length')) {
          step = 3;
        }

        var fields = 'timeframe lookingForBigLaw lookingForMidSizedFirms lookingForBoutiques lookingForSmallFirms lookingForInHouse usedHeadhunter'.w();
        if (fields.any(function (field) {
          return get(lawyer, field) !== null;
        })) {
          step = 4;
        }

        set(this, 'step', step);
      }
    }),

    lastStep: 4,

    trackStep: observer('step', function () {
      var names = {
        1: 'Locations Step',
        2: 'Practice Areas Step',
        3: 'Details Step',
        4: 'Resume Step',
        5: 'Confirmation Step'
      };

      this.analytics.page('Onboarding Modal', names[get(this, 'step')]);
    }),

    nextDisabled: computed('lawyer.{linkedinUrl,schoolEmail,schoolAccessCode}', function () {
      var lawyer = get(this, 'lawyer');
      return !get(lawyer, 'linkedinUrl') && !get(lawyer, 'schoolEmail') && !get(lawyer, 'schoolAccessCode');
    }),

    actions: {
      receivedLinkedinUrl: function receivedLinkedinUrl(url) {
        set(get(this, 'lawyer'), 'linkedinUrl', url);
      },

      modalClosed: function modalClosed() {
        this.sendAction('modalClosed');
      },

      next: function next() {
        var _this = this;

        if (!get(this, 'nextDisabled')) {
          set(this, 'isSaving', true);
          set(this, 'lawyer.onboarded', true);
          var promise = get(this, 'lawyer').save();
          if (get(this, 'lawyer.states.START')) {
            promise = promise.then(function () {
              return get(_this, 'lawyer').requestAccess();
            });
          }
          promise.then(function (lawyer) {
            _this.sendAction('on-finish', lawyer);
            set(_this, 'step', 5);
          }, function () {
            console.log("failed");
          })['finally'](function () {
            return set(_this, 'isSaving', false);
          });
        }
      },

      previous: function previous() {
        if (get(this, 'step') > 1) {
          set(this, 'step', get(this, 'step') - 1);
        }
      },

      close: function close() {
        this.sendAction('on-finish', get(this, 'lawyer'));
      },

      deleteResume: function deleteResume() {
        get(this, 'lawyer').deleteAttachment('resume', this.get('session'));
      },

      verifyCode: function verifyCode() {
        var _this2 = this;

        set(this, 'verifyingCode', true);
        set(this, 'lawyer.schoolEmail', null);
        set(this, 'lawyer.onboarded', true);
        var promise = get(this, 'lawyer').save();
        if (get(this, 'lawyer.states.START')) {
          promise = promise.then(function () {
            return get(_this2, 'lawyer').verifyStudent();
          }, function () {
            return set(_this2, 'verifyingCode', false);
          });
        }
        promise.then(function (lawyer) {
          _this2.sendAction('on-finish', lawyer);
          set(_this2, 'step', 6);
        })['finally'](function () {
          return set(_this2, 'verifyingCode', false);
        });
      },

      verifyEmail: function verifyEmail() {
        var _this3 = this;

        set(this, 'verifyingEmail', true);
        set(this, 'lawyer.schoolAccessCode', null);
        set(this, 'lawyer.onboarded', true);
        var promise = get(this, 'lawyer').save();
        if (get(this, 'lawyer.states.START')) {
          promise = promise.then(function () {
            return get(_this3, 'lawyer').verifyStudent();
          }, function () {
            return set(_this3, 'verifyingEmail', false);
          });
        }
        promise.then(function (lawyer) {
          _this3.sendAction('on-finish', lawyer);
          set(_this3, 'step', 7);
        })['finally'](function () {
          return set(_this3, 'verifyingEmail', false);
        });
      }
    }
  });
});