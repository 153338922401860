define('laterally/components/keyboard-nav-input/component', ['exports', 'ember'], function (exports, _ember) {

  var on = _ember['default'].on;

  exports['default'] = _ember['default'].TextField.extend({

    // attrs
    value: null,
    nav: null,

    dispatchKeyDown: on('keyDown', function (event) {
      var direction = null;
      switch (event.keyCode) {
        case 13:
          // enter
          this.get('nav').send('select');break;
        case 38:
          // up arrow
          direction = 1;break;
        case 40:
          // down arrow
          direction = -1;break;
        case 9:
          // tab
          direction = event.shiftKey ? -1 : +1;break;
        default:
          return;
      }
      event.preventDefault();
      if (direction) {
        this.get('nav').send('move', direction);
      }
    })

  });
});