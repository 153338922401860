define('laterally/views/pending-message', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].View.extend({

    classNames: ['pending-message'],
    classNameBindings: ['isAnswered'],

    isAnswered: alias('controller.answered')

  });
});