define('laterally/components/form-radio-button/component', ['exports', 'ember', 'laterally/components/form-for/input'], function (exports, _ember, _laterallyComponentsFormForInput) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend(_laterallyComponentsFormForInput['default'], {

    classNames: ['form-for--radio-button'],
    classNameBindings: ['checked:is-checked:is-unchecked'],

    // attrs
    option: null,

    checked: computed('option', 'value', function (_, checked) {
      var option = get(this, 'option');
      if (checked === true) {
        set(this, 'value', option);
      }
      return this.get('value') === option;
    })

  });
});