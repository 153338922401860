define('laterally/components/form-checkbox-in-array/component', ['exports', 'ember', 'laterally/components/form-for/input'], function (exports, _ember, _laterallyComponentsFormForInput) {
  exports['default'] = _ember['default'].Component.extend(_laterallyComponentsFormForInput['default'], {

    classNames: ['form-for--checkbox', 'form-for--checkbox-in-array'],
    classNameBindings: ['isChecked:is-checked:is-unchecked', 'isDisabled:is-disabled:is-enabled'],

    // attrs
    option: null,
    max: null,

    isChecked: null,
    isDisabled: null

  });
});