define('laterally/components/select-dropdown/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Select.extend({
    classNames: ['select-dropdown-component'],

    valueDidChange: (function () {
      var el = this.$();

      if (el) {
        if (!this.get('value')) {
          el.addClass('is-empty');
        } else {
          el.removeClass('is-empty');
        }
      }
    }).observes('value').on('didInsertElement')
  });
});