define('laterally/components/view-more', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['view-more'],

    length: 140,

    collapsed: true,

    truncated: (function () {
      return this.get('content').slice(0, this.get('length'));
    }).property('content', 'length'),

    canCollapse: (function () {
      return this.get('content.length') >= this.get('length') + 10;
    }).property('content.length', 'length'),

    actions: {

      viewMore: function viewMore() {
        this.set('collapsed', false);
      },

      viewLess: function viewLess() {
        this.set('collapsed', true);
      }

    }

  });
});