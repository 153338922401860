define('laterally/routes/refer', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('token', this.store.createRecord('accessToken'));
    },

    model: function model() {
      return get(this, 'referrerManager').fetchOrInitReferrer(get(this.session, 'user'));
    }

  });
});