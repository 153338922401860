define('laterally/models/move-summary', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    firm: _emberData['default'].belongsTo('firm'),
    firmName: _emberData['default'].attr('string'),
    averageEmployees: _emberData['default'].attr('number'),
    attritionRate: _emberData['default'].attr('number'),
    experiencesCount: _emberData['default'].attr('number'),
    departureCount: _emberData['default'].attr('number'),
    arrivalCount: _emberData['default'].attr('number'),
    profitPerPartner: _emberData['default'].attr('number'),
    indexView: _emberData['default'].attr('boolean'),

    firmDepartureRate: _emberData['default'].attr('number'),
    inHouseDepartureRate: _emberData['default'].attr('number'),
    governmentDepartureRate: _emberData['default'].attr('number'),
    clerkshipDepartureRate: _emberData['default'].attr('number'),
    otherDepartureRate: _emberData['default'].attr('number'),

    joinedFromFirm: _emberData['default'].attr('number'),
    joinedFromInhouse: _emberData['default'].attr('number'),
    joinedFromGovernment: _emberData['default'].attr('number'),
    joinedFromClerkship: _emberData['default'].attr('number'),
    joinedFromOther: _emberData['default'].attr('number'),

    topArrivals: _emberData['default'].hasMany('experience'),
    topDepartures: _emberData['default'].hasMany('experience'),

    arrivals: _emberData['default'].hasMany('experience'),
    departures: _emberData['default'].hasMany('experience'),

    departureRate: _emberData['default'].attr('number')

  });
});