define('laterally/controllers/firm-user', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({

    needs: ['application'],

    loadCategories: (function () {
      this.get('categories');
    }).on('init'),

    categories: alias('controllers.application.practiceAreaCategories'),

    editingLocations: [],

    sortedFirmLocationUsers: (function () {
      return this.get("model.firmLocationUsers").sortBy("firmLocation.location.name");
    }).property("model.firmLocationUsers")

  });
});