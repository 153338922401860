define('laterally/transforms/object', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var $ = _ember['default'].$;
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(value) {
      if (!$.isPlainObject(value)) {
        return {};
      } else {
        return value;
      }
    },

    serialize: function serialize(value) {
      if (!$.isPlainObject(value)) {
        return {};
      } else {
        return value;
      }
    }

  });
});