define('laterally/controllers/application/terms-of-service-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    needs: ['candidates'],

    candidates: _ember['default'].computed.alias("controllers.candidates"),
    userable: _ember['default'].computed.alias("session.user.userable"),

    closable: true,

    actions: {
      closeModal: function closeModal() {
        this.get("userable.steps.APPROVED");
        this.get("userable").shownToS();
        return true;
      },

      submit: function submit() {
        var _this = this;

        this.get("userable").acceptTerms().then(function () {
          _this.send("closeModal");
        }, _ember['default'].K);
      }
    }

  });
});