define('laterally/components/keyboard-nav-target/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var on = _ember['default'].on;
  var computed = _ember['default'].computed;

  function sendToNav(action, property) {
    return function () {
      get(this, 'nav').send(action, get(this, property));
    };
  }

  var targetClass = 'keyboard-nav-target';

  exports.targetClass = targetClass;
  exports['default'] = _ember['default'].Component.extend({

    classNames: [targetClass],
    classNameBindings: ['isFocused'],

    isFocused: computed('nav.focused', function () {
      return get(this, 'element') === get(this, 'nav.focused');
    }),

    // attrs
    value: null,
    nav: null,

    didSelect: on('mouseDown', sendToNav('select', 'value')),
    didFocus: on('mouseEnter', sendToNav('focus', 'element')),
    didBlur: on('willDestroyElement', sendToNav('willDestroyChild', 'element')),
    didInsert: on('didInsertElement', sendToNav('didInsertChild', 'element'))

  });
});