define('laterally/models/move-breakdown', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    firms: hasMany('firm'),

    departuresToFirm: attr('number'),
    departuresToGovernment: attr('number'),
    departuresToInHouse: attr('number'),
    departuresToClerkship: attr('number'),
    departuresToOther: attr('number'),
    departuresToUnknown: attr('number'),

    lateralDeparturesToFirm: attr('number'),
    lateralDeparturesToGovernment: attr('number'),
    lateralDeparturesToInHouse: attr('number'),
    lateralDeparturesToClerkship: attr('number'),
    lateralDeparturesToOther: attr('number'),
    lateralDeparturesToUnknown: attr('number'),

    nonLateralDeparturesToFirm: attr('number'),
    nonLateralDeparturesToGovernment: attr('number'),
    nonLateralDeparturesToInHouse: attr('number'),
    nonLateralDeparturesToClerkship: attr('number'),
    nonLateralDeparturesToOther: attr('number'),
    nonLateralDeparturesToUnknown: attr('number'),

    stayLikelihoods: attr('array'),
    homegrownStayLikelihoods: attr('array'),
    lateralStayLikelihoods: attr('array'),

    avgStayLikelihoods: attr('array'),
    avgHomegrownStayLikelihoods: attr('array'),
    avgLateralStayLikelihoods: attr('array')
  });
});