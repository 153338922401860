define('laterally/initializers/adapter-session', ['exports'], function (exports) {
  exports['default'] = {

    name: 'adapter-session',
    after: 'store',

    initialize: function initialize(container) {
      container.injection('adapter', 'session', 'service:session');
    }

  };
});