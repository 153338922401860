define('laterally/controllers/reset-password/change-password', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Controller.extend({

    password: null,
    perishableToken: null,

    errors: null,

    ajaxHeaders: (function () {
      var headers = {};
      headers['Authorization'] = ['Bearer', this.get('session.token.value')].join(' ');

      return headers;
    }).property('session.token'),

    actions: {

      submit: function submit() {
        this.set("errors", null);
        $.ajax(_laterallyConfigEnvironment['default'].APP.API_HOST + '/api/user_passwords/change', { type: 'POST', headers: this.get('ajaxHeaders'), data: {
            perishable_token: this.get("perishableToken"),
            password: this.get("password")
          } }).done(this.handleSubmit.bind(this));
      }

    },

    handleSubmit: (function (response) {
      var _this = this;

      if (response && response.user) {
        var credentials = {
          email: response.user.email,
          password: this.get('password')
        };

        this.session.openWithCredentials(credentials).then(function () {
          _this.transitionToRoute("reset_password.changed");
        });
      } else {
        this.set("errors", { token: [{ message: "this token is invalid. Please reset your password again" }] });
      }
    }).track("Changing Password", function (response) {
      return response;
    })

  });
});