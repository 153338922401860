define('laterally/services/error-logger', ['exports', 'laterally/config/environment', 'ember', 'ember-data'], function (exports, _laterallyConfigEnvironment, _ember, _emberData) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Object.extend({
    Bugsnag: null,

    use: function use(Bugsnag, container) {
      var prettyPrintError = function prettyPrintError(error) {
        if (error.status && error.statusText && error.responseText) {
          _ember['default'].Logger.error(error.status + ' ' + error.statusText + ' - ' + error.responseText);
        } else {
          _ember['default'].Logger.error(error);
        }
      };

      set(this, 'Bugsnag', Bugsnag);

      if (Bugsnag) {
        Bugsnag.appVersion = _laterallyConfigEnvironment['default'].currentRevision;
        Bugsnag.apiKey = _laterallyConfigEnvironment['default'].APP.BUGSNAG_API_KEY;
        Bugsnag.releaseStage = _laterallyConfigEnvironment['default'].environment;
        Bugsnag.notifyReleaseStages = ['staging', 'production'];
        Bugsnag.autoNotify = true;

        _ember['default'].onerror = function (error) {
          if (!(error.__reason_with_error_thrown__ || {}).status && !(error instanceof _emberData['default'].InvalidError)) {
            Bugsnag.context = container.lookup('controller:application').get('currentPath');
            Bugsnag.notifyException(error);

            if (_laterallyConfigEnvironment['default'].environment === 'development' || _laterallyConfigEnvironment['default'].environment === 'staging') {
              if (error) {
                prettyPrintError(error);
                if (get(error, 'stack')) {
                  _ember['default'].Logger.error(get(error, 'stack'));
                }
              }
            }
          }
        };

        _ember['default'].RSVP.on('error', function (error) {
          Bugsnag.context = container.lookup('controller:application').get('currentPath');
          Bugsnag.notifyException(error);

          if (_laterallyConfigEnvironment['default'].environment === 'development' || _laterallyConfigEnvironment['default'].environment === 'staging') {
            if (error) {
              prettyPrintError(error);
              if (get(error, 'stack')) {
                _ember['default'].Logger.error(get(error, 'stack'));
              }
            }
          }
        });
      }

      this.tokenDidChange();
    },

    tokenDidChange: (function () {
      var Bugsnag = get(this, 'Bugsnag');

      if (Bugsnag) {
        var session = get(this, 'session');
        var actingUser = get(session, 'actingUser');
        var possessingUserId = get(session, 'possessingUserId');

        if (actingUser) {
          actingUser.then(function (user) {
            Bugsnag.user = {
              id: user.get('id'),
              email: user.get('email'),
              name: user.get('profile.name')
            };
          });
        } else {
          Bugsnag.user = {};
        }

        if (possessingUserId) {
          Bugsnag.metaData = {
            possessor: {
              userId: possessingUserId
            }
          };
        } else {
          Bugsnag.metaData = {};
        }
      }
    }).observes('session.token')
  });
});