define('laterally/controllers/selectable-practice-area', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;
  var sort = _ember['default'].computed.sort;

  exports['default'] = _ember['default'].Controller.extend({
    needs: ['selectable-practice-areas'],
    practiceAreas: alias('controllers.selectable-practice-areas'),

    sortedChildren: sort('model.practiceArea.children', 'practiceAreas.sorting'),

    checked: (function (key, value) {
      var selections = this.get('practiceAreas.selections');
      var practiceArea = this.get('model.practiceArea.name');
      var checked = selections.contains(practiceArea);
      if (arguments.length === 2 && checked ^ value) {
        if (value) {
          selections.addObject(practiceArea);
        } else {
          selections.removeObject(practiceArea);
        }
        return value;
      } else {
        return checked;
      }
    }).property('practiceAreas.selections.[]')

  });
});