define('laterally/components/selectable-tokens', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['selectable-tokens'],

    optionLabelPath: "content.",
    optionValuePath: "content.",

    value: [],
    selectValue: null,
    inverted: false,

    selectObserver: (function () {
      var selectValue = this.get('selectValue');
      if (!_ember['default'].isNone(selectValue)) {
        var value = this.get('value');
        if (value.contains(selectValue)) {
          value.removeObject(selectValue);
        } else {
          value.addObject(selectValue);
        }
        this.set('selectValue', null);
      }
    }).observes('selectValue'),

    invertedValues: (function () {
      var content = this.get('content');
      var labelPath = this.get('optionLabelPath');
      var valuePath = this.get('optionValuePath');

      return this.get('value').map(function (value) {
        var contentItem = content.find(function (item) {
          return item[valuePath.replace('content.', '')] === value;
        });

        if (contentItem) {
          return contentItem[labelPath.replace('content.', '')];
        } else {
          return value;
        }
      });
    }).property('value.[]', 'content.[]'),

    actions: {

      remove: function remove(value) {
        if (this.get('inverted')) {
          var labelPath = this.get('optionLabelPath');
          var valuePath = this.get('optionValuePath');

          this.get('value').removeObject(this.get('content').find(function (item) {
            return item[labelPath.replace('content.', '')] === value;
          })[valuePath.replace('content.', '')]);
        } else {
          this.get('value').removeObject(value);
        }
      }

    }

  });
});