define('laterally/controllers/firm-user/firm-location-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    // Why do all this bullshit? Because Ember data can suck my dick. So whenever
    // you create a firmLocationPracticeArea, it fires a PUT and then the server
    // responds with the serialized version of the firmLocationPracticeArea which
    // has embedded in it the firmLocationUsers that correspond to this
    // contoroller so when Ember loads the new data, all the old controllers, and
    // old values of 'editing' get dumped and new controllers get created so we
    // have to resort to this fucking bullshit.
    editing: (function (_, value) {
      var locations = this.get('parentController.editingLocations');
      var location = this.get('content.firmLocation.location.id');
      var editing = locations.contains(location);
      if (arguments.length === 2 && editing ^ value) {
        if (value) {
          locations.addObject(location);
        } else {
          locations.removeObject(location);
        }
        return value;
      }
      return editing;
    }).property('parentController.editingLocations.[]'),

    groupedPracticeAreas: (function () {
      var practiceAreas = this.get('model.firmLocation.practiceAreas');
      var hash = practiceAreas.reduce(function (hash, practiceArea) {
        var category = practiceArea.get('practiceAreaCategory');
        if (!category) {
          category = practiceArea.get('parent.practiceAreaCategory');
        }
        if (category) {
          var catName = category.get('name');
          var name = practiceArea.get('name');
          if (hash[catName]) {
            hash[catName].practiceAreas.push(name);
          } else {
            hash[catName] = {
              category: catName,
              practiceAreas: [name],
              order: category.get('order')
            };
          }
        }
        return hash;
      }, {});
      return _ember['default'].keys(hash).map(function (key) {
        var obj = hash[key];
        obj.practiceAreas = obj.practiceAreas.sort().join(', ');
        return obj;
      }).sortBy('order');
    }).property('firmLocation.practiceAreas.@each.practiceAreaCategory'),

    actions: {

      edit: function edit() {
        this.set('editing', true);
      },

      save: function save() {
        this.get('firmLocation').save();
        this.set('editing', false);
      }

    }

  });
});