define('laterally/controllers/candidates/firm-location', ['exports', 'ember', 'laterally/utils/computed/array-controller'], function (exports, _ember, _laterallyUtilsComputedArrayController) {
  exports['default'] = _ember['default'].Controller.extend({

    visible: (function () {
      return this.get('firmLocationJobs').anyBy('visible');
    }).property('firmLocationJobs.@each.visible'),

    firmLocationJobs: (0, _laterallyUtilsComputedArrayController['default'])('model.firmLocationJobs', 'candidates/firmLocationJob', 'job.name', 'job.id'),

    locationId: (function () {
      return this.get('model.location.name').replace(/\W/g, '').toLowerCase();
    }).property('location.name')

  });
});