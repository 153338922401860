define('laterally/mixins/profile-picture', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {
  var Mixin = _ember['default'].Mixin;
  var get = _ember['default'].get;
  exports['default'] = Mixin.create({

    classNames: ["profile-picture"],
    classNameBindings: ["styleClass", "hasCorners", "anonymous"],

    attributeBindings: ["src"],

    style: "30x30",
    hasCorners: false,

    styleClass: (function () {
      return "style-" + this.get("style");
    }).property("style"),

    src: (function () {
      if (get(this, 'urls')) {
        return [_laterallyConfigEnvironment['default'].APP.API_HOST, get(get(this, 'urls'), get(this, 'style'))].join('/');
      } else {
        return _laterallyConfigEnvironment['default'].APP.API_HOST + '/' + this.get("model.profilePicture." + this.get("style"));
      }
    }).property("model.profilePicture", "style", 'urls'),

    updateStyles: function updateStyles() {
      var $this = this.$('img');

      if ($this) {
        var width = $this.width();
        var height = $this.height();

        if (!height && !width) {
          $this.css({
            width: "100%",
            height: "100%"
          });
        }

        if (height > width) {
          $this.css({
            width: "100%",
            height: "auto"
          });
        } else {
          $this.css({
            width: "auto",
            height: "100%"
          });
        }
      }
    },

    dragger: (function () {
      this.updateStyles();
      this.$('img').on('load.resize', this.updateStyles.bind(this));
    }).on("didInsertElement")

  });
});