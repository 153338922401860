define('laterally/controllers/application/claim-modal', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({
    user: alias('session.user.content'),
    termsAccepted: true,

    actions: {
      claim: function claim(modal) {
        var user = this.get('user');
        if (this.get('termsAccepted')) {
          user.save().then((function () {
            user.set('claimed', true);
            this.analytics.track('Claimed profile', {
              user: this.getProperties('id', 'name')
            });
            modal.close();
          }).bind(this));
        }
      },

      modalClosed: function modalClosed() {
        this.send('disconnectModal');
      }
    }
  });
});