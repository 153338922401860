define('laterally/views/firms/index/firm', ['exports', 'ember'], function (exports, _ember) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].View.extend({

    displayDrawer: false,

    slider: (function () {
      if (!this.$()) {
        return;
      }
      if (this.get('controller.displayDrawer')) {
        this.set('displayDrawer', true);
        _ember['default'].run.schedule('afterRender', (function () {
          this.$('.drawer').slideDown({
            progress: (function () {
              var offset = this.$().offset().top;
              var scroll = $(window).scrollTop();
              if (scroll > offset) {
                $(window).scrollTop(offset);
              }
            }).bind(this),
            duration: 150
          });
        }).bind(this));
      } else {
        this.$('.drawer').slideUp(150, (function () {
          this.set('displayDrawer', false);
        }).bind(this));
      }
    }).observes('controller.displayDrawer')

  });
});