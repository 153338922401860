define('laterally/controllers/application/footer', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  var equal = computed.equal;

  exports['default'] = _ember['default'].Controller.extend({

    needs: ['application'],

    userable: alias('session.userable'),
    isLanding: computed('controllers.application.currentPath', function () {
      var path = get(this, 'controllers.application.currentPath');
      return ['landing', 'landing.index', 'landing-move-tracker', 'page'].contains(path);
    })

  });
});