define('laterally/components/confirm-action-popover/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['confirm-action-popover'],

    // attrs
    isConfirming: null,
    confirmer: null,

    abortLabel: 'No',
    confirmLabel: 'Yes',

    actions: {

      confirm: function confirm() {
        get(this, 'confirmer').send('confirm');
      },

      cancel: function cancel() {
        get(this, 'confirmer').send('cancel');
      }

    }

  });
});