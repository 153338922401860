define('laterally/components/tab-content/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    tabs: null,

    name: null,
    disabled: false,

    willInsertElement: function willInsertElement() {
      get(this, 'tabs').registerTab(this);
    }
  });
});