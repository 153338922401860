define('laterally/routes/lawyer', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, model) {
      set(controller, 'model', model);
      set(controller, 'canEdit', model === get(this, 'session.userable'));

      // HACK: Gotta run in 1000 since the debounced observers wait that long
      run.later(this, function () {
        set(controller, 'trackingEnabled', true);
      }, 1000);
    },

    model: function model(params) {
      return this.store.find('lawyer', params.lawyer_id);
    },

    afterModel: function afterModel(model) {
      if (model === this.session.get('userable')) {
        if (get(model, 'onboarded')) {
          this.store.findQuery('jobSelection', { lawyer_id: get(model, 'id') });
          return get(model, 'jobSelections');
        }
      }
    },

    actions: {
      exportLawyer: function exportLawyer(lawyerId) {
        var popup = window.open('about:blank');

        this.session.getOneTimeToken().then(function (token) {
          popup.location.href = _laterallyConfigEnvironment['default'].APP.API_HOST + '/lawyer/' + lawyerId + '.pdf' + '?one_time_token=' + get(token, 'value');
        });
      }
    }

  });
});