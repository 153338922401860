define('laterally/controllers/pending-message', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;
  var get = _ember['default'].get;
  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Controller.extend({
    lawyer: alias('session.userable'),
    selectableFirms: alias('session.userable.firms'),

    showDoubleSubmitScreen: (function () {
      return !get(this, 'lawyer.doubleSubmitVerified') || get(this, 'lawyer.state') !== 'PENDING';
    }).property('lawyer.doubleSubmitVerified'),

    answered: false,

    selectedFirms: null,

    init: function init() {
      set(this, 'selectedFirms', []);
    },

    actions: {
      firmSelected: function firmSelected(firm) {
        get(this, 'selectedFirms').addObject(firm);
      },

      firmDeselected: function firmDeselected(firm) {
        get(this, 'selectedFirms').removeObject(firm);
      },

      applied: function applied() {
        this.set('answered', true);
      },

      cancel: function cancel() {
        this.set('answered', false);
      },

      submit: function submit() {
        var controller = this;
        var lawyer = this.get('lawyer');
        var submitted = this.get('selectedFirms');
        var promise;

        set(this, 'isSaving', true);

        if (get(this, 'lawyer.doubleSubmitVerified')) {
          var defer = _ember['default'].RSVP.defer();
          promise = defer.promise;
          defer.resolve();
        } else {
          promise = lawyer.markDoubleSubmissions({ firm_ids: submitted.mapBy('id') });
        }

        promise.then(function () {
          lawyer.submitForApproval().then(function () {
            controller.set('showDoubleSubmitScreen', false);
          })['finally'](function () {
            set(controller, 'isSaving', false);
          });
        });
      }

    }

  });
});