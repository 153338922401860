define('laterally/routes/explorees/connect', ['exports', 'ember', 'laterally/routes/concerns/paged', 'laterally/routes/concerns/ordered', 'laterally/utils/deferred-receive-action'], function (exports, _ember, _laterallyRoutesConcernsPaged, _laterallyRoutesConcernsOrdered, _laterallyUtilsDeferredReceiveAction) {
  var RSVP = _ember['default'].RSVP;
  var get = _ember['default'].get;
  var setProperties = _ember['default'].setProperties;
  exports['default'] = _ember['default'].Route.extend(_laterallyRoutesConcernsPaged['default'], _laterallyRoutesConcernsOrdered['default'], {

    queryParams: {
      state: { refreshModel: true }
    },

    setupController: function setupController(controller, hash) {
      controller.setProperties(hash);
    },

    model: function model(params) {
      var _this = this;

      params.state = 'saved';
      var query = params;

      query.groupId = query.campaign_id;

      return this.store.fetchById('exploreeGroup', params.campaign_id).then(function (exploreeGroup) {
        query.exploreeGroupShareToken = exploreeGroup.get('shareToken');

        return new _ember['default'].RSVP.hash({
          model: _this.store.find('exploree', query),
          group: exploreeGroup,
          exploreShare: _this.store.find('exploreShare', query),
          connections: _this.store.findQuery('connection', { campaign_id: query.groupId })
        });
      });
    },

    afterModel: function afterModel(hash) {
      get(hash.group, 'conversations');
      return get(hash.group, 'job');
    },

    selectGroupIdAndState: function selectGroupIdAndState(groupId, state) {
      var qp = this.get('defaults');
      qp.state = state;

      this.transitionTo('explorees.connect', groupId, { queryParams: qp }).then(function () {});
    },

    actions: {
      explainConnectable: (0, _laterallyUtilsDeferredReceiveAction['default'])(function (connectable, explorees, exploreesCount) {
        var _this2 = this;

        if (!exploreesCount) {
          exploreesCount = get(explorees, 'length');
        }

        var campaign = get(connectable, 'campaign');

        return RSVP.hash({ job: get(campaign, 'job'), connections: get(campaign, 'connections') }).then(function (hash) {
          var job = hash.job;
          var connections = hash.connections;

          var skipExplanation = false;

          if (!get(campaign, 'connectWithMessage') && get(explorees, 'length') === 1) {
            if (get(campaign, 'isLive')) {
              var lastSavedConnection = get(connections.filterBy('isNew', false).sortBy('createdAtUnix'), 'lastObject');

              if (lastSavedConnection && get(lastSavedConnection, 'createdAt').isAfter(get(job, 'updatedAt'))) {
                skipExplanation = true;
              }
            }
          }

          if (skipExplanation) {
            return RSVP.resolve(connectable);
          } else {
            var deferred = RSVP.defer();
            var promise = deferred.promise;

            setProperties(_this2.controller, {
              connectableToExplain: connectable,
              someExploreesForConnectable: explorees,
              exploreesForConnectableCount: exploreesCount,
              connectableExplanationDeferred: deferred
            });

            return promise;
          }
        });

        // TODO - logic for showing modal/not
        // only show modal if checkbox is unchecked and
        // lastSavedConnection exists and is created *after* campaign.job updated
      }),

      selectGroup: function selectGroup(group) {
        this.send('selectGroupAndState', group, '');
      },

      selectGroupAndState: function selectGroupAndState(group, state) {
        this.selectGroupIdAndState(group.get('id'), state);
        this.analytics.track('Selected explore group and state', {
          exploreeGroup: group.getProperties('id', 'name'),
          state: state
        });
      },

      contactExploree: function contactExploree(message, exploree, campaign) {
        var route = this;
        this.send("openModal", "application/contactModal", {
          exploree: exploree,
          exploreeGroup: campaign,
          sendTo: exploree,
          defaultMessage: message,
          afterSend: function afterSend() {
            route.send('messageSentFromCampaign', campaign);
          }
        });
      },

      loading: function loading() {
        this.controllerFor('explorees/connect').set('loading', true);
        var current = this.controllerFor('application').get('currentRouteName');
        // Bubble the event only if we're transitioning from another route because
        // even catching this event doesn't make the template render faster so we
        // might as well hit some global loading template.
        return current !== this.get('routeName');
      },

      didTransition: function didTransition() {
        this.controllerFor('explorees/connect').set('loading', false);
        return true;
      }

    }

  });
});