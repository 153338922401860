define('laterally/components/debounced-input/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    value: null,

    keyUp: function keyUp() {
      run.debounce(this, this.triggerUpdate, 450);
    },

    triggerUpdate: function triggerUpdate() {
      this.sendAction('on-change', get(this, 'value'));
    }
  });
});