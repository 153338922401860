define('laterally/routes/concerns/lawyer/attachment', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    afterModel: function afterModel() {
      this.get('analytics').track('On ' + this.get('attachmentName') + ' page');
    },

    renderTemplate: function renderTemplate() {
      this.render('concerns/lawyer/attachment', {
        controller: 'lawyer/' + this.get('attachmentName')
      });
    }

  });
});