define('laterally/components/filter-set/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['filter-set-component'],

    filterSet: null,
    isActive: null,

    isRenaming: false,

    actions: {
      toggleRenaming: function toggleRenaming() {
        var _this = this;

        this.toggleProperty('isRenaming');

        _ember['default'].run.scheduleOnce('afterRender', function () {
          _this.$('input').focus();
        });
      },

      abortRename: function abortRename() {
        get(this, 'filterSet').rollback();
        this.toggleProperty('isRenaming');
      },

      rename: function rename() {
        this.sendAction('rename', get(this, 'filterSet'));
        this.toggleProperty('isRenaming');
      },

      'delete': function _delete() {
        this.sendAction('delete', get(this, 'filterSet'));
      }
    }
  });
});