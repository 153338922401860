define('laterally/models/offer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    createdAt: _emberData['default'].attr('date'),

    lawyer: _emberData['default'].belongsTo('lawyer'),

    firm: _emberData['default'].belongsTo('firm')

  });
});