define('laterally/views/jobs-list/firm', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    slider: (function () {
      var firmLocationJobs = this.get('controller.firmLocationJobs');

      if (firmLocationJobs.length && firmLocationJobs.isEvery('removing')) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          this.$().slideUp(250);
        });
      }
    }).observes('controller.firmLocationJobs.@each.removing')

  });
});