define('laterally/controllers/jobs-list/firm', ['exports', 'ember', 'laterally/utils/computed/array-controller'], function (exports, _ember, _laterallyUtilsComputedArrayController) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({

    jobsListController: alias('parentController.parentController'),
    lawyer: alias('jobsListController.lawyer'),
    user: alias('lawyer.user'),
    openDrawer: alias('jobsListController.openDrawer'),
    removable: alias('jobsListController.removable'),

    closed: (function () {
      return this.get('firmLocationJobs').isEvery('closed');
    }).property('firmLocationJobs.@each.closed'),

    allPassed: (function () {
      return this.get('firmLocationJobs').mapProperty('jobSelection').isEvery('passed');
    }).property('firmLocationJobs.@each.jobSelection.passed'),

    allPassConfirmed: (function () {
      return this.get('firmLocationJobs').mapProperty('jobSelection').isEvery('passConfirmed');
    }).property('firmLocationJobs.@each.jobSelection.passConfirmed'),

    allWithdrawn: (function () {
      return this.get('firmLocationJobs').mapProperty('jobSelection').isEvery('withdrawnByLawyerAt');
    }).property('firmLocationJobs.@each.jobSelection.withdrawnByLawyerAt'),

    submitted: (function () {
      return !this.get('allPassConfirmed') && !this.get('allWithdrawn');
    }).property('allPassConfirmed', 'allWithdrawn'),

    hasOneJob: _ember['default'].computed.equal('firmLocationJobRecords.length', 1),
    hasMultipleJobs: _ember['default'].computed.gt('firmLocationJobRecords.length', 1),

    firmLocationJobRecords: (function () {
      return (this.get('lawyer.jobSelections') || []).mapBy('firmLocationJob').filterBy('firmLocation.firm', this.get('model'));
    }).property('lawyer.jobSelections.[]'),

    firmLocationJobs: (0, _laterallyUtilsComputedArrayController['default'])('firmLocationJobRecords', 'jobs_list/firm-location-job'),

    filteredFirmLocationJobs: (function () {
      var statusFilter = this.get('jobsListController.statusFilter');
      return this.get('firmLocationJobs').filter(function (flj) {
        return flj.get('status') == statusFilter;
      });
    }).property('jobsListController.statusFilter', 'firmLocationJobs.@each.status'),

    conversation: (function () {
      return (this.get('lawyer.conversations') || []).findBy('firmUsers.firstObject.firm', this.get('model'));
    }).property('lawyer.conversations.@each.firms')

  });
});