define('laterally/components/sort-toggler/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var or = computed.or;
  exports['default'] = Component.extend({
    tagName: 'button',
    classNames: ['sort-toggler'],
    classNameBindings: ['isSorted:is-active', 'isSortedAsc:is-ascending', 'isSortedDesc:is-descending'],
    sortCallback: null,
    descendingFirst: false,

    sort: null,
    by: null,

    isSorted: or('isSortedAsc', 'isSortedDesc'),

    isSortedAsc: computed('sort', 'by', function () {
      return get(this, 'sort') === get(this, 'by');
    }),

    isSortedDesc: computed('sort', 'by', function () {
      return get(this, 'sort') === ['-', get(this, 'by')].join('');
    }),

    click: function click() {
      if (get(this, 'descendingFirst')) {
        if (get(this, 'isSortedDesc')) {
          set(this, 'sort', get(this, 'by'));
        } else {
          set(this, 'sort', '-' + get(this, 'by'));
        }
      } else {
        if (get(this, 'isSortedAsc')) {
          set(this, 'sort', '-' + get(this, 'by'));
        } else {
          set(this, 'sort', get(this, 'by'));
        }
      }

      if (this.get('sortCallback')) {
        this.sendAction('sortCallback', get(this, 'by'));
      }
    }
  });
});