define('laterally/components/date-to-quarter/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',
    date: null,

    quarter: (function () {
      return getQuarter(get(this, 'date'));
    }).property('date'),

    year: (function () {
      var date = get(this, 'date');
      if (date) {
        return date.getUTCFullYear();
      }
    }).property('date')

  });

  function getQuarter(d) {
    if (d == null) {
      return null;
    }
    var q = [1, 2, 3, 4];
    return q[Math.floor(d.getMonth() / 3)];
  }
});