define('laterally/routes/explorees/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.findQuery('campaign', { sort: '-created_at' });
    },

    actions: {
      selectGroup: function selectGroup(group) {
        var filterSetId = group.get('filterSets.lastObject.id');
        this.transitionTo('explorees.campaign', group.get('id'), { queryParams: { filterSetId: filterSetId } });
      }
    }
  });
});