define('laterally/routes/jobs/index', ['exports', 'ember'], function (exports, _ember) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var moment = window.moment;
  exports['default'] = Route.extend({
    queryParams: {
      startTime: { refreshModel: true },
      endTime: { refreshModel: true },
      startExperience: { refreshModel: true },
      endExperience: { refreshModel: true },
      locationIds: { refreshModel: true },
      practiceAreaIds: { refreshModel: true },
      sort: { refreshModel: true }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.initLocations();
      controller.initPracticeAreas();

      set(controller, 'isLoading', false);
    },

    model: function model(params) {
      return this.store.find('historicalGroup', {
        start_date: this.getStartDateFromStartTime(params.startTime),
        end_date: this.getEndDateFromEndTime(params.endTime),
        experience_min: params.startExperience || undefined,
        experience_max: this.normalizeEndExperience(params.endExperience) || undefined,
        location_ids: params.locationIds,
        practice_area_ids: params.practiceAreaIds,
        sort: params.sort
      });
    },

    afterModel: function afterModel() {
      return get(this.controllerFor('jobs/index'), 'filterOptions');
    },

    getStartDateFromStartTime: function getStartDateFromStartTime(time) {
      if (time) {
        return moment(time, '[qQ]Q-YYYY').format('YYYY-MM-DD');
      } else {
        return undefined;
      }
    },

    getEndDateFromEndTime: function getEndDateFromEndTime(time) {
      if (time) {
        return moment(time, '[qQ]Q-YYYY').endOf('quarter').format('YYYY-MM-DD');
      } else {
        return undefined;
      }
    },

    normalizeEndExperience: function normalizeEndExperience(exp) {
      if (exp > get(this.controllerFor('jobs/index'), 'maxExperience')) {
        return null;
      } else {
        return exp;
      }
    },

    actions: {
      // https://github.com/emberjs/ember.js/issues/5566
      queryParamsDidChange: function queryParamsDidChange() {
        _ember['default'].run.once(this, this.refresh);
      },

      loading: function loading() {
        return set(this.controllerFor('jobs/index'), 'isLoading', true);
      },

      timeSelected: function timeSelected(times) {
        this.analytics.track('Historical Jobs: Changed time period filter');
        this.controller.useTimes(times);
      },

      experienceSelected: function experienceSelected(times) {
        this.analytics.track('Historical Jobs: Changed experience filter');
        this.controller.useExperiences(times);
      },

      locationsSelected: function locationsSelected(locations) {
        this.analytics.track('Historical Jobs: Changed locations filter');
        this.controller.useLocations(locations);
      },

      practiceAreasSelected: function practiceAreasSelected(practiceAreas) {
        this.analytics.track('Historical Jobs: Changed practice areas filter');
        this.controller.usePracticeAreas(practiceAreas);
      },

      locationRemoved: function locationRemoved(location) {
        this.analytics.track('Historical Jobs: Removed location from filter');
        this.controller.dropLocation(location);
      },

      practiceAreaRemoved: function practiceAreaRemoved(practiceArea) {
        this.analytics.track('Historical Jobs: Removed practice area from filter');
        this.controller.dropPracticeArea(practiceArea);
      },

      track: function track(name) {
        this.analytics.track(name);
      }
    }
  });
});