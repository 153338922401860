define('laterally/components/range-filter/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['range-filter'],
    classNameBindings: ['sliderIsVisible:is-open:is-closed'],

    sliderIsVisible: true,
    updatedValues: null,

    formatTo: function formatTo(value) {
      return value;
    },

    formatFrom: function formatFrom(value) {
      return value;
    },

    showSlider: false,

    didInsertElement: function didInsertElement() {
      set(this, 'showSlider', true);
    },

    actions: {
      rangeDidUpdate: function rangeDidUpdate(values) {
        set(this, 'updatedValues', values);
      },

      rangeDidChange: function rangeDidChange(value) {
        this.sendAction('on-change', value);
      }
    }
  });
});