define('laterally/initializers/ember-cli-auto-register-helpers', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {
  var initialize = function initialize() {
    var matcher = new RegExp(_laterallyConfigEnvironment['default'].modulePrefix + '/helpers/.*');

    _ember['default'].A(_ember['default'].keys(window.require.entries)).filter(function (path) {
      return matcher.test(path);
    }).forEach(function (path) {
      var helperName = path.replace(_laterallyConfigEnvironment['default'].modulePrefix + '/helpers/', '');
      _ember['default'].Handlebars.registerHelper(helperName, window.require(path)['default']);
    });
  };

  exports.initialize = initialize;
  exports['default'] = {
    name: 'ember-cli-auto-register-helpers',

    initialize: initialize
  };
});