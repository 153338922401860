define('laterally/views/refer', ['exports', 'ember'], function (exports, _ember) {
  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].View.extend({
    classNames: ['refer'],

    didInsertElement: function didInsertElement() {
      $(window).scrollTop(0);
    }
  });
});