define('laterally/initializers/analytics', ['exports'], function (exports) {
	exports['default'] = {

		name: 'analytics',
		after: 'store',

		initialize: function initialize(container) {
			container.injection('service:analytics', 'session', 'service:session');

			container.injection('router:main', 'analytics', 'service:analytics');
			container.injection('route', 'analytics', 'service:analytics');
			container.injection('controller', 'analytics', 'service:analytics');
			container.injection('component', 'analytics', 'service:analytics');
		}

	};
});