define('laterally/models/firm', ['exports', 'ember-data', 'laterally/models/concerns/profile-picture'], function (exports, _emberData, _laterallyModelsConcernsProfilePicture) {
  exports['default'] = _emberData['default'].Model.extend(_laterallyModelsConcernsProfilePicture['default'], {

    logo: _emberData['default'].attr(),

    offers: _emberData['default'].hasMany('offer'),

    name: _emberData['default'].attr("string"),
    type: _emberData['default'].attr("string"),
    prestigeRank: _emberData['default'].attr("number"),
    hasInsideCounsel: _emberData['default'].attr("boolean"),
    hasOutsideCounsel: _emberData['default'].attr("boolean"),
    canExplore: _emberData['default'].attr("boolean"),

    lawyersCount: _emberData['default'].attr("number"),
    outsideCounselCount: _emberData['default'].attr("number"),

    awardsCount: _emberData['default'].attr("number"),
    casesCount: _emberData['default'].attr("number"),
    clerkshipsCount: _emberData['default'].attr("number"),
    clientsCount: _emberData['default'].attr("number"),
    lawSchoolsCount: _emberData['default'].attr("number"),
    practiceAreasCount: _emberData['default'].attr("number"),
    presentationsCount: _emberData['default'].attr("number"),
    publicationsCount: _emberData['default'].attr("number"),
    transactionsCount: _emberData['default'].attr("number"),

    awardsPerLawyerCount: _emberData['default'].attr("number"),
    casesPerLawyerCount: _emberData['default'].attr("number"),
    clerkshipsPerLawyerCount: _emberData['default'].attr("number"),
    clientsPerLawyerCount: _emberData['default'].attr("number"),
    presentationsPerLawyerCount: _emberData['default'].attr("number"),
    publicationsPerLawyerCount: _emberData['default'].attr("number"),
    transactionsPerLawyerCount: _emberData['default'].attr("number"),

    profitPerPartner: _emberData['default'].attr("string"),
    associatesPerPartner: _emberData['default'].attr("string"),
    percentClerked: _emberData['default'].attr("number"),
    selectivity: _emberData['default'].attr("string"),

    firmLocations: _emberData['default'].hasMany("firmLocation"),
    inviteToken: _emberData['default'].belongsTo("inviteToken"),
    jobs: _emberData['default'].hasMany("job", { async: true }),

    latestJobCreation: _emberData['default'].attr('moment'),
    hasJobCreatedWithinPastWeek: (function () {
      var creation = this.get('latestJobCreation');

      if (creation) {
        return window.moment().diff(creation, 'days') < 7;
      } else {
        return false;
      }
    }).property('latestJobCreation').volatile(),

    category: _emberData['default'].attr('string'),

    anonymous: _emberData['default'].attr('boolean', { defaultValue: false })
  });
});