define('laterally/utils/computed/store-find', ['exports', 'ember'], function (exports, _ember) {

  var computed = _ember['default'].computed;

  exports['default'] = function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return computed(function () {
      var _store;

      return (_store = this.store).find.apply(_store, args);
    });
  };
});