define('laterally/controllers/concerns/paged', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    queryParams: ['page'],

    page: 1,

    total: (function () {
      if (this.get('content.meta')) {
        return this.get('content.meta.total') || 0;
      } else {
        return this.get('content.length');
      }
    }).property('content'),

    actions: {

      goToPage: function goToPage(number) {
        this.set('page', number);
      }

    }

  });
});