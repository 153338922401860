define('laterally/components/filtered-objects/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    objects: null,
    filterProperty: null,
    filterValue: null,

    filteredObjects: computed('objects.[]', 'filterProperty', 'filterValue', function () {
      return get(this, 'objects').filterProperty(get(this, 'filterProperty'), get(this, 'filterValue'));
    })
  });
});