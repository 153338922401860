define('laterally/components/user-authenticator/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['user-authenticator'],

    token: null,
    user: null,
    product: null,

    actions: {
      signUp: function signUp() {
        var user = get(this, 'user');
        var credentials = getProperties(user, 'email password'.w());

        this.sendAction('signUp', user, false, credentials);
        this.sendAction('dismiss');
      },

      outsideClick: function outsideClick() {
        this.sendAction('dismiss');
      },

      escape: function escape() {
        this.sendAction('dismiss');
      },

      dismiss: function dismiss() {
        this.sendAction('dismiss');
      }
    }
  });
});