define('laterally/components/historical-group/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['historical-group'],
    classNameBindings: ['isCollapsed'],

    isCollapsed: true,
    track: 'track',

    actions: {
      toggleCollapsed: function toggleCollapsed() {
        this.sendAction('track', 'Historical Jobs: Expanded Firm Jobs list');
        this.toggleProperty('isCollapsed');
      }
    }
  });
});