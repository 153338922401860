define('laterally/router', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _laterallyConfigEnvironment['default'].locationType,

    page: (function () {
      var application = this.container.lookup('controller:application');
      var route = application.get('currentRouteName');

      if (route === 'landing.index' || route === 'landing-move-tracker') {
        route = 'landing';
      }

      this.get('analytics').page(route);
    }).on('didTransition')
  });

  Router.map(function () {
    this.resource('students');

    this.resource('landing', function () {
      this.modal('user-authenticator', {
        withParams: ['shouldAuthenticate'],
        otherParams: {
          token: 'token',
          user: 'user',
          product: 'product'
        },
        actions: {
          signUp: 'userSignedUp'
        },
        dismissWithOutsideClick: false,
        dismissWithEscape: false
      });

      this.modal('our-pledge', {
        withParams: ['willReadPledge'],
        dismissWithOutsideClick: false,
        dismissWithEscape: false
      });
    });

    this.resource('logout');

    this.route('login');
    this.route('sign-up');
    this.route('referred', { path: '/r/:referral_token' });

    this.route('invite-token', { path: '/join/:token_id' });
    this.route('vote', { path: '/vote/:exploree_group_share_token' });

    this.resource('reset_password', function () {
      this.route('form', { path: '/' });
      this.route('thanks');
      this.route('change_password', { path: '/:perishable_token' });
      this.route('changed');
    });

    this.route('recruiters/derek-mize');

    this.route('employer');

    this.route('fee-agreement');
    this.route('page', { path: '/:path' });
    this.route('connections-faq', { path: '/connections/faq' });

    this.route('chat', { path: '/chat/:claimToken' });
    this.route('connect', { path: '/connect/:claimToken' });
    this.route('ex3', { path: '/ex3/:token/:exploreeGroupId' });

    this.route('authenticated', { path: '/' }, function () {
      this.resource('possess', { path: '/possess/:userId' });

      this.modal('renew-session', {
        withParams: ['willRenewSession'],
        otherParams: {
          newToken: 'token'
        },
        actions: {
          renewSession: 'renewSession'
        }
      });

      this.modal('user-claimer', {
        withParams: {
          userToClaim: 'user',
          userClaimOptions: 'options',
          userClaimDeferred: 'deferred'
        },
        dismissWithOutsideClick: false,
        dismissWithEscape: false
      });

      this.modal('locations-selector', {
        withParams: {
          locations: 'locations',
          locationSelections: 'selections'
        },
        actions: {
          selectLocations: 'locationsSelected'
        },
        dismissWithOutsideClick: false,
        dismissWithEscape: false
      });

      this.modal('practice-areas-selector', {
        withParams: {
          practiceAreaCategories: 'practiceAreaCategories',
          practiceAreaSelections: 'selections'
        },
        actions: {
          selectPracticeAreas: 'practiceAreasSelected'
        },
        dismissWithOutsideClick: false,
        dismissWithEscape: false
      });

      this.resource('onboarding', { path: '/join' }, function () {
        this.route('lawyers', { path: '/' });
        this.route('employers');
        this.route('students');
      });

      this.resource('applied');

      this.resource('profile', { path: '/' });
      this.resource('lawyer', { path: '/lawyer/:lawyer_id' }, function () {
        this.route('resume');
        this.route('transcript');
        this.route('deal-sheet');
      });

      this.resource('offers', function () {
        this.route('show', { path: '/:offer_id' });
      });

      this.resource('candidates', { path: '/candidates' }, function () {
        this.route('index', { path: '/' });
      });

      this.resource('explorees', { path: '/explore' }, function () {
        this.route('index', { path: '/' });
        this.route('campaign', { path: '/:campaign_id' });
        this.route('message', { path: ':exploree_group_id/message' });
        this.route('connect', { path: '/:campaign_id/connect' }, function () {
          this.modal('connectable-explanation', {
            withParams: {
              connectableToExplain: 'connectable',
              someExploreesForConnectable: 'explorees',
              exploreesForConnectableCount: 'exploreesCount',
              connectableExplanationDeferred: 'deferred'
            },
            dismissWithOutsideClick: false,
            dismissWithEscape: false
          });
        });
      });

      this.resource('explore-jobs', function () {
        this.route('edit', { path: '/:exploree_group_id' });
      });

      this.resource('jobs', { path: '/historical' }, function () {
        this.route('index', { path: '/' });
      });

      this.resource('external-jobs', { path: '/inhouse' }, function () {
        this.route('index', { path: '/' });
      });

      this.resource('move-tracker', function () {
        this.route('in-house');
      });

      this.resource('firms', function () {
        this.route('selected');
      });
      this.resource('applications');

      this.resource('firm-user', { path: '/firm/:firm-user_id' });

      this.resource('conversations', function () {
        this.route('show', { path: '/:conversation_id' }, function () {
          this.route('job', { path: '/job' });
        });
      });

      this.resource('connections', function () {

        this.resource('connection', { path: '/:id' }, function () {
          // this.route('chat');
        });
      });

      this.resource('settings', function () {
        this.route('change_password');
        this.route('profile_picture');
      });
    });

    this.route('refer', { path: '/invite' });

    this.route('landing-move-tracker', { path: '/move-tracker' }, function () {
      this.modal('user-authenticator', {
        withParams: ['shouldAuthenticate'],
        otherParams: {
          token: 'token',
          user: 'user',
          product: 'product'
        },
        actions: {
          signUp: 'userSignedUp'
        },
        dismissWithOutsideClick: false,
        dismissWithEscape: false
      });

      this.modal('our-pledge', {
        withParams: ['willReadPledge'],
        dismissWithOutsideClick: false,
        dismissWithEscape: false
      });
    });

    this.route('landing-recruiters', { path: '/attorney-consulting' }, function () {
      this.modal('user-authenticator', {
        withParams: ['shouldAuthenticate'],
        otherParams: {
          token: 'token',
          user: 'user',
          product: 'product'
        },
        actions: {
          signUp: 'userSignedUp'
        },
        dismissWithOutsideClick: false,
        dismissWithEscape: false
      });

      this.modal('our-pledge', {
        withParams: ['willReadPledge'],
        dismissWithOutsideClick: false,
        dismissWithEscape: false
      });
    });

    // needed for tests
    this.route("matchall", {
      path: '*:'
    });
  });
  exports['default'] = Router;
});