define('laterally/controllers/selectable-schools', ['exports', 'ember'], function (exports, _ember) {

  var sort = _ember['default'].computed.sort;

  exports['default'] = _ember['default'].ArrayController.extend({
    // controller is rendered under ApplicationRoute,
    // so using action hashes won't be helpful
    onSave: null,

    sorted: sort('model', 'sorting'),
    sorting: ['name'],

    selections: _ember['default'].A(),

    actions: {
      save: function save() {
        var onSave = this.get('onSave');

        if (onSave) {
          onSave(this.get('selections'));
        }
      },

      modalClosed: function modalClosed() {
        this.send('save');
        return true;
      }
    }
  });
});