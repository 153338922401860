define('laterally/controllers/settings/profile-picture', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({

    userable: alias('session.user.userable'),
    success: false,

    actions: {
      changePassword: function changePassword() {
        var _this = this;

        this.get('userable').save().then(function () {
          _this.set('success', true);
          setTimeout(function () {
            _this.set('success', false);
          }, 3000);
        });
      }
    }
  });
});