define('laterally/components/slide-toggle', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var not = _ember['default'].computed.not;
  var equal = _ember['default'].computed.equal;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['slide-toggle'],
    classNameBindings: ['directionClass'],

    directionClass: (function () {
      return 'slide-toggle--' + this.get('direction');
    }).property('direction'),

    open: not('closed'),
    closed: false,
    direction: 'vertical',
    vertical: equal('direction', 'vertical'),
    duration: 500,
    scroll: false,
    // Not the same as oneWay, this does not respond to upstream changes, which
    // we don't want otherwise the content will unrender when open is false.
    display: (function () {
      return this.get('open');
    }).property(),

    hider: (function () {
      if (!this.get('open')) {
        this.$().hide();
      }
    }).on('didInsertElement'),

    slider: (function () {
      if (this.get('open')) {
        this.set('display', true);
      }
      _ember['default'].run.scheduleOnce('afterRender', this, 'slide');
    }).observes('open'),

    slide: function slide() {
      var key = this.get('vertical') ? 'height' : 'width';
      var toggle = this.get('open') ? 'show' : 'hide';
      var props = { opacity: toggle };
      props[key] = toggle;
      var options = {
        progress: this.sendProgressAction.bind(this),
        duration: this.get('duration'),
        done: this.sendDoneAction.bind(this),
        start: this.sendStartAction.bind(this) };
      this.$().stop().animate(props, options);
    },

    sendProgressAction: function sendProgressAction(animation, progress, remaining) {
      var action = this.get('open') ? 'progress-open' : 'progress-close';
      this.sendAction(action, this, animation, progress, remaining);
    },

    sendDoneAction: function sendDoneAction() {
      var action = this.get('open') ? 'opened' : 'closed';
      this.sendAction('on-' + action);
    },

    sendStartAction: function sendStartAction() {
      var action = this.get('open') ? 'open' : 'close';
      this.sendAction('on-' + action);
    },

    didInsertElement: function didInsertElement() {
      if (get(this, 'open')) {
        this.sendAction('on-started-open');
      }
    },

    actions: {
      close: function close(bool) {
        if (bool) {
          this.set('closed', true);
        }
      }
    }

  });
});