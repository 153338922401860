define('laterally/components/practice-areas-accordion/component', ['exports', 'ember'], function (exports, _ember) {

  var on = _ember['default'].on;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['practice-areas-accordion', 'panel-group'],

    initializeCollapse: on('didInsertElement', function () {
      var _this = this;

      this.$().collapse().on('show.bs.collapse', function (event) {
        return _this.track('Opened');
      }).on('hide.bs.collapse', function (event) {
        return _this.track('Closed');
      });
    }),

    willDestroyElement: function willDestroyElement() {
      this.$().off('show.bs.collapse hide.bs.collapse');
    }

  });
});