define('laterally/models/practice-area-category', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Model.extend({

    name: _emberData['default'].attr('string'),
    order: _emberData['default'].attr('number'),

    practiceAreaEntries: _emberData['default'].hasMany('practiceAreaEntry'),
    practiceAreas: computed('practiceAreaEntries.[]', function () {
      var joined = get(this, 'practiceAreaEntries').map(function (entry) {
        var practiceArea = get(entry, 'practiceArea');

        if (practiceArea) {
          return get(practiceArea, 'kids').toArray().concat(practiceArea);
        } else {
          return null;
        }
      });

      return [].concat.apply([], joined.compact());
    })
  });
});