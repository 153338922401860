define('laterally/helpers/jobs-history/experience-filter-label', ['exports', 'ember'], function (exports, _ember) {

  function label(range, options) {
    var threshold = options && options.hash && options.hash.threshold;

    if (threshold) {
      if (range[0] > threshold) {
        range[0] = threshold + '+';
      }

      if (range[1] > threshold) {
        range[1] = threshold + '+';
      }
    }

    return range.map(function (val) {
      return [val, val === 1 ? 'yr' : 'yrs'].join(' ');
    }).uniq().join(' - ');
  }

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(label);
});