define('laterally/components/track-focus/component', ['exports', 'ember', 'laterally/utils/computed/not-with-set'], function (exports, _ember, _laterallyUtilsComputedNotWithSet) {

  var on = _ember['default'].on;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Component.extend({

    // attrs
    focused: (0, _laterallyUtilsComputedNotWithSet['default'])('blurred'),
    blurred: false,
    'on-focus': null,
    'on-blur': null,

    clickEventAttacher: on('didInsertElement', observer('focused', function () {
      var _this = this;

      if (this.get('focused')) {
        _ember['default'].run.later(function () {
          _ember['default'].$(window).on(_this.get('eventName'), function (event) {
            if (!$.contains(_this.get('element'), event.target)) {
              set(_this, 'focused', false);
            }
          });
        });
      }
    })),

    clickEventDetacher: observer('focused', function () {
      if (!get(this, 'focused')) {
        this.detachEvent();
      }
    }),

    detachEvent: on('willDestroyElement', function () {
      _ember['default'].$(window).off(this.get('eventName'));
    }),

    eventName: computed(function () {
      return 'click.track-focus.' + this.get('elementId');
    }),

    actionSender: observer('focused', function () {
      if (get(this, 'focused')) {
        this.sendAction('on-focus');
      } else {
        this.sendAction('on-blur');
      }
    })

  });
});