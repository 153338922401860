define('laterally/routes/login', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      if (this.session.get('token')) {
        this.transitionTo('authenticated');
      }
    },

    model: function model() {
      return this.session.prepareToken();
    }

  });
});