define('laterally/components/vote/result-drawer/component', ['exports', 'ember', 'laterally/utils/computed/store-locally'], function (exports, _ember, _laterallyUtilsComputedStoreLocally) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Component.extend({

    saveComment: 'saveComment',
    identifyUser: 'identifyUser',
    canComment: null,
    exploree: null,
    newCommentText: '',

    realExploree: alias('exploree.model'),
    currentExploreVoter: alias('session.exploreVoter'),

    commentsSorting: ['createdAt'],
    sortedComments: _ember['default'].computed.sort('realExploree.exploreeComments', 'commentsSorting'),

    commentFieldFocusName: (function () {
      return 'exploree-comment-textarea-' + this.get('realExploree.id');
    }).property('realExploree.id'),

    postedComments: _ember['default'].computed.filterBy('sortedComments', 'isNew', false),

    commentByVoter: (function () {
      var comments = this.get('realExploree.exploreeComments');
      var voterId = this.get('currentExploreVoter.id');
      if (comments) {
        return comments.find(function (theComment) {
          return theComment.get('exploreVoter.id') == voterId;
        });
      }
    }).property('realExploree.exploreeComments.@each.exploreVoter.id', 'currentExploreVoter.id'),

    showResponses: (function () {
      return this.get('canComment') || this.get('realExploree.exploreVotes.length') + this.get('realExploree.exploreeComments.length') > 0;
    }).property('realExploree.exploreVotes.length', 'realExploree.exploreeComments.length'),

    requireIdentity: function requireIdentity(fn) {
      this.sendAction('identifyUser', fn);
    },

    actions: {

      saveComment: function saveComment() {
        var _this = this;

        this.analytics.track('Explore Vote: Saving Comment');
        this.requireIdentity(function () {
          _this.sendAction('saveComment', _this.get('newCommentText'), _this.get('realExploree'));
          _this.set('newCommentText', '');
        });
      },

      saveCommentOnEnter: function saveCommentOnEnter(something, another) {
        this.send('saveComment');
        return false;
      },

      focusCommentField: function focusCommentField(target) {
        this.$('.explore-vote-comments--textarea').focus();
      }
    }
  });
});