define('laterally/models/connection', ['exports', 'ember', 'ember-data', 'laterally/mixins/connectable'], function (exports, _ember, _emberData, _laterallyMixinsConnectable) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend(_laterallyMixinsConnectable['default'], {
    lawyerId: attr('string'),
    conversation: belongsTo('conversation', { async: true }),

    campaign: belongsTo('campaign', { async: false }),
    job: belongsTo('job', { async: true }),
    jobSummary: belongsTo('jobSummary', { async: false }),

    seenByLawyer: attr('boolean'),
    acceptedByLawyer: attr('boolean'),
    archived: attr('boolean'),
    isAssisted: attr('boolean'),

    createdAt: attr('moment'),
    createdAtUnix: computed('createdAt', function () {
      return get(this, 'createdAt').unix();
    }),

    requestedHelpAt: attr('moment', { writeOnly: true })
  });
});