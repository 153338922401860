define('laterally/helpers/limit-string', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(function (string, options) {
    if (!string) {
      return '';
    }

    var hash = options.hash || {};
    var limit = hash.limit || 35;

    var limited = string;

    if (string.length > limit) {
      limited = [string.substr(0, limit), '&hellip;'].join('');
    }

    return new _ember['default'].Handlebars.SafeString(limited);
  });
});