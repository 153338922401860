define('laterally/components/explorees/campaign-chooser/component', ['exports', 'ember'], function (exports, _ember) {

  var filter = _ember['default'].computed.filterProperty;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['campaign-chooser-component'],

    campaigns: null,
    liveCampaigns: filter('campaigns', 'isLive', true),
    closedCampaigns: filter('campaigns', 'isClosed', true),

    campaignSorting: ['createdAtUnix:desc'],
    sortedCampaigns: _ember['default'].computed.sort('campaigns', 'campaignSorting'),

    actions: {
      createCampaign: function createCampaign(name, resolve, reject) {
        this.sendAction('createCampaign', name, resolve, reject);
      },

      selectCampaign: function selectCampaign(campaign) {
        this.sendAction('selectCampaign', campaign);
      }
    }
  });
});