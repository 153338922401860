define('laterally/routes/conversations/show/index', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Route.extend({

    afterModel: function afterModel(model, transition) {
      // Send this action to notify the conversations controller to switch to the
      // appropriate tab.
      transition.send('didLoadConversation', model);
    },

    transitionIfConversationEmpty: function transitionIfConversationEmpty(conversation) {
      this.transitionTo('conversations.index');
    },

    actions: {

      messageDeletedFromConversation: function messageDeletedFromConversation(conversation) {
        if (get(conversation, 'messages.length') === 0 && get(conversation, 'connectionId') == null) {
          conversation.deleteRecord();
          this.send('deleteConversationFromList', conversation);
          this.transitionIfConversationEmpty(conversation);
        }
      },

      deleteConversationFromList: function deleteConversationFromList(conversation) {
        var conversations = this.modelFor('conversations');
        conversations.removeObject(conversation);
      }

    }

  });
});