define('laterally/components/explore-voter-identify/component', ['exports', 'ember', 'laterally/models/lawyer'], function (exports, _ember, _laterallyModelsLawyer) {

  var get = _ember['default'].get;
  var alias = _ember['default'].computed.alias;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Component.extend({

    identifyUserModalClosed: 'identifyUserModalClosed',
    createVoter: 'createVoter',
    selectVoter: 'selectVoter',
    modal: null,
    groupVoters: null,

    newVoter: null,
    firmVoterNames: _ember['default'].computed.map('firmVoters', function (item, index) {
      return item.get('name');
    }),

    shortlistedGroupVoters: (function () {
      if (this.get('groupVoters')) {
        return this.get('groupVoters').slice(0, 4);
      }
    }).property('groupVoters'),

    groupVotersPresent: (function () {
      return this.get('groupVoters.length') > 0;
    }).property('groupVoters.length'),

    actions: {

      createVoter: function createVoter() {
        this.analytics.track('Explore Vote: Submitted Change/Set User (Name) Form (in modal)');
        this.get('modal').close();
        this.sendAction('createVoter', this.get('newVoter.name'));
      },

      selectVoter: function selectVoter(voter) {
        this.analytics.track('Explore Vote: Picked User from List in Modal');
        this.get('modal').close();
        this.sendAction('selectVoter', voter);
      }

    }

  });
});