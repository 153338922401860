define('laterally/routes/concerns/require-lawyer-step', ['exports', 'ember', 'laterally/routes/concerns/require-user', 'laterally/models/lawyer'], function (exports, _ember, _laterallyRoutesConcernsRequireUser, _laterallyModelsLawyer) {
  exports['default'] = _ember['default'].Mixin.create(_laterallyRoutesConcernsRequireUser['default'], {

    verifyLawyerStep: (function (user) {
      if (!user.get('isDeveloper') && user.get('isLawyer') && user.get('userable.step') < _laterallyModelsLawyer['default'].STEPS[this.get('requiredStep')]) {
        this.trigger('rejectedStep');
      } else {
        this.trigger('acceptedStep');
      }
    }).on('requiredUser')

  });
});