define("laterally/templates/lawyer/index/-responses", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 1,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement, blockArguments) {
            var dom = env.dom;
            var hooks = env.hooks,
                set = hooks.set,
                get = hooks.get,
                subexpr = hooks.subexpr,
                inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment, 1, 1, contextualElement);
            set(env, context, "isPulsing", blockArguments[0]);
            inline(env, morph0, context, "content-editable", [], { "value": get(env, context, "lawyer.questionMoving"), "error": subexpr(env, context, "and", [subexpr(env, context, "not", [get(env, context, "lawyer.questionMoving")], {}), get(env, context, "showErrorsFor.questionMoving")], {}), "label": "Type response here", "limit": 750, "editable": get(env, context, "isEditing"), "editMode": get(env, context, "isEditing"), "isPulsing": get(env, context, "isPulsing") });
            return fragment;
          }
        };
      })();
      var child1 = (function () {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("dt");
            var el2 = dom.createTextNode("\n          What's been your best moment as a lawyer?\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "action");
            dom.setAttribute(el2, "data-toggle", "tooltip");
            dom.setAttribute(el2, "data-placement", "right");
            dom.setAttribute(el2, "data-container", "body");
            dom.setAttribute(el2, "title", "Employers love to hear about an awesome accomplishment or the passion you have for lawyering.");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-question-circle");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("dd");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks,
                get = hooks.get,
                inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            inline(env, morph0, context, "content-editable", [], { "value": get(env, context, "lawyer.questionProudest"), "label": "Type response here", "limit": 500, "editable": get(env, context, "isEditing"), "editMode": get(env, context, "isEditing") });
            return fragment;
          }
        };
      })();
      var child2 = (function () {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("dt");
            var el2 = dom.createTextNode("What would you be doing if you weren’t a lawyer?");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("dd");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks,
                get = hooks.get,
                inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            inline(env, morph0, context, "content-editable", [], { "value": get(env, context, "lawyer.questionOtherCareer"), "label": "Type response here", "limit": 500, "editable": get(env, context, "isEditing"), "editMode": get(env, context, "isEditing") });
            return fragment;
          }
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.4",
            blockParams: 1,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement, blockArguments) {
              var dom = env.dom;
              var hooks = env.hooks,
                  set = hooks.set,
                  get = hooks.get,
                  inline = hooks.inline;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment, 1, 1, contextualElement);
              set(env, context, "isPulsing", blockArguments[0]);
              inline(env, morph0, context, "content-editable", [], { "value": get(env, context, "lawyer.questionBesidesResume"), "label": "Type response here", "limit": 500, "editable": get(env, context, "isEditing"), "editMode": get(env, context, "isEditing"), "isPulsing": get(env, context, "isPulsing") });
              return fragment;
            }
          };
        })();
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("dt");
            var el2 = dom.createTextNode("\n          What should employers know that's not in your resume? ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("small");
            dom.setAttribute(el2, "class", "optional");
            var el3 = dom.createTextNode("(optional)");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "action");
            dom.setAttribute(el2, "data-toggle", "tooltip");
            dom.setAttribute(el2, "data-placement", "right");
            dom.setAttribute(el2, "data-container", "body");
            dom.setAttribute(el2, "title", "This is where you can clear up any gaps or discrepancies in your profile, or explain the qualities you bring to an employer that might not be apparent on your resume.");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-question-circle");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks,
                get = hooks.get,
                block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            block(env, morph0, context, "focus-target", [], { "name": "question-besides-resume", "on-focus": "edit", "on-focus-target": get(env, context, "editable"), "tagName": "dd" }, child0, null);
            return fragment;
          }
        };
      })();
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.4",
        blockParams: 2,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "editable-card-title");
          var el2 = dom.createTextNode("Responses");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("dl");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("dt");
          var el3 = dom.createTextNode("\n        Why are you looking to make a move? What will your new position offer that your last one didn’t? ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("small");
          dom.setAttribute(el3, "class", "optional");
          var el4 = dom.createTextNode("(optional)");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "action");
          dom.setAttribute(el3, "data-toggle", "tooltip");
          dom.setAttribute(el3, "data-placement", "right");
          dom.setAttribute(el3, "data-container", "body");
          dom.setAttribute(el3, "title", "Employers want to know your reasons for wanting to change locations or practices, how a move will help your career development, or qualities that matter to you in your next employer.");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "fa fa-question-circle");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement, blockArguments) {
          var dom = env.dom;
          var hooks = env.hooks,
              set = hooks.set,
              get = hooks.get,
              block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [3]);
          var morph0 = dom.createMorphAt(element0, 3, 3);
          var morph1 = dom.createMorphAt(element0, 4, 4);
          var morph2 = dom.createMorphAt(element0, 5, 5);
          var morph3 = dom.createMorphAt(element0, 6, 6);
          set(env, context, "isEditing", blockArguments[0]);
          set(env, context, "editable", blockArguments[1]);
          block(env, morph0, context, "focus-target", [], { "name": "question-moving", "on-focus": "edit", "on-focus-target": get(env, context, "editable"), "tagName": "dd" }, child0, null);
          block(env, morph1, context, "if", [get(env, context, "showQuestionProudest")], {}, child1, null);
          block(env, morph2, context, "if", [get(env, context, "showQuestionOtherCareer")], {}, child2, null);
          block(env, morph3, context, "if", [get(env, context, "showQuestionBesidesResume")], {}, child3, null);
          return fragment;
        }
      };
    })();
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.4",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "lawyer-responses");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks,
            get = hooks.get,
            block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        block(env, morph0, context, "editable-card", [], { "model": get(env, context, "lawyer"), "isEditing": get(env, context, "isEditing"), "savedOrCanceled": "highlightProgressErrors" }, child0, null);
        return fragment;
      }
    };
  })());
});