define('laterally/components/jobs-history/time-filter/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var moment = window.moment;
  exports['default'] = Component.extend({
    minTime: null,
    maxTime: null,
    startTime: null,
    endTime: null,

    toMoment: function toMoment(string) {
      return moment(string, '[Q]Q-YYYY');
    },

    timeRange: computed('minTime', 'maxTime', function () {
      return {
        min: 0,
        max: this.toMoment(get(this, 'maxTime')).diff(this.toMoment(get(this, 'minTime')), 'months') / 3
      };
    }),

    timeDefaults: computed('startTime', 'endTime', 'minTime', 'maxTime', function () {
      return [this.toMoment(get(this, 'startTime')).diff(this.toMoment(get(this, 'minTime')), 'months') / 3, this.toMoment(get(this, 'endTime')).diff(this.toMoment(get(this, 'minTime')), 'months') / 3];
    }),

    willInsertElement: function willInsertElement() {
      var _this = this;

      set(this, 'formatTo', function (value, idx) {
        if (idx === 1) {
          value--;
        }

        return _this.toMoment(get(_this, 'minTime')).add(value * 3, 'months').format('[Q]Q-YYYY');
      });
    },

    formatTo: null,

    formatFrom: function formatFrom(value, idx) {
      if (idx === 1) {
        value++;
      }

      return value;
    },

    actions: {
      timeSelected: function timeSelected(values) {
        this.sendAction('timeSelected', values);
      }
    }
  });
});