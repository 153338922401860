define('laterally/serializers/firm-location', ['exports', 'ember-data', 'laterally/serializers/application'], function (exports, _emberData, _laterallySerializersApplication) {
  exports['default'] = _laterallySerializersApplication['default'].extend(_emberData['default'].EmbeddedRecordsMixin, {

    attrs: {
      practiceAreas: {
        serialize: 'ids'
      }
    }

  });
});