define('laterally/components/max-value-label/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',

    classNames: ['label'],
    classNameBindings: ['stateClass'],

    // attrs
    value: null,
    max: null,

    stateClass: computed('isUnderMax', function () {
      return get(this, 'isUnderMax') ? 'label-default' : 'label-danger';
    }),

    isUnderMax: computed('value', 'max', function () {
      return get(this, 'value') < get(this, 'max');
    })

  });
});