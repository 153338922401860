define('laterally/helpers/format-moment', ['exports', 'ember'], function (exports, _ember) {

  function formatDate(value, options) {
    var format = options.hash.format;
    var blank = options.hash.blank || value;

    return value ? window.moment(value).format(format) : blank;
  }

  exports.formatDate = formatDate;
  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(formatDate);
});