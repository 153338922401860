define('laterally/components/sign-up-form/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var service = _ember['default'].inject.service;

  exports['default'] = _ember['default'].Component.extend({

    store: service(),

    classNames: ['sign-up-form'],

    // attrs
    'on-create': null,
    'on-login': null,
    forEmployer: false,
    showConfidentiality: true,

    user: (function () {
      // Initialize the values to empty strings, otherwise errors will go away
      // when you first click into the input because the value is changing from
      // undefined to ''.
      return this.get('store').createRecord('user', {
        email: '',
        password: ''
      });
    }).property(),

    signUp: function signUp() {
      var _this = this;

      var user = this.get('user');
      user.set('referralToken', this.referralManager.get('token.value'));

      var credentials = user.getProperties('email password'.w());

      user.save().then(function (user) {
        _this.sendAction('on-create', user, get(_this, 'forEmployer'), credentials);
      });
    },

    logIn: function logIn(failureCb) {
      var token = this.get('store').createRecord('accessToken', {
        userEmail: get(this, 'user.email'),
        userPassword: get(this, 'user.password')
      });

      this.sendAction('on-login', token, failureCb);
    },

    actions: {

      submit: function submit() {
        var _this2 = this;

        if (get(this, 'on-login')) {
          this.logIn(function () {
            _this2.signUp();
          });
        } else {
          this.signUp();
        }
      }

    }

  });
});