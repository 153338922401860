define('laterally/components/selectable-input', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  var and = _ember['default'].computed.and;
  var on = _ember['default'].on;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['selectable-input'],
    classNameBindings: ['hasResults'],

    // attrs
    value: null,
    content: null,
    collection: null,
    placeholder: null,
    'on-enter': 'on-enter',

    showResults: true,
    showResultsAfterTyping: observer('value', function () {
      set(this, 'showResults', true);
    }),

    hasResults: and('results.length', 'showResults'),

    results: computed('value', 'content.[]', function () {
      var value = get(this, 'value');
      if (value) {
        var query = value.toLowerCase().replace('\\', '');
        var content = get(this, 'content');

        var results = content.filter(function (item) {
          return item.match(new RegExp('\\b' + query, 'gi'));
        });

        // Sort results higher whose matched value occurs earlier in the string
        results = results.sort(function (a, b) {
          return a.toLowerCase().indexOf(query) - b.toLowerCase().indexOf(query);
        });

        results = results.slice(0, 10); // Only take the first 10 results
        return results;
      }

      return [];
    }),

    ensureInView: function ensureInView() {
      var results = this.$('.selectable-input-results');
      var focused = results.find('.is-focused');
      if (focused.length > 0) {
        var position = focused.position().top;
        if (position < 0) {
          return results.scrollTop(results.scrollTop() + position);
        }
        position += focused.outerHeight() - results.height();
        if (position > 0) {
          return results.scrollTop(results.scrollTop() + position);
        }
      }
    },

    selectOnTab: on('keyDown', function (event) {
      if (event.keyCode === 9) {
        this.$('.is-focused').mousedown();
      }
    }),

    notifyDenormalized: on('focusOut', function () {
      var value = get(this, 'value');
      var content = get(this, 'content');
      if (value && content.indexOf(value) === -1) {
        this.sendAction('selectedDenormalized', value);
      }
    }),

    actions: {

      select: function select(value) {
        set(this, 'value', value);
        set(this, 'showResults', false);
      },

      ensureInView: function ensureInView() {
        run.scheduleOnce('afterRender', this, 'ensureInView');
      },

      'on-enter': function onEnter() {
        this.sendAction('on-enter');
      }

    }

  });
});