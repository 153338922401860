define('laterally/components/radio-button', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var on = _ember['default'].on;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',

    // attrs
    name: null,
    value: null,

    attributeBindings: ['type', 'checked'],
    type: 'radio',

    checked: computed('name', 'value', function (_, checked) {
      var value = get(this, 'value');
      if (checked === true) {
        set(this, 'name', value);
      }
      return this.get('name') === value;
    }),

    componentValueDidChange: observer('checked', function () {
      this.$().prop('checked', get(this, 'checked'));
    }),

    elementValueDidChange: on('change', function () {
      set(this, 'checked', this.$().prop('checked'));
      if (get(this, 'on-change')) {
        this.sendAction('on-change');
      }
    })

  });
});