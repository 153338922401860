define('laterally/store', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  function extractErrors(errors, name) {
    var matcher = new RegExp(name + '(.+)'),
        match;
    return _ember['default'].keys(errors).reduce(function (hash, key) {
      if (match = key.match(matcher)) {
        hash[match[1].camelize()] = errors[key];
        delete errors[key];
      }
      return hash;
    }, {});
  }

  exports['default'] = _emberData['default'].Store.extend({

    load: function load(model, json) {
      model = this.modelFor(model);
      var adapter = this.adapterFor(model);
      var serializer = this.serializerFor(model);
      var root = adapter.pathForType(model.typeKey).singularize();
      var id = json[root].id;
      var record = serializer.extract(this, model, json, id, 'single');
      return this.update(model, record);
    },

    recordWasInvalid: function recordWasInvalid(record, errors) {
      var serializer = this.serializerFor(record.constructor);
      if (_emberData['default'].EmbeddedRecordsMixin.detect(serializer)) {
        record.eachRelationship(function (key, relationship) {
          if (relationship.kind !== 'belongsTo') {
            return;
          }
          var associated = record.get(key);
          if (associated) {
            var associatedErrors = extractErrors(errors, key);
            this.recordWasInvalid(associated, associatedErrors);
          }
        }, this);
      }
      this._super.apply(this, arguments);
    }

  });
});