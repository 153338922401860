define("laterally/components/lawyer-search-details/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.4",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks,
                  get = hooks.get,
                  subexpr = hooks.subexpr,
                  concat = hooks.concat,
                  attribute = hooks.attribute;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element8 = dom.childAt(fragment, [1]);
              var attrMorph0 = dom.createAttrMorph(element8, 'class');
              attribute(env, attrMorph0, element8, "class", concat(env, [subexpr(env, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 4], {}), "is-active"], {})]));
              return fragment;
            }
          };
        })();
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "lawyer-search-details-steps");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("span");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks,
                get = hooks.get,
                subexpr = hooks.subexpr,
                concat = hooks.concat,
                attribute = hooks.attribute,
                block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element9 = dom.childAt(fragment, [1]);
            var element10 = dom.childAt(element9, [1]);
            var element11 = dom.childAt(element9, [3]);
            var element12 = dom.childAt(element9, [5]);
            var attrMorph0 = dom.createAttrMorph(element10, 'class');
            var attrMorph1 = dom.createAttrMorph(element11, 'class');
            var attrMorph2 = dom.createAttrMorph(element12, 'class');
            var morph0 = dom.createMorphAt(element9, 7, 7);
            attribute(env, attrMorph0, element10, "class", concat(env, [subexpr(env, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 1], {}), "is-active"], {})]));
            attribute(env, attrMorph1, element11, "class", concat(env, [subexpr(env, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 2], {}), "is-active"], {})]));
            attribute(env, attrMorph2, element12, "class", concat(env, [subexpr(env, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 3], {}), "is-active"], {})]));
            block(env, morph0, context, "unless", [subexpr(env, context, "not", [get(env, context, "lawyer.states.REJECTED")], {})], {}, child0, null);
            return fragment;
          }
        };
      })();
      var child1 = (function () {
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        Access Requested\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            return fragment;
          }
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.4",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        Verify your identity to get access\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              return fragment;
            }
          };
        })();
        var child1 = (function () {
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.4",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        Tell us about your search to get access\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              return fragment;
            }
          };
        })();
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 0,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement) {
            var dom = env.dom;
            var hooks = env.hooks,
                get = hooks.get,
                subexpr = hooks.subexpr,
                block = hooks.block;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, null);
            dom.insertBoundary(fragment, 0);
            block(env, morph0, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 4], {})], {}, child0, child1);
            return fragment;
          }
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("fieldset");
                dom.setAttribute(el1, "class", "lawyer-search-details-locations");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("legend");
                var el3 = dom.createTextNode("\n            Where are you looking?\n            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "lawyer-search-details-change-later");
                var el4 = dom.createTextNode("\n              You can change your answers later.\n            ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks,
                    get = hooks.get,
                    inline = hooks.inline;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
                inline(env, morph0, context, "locations-select", [], { "regions": get(env, context, "regions"), "selections": get(env, context, "lawyer.desiredLocations"), "inset": true });
                return fragment;
              }
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.4",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("fieldset");
                  dom.setAttribute(el1, "class", "lawyer-search-details-practice-areas");
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("legend");
                  var el3 = dom.createTextNode("\n            Which areas of law would you like to practice?\n            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "lawyer-search-details-change-later");
                  var el4 = dom.createTextNode("\n              You can change your answers later.\n            ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n          ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks,
                      get = hooks.get,
                      inline = hooks.inline;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
                  inline(env, morph0, context, "practice-areas-select", [], { "practiceAreaCategories": get(env, context, "practiceAreaCategories"), "selections": get(env, context, "lawyer.desiredPracticeAreas") });
                  return fragment;
                }
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  isHTMLBars: true,
                  revision: "Ember@1.11.4",
                  blockParams: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  build: function build(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "lawyer-search-details-timeframe");
                    var el2 = dom.createTextNode("\n          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "lawyer-search-details-interested-in");
                    var el2 = dom.createTextNode("\n          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("fieldset");
                    dom.setAttribute(el2, "class", "form-for--property");
                    var el3 = dom.createTextNode("\n            ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("legend");
                    var el4 = dom.createTextNode("Which types of employers interest you?");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n            ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "lawyer-search-details-interested-in-options");
                    var el4 = dom.createTextNode("\n              ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n              ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n              ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n              ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n            ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "lawyer-search-details-used-headhunter");
                    var el2 = dom.createTextNode("\n          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  render: function render(context, env, contextualElement) {
                    var dom = env.dom;
                    var hooks = env.hooks,
                        subexpr = hooks.subexpr,
                        get = hooks.get,
                        inline = hooks.inline;
                    dom.detectNamespace(contextualElement);
                    var fragment;
                    if (env.useFragmentCache && dom.canClone) {
                      if (this.cachedFragment === null) {
                        fragment = this.build(dom);
                        if (this.hasRendered) {
                          this.cachedFragment = fragment;
                        } else {
                          this.hasRendered = true;
                        }
                      }
                      if (this.cachedFragment) {
                        fragment = dom.cloneNode(this.cachedFragment, true);
                      }
                    } else {
                      fragment = this.build(dom);
                    }
                    var element5 = dom.childAt(fragment, [3, 1, 3]);
                    var morph0 = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                    var morph1 = dom.createMorphAt(element5, 1, 1);
                    var morph2 = dom.createMorphAt(element5, 3, 3);
                    var morph3 = dom.createMorphAt(element5, 5, 5);
                    var morph4 = dom.createMorphAt(element5, 7, 7);
                    var morph5 = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
                    inline(env, morph0, context, "form-radio-buttons", [], { "form": get(env, context, "form"), "property": "timeframe", "label": "When are you looking to move?", "content": subexpr(env, context, "list", ["3 months", "6 months", "A year", "Just browsing"], {}) });
                    inline(env, morph1, context, "form-checkbox", [], { "form": get(env, context, "form"), "property": "lookingForBigLaw", "label": "Big Law" });
                    inline(env, morph2, context, "form-checkbox", [], { "form": get(env, context, "form"), "property": "lookingForMidSizedFirms", "label": "Mid-Sized Firms" });
                    inline(env, morph3, context, "form-checkbox", [], { "form": get(env, context, "form"), "property": "lookingForBoutiques", "label": "Boutiques" });
                    inline(env, morph4, context, "form-checkbox", [], { "form": get(env, context, "form"), "property": "lookingForInHouse", "label": "In-House" });
                    inline(env, morph5, context, "form-yes-no", [], { "form": get(env, context, "form"), "property": "usedHeadhunter", "label": "Have you used a headhunter in the last 6 months?" });
                    return fragment;
                  }
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    return {
                      isHTMLBars: true,
                      revision: "Ember@1.11.4",
                      blockParams: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      build: function build(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("            ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("fieldset");
                        var el2 = dom.createTextNode("\n              ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.setAttribute(el2, "class", "text-center");
                        var el3 = dom.createTextNode("\n                ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("p");
                        var el4 = dom.createTextNode("Note: Facebook's browser prevents you from using LinkedIn to verify your account.");
                        dom.appendChild(el3, el4);
                        var el4 = dom.createElement("br");
                        dom.appendChild(el3, el4);
                        var el4 = dom.createTextNode("Please open Laterally on your phone’s Safari or Chrome browser to verify with LinkedIn.");
                        dom.appendChild(el3, el4);
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n              ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      render: function render(context, env, contextualElement) {
                        var dom = env.dom;
                        dom.detectNamespace(contextualElement);
                        var fragment;
                        if (env.useFragmentCache && dom.canClone) {
                          if (this.cachedFragment === null) {
                            fragment = this.build(dom);
                            if (this.hasRendered) {
                              this.cachedFragment = fragment;
                            } else {
                              this.hasRendered = true;
                            }
                          }
                          if (this.cachedFragment) {
                            fragment = dom.cloneNode(this.cachedFragment, true);
                          }
                        } else {
                          fragment = this.build(dom);
                        }
                        return fragment;
                      }
                    };
                  })();
                  var child1 = (function () {
                    return {
                      isHTMLBars: true,
                      revision: "Ember@1.11.4",
                      blockParams: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      build: function build(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("            ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("fieldset");
                        var el2 = dom.createTextNode("\n              ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("div");
                        dom.setAttribute(el2, "class", "text-center");
                        var el3 = dom.createTextNode("\n                ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createComment("");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n              ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      render: function render(context, env, contextualElement) {
                        var dom = env.dom;
                        var hooks = env.hooks,
                            get = hooks.get,
                            inline = hooks.inline;
                        dom.detectNamespace(contextualElement);
                        var fragment;
                        if (env.useFragmentCache && dom.canClone) {
                          if (this.cachedFragment === null) {
                            fragment = this.build(dom);
                            if (this.hasRendered) {
                              this.cachedFragment = fragment;
                            } else {
                              this.hasRendered = true;
                            }
                          }
                          if (this.cachedFragment) {
                            fragment = dom.cloneNode(this.cachedFragment, true);
                          }
                        } else {
                          fragment = this.build(dom);
                        }
                        var morph0 = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                        inline(env, morph0, context, "linkedin-verification", [], { "providedUrl": get(env, context, "lawyer.linkedinUrl"), "on-auth": "receivedLinkedinUrl" });
                        return fragment;
                      }
                    };
                  })();
                  var child2 = (function () {
                    return {
                      isHTMLBars: true,
                      revision: "Ember@1.11.4",
                      blockParams: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      build: function build(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("            ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("fieldset");
                        var el2 = dom.createTextNode("\n              ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("legend");
                        dom.setAttribute(el2, "class", "lawyer-search-details-or");
                        var el3 = dom.createTextNode("- OR -");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n            ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      render: function render(context, env, contextualElement) {
                        var dom = env.dom;
                        var hooks = env.hooks,
                            get = hooks.get,
                            subexpr = hooks.subexpr,
                            concat = hooks.concat,
                            attribute = hooks.attribute;
                        dom.detectNamespace(contextualElement);
                        var fragment;
                        if (env.useFragmentCache && dom.canClone) {
                          if (this.cachedFragment === null) {
                            fragment = this.build(dom);
                            if (this.hasRendered) {
                              this.cachedFragment = fragment;
                            } else {
                              this.hasRendered = true;
                            }
                          }
                          if (this.cachedFragment) {
                            fragment = dom.cloneNode(this.cachedFragment, true);
                          }
                        } else {
                          fragment = this.build(dom);
                        }
                        var element2 = dom.childAt(fragment, [1]);
                        var attrMorph0 = dom.createAttrMorph(element2, 'class');
                        attribute(env, attrMorph0, element2, "class", concat(env, ["text-center ", subexpr(env, context, "if", [subexpr(env, context, "and", [get(env, context, "lawyer.linkedinUrl"), get(env, context, "lawyer.resume")], {}), "lawyer-search-details-verified-both"], {})]));
                        return fragment;
                      }
                    };
                  })();
                  var child3 = (function () {
                    var child0 = (function () {
                      var child0 = (function () {
                        return {
                          isHTMLBars: true,
                          revision: "Ember@1.11.4",
                          blockParams: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          build: function build(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                      Are you sure you want to remove this resume?\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          render: function render(context, env, contextualElement) {
                            var dom = env.dom;
                            dom.detectNamespace(contextualElement);
                            var fragment;
                            if (env.useFragmentCache && dom.canClone) {
                              if (this.cachedFragment === null) {
                                fragment = this.build(dom);
                                if (this.hasRendered) {
                                  this.cachedFragment = fragment;
                                } else {
                                  this.hasRendered = true;
                                }
                              }
                              if (this.cachedFragment) {
                                fragment = dom.cloneNode(this.cachedFragment, true);
                              }
                            } else {
                              fragment = this.build(dom);
                            }
                            return fragment;
                          }
                        };
                      })();
                      return {
                        isHTMLBars: true,
                        revision: "Ember@1.11.4",
                        blockParams: 2,
                        cachedFragment: null,
                        hasRendered: false,
                        build: function build(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createTextNode("                    ");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createElement("a");
                          dom.setAttribute(el1, "href", "");
                          dom.setAttribute(el1, "class", "editable-button--delete");
                          var el2 = dom.createTextNode("\n                      Remove\n                    ");
                          dom.appendChild(el1, el2);
                          dom.appendChild(el0, el1);
                          var el1 = dom.createTextNode("\n");
                          dom.appendChild(el0, el1);
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        render: function render(context, env, contextualElement, blockArguments) {
                          var dom = env.dom;
                          var hooks = env.hooks,
                              set = hooks.set,
                              get = hooks.get,
                              element = hooks.element,
                              block = hooks.block;
                          dom.detectNamespace(contextualElement);
                          var fragment;
                          if (env.useFragmentCache && dom.canClone) {
                            if (this.cachedFragment === null) {
                              fragment = this.build(dom);
                              if (this.hasRendered) {
                                this.cachedFragment = fragment;
                              } else {
                                this.hasRendered = true;
                              }
                            }
                            if (this.cachedFragment) {
                              fragment = dom.cloneNode(this.cachedFragment, true);
                            }
                          } else {
                            fragment = this.build(dom);
                          }
                          var element1 = dom.childAt(fragment, [1]);
                          var morph0 = dom.createMorphAt(fragment, 3, 3, contextualElement);
                          dom.insertBoundary(fragment, null);
                          set(env, context, "isConfirming", blockArguments[0]);
                          set(env, context, "confirmer", blockArguments[1]);
                          element(env, element1, context, "action", ["confirm"], { "target": get(env, context, "confirmer") });
                          block(env, morph0, context, "confirm-action-popover", [], { "isConfirming": get(env, context, "isConfirming"), "confirmer": get(env, context, "confirmer") }, child0, null);
                          return fragment;
                        }
                      };
                    })();
                    return {
                      isHTMLBars: true,
                      revision: "Ember@1.11.4",
                      blockParams: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      build: function build(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("span");
                        var el2 = dom.createTextNode("Resume added");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n\n                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("div");
                        dom.setAttribute(el1, "class", "editable-buttons");
                        var el2 = dom.createTextNode("\n");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("                ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      render: function render(context, env, contextualElement) {
                        var dom = env.dom;
                        var hooks = env.hooks,
                            block = hooks.block;
                        dom.detectNamespace(contextualElement);
                        var fragment;
                        if (env.useFragmentCache && dom.canClone) {
                          if (this.cachedFragment === null) {
                            fragment = this.build(dom);
                            if (this.hasRendered) {
                              this.cachedFragment = fragment;
                            } else {
                              this.hasRendered = true;
                            }
                          }
                          if (this.cachedFragment) {
                            fragment = dom.cloneNode(this.cachedFragment, true);
                          }
                        } else {
                          fragment = this.build(dom);
                        }
                        var morph0 = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                        block(env, morph0, context, "confirm-action", [], { "action": "deleteResume" }, child0, null);
                        return fragment;
                      }
                    };
                  })();
                  var child4 = (function () {
                    return {
                      isHTMLBars: true,
                      revision: "Ember@1.11.4",
                      blockParams: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      build: function build(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("              ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      render: function render(context, env, contextualElement) {
                        var dom = env.dom;
                        var hooks = env.hooks,
                            get = hooks.get,
                            inline = hooks.inline;
                        dom.detectNamespace(contextualElement);
                        var fragment;
                        if (env.useFragmentCache && dom.canClone) {
                          if (this.cachedFragment === null) {
                            fragment = this.build(dom);
                            if (this.hasRendered) {
                              this.cachedFragment = fragment;
                            } else {
                              this.hasRendered = true;
                            }
                          }
                          if (this.cachedFragment) {
                            fragment = dom.cloneNode(this.cachedFragment, true);
                          }
                        } else {
                          fragment = this.build(dom);
                        }
                        var morph0 = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        inline(env, morph0, context, "embed-pdf", [], { "src": get(env, context, "lawyer.resume.original") });
                        return fragment;
                      }
                    };
                  })();
                  var child5 = (function () {
                    return {
                      isHTMLBars: true,
                      revision: "Ember@1.11.4",
                      blockParams: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      build: function build(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("              ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      render: function render(context, env, contextualElement) {
                        var dom = env.dom;
                        var hooks = env.hooks,
                            get = hooks.get,
                            subexpr = hooks.subexpr,
                            inline = hooks.inline;
                        dom.detectNamespace(contextualElement);
                        var fragment;
                        if (env.useFragmentCache && dom.canClone) {
                          if (this.cachedFragment === null) {
                            fragment = this.build(dom);
                            if (this.hasRendered) {
                              this.cachedFragment = fragment;
                            } else {
                              this.hasRendered = true;
                            }
                          }
                          if (this.cachedFragment) {
                            fragment = dom.cloneNode(this.cachedFragment, true);
                          }
                        } else {
                          fragment = this.build(dom);
                        }
                        var morph0 = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        inline(env, morph0, context, "file-input", [], { "value": get(env, context, "lawyer.resume"), "model": get(env, context, "lawyer"), "allowedTypes": "application/pdf", "attachment": "resume", "buttonClassNames": "btn btn-file btn-block btn-default", "uploadMessage": subexpr(env, context, "if", [get(env, context, "isFBIOS"), "Or, upload your resume instead", "Upload your resume"], {}) });
                        return fragment;
                      }
                    };
                  })();
                  return {
                    isHTMLBars: true,
                    revision: "Ember@1.11.4",
                    blockParams: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    build: function build(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "style", "padding: 10px 0");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("fieldset");
                      dom.setAttribute(el2, "class", "lawyer-search-details-resume");
                      var el3 = dom.createTextNode("\n            ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("legend");
                      dom.setAttribute(el3, "class", "text-center");
                      var el4 = dom.createTextNode("\n");
                      dom.appendChild(el3, el4);
                      var el4 = dom.createComment("");
                      dom.appendChild(el3, el4);
                      var el4 = dom.createTextNode("            ");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("          ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    render: function render(context, env, contextualElement) {
                      var dom = env.dom;
                      var hooks = env.hooks,
                          get = hooks.get,
                          block = hooks.block;
                      dom.detectNamespace(contextualElement);
                      var fragment;
                      if (env.useFragmentCache && dom.canClone) {
                        if (this.cachedFragment === null) {
                          fragment = this.build(dom);
                          if (this.hasRendered) {
                            this.cachedFragment = fragment;
                          } else {
                            this.hasRendered = true;
                          }
                        }
                        if (this.cachedFragment) {
                          fragment = dom.cloneNode(this.cachedFragment, true);
                        }
                      } else {
                        fragment = this.build(dom);
                      }
                      var element3 = dom.childAt(fragment, [1]);
                      var element4 = dom.childAt(element3, [5]);
                      var morph0 = dom.createMorphAt(element3, 1, 1);
                      var morph1 = dom.createMorphAt(element3, 3, 3);
                      var morph2 = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
                      var morph3 = dom.createMorphAt(element4, 3, 3);
                      block(env, morph0, context, "if", [get(env, context, "isFBIOS")], {}, child0, child1);
                      block(env, morph1, context, "unless", [get(env, context, "isFBIOS")], {}, child2, null);
                      block(env, morph2, context, "if", [get(env, context, "lawyer.resume")], {}, child3, null);
                      block(env, morph3, context, "if", [get(env, context, "lawyer.resume")], {}, child4, child5);
                      return fragment;
                    }
                  };
                })();
                var child1 = (function () {
                  var child0 = (function () {
                    return {
                      isHTMLBars: true,
                      revision: "Ember@1.11.4",
                      blockParams: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      build: function build(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("        ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("p");
                        dom.setAttribute(el1, "class", "text-center");
                        var el2 = dom.createTextNode("We'll send you an email shortly. Once you get access, refresh this page to view jobs.");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n      ");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      render: function render(context, env, contextualElement) {
                        var dom = env.dom;
                        dom.detectNamespace(contextualElement);
                        var fragment;
                        if (env.useFragmentCache && dom.canClone) {
                          if (this.cachedFragment === null) {
                            fragment = this.build(dom);
                            if (this.hasRendered) {
                              this.cachedFragment = fragment;
                            } else {
                              this.hasRendered = true;
                            }
                          }
                          if (this.cachedFragment) {
                            fragment = dom.cloneNode(this.cachedFragment, true);
                          }
                        } else {
                          fragment = this.build(dom);
                        }
                        return fragment;
                      }
                    };
                  })();
                  return {
                    isHTMLBars: true,
                    revision: "Ember@1.11.4",
                    blockParams: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    build: function build(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    render: function render(context, env, contextualElement) {
                      var dom = env.dom;
                      var hooks = env.hooks,
                          get = hooks.get,
                          subexpr = hooks.subexpr,
                          block = hooks.block;
                      dom.detectNamespace(contextualElement);
                      var fragment;
                      if (env.useFragmentCache && dom.canClone) {
                        if (this.cachedFragment === null) {
                          fragment = this.build(dom);
                          if (this.hasRendered) {
                            this.cachedFragment = fragment;
                          } else {
                            this.hasRendered = true;
                          }
                        }
                        if (this.cachedFragment) {
                          fragment = dom.cloneNode(this.cachedFragment, true);
                        }
                      } else {
                        fragment = this.build(dom);
                      }
                      var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, null);
                      dom.insertBoundary(fragment, 0);
                      block(env, morph0, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 5], {})], {}, child0, null);
                      return fragment;
                    }
                  };
                })();
                return {
                  isHTMLBars: true,
                  revision: "Ember@1.11.4",
                  blockParams: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  build: function build(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  render: function render(context, env, contextualElement) {
                    var dom = env.dom;
                    var hooks = env.hooks,
                        get = hooks.get,
                        subexpr = hooks.subexpr,
                        block = hooks.block;
                    dom.detectNamespace(contextualElement);
                    var fragment;
                    if (env.useFragmentCache && dom.canClone) {
                      if (this.cachedFragment === null) {
                        fragment = this.build(dom);
                        if (this.hasRendered) {
                          this.cachedFragment = fragment;
                        } else {
                          this.hasRendered = true;
                        }
                      }
                      if (this.cachedFragment) {
                        fragment = dom.cloneNode(this.cachedFragment, true);
                      }
                    } else {
                      fragment = this.build(dom);
                    }
                    var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, null);
                    dom.insertBoundary(fragment, 0);
                    block(env, morph0, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 4], {})], {}, child0, child1);
                    return fragment;
                  }
                };
              })();
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.4",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  var hooks = env.hooks,
                      get = hooks.get,
                      subexpr = hooks.subexpr,
                      block = hooks.block;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, null);
                  dom.insertBoundary(fragment, 0);
                  block(env, morph0, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 3], {})], {}, child0, child1);
                  return fragment;
                }
              };
            })();
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks,
                    get = hooks.get,
                    subexpr = hooks.subexpr,
                    block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 2], {})], {}, child0, child1);
                return fragment;
              }
            };
          })();
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.4",
            blockParams: 1,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement, blockArguments) {
              var dom = env.dom;
              var hooks = env.hooks,
                  set = hooks.set,
                  get = hooks.get,
                  subexpr = hooks.subexpr,
                  block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, null);
              dom.insertBoundary(fragment, 0);
              set(env, context, "step", blockArguments[0]);
              block(env, morph0, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 1], {})], {}, child0, child1);
              return fragment;
            }
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.4",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "fa fa-spin fa-spinner");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  return fragment;
                }
              };
            })();
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            Request Access\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks,
                    get = hooks.get,
                    block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, null);
                block(env, morph0, context, "if", [get(env, context, "isSaving")], {}, child0, null);
                return fragment;
              }
            };
          })();
          var child1 = (function () {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            Next ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "lawyer-search-details-next-icon");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                return fragment;
              }
            };
          })();
          var child2 = (function () {
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            Please select at least one location.\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                return fragment;
              }
            };
          })();
          var child3 = (function () {
            var child0 = (function () {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.4",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            Please select at least one practice area.\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  return fragment;
                }
              };
            })();
            var child1 = (function () {
              return {
                isHTMLBars: true,
                revision: "Ember@1.11.4",
                blockParams: 0,
                cachedFragment: null,
                hasRendered: false,
                build: function build(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            Please upload your resume.\n          ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                render: function render(context, env, contextualElement) {
                  var dom = env.dom;
                  dom.detectNamespace(contextualElement);
                  var fragment;
                  if (env.useFragmentCache && dom.canClone) {
                    if (this.cachedFragment === null) {
                      fragment = this.build(dom);
                      if (this.hasRendered) {
                        this.cachedFragment = fragment;
                      } else {
                        this.hasRendered = true;
                      }
                    }
                    if (this.cachedFragment) {
                      fragment = dom.cloneNode(this.cachedFragment, true);
                    }
                  } else {
                    fragment = this.build(dom);
                  }
                  return fragment;
                }
              };
            })();
            return {
              isHTMLBars: true,
              revision: "Ember@1.11.4",
              blockParams: 0,
              cachedFragment: null,
              hasRendered: false,
              build: function build(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              render: function render(context, env, contextualElement) {
                var dom = env.dom;
                var hooks = env.hooks,
                    get = hooks.get,
                    subexpr = hooks.subexpr,
                    block = hooks.block;
                dom.detectNamespace(contextualElement);
                var fragment;
                if (env.useFragmentCache && dom.canClone) {
                  if (this.cachedFragment === null) {
                    fragment = this.build(dom);
                    if (this.hasRendered) {
                      this.cachedFragment = fragment;
                    } else {
                      this.hasRendered = true;
                    }
                  }
                  if (this.cachedFragment) {
                    fragment = dom.cloneNode(this.cachedFragment, true);
                  }
                } else {
                  fragment = this.build(dom);
                }
                var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, null);
                dom.insertBoundary(fragment, 0);
                block(env, morph0, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 2], {})], {}, child0, child1);
                return fragment;
              }
            };
          })();
          return {
            isHTMLBars: true,
            revision: "Ember@1.11.4",
            blockParams: 0,
            cachedFragment: null,
            hasRendered: false,
            build: function build(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "lawyer-search-details-next-hint");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            render: function render(context, env, contextualElement) {
              var dom = env.dom;
              var hooks = env.hooks,
                  get = hooks.get,
                  subexpr = hooks.subexpr,
                  concat = hooks.concat,
                  attribute = hooks.attribute,
                  element = hooks.element,
                  block = hooks.block;
              dom.detectNamespace(contextualElement);
              var fragment;
              if (env.useFragmentCache && dom.canClone) {
                if (this.cachedFragment === null) {
                  fragment = this.build(dom);
                  if (this.hasRendered) {
                    this.cachedFragment = fragment;
                  } else {
                    this.hasRendered = true;
                  }
                }
                if (this.cachedFragment) {
                  fragment = dom.cloneNode(this.cachedFragment, true);
                }
              } else {
                fragment = this.build(dom);
              }
              var element0 = dom.childAt(fragment, [1]);
              var morph0 = dom.createMorphAt(element0, 1, 1);
              var attrMorph0 = dom.createAttrMorph(element0, 'class');
              var morph1 = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              attribute(env, attrMorph0, element0, "class", concat(env, ["lawyer-search-details-next", subexpr(env, context, "if", [get(env, context, "nextDisabled"), " is-disabled"], {}), subexpr(env, context, "if", [get(env, context, "isSaving"), " is-saving"], {})]));
              element(env, element0, context, "action", ["next"], {});
              block(env, morph0, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), get(env, context, "lastStep")], {})], {}, child0, child1);
              block(env, morph1, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 1], {})], {}, child2, child3);
              return fragment;
            }
          };
        })();
        return {
          isHTMLBars: true,
          revision: "Ember@1.11.4",
          blockParams: 1,
          cachedFragment: null,
          hasRendered: false,
          build: function build(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "lawyer-search-details-nav");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "lawyer-search-details-previous-icon");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          render: function render(context, env, contextualElement, blockArguments) {
            var dom = env.dom;
            var hooks = env.hooks,
                set = hooks.set,
                get = hooks.get,
                block = hooks.block,
                subexpr = hooks.subexpr,
                concat = hooks.concat,
                attribute = hooks.attribute,
                element = hooks.element,
                inline = hooks.inline;
            dom.detectNamespace(contextualElement);
            var fragment;
            if (env.useFragmentCache && dom.canClone) {
              if (this.cachedFragment === null) {
                fragment = this.build(dom);
                if (this.hasRendered) {
                  this.cachedFragment = fragment;
                } else {
                  this.hasRendered = true;
                }
              }
              if (this.cachedFragment) {
                fragment = dom.cloneNode(this.cachedFragment, true);
              }
            } else {
              fragment = this.build(dom);
            }
            var element6 = dom.childAt(fragment, [2]);
            var element7 = dom.childAt(element6, [1]);
            var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
            var attrMorph0 = dom.createAttrMorph(element7, 'class');
            var morph1 = dom.createMorphAt(element7, 3, 3);
            var morph2 = dom.createMorphAt(element6, 3, 3);
            dom.insertBoundary(fragment, 0);
            set(env, context, "form", blockArguments[0]);
            block(env, morph0, context, "liquid-with", [get(env, context, "step")], {}, child0, null);
            attribute(env, attrMorph0, element7, "class", concat(env, ["lawyer-search-details-previous ", subexpr(env, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 1], {}), "is-hidden"], {}), " ", subexpr(env, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 5], {}), "is-final"], {})]));
            element(env, element7, context, "action", ["previous"], {});
            inline(env, morph1, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 5], {}), "Go Back", "Previous"], {});
            block(env, morph2, context, "unless", [subexpr(env, context, "eq", [get(env, context, "step"), 5], {})], {}, child1, null);
            return fragment;
          }
        };
      })();
      return {
        isHTMLBars: true,
        revision: "Ember@1.11.4",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "lawyer-search-details-header");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "lawyer-search-details-title");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks,
              get = hooks.get,
              subexpr = hooks.subexpr,
              block = hooks.block;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element13 = dom.childAt(fragment, [1]);
          var morph0 = dom.createMorphAt(element13, 1, 1);
          var morph1 = dom.createMorphAt(dom.childAt(element13, [3]), 1, 1);
          var morph2 = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          block(env, morph0, context, "unless", [subexpr(env, context, "eq", [get(env, context, "step"), 5], {})], {}, child0, null);
          block(env, morph1, context, "if", [subexpr(env, context, "eq", [get(env, context, "step"), 5], {})], {}, child1, child2);
          block(env, morph2, context, "form-for", [], { "model": get(env, context, "lawyer"), "class": "lawyer-search-details-form" }, child3, null);
          return fragment;
        }
      };
    })();
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.4",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks,
            block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var morph0 = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, null);
        dom.insertBoundary(fragment, 0);
        block(env, morph0, context, "modal-dialog", [], { "size": "lg", "closeable": true, "modalClosed": "modalClosed" }, child0, null);
        return fragment;
      }
    };
  })());
});