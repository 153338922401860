define('laterally/models/firm-location-job', ['exports', 'ember-data'], function (exports, _emberData) {

  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var alias = Ember.computed.alias;

  exports['default'] = _emberData['default'].Model.extend({

    firmLocation: belongsTo('firmLocation'),
    job: belongsTo('job'),
    jobSelections: hasMany('jobSelection')

  });
});