define('laterally/components/locations-select/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var sort = computed.sort;
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['locations-select'],
    classNameBindings: ['inset:locations-select-inset'],
    orderSorting: ['order'],

    // attrs
    selections: null,
    regions: null,
    inset: false,

    region: null,
    showInternational: false,

    internationalRegions: computed('regions.[]', function () {
      return get(this, 'regions').filterBy('isInternational', true);
    }),

    nonInternationalRegions: computed('regions.[]', function () {
      return get(this, 'regions').filterBy('isInternational', false);
    }),

    sortedInternationalRegions: sort('internationalRegions', 'orderSorting'),

    locationGrouper: function locationGrouper(object) {
      return get(object, 'state');
    },

    locationGroupSorter: function locationGroupSorter(a, b) {
      if (a < b) {
        return -1;
      }

      if (a > b) {
        return 1;
      }

      return 0;
    },

    internationalLocations: computed('regions.[]', function () {
      return [].concat.apply([], get(this, 'regions').filterBy('isInternational', true).map(function (r) {
        return get(r, 'locations').toArray();
      }));
    }),

    nonInternationalLocations: computed('regions.[]', function () {
      return [].concat.apply([], get(this, 'regions').filterBy('isInternational', false).map(function (r) {
        return get(r, 'locations').toArray();
      }));
    }),

    actions: {
      selectRegion: function selectRegion(region) {
        set(this, 'region', region);
      },

      clearRegion: function clearRegion() {
        set(this, 'region', null);
      },

      toggleInternational: function toggleInternational() {
        this.toggleProperty('showInternational');
      }
    }

  });
});