define('laterally/components/copyable-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'input',

    classNames: ['copyable-input'],

    attributeBindings: ['value'],

    highlight: (function () {
      if (this.get("trackAs")) {
        this.get('analytics').track(this.get("trackAs"));
      }
      this.get('element').setSelectionRange(0, 9999);
    }).on('click')

  });
});