define('laterally/components/selectized-dropdown/component', ['exports', 'ember'], function (exports, _ember) {
  var Select = _ember['default'].Select;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var equal = _ember['default'].computed.equal;
  var run = _ember['default'].run;
  exports['default'] = Select.extend({
    optionView: _ember['default'].SelectOption.extend({
      classNameBindings: ['zeroed'],
      zeroed: equal('content.zeroed', true)
    }),

    realValue: null,

    willInsertElement: function willInsertElement() {
      set(this, 'realValue', get(this, 'value'));
    },

    didInsertElement: function didInsertElement() {
      this.set('value', get(this, 'realValue'));
      this.initChosen();
    },

    initChosen: function initChosen() {
      var _this = this;

      this.$().append("<option></option");
      this.$().chosen({
        placeholder_text_single: get(this, 'label') || "Firm",
        placeholder_text_multiple: " ",
        inherit_select_classes: true,
        allow_single_deselect: true
      });

      run.next(function () {
        _this.$().next().find('.search-field > input').focus(function () {
          $(this).attr('placeholder', "Search...");
        }).blur(function () {
          $(this).attr('placeholder', "");
        });
      });
    },

    foo: (function () {
      var _this2 = this;

      run.next(function () {
        _this2.$().trigger('chosen:updated');
      });
    }).observes('content.[]', 'selection.[]')
  });
});