define("laterally/helpers/humanized-duration", ["exports", "ember"], function (exports, _ember) {
  var Handlebars = _ember["default"].Handlebars;
  var moment = window.moment;

  function humanizedDuration(count, period) {
    var dur = moment.duration(count, period).humanize();
    if (dur.slice(0, 5) !== "a few") {
      dur = dur.replace(/a (.+)/, "1 $1");
    }

    return new Handlebars.SafeString(dur);
  }

  exports.humanizedDuration = humanizedDuration;
  exports["default"] = Handlebars.makeBoundHelper(humanizedDuration);
});