define('laterally/components/locations-selector/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['locations-selector'],

    locations: null,
    selections: null,

    newSelections: null,

    initNewSelections: (function () {
      set(this, 'newSelections', get(this, 'selections').slice());
    }).on('init'),

    regions: computed('locations', function () {
      return get(this, 'locations').mapBy('region').uniq().compact().sortBy('name');
    }),

    willDismiss: function willDismiss() {
      this.sendAction('selectLocations', get(this, 'newSelections'));
    },

    actions: {
      outsideClick: function outsideClick() {
        this.willDismiss();
        this.sendAction('dismiss');
      },

      escape: function escape() {
        this.willDismiss();
        this.sendAction('dismiss');
      },

      abort: function abort() {
        this.sendAction('dismiss');
      },

      commit: function commit() {
        this.willDismiss();
        this.sendAction('dismiss');
      }
    }
  });
});