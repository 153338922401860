define("laterally/utils/computed/equal-with-set", ["exports"], function (exports) {
  exports["default"] = equalWithSet;

  function equalWithSet(dependentKey, equalValue, falseValue) {
    return (function (key, value) {
      if (arguments.length === 2) {
        this.set(dependentKey, value ? equalValue : falseValue);
      }
      return this.get(dependentKey) === equalValue;
    }).property(dependentKey);
  }
});