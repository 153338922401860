define('laterally/components/locations-list/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var sort = _ember['default'].computed.sort;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['locations-list'],

    locationsSorting: ['name'],
    sortedLocations: sort('locations', 'locationsSorting'),

    numColumns: 3,

    columns: (function () {
      return get(this, 'sortedLocations').inGroups(get(this, 'numColumns'));
    }).property('sortedLocations.@each', 'numColumns')
  });
});