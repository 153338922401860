define('laterally/models/access-token', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    value: alias('id'),
    exp: attr('number', { readOnly: true }),

    user: belongsTo('user', { readOnly: true, async: true }),
    possessedUser: belongsTo('user', { readOnly: true, async: true }),

    userId: computed('data', function () {
      return get(this, 'data.user');
    }),

    possessedUserId: computed('data', function () {
      return get(this, 'data.possessedUser');
    }),

    userIsAdmin: attr('boolean'),

    userEmail: attr('string', { writeOnly: true }),
    userPassword: attr('string', { writeOnly: true }),
    exploreeClaimToken: attr('string', { writeOnly: true }),
    singleAccessToken: attr('string', { writeOnly: true }),

    expired: computed('exp', function () {
      return new Date().getTime() / 1000 > get(this, 'exp');
    }).volatile()
  });
});