define('laterally/components/concerns/autocomplete', ['exports', 'ember'], function (exports, _ember) {
  var $ = _ember['default'].$;
  var get = _ember['default'].get;
  var extend = $.extend;
  exports['default'] = _ember['default'].Mixin.create({

    results: [],

    column: 'name', // by default use the name

    selected: $(),

    selectedActivator: (function () {
      var selected = this.get('selected');
      if (selected) {
        this.$('li').removeClass('active');
        selected.addClass('active');
      }
    }).observes('selected'),

    getResults: function getResults() {
      var component = this;
      var column = this.get('column');

      var value = this.get('value'),
          results = [];
      if (value) {
        results = this.get('targetObject.store').find(this.get('model'), {
          autocomplete: extend({}, {
            query: value,
            column: this.get('column')
          }, get(this, 'queryParams') || {})
        });
      }

      if (results && results.length !== 0) {
        results.then(function (unsortedResults) {
          var sortedResults = unsortedResults.get('content').sort(function (a, b) {
            return a.get(column).toLowerCase().indexOf(value) - b.get(column).toLowerCase().indexOf(value);
          });

          component.set('results', sortedResults);
        });
      } else {
        this.set('results', results);
      }
    },

    resultsLoader: (function () {
      if (this.get('ignoreValueChange')) {
        return;
      }
      _ember['default'].run.debounce(this, this.getResults, 250);
    }).observes('value'),

    interpretKeyPress: (function (event) {
      var code = event.keyCode;
      if (code === 38 || code === 40 || code === 13) {
        event.preventDefault();
      }
      switch (code) {
        case 38:
          this.send('move', true);break; // Up
        case 40:
          this.send('move', false);break; // Down
        case 13:
          this.send('enter');break; // Enter
      }
    }).on('keyDown'),

    ResultsView: _ember['default'].CollectionView.extend({

      tagName: 'ul',

      classNames: ['dropdown-menu'],

      isVisible: _ember['default'].computed.and('parentView.results.length', 'parentView.editing'),

      content: _ember['default'].computed.alias('parentView.results'),

      preventInputBlur: (function (event) {
        event.preventDefault();
      }).on('mouseDown'),

      itemViewClass: _ember['default'].View.extend({

        defineColumn: (function () {
          var key = 'content.' + this.get('parentView.parentView.column');
          _ember['default'].defineProperty(this, 'column', _ember['default'].computed.alias(key));
        }).on('init'),

        click: function click(event) {
          event.preventDefault();
          event.stopPropagation();
          this.get('parentView.parentView').send('select', this.get('content'));
        },

        mouseEnter: function mouseEnter() {
          this.set('parentView.parentView.selected', this.$());
        },

        mouseLeave: function mouseLeave() {
          this.set('parentView.parentView.selected', $());
        }

      })

    }),

    actions: {

      select: function select(value) {
        this.set('results', []);
        this.set('ignoreValueChange', true);
        this.set('value', value.get(this.get('column')));
        this.set('ignoreValueChange', false);
        this.sendAction('didAutocomplete', value);
      },

      move: function move(up) {
        var selected = this.get('selected');
        if (selected.length) {
          selected = up ? selected.prev() : selected.next();
        } else {
          selected = up ? this.$('li:last') : this.$('li:first');
        }
        this.set('selected', selected);
      },

      enter: function enter() {
        var selected = this.get('selected');
        if (selected) {
          selected.click();
        }
      }

    }

  });
});