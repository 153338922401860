define('laterally/components/tabs-wrapper/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var A = _ember['default'].A;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    tagName: '',
    selectedTab: null,
    selectTab: null,

    tabs: null,

    tabChanged: _ember['default'].observer('selectedTab', function () {
      this.sendAction('selectTab', get(this, 'selectedTab'));
    }),

    init: function init() {
      this._super();
      set(this, 'tabs', A());
    },

    willInsertElement: function willInsertElement() {
      var tabs = get(this, 'tabs');
      var enabledTabs = tabs.filterBy('disabled', false);
      var tab = enabledTabs.findBy('name', get(this, 'selectedTab')) || get(enabledTabs, 'firstObject') || get(tabs, 'firstObject');

      set(this, 'selectedTab', get(tab, 'name'));
    },

    registerTab: function registerTab(tab) {
      get(this, 'tabs').addObject(tab);
    }

  });
});