define('laterally/components/accordion-list/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var guidFor = _ember['default'].guidFor;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['accordion-list', 'panel-group'],

    // attrs
    content: [],
    titlePath: null,

    panels: computed('content.[]', function () {
      var titlePath = get(this, 'titlePath');
      return get(this, 'content').map(function (item) {
        return {
          item: item,
          title: get(item, titlePath),
          id: 'accordion-list-panel-' + guidFor(item)
        };
      });
    })

  });
});