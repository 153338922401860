define('laterally/initializers/embedded-records', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var get = _ember['default'].get;

  function includeRecords(serializer, relationship) {
    var attrs = serializer.get('attrs');
    var options = get(attrs, relationship.key);
    if (options) {
      return get(options, 'embedded') === 'always' || get(options, 'serialize') === 'records';
    } else {
      return false;
    }
  }

  var initialize = function initialize() {
    _emberData['default'].EmbeddedRecordsMixin.reopen({

      serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
        this._super.apply(this, arguments);
        if (includeRecords(this, relationship)) {
          var key = this.keyForRelationship(relationship.key);
          var value = json[key];
          if (value) {
            delete json[key];
            json[key + '_attributes'] = value;
          }
        }
      },

      serializeHasMany: function serializeHasMany(record, json, relationship) {
        this._super.apply(this, arguments);
        if (includeRecords(this, relationship)) {
          var key = this.keyForAttribute(relationship.key);
          var value = json[key];
          if (value) {
            delete json[key];
            json[key + '_attributes'] = value;
          }
        }
      }

    });
  };

  exports.initialize = initialize;
  exports['default'] = {
    name: 'embedded-records',
    initialize: initialize
  };
});