define('laterally/controllers/vote', ['exports', 'ember', 'laterally/controllers/concerns/paged', 'laterally/controllers/concerns/ordered', 'laterally/models/exploree-group-membership'], function (exports, _ember, _laterallyControllersConcernsPaged, _laterallyControllersConcernsOrdered, _laterallyModelsExploreeGroupMembership) {

  var alias = _ember['default'].computed.alias;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].ArrayController.extend(_laterallyControllersConcernsPaged['default'], _laterallyControllersConcernsOrdered['default'], _laterallyModelsExploreeGroupMembership.stateProperties, {

    itemController: 'explorees/exploree',

    firmUser: alias('session.userable'),

    displayLastMove: false,

    queryParams: ['state'],

    // This is automatically set in the route.
    group: null,

    state: 'saved',
    orderDirection: 'asc',
    orderColumn: 'firms.name',

    groupVoters: _ember['default'].computed.filter('uniqueVoters', function (voter) {
      if (voter.get('splitGroupIds')) {
        return voter.get('splitGroupIds').contains(this.get('group.id'));
      }
    }),

    currentExploreVoter: alias('session.exploreVoter'),
    uniqueVoters: _ember['default'].computed.uniq('voters'),

    voteCount: (function () {
      return this.get('currentExploreVoter.voteCount') || 0;
    }).property('currentExploreVoter.voteCount'),

    scrollToTopObserver: (function () {
      if (!this.get('loading')) {
        _ember['default'].run.schedule('afterRender', function () {
          var results = _ember['default'].$('.explore-results');

          if (results.length) {
            var offset = results.offset().top;
            if (offset < _ember['default'].$(window).scrollTop()) {
              _ember['default'].$('html, body').animate({
                scrollTop: offset
              }, 500);
            }
          }
        });
      }
    }).observes('loading'),

    createVote: function createVote(exploree, value) {
      var vote = this.store.createRecord('exploreVote', { value: value });
      vote.set('exploree', exploree);
      vote.set('exploreVoter', this.get('currentExploreVoter'));
      vote.set('exploreeGroupShareToken', this.get('group.shareToken'));
      return vote.save();
    },

    getExistingVote: function getExistingVote(exploree) {
      var _this = this;

      if (this.get('votes')) {
        var matching = this.get('votes').filter(function (v) {
          return v.get('exploree.id') == exploree.get('id') && v.get('exploreVoter.id') == _this.get('currentExploreVoter.id');
        });
        if (matching) {
          return matching.get('firstObject');
        }
      }
    },

    setVoter: function setVoter(voter) {
      this.send('setCurrentVoter', voter);
    },

    actions: {

      drawerOpened: function drawerOpened(component /*, animation, progress, remaining */) {
        var element = component.$().parent();
        var offset = element.offset().top;
        var scroll = _ember['default'].$(window).scrollTop();
        if (scroll > 100 && scroll > offset) {
          _ember['default'].$(window).scrollTop(offset);
        }
      },

      identifyUser: function identifyUser(fn) {
        if (this.get('currentExploreVoter.id')) {
          fn();
        } else {
          this.set('showVoterIdentifyModal', true);
          this.set('delayedAction', fn);
        }
      },

      changeUser: function changeUser() {
        this.set('showVoterIdentifyModal', true);
        this.analytics.track('Explore Vote: Clicked Change User');
      },

      identifyUserModalClosed: function identifyUserModalClosed() {
        this.set('showVoterIdentifyModal', false);
        if (this.get('currentExploreVoter.id')) {
          var fn = this.get('delayedAction');
          if (fn) {
            fn();
            this.set('delayedAction', null);
          }
        }
      },

      vote: function vote(exploree, value) {
        var _this2 = this;

        var vote = this.getExistingVote(exploree);
        if (vote) {
          vote.set('value', value);
          return vote.save();
        } else {
          var newVote = this.createVote(exploree, value);
          newVote.then(function (vote) {
            _this2.get('votes').addObject(vote);
          });
          return newVote;
        }
      },

      unVote: function unVote(exploree) {
        var vote = this.getExistingVote(exploree);
        if (vote) {
          vote.set('value', null);
          return vote.save();
        }
      },

      createVoter: function createVoter(newVoterName) {
        var _this3 = this;

        var exploreeGroupShareToken = this.get('group.shareToken');
        var voter = this.store.createRecord('explore-voter', { name: newVoterName, exploreeGroupShareToken: exploreeGroupShareToken });
        return voter.save().then(function (savedVoter) {
          _this3.setVoter(savedVoter);
          _this3.get('voters').addObject(savedVoter);
        });
      },

      selectVoter: function selectVoter(voter) {
        this.setVoter(voter);
      },

      saveComment: function saveComment(commentText, exploree) {
        var comment = this.store.createRecord('exploreeComment', {
          text: commentText,
          exploree: exploree,
          exploreVoter: this.get('currentExploreVoter'),
          exploreeGroupShareToken: this.get('group.shareToken')
        });
        comment.save();
      },

      focusComment: function focusComment(exploreeId) {
        var _this4 = this;

        run.later(function () {
          var focusTargetName = 'exploree-comment-textarea-' + exploreeId;
          _this4.send('focusOn', focusTargetName, 'vote', { exploree_group_share_token: _this4.get('group.shareToken') });
        }, 250);
      }
    }

  });
});