define('laterally/controllers/lawyer/transcript', ['exports', 'ember', 'laterally/controllers/lawyer/attachment'], function (exports, _ember, _laterallyControllersLawyerAttachment) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend(_laterallyControllersLawyerAttachment['default'], {

    model: null,
    lawyer: alias('model'),
    canEdit: null,

    attachment: alias('lawyer.transcript'),

    attachmentName: 'transcript'

  });
});