define('laterally/services/referrer-manager', ['exports', 'ember', 'laterally/utils/computed/stored-property'], function (exports, _ember, _laterallyUtilsComputedStoredProperty) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Object.extend({
    _tokenValue: (0, _laterallyUtilsComputedStoredProperty['default'])('referrerToken'),

    fetchOrInitReferrer: function fetchOrInitReferrer(user) {
      var _this = this;

      var promise = new RSVP.Promise(function (resolve) {
        var tokenValue = get(_this, '_tokenValue');

        if (tokenValue) {
          _this.store.find('referrer', tokenValue).then(resolve);
        } else {
          var attrs = {};

          if (user) {
            attrs = {
              firstName: get(user, 'userable.firstName'),
              lastName: get(user, 'userable.lastName'),
              email: get(user, 'email')
            };
          }

          resolve(_this.store.createRecord('referrer', attrs));
        }
      });

      return promise;
    },

    createReferrer: function createReferrer(initializedReferrer) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        initializedReferrer.save().then(function (referrer) {
          set(_this2, '_tokenValue', get(referrer, 'token'));
          resolve(referrer);
        }, reject);
      });
    },

    clearReferrer: function clearReferrer() {
      set(this, '_tokenValue', null);
    }
  });
});