define('laterally/models/explore-voter', ['exports', 'ember-data'], function (exports, _emberData) {

  var alias = Ember.computed.alias;

  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    firm: _emberData['default'].belongsTo('firm'),
    exploreeGroupShareToken: _emberData['default'].attr('string'),
    exploreVotes: _emberData['default'].hasMany('exploreVote'),
    exploreeGroupIds: _emberData['default'].attr('string'),

    voteCount: (function () {
      var nonemptyVotes = this.get('exploreVotes').filter(function (vote) {
        return vote.get('value');
      });
      return nonemptyVotes.get('length');
    }).property('exploreVotes.@each.value'),

    splitGroupIds: (function () {
      if (this.get('exploreeGroupIds')) {
        return this.get('exploreeGroupIds').split(',');
      }
    }).property('exploreeGroupIds')
  });
});