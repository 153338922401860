define('laterally/components/selectable-item/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    // attrs
    selections: null,
    selected: null,
    deselected: null,

    isSelected: computed('selections.[]', 'item', function () {
      return get(this, 'selections').contains(get(this, 'item'));
    }),

    isDisabled: false,

    actionSender: observer('isSelected', function () {
      var action = get(this, 'isSelected') ? 'selected' : 'deselected';
      this.sendAction(action, get(this, 'item'));
    })

  });
});