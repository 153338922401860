define('laterally/controllers/landing', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var equal = _ember['default'].computed.equal;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Controller.extend({
    shouldAuthenticate: false,
    willReadPledge: false,

    activeTab: 'jobs',
    asMoveTracker: equal('activeTab', 'move-tracker'),
    forMoveTracker: false,

    product: computed('forMoveTracker', function () {
      if (get(this, 'forMoveTracker')) {
        return 'Move Tracker';
      } else {
        return 'opportunities on Laterally';
      }
    }),

    actions: {
      toggleTab: function toggleTab(val) {
        this.analytics.track('Landing: Selected ' + val + ' tab');
        set(this, 'activeTab', val);
      }
    }
  });
});