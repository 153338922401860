define('laterally/components/firms/index/firm-row/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;

  exports['default'] = _ember['default'].Component.extend({

    tagName: '',

    store: service(),

    // attrs
    firm: null,
    locationId: null,
    practiceAreaId: null,
    selectedFirm: null,
    selectedState: null,
    'on-select': 'select',
    'on-open': null,
    'on-close': null,
    hideFirm: null,

    lawyer: computed('session.userable', function () {
      var userable = get(this, 'session.userable');
      if (userable) {
        return get(userable, 'isLawyer') && userable;
      }
    }),

    isSelected: computed('selectedFirm', function () {
      return get(this, 'selectedFirm') === get(this, 'firm');
    }),

    firmLocationJobs: computed(function () {
      var _this = this;

      var store = get(this, 'store');
      var firmId = get(this, 'firm.id');
      var locationId = get(this, 'locationId');
      var practiceAreaId = get(this, 'practiceAreaId');
      var state = get(this, 'selectedState');
      var promise = store.find('firmLocationJob', { firmId: firmId, locationId: locationId, practiceAreaId: practiceAreaId, state: state });
      promise.then(function () {
        set(_this, 'firmLocationJobsLoaded', true);
      });
      return promise;
    }),

    firmLocationJobsLoaded: false,

    selectedCount: computed('firm', 'lawyer.jobSelections.[]', function () {
      var lawyer = get(this, 'lawyer');
      if (lawyer) {
        return get(lawyer, 'jobSelections').filterBy('firmLocationJob.firmLocation.firm', get(this, 'firm')).filterBy('hiddenFromLawyers', false).get('length');
      }
    }),

    hidden: computed('firmLocationJobsLoaded', 'firmLocationJobs.[]', 'lawyer.jobSelections.@each.hiddenFromLawyers', function () {
      var lawyer = get(this, 'lawyer');
      if (!lawyer) {
        return false;
      }
      if (!get(this, 'firmLocationJobsLoaded')) {
        return false;
      }
      var onHiddenTab = get(this, 'selectedState') === 'hidden';
      var jobSelections = get(lawyer, 'jobSelections');
      return get(this, 'firmLocationJobs').every(function (firmLocationJob) {
        var jobSelection = jobSelections.findBy('firmLocationJob', firmLocationJob);
        return !!(onHiddenTab ^ (jobSelection && get(jobSelection, 'hiddenFromLawyers')));
      });
    }),

    actions: {

      opened: function opened() {
        this.sendAction('on-open', get(this, 'firm'));
      },

      closed: function closed() {
        this.sendAction('on-close', get(this, 'firm'));
      },

      select: function select() {
        this.sendAction('on-select', get(this, 'firm'));
      },

      hideLocationJob: function hideLocationJob(job) {
        if (this.get('hidden')) {
          var firm = job.get('firmLocation.firm');
          this.sendAction('hideFirm', firm);
        }
      }

    }

  });
});