define('laterally/routes/profile', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      var _this = this;

      this.session.get('user').then(function (user) {
        if (user) {
          if (user.get('isLawyer')) {
            _this.transitionTo('firms');
          } else if (user.get('isFirm')) {
            _this.transitionTo('firm-user', user.get('userable'));
          } else if (user.get('isDeveloper')) {
            _this.transitionTo('landing');
          } else if (user.get('employerApplicationId')) {
            _this.transitionTo('applied');
          } else {
            _this.transitionTo('onboarding');
          }
        } else {
          _this.transitionTo('login');
        }
      });
    }

  });
});