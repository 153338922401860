define('laterally/components/linkedin-verification/component', ['exports', 'ember', 'laterally/config/environment', 'laterally/utils/deferred-receive-action'], function (exports, _ember, _laterallyConfigEnvironment, _laterallyUtilsDeferredReceiveAction) {
  var Component = _ember['default'].Component;
  var RSVP = _ember['default'].RSVP;
  var $ = _ember['default'].$;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  var _ = window._;
  exports['default'] = Component.extend({
    classNames: ['linkedin-verification'],
    classNameBindings: ['providedUrl:-has-url'],

    providedUrl: null,

    onMessage: function onMessage(dfr, event) {
      var _this = this;

      run(function () {
        if (event.data.match && event.data.match(/^verification:/)) {
          set(_this, 'providedUrl', event.data.split(/^verification:/)[1]);

          dfr.resolve();
          window.removeEventListener('message', _this.messageListener);
        }
      });
    },

    actions: {
      authenticate: (0, _laterallyUtilsDeferredReceiveAction['default'])(function () {
        var dfr = RSVP.defer();

        this.messageListener = _.bind(this.onMessage, this, dfr);
        window.addEventListener('message', this.messageListener, false);

        var popup = window.open('about:blank');

        this.session.getOneTimeToken().then(function (token) {
          popup.location.href = _laterallyConfigEnvironment['default'].APP.API_HOST + '/auth/linkedin?one_time_token=' + get(token, 'value');
        });

        return dfr.promise;
      })
    }
  });
});