define('laterally/components/explore-vote-actions/component', ['exports', 'ember'], function (exports, _ember) {

  var computed = _ember['default'].computed;
  var alias = computed.alias;

  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({

    identifyUser: 'identifyUser',
    vote: 'vote',
    unVote: 'unVote',
    exploree: null,
    currentVoterId: null,

    existingVote: (function () {
      var _this = this;

      return this.get('exploree.exploreVotes').find(function (v) {
        return v.get('exploreVoter.id') == _this.get('currentVoterId');
      });
    }).property('exploree.exploreVotes.@each.id', 'currentVoterId'),

    existingComment: (function () {
      return this.get('exploree.commentsCount') && this.get('exploree.commentsCount') > 0;
    }).property('exploree.commentsCount'),

    requireIdentity: function requireIdentity(fn) {
      this.sendAction('identifyUser', fn);
    },

    actions: {
      voteYes: function voteYes() {
        var _this2 = this;

        this.analytics.track('Explore Vote: Clicked Vote Yes');
        this.requireIdentity(function () {
          _this2.sendAction('vote', _this2.get('exploree'), 'yes');
        });
      },

      voteNo: function voteNo() {
        var _this3 = this;

        this.analytics.track('Explore Vote: Clicked Vote No');
        this.requireIdentity(function () {
          _this3.sendAction('vote', _this3.get('exploree'), 'no');
        });
      },

      unVote: function unVote() {
        this.analytics.track('Explore Vote: Removed Vote');
        this.sendAction('unVote', this.get('exploree'));
      },

      clickComment: function clickComment() {
        this.analytics.track('Explore Vote: Click [comment] button');
        this.sendAction('toggleOpen');
        this.sendAction('focusComment', this.get('exploree.id'));
      }
    }

  });
});