define('laterally/routes/explorees/campaign', ['exports', 'ember', 'laterally/routes/concerns/paged', 'laterally/routes/concerns/ordered'], function (exports, _ember, _laterallyRoutesConcernsPaged, _laterallyRoutesConcernsOrdered) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;

  var FILTERS = ['practiceAreas', 'practiceAreasMatch', 'locations', 'positions', 'firms', 'schools', 'schoolRanks', 'firmRanks', 'hasClerkships', 'hasBio', 'graduationYearMin', 'graduationYearMax', 'lastMoveYear', 'lastMoveUnknown', 'keywords'];

  exports['default'] = _ember['default'].Route.extend(_laterallyRoutesConcernsPaged['default'], _laterallyRoutesConcernsOrdered['default'], {

    queryParams: {
      filterSetId: { refreshModel: true },
      state: { refreshModel: true }
    },

    setupController: function setupController(controller, hash) {
      if (hash.filters) {
        controller.useFilters(hash.filters);
      }

      delete hash.filters;
      controller.setProperties(hash);

      _ember['default'].run.next(function () {
        controller.get('practiceAreaCategories');
        controller.get('locationOptions');
        controller.get('firmOptions');
        controller.get('schoolOptions');
        controller.get('practiceAreaObjects');
      });
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.resetFilters();
      }
    },

    model: function model(params) {
      var _this = this;

      var others = {
        state: params.state,
        page: params.page,
        orderColumn: params.orderColumn,
        orderDirection: params.orderDirection
      };

      return this.store.findById('exploreeGroup', params.campaign_id).then(function (group) {
        var query;
        var filterSet;
        var filters = _this.getControllerFilters();

        if (params.filterSetId) {
          filterSet = get(group, 'filterSets').findBy('id', params.filterSetId);

          if (filterSet) {
            filters = get(filterSet, 'filters');
          }
        }

        query = _ember['default'].merge(others, filters);
        query.group_id = params.campaign_id;

        return new _ember['default'].RSVP.hash({
          model: _this.store.find('exploree', query),
          group: group,
          filterSet: filterSet,
          filters: filters
        });
      });
    },

    afterModel: function afterModel(hash, transition) {
      transition.send('visitedCampaign', hash.group);
      return this.controllerFor('explorees/campaign').get('firmOptions');
    },

    defaults: (function () {
      var controller = this.controllerFor('explorees/campaign');
      return controller.get('defaultFilters');
    }).property(),

    getDefaults: function getDefaults() {
      var controller = this.controllerFor('explorees/campaign');
      var filters = controller.get('defaultFilters');
      filters.practiceAreas = [];
      filters.locations = [];
      filters.firms = [];
      filters.schools = [];
      filters.schoolRanks = [];
      filters.firmRanks = [];

      return filters;
    },

    getControllerFilters: function getControllerFilters() {
      return this.controllerFor('explorees/campaign').getProperties(FILTERS);
    },

    selectGroupIdAndState: function selectGroupIdAndState(groupId, state) {
      var qp = this.get('defaults');
      qp.state = state;

      this.resetFilters();
      this.transitionTo('explorees.campaign', groupId, { queryParams: qp }).then(function () {});
    },

    resetFilters: function resetFilters() {
      this.controllerFor('explorees.campaign').setProperties(this.getDefaults());
    },

    actions: {

      // Remove filterSet from URL whenever a filter changes
      filterDidChange: function filterDidChange() {
        var controller = this.controllerFor('explorees.campaign');
        var id = controller.get('filterSetId');

        set(controller, 'page', 1);

        if (id) {
          set(controller, 'filterSetId', null); // Will trigger refresh
        } else {
            this.refresh();
          }
      },

      clearFilters: function clearFilters() {
        var defaults = this.get('defaults');
        var controller = this.controllerFor('explorees.campaign');
        defaults['state'] = controller.get('state');
        controller.setProperties(defaults);
        controller.set('filterSet', null);
        this.analytics.track('Cleared explore filters');
      },

      selectGroup: function selectGroup(group) {
        this.send('selectGroupAndState', group, '');
      },

      closedGroup: function closedGroup() {
        this.transitionTo('explorees.index');
      },

      destroyedGroup: function destroyedGroup() {
        this.transitionTo('explorees.index');
      },

      selectGroupAndState: function selectGroupAndState(group, state) {
        this.selectGroupIdAndState(group.get('id'), state);
        this.analytics.track('Selected explore group and state', {
          exploreeGroup: group.getProperties('id', 'name'),
          state: state
        });
      },

      showTermsOfService: (function () {
        this.send('openModal', 'application/termsOfServiceModal');
      }).track('Shown Terms of Service'),

      contactExploree: function contactExploree(message, exploree, campaign) {
        var route = this;
        this.send("openModal", "application/contactModal", {
          exploree: exploree,
          exploreeGroup: campaign,
          sendTo: exploree,
          defaultMessage: message,
          afterSend: function afterSend() {
            route.send('messageSentFromCampaign', campaign);
          }
        });
      },

      loading: function loading() {
        this.controllerFor('explorees/campaign').set('loading', true);
        var current = this.controllerFor('application').get('currentRouteName');
        // Bubble the event only if we're transitioning from another route because
        // even catching this event doesn't make the template render faster so we
        // might as well hit some global loading template.
        return current !== this.get('routeName');
      },

      didTransition: function didTransition() {
        this.controllerFor('explorees/campaign').set('loading', false);
        return true;
      }

    }

  });
});