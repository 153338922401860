define('laterally/services/slack', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Service.extend({
    notify: function notify(messageText) {
      if (_laterallyConfigEnvironment['default'].environment != 'production') {
        console.log("FOR SLACK (but only in prod): " + messageText);
        return;
      }
      $.ajax({
        type: 'POST',
        url: 'https://hooks.slack.com/services/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',
        data: JSON.stringify({ text: messageText }),
        dataType: 'json',
        headers: null
      });
    }
  });
});