define('laterally/components/external-jobs-page/component', ['exports', 'ember', 'laterally/mixins/inaccessible'], function (exports, _ember, _laterallyMixinsInaccessible) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var alias = computed.alias;
  var sumBy = computed.sumBy;
  exports['default'] = Component.extend(_laterallyMixinsInaccessible['default'], {
    classNames: ['jobs-history', 'external-jobs'],

    groups: null,

    minExperience: null,
    maxExperience: null,
    experienceThreshold: null,
    locationOptions: null,
    practiceAreaOptions: null,
    practiceAreaCategoryOptions: null,
    openIntercom: 'openIntercom',
    hideJob: 'hideJob',
    saveJob: 'saveJob',
    goToPage: 'goToPage',
    reloadModel: 'reloadModel',

    startExperience: null,
    endExperience: null,
    locations: [],
    practiceAreas: [],

    jobsCount: alias('groups.length'),

    userableAllowed: computed('userable.{state,stage}', function () {
      return !get(this, 'userable.steps.SELECTING');
    }),

    actions: {

      experienceSelected: function experienceSelected(values) {
        this.sendAction('experienceSelected', values);
      },

      selectLocations: function selectLocations() {
        this.sendAction('selectLocations', get(this, 'locationOptions'), get(this, 'locations'));
      },

      selectPracticeAreas: function selectPracticeAreas() {
        this.sendAction('selectPracticeAreas', get(this, 'practiceAreaCategoryOptions'), get(this, 'practiceAreas'));
      },

      removeLocation: function removeLocation(location) {
        this.sendAction('locationRemoved', location);
      },

      removePracticeArea: function removePracticeArea(practiceArea) {
        this.sendAction('practiceAreaRemoved', practiceArea);
      },

      openIntercom: function openIntercom() {
        this.sendAction('openIntercom');
      },

      hideJob: function hideJob(job) {
        this.sendAction('hideJob', job);
      },

      saveJob: function saveJob(job, delay) {
        this.sendAction('saveJob', job, delay);
      },

      kindChanged: function kindChanged(kind) {
        if (get(this, 'kind') !== kind) {
          this.sendAction('kindSelected', kind);
        }
      },

      goToPage: function goToPage(number) {
        this.sendAction('goToPage', number);
      },

      reloadModel: function reloadModel() {
        this.sendAction('reloadModel');
      },

      setKeywords: function setKeywords(keywords) {
        this.sendAction('reloadModel');
        this.sendAction('keywordsChanged', keywords);
      }
    }
  });
});