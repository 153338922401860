define('laterally/models/practice-area', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  var alias = _ember['default'].computed.alias;

  exports['default'] = _emberData['default'].Model.extend({

    name: attr("string"),
    category: attr("string"),
    childOrder: attr('number'),

    parent: belongsTo('practiceArea', { inverse: 'kids' }),
    kids: hasMany('practiceArea', { inverse: 'parent' }),
    children: alias('kids'),

    practiceAreaCategory: belongsTo('practiceAreaCategory'),
    practiceAreaEntries: hasMany('practiceAreaEntries'),
    firmLocations: hasMany('firmLocation'),

    sortChildren: ['childOrder:asc'],
    sortedChildren: _ember['default'].computed.sort('children', 'sortChildren')

  });
});