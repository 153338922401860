define('laterally/components/tool-tip', ['exports', 'ember'], function (exports, _ember) {

  var not = _ember['default'].computed.not;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['tool-tip'],

    attributeBindings: ['placement:data-placement', 'title'],

    enabled: not('disabled'),
    disabled: false,

    _initializedTooltip: false,

    initializeTooltip: (function () {
      if (this.get('enabled') && !this.get('_initializedTooltip')) {
        this.set('_initializedTooltip', true);
        this.$().tooltip({ container: 'body' });
      }
    }).observes('enabled').on('willInsertElement'),

    destroyTooltip: (function () {
      if (!this.get('enabled') && this.get('_initializedTooltip')) {
        this.set('_initializedTooltip', false);
        this.$().tooltip('destroy');
      }
    }).observes('enabled').on('willDestroyElement')

  });
});