define('laterally/controllers/application/unsaved-changes-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['lawyer'],

    actions: {

      'continue': function _continue() {
        this.send('closeModal');
        this.get('controllers.lawyer').set('retrying', true);
        if (this.get('previousTransition')) {
          this.get("previousTransition").retry();
        }
      }

    }

  });
});