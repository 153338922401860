define('laterally/controllers/firm-user/practice-area', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    needs: ['firmUser'],

    childrenSort: ['name'],
    children: _ember['default'].computed.sort('model.children', 'childrenSort'),

    firmLocationUserController: _ember['default'].computed.alias('parentController.firmLocationUserController'),

    checked: (function (_, value) {
      var practiceArea = this.get('content');
      var practiceAreas = this.get('firmLocationUserController.model.firmLocation.practiceAreas');
      var checked = !!practiceAreas.contains(practiceArea);
      if (arguments.length === 2 && checked ^ value) {
        if (value) {
          practiceAreas.addObject(practiceArea);
        } else {
          practiceAreas.removeObject(practiceArea);
        }
      }
      return checked;
    }).property('firmLocationUserController.firmLocation.practiceAreas.[]')

  });
});