define('laterally/routes/explore-jobs/edit', ['exports', 'ember', 'laterally/utils/has-many-committer'], function (exports, _ember, _laterallyUtilsHasManyCommitter) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      var _this = this;

      return this.store.fetchById('exploreeGroup', params.exploree_group_id).then(function (group) {
        return group.get('job').then(function (job) {
          var hash = { campaign: group };
          if (job) {
            hash['model'] = job;
          } else {
            var attrs = {
              firm: _this.session.get('userable.firm'),
              firmUser: _this.session.get('userable'),
              exploreeGroup: group,
              isPublic: false
            };
            hash['model'] = _this.store.createRecord('job', attrs);
          }
          hash['committer'] = _ember['default'].Object.createWithMixins(_laterallyUtilsHasManyCommitter['default'], {
            records: hash['model'].get('firmGroupMembers')
          });
          return hash;
        });
      });
    },

    setupController: function setupController(controller, hash) {
      controller.setProperties(hash);
    },

    actions: {
      goToExplore: function goToExplore(exploreeGroup) {
        this.transitionTo('explorees.campaign', exploreeGroup.get('id'));
      }
    }

  });
});