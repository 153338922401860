define('laterally/components/message-input', ['exports', 'ember', 'laterally/utils/computed/store-locally'], function (exports, _ember, _laterallyUtilsComputedStoreLocally) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['message-input'],
    inputPlaceholder: '',

    replacements: null,

    message: '',
    messageIsEmpty: _ember['default'].computed.equal('message.length', 0),

    normalizedMessage: (function (key, value) {
      if (arguments.length > 1) {
        this.set('message', value);
        return value;
      } else {
        var message = this.get('message') || '';
        var replacements = this.get('replacements') || {};

        _ember['default'].keys(replacements).forEach(function (key) {
          message = message.replace('{{' + key + '}}', replacements[key]);
        });

        return message;
      }
    }).property('message'),

    submitOnEnter: (0, _laterallyUtilsComputedStoreLocally['default'])('submit-on-enter', false),

    keyPress: function keyPress(e) {
      var $textarea = this.$('textarea');
      var $target = this.$(e.target);

      if ($target.is($textarea) && e.keyCode === 13 && !e.shiftKey) {
        if (this.get('submitOnEnter')) {
          e.preventDefault();
          this.send('submit');
        }
      }
    },

    autosizeTextArea: (function () {
      this.$('textarea').textareaAutoSize();
    }).on('didInsertElement'),

    actions: {

      submit: function submit() {
        if (!this.get('messageIsEmpty')) {
          this.sendAction('messageSubmitted', this.get('message'));
          this.set('message', '');
          _ember['default'].run.scheduleOnce('afterRender', this, function () {
            // Must check because the conversation modal hides the textarea
            // when you submit.
            if (this.$()) {
              this.$('textarea').trigger('input');
            }
          });
        }
      }

    }

  });
});