define('laterally/components/form-text-field/component', ['exports', 'ember', 'laterally/components/form-for/input'], function (exports, _ember, _laterallyComponentsFormForInput) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  var defaultTypes = { email: 'email', password: 'password' };

  exports['default'] = _ember['default'].Component.extend(_laterallyComponentsFormForInput['default'], {

    classNames: ['form-for--text-field'],

    // attrs
    type: computed('property', function () {
      return defaultTypes[get(this, 'property')] || 'text';
    }),
    placeholder: computed('form.hasLabelPlaceholder', 'label', function () {
      if (get(this, 'form.hasLabelPlaceholder')) {
        return get(this, 'label');
      }
    }),

    disabled: false,

    actions: {

      focus: function focus() {
        this.$('input').focus();
      }

    }

  });
});