define('laterally/routes/landing-recruiters', ['exports', 'ember', 'laterally/routes/landing'], function (exports, _ember, _laterallyRoutesLanding) {
  var Route = _ember['default'].Route;
  var get = _ember['default'].get;
  exports['default'] = _laterallyRoutesLanding['default'].extend({
    setupController: function setupController(c, model) {
      this.controllerFor('landing').set('model', model);
      this.controllerFor('landing').set('activeTab', 'recruiters');
    },

    renderTemplate: function renderTemplate() {
      this.render('landing');
    }
  });
});