define('laterally/utils/track-properties', ['exports', 'ember', 'laterally/utils/track-property'], function (exports, _ember, _laterallyUtilsTrackProperty) {
  exports['default'] = trackProperties;

  var uuid = _ember['default'].uuid;
  var merge = _ember['default'].merge;

  function trackProperties(events) {
    var prefix = arguments.length <= 1 || arguments[1] === undefined ? '' : arguments[1];

    var trackers = {};
    for (var property in events) {
      var event = events[property];
      if (typeof event === 'string') {
        var key = '__trackProperty' + uuid();
        trackers[key] = (0, _laterallyUtilsTrackProperty['default'])(prefix + property, events[property]);
      } else {
        merge(trackers, trackProperties(event, property + '.'));
      }
    }
    return trackers;
  }
});