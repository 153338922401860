define('laterally/routes/invite-token', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      set(controller, 'user', this.store.createRecord('invitedUser', {
        email: get(model, 'firmUser.inviteEmail'),
        inviteToken: model
      }));
    },

    model: function model(params) {
      return this.store.find('inviteToken', params.token_id);
    }

  });
});