define('laterally/routes/concerns/require-lawyer-state', ['exports', 'ember', 'laterally/routes/concerns/require-user'], function (exports, _ember, _laterallyRoutesConcernsRequireUser) {
  exports['default'] = _ember['default'].Mixin.create(_laterallyRoutesConcernsRequireUser['default'], {

    verifyState: (function (user) {
      if (user.get('isLawyer') && user.get('userable.state') !== this.get('requiredState')) {
        this.trigger('rejectedState');
      }
    }).on('requiredUser')

  });
});