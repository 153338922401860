define('laterally/models/exploree-comment', ['exports', 'ember-data'], function (exports, _emberData) {

  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  exports['default'] = _emberData['default'].Model.extend({
    text: attr(),
    exploreeGroupShareToken: attr('string'),
    createdAt: attr('date'),

    exploree: belongsTo('exploree'),
    exploreVoter: belongsTo('exploreVoter'),
    firm: belongsTo('firm')
  });
});