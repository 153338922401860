define('laterally/components/count-selected-entries/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['count-by'],
    classNameBindings: ['count:is-non-zero:is-zero'],

    // attrs
    content: [],
    value: null,

    count: (function () {
      var _this = this;

      var inThisCategory = this.get('content').filter(function (practiceArea) {
        var inAsParent = practiceArea.get('practiceAreaEntries').findBy('practiceAreaCategory', _this.get('value'));
        var inAsChild = practiceArea.get('parent') && practiceArea.get('parent.practiceAreaEntries').findBy('practiceAreaCategory', _this.get('value'));
        return inAsParent || inAsChild;
      });
      return inThisCategory.length;
    }).property('content.[]', 'value')

  });
});