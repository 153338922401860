define('laterally/components/firm-jobs-explanation/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({

    openIntercom: 'openIntercom',

    actions: {
      openIntercom: function openIntercom() {
        this.sendAction('openIntercom');
      }
    }
  });
});