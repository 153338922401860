define('laterally/routes/connections', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Route.extend({
    activate: function activate() {
      set(this.controllerFor('authenticated'), 'preventLegacyClaimModal', true);
    },

    deactivate: function deactivate() {
      set(this.controllerFor('authenticated'), 'preventLegacyClaimModal', false);
    },

    beforeModel: function beforeModel(transition) {
      if (!get(this.session, 'user.userable.isLawyer')) {
        transition.abort();
      }
    }
  });
});