define('laterally/controllers/explorees/result/actions', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  function ensureHasAccess(fn) {
    return function ensuringHasAccess() {
      if (this.get('hasAccess')) {
        return fn.call(this);
      }
      this.send('showTermsOfService');
    };
  }

  exports['default'] = _ember['default'].Controller.extend({

    needs: ['explorees', 'explorees/campaign', 'explorees/message', 'explorees/campaign'],
    exploreeGroup: alias('controllers.explorees/campaign.group'),

    messenger: _ember['default'].inject.service(),
    firmUser: alias('session.userable'),
    exploree: alias('model'),
    hasAccess: alias('firmUser.states.AGREED'),

    state: alias('controllers.explorees/campaign.state'),
    group: alias('controllers.explorees/campaign.group'),

    // We can do this because the only one of the exploree's conversations that
    // should be loaded should be a conversation with the currentUser.
    conversation: (function () {
      return this.get('exploreeGroup.conversations').findProperty('lawyers.firstObject.id', this.get('model.lawyerId'));
    }).property('exploreeGroup.conversations.@each', 'model.lawyerId'),

    // Only show undo buttons when there is a membeship and we didn't just hide
    // the exploree.
    showUndoButtons: (function () {
      return this.get('membership');
    }).property('membership'),

    membership: (function () {
      var group = this.get('group');
      if (group) {
        return this.get('model.memberships').find(function (membership) {
          // Must filter out deleted memberships because they can float around
          // in the association for some reason.
          return !membership.get('isDeleted') && membership.get('exploreeGroup') === group;
        });
      }
    }).property('model.memberships.[]', 'group'),

    defaultMessage: function defaultMessage() {
      var messageController = this.get('controllers.explorees/message');
      return this.get('group.message') || messageController.get('defaultMessage');
    },

    createMembership: function createMembership(options) {
      var _this = this;

      var group = options.group || this.get('group');
      var state = options.state;
      if (group) {
        var membership = this.get('membership');

        if (membership) {
          membership.set('state', state);
          membership.set('exploreeGroup', group);
          membership.save().then(function () {
            _this.get('group').reload();
          });
        } else {
          return this.get('model.memberships').createRecord({
            state: state, exploreeGroup: group
          }).save().then(function () {
            _this.get('group').reload();
          });;
        }
      }
    },

    actions: {

      selectGroup: function selectGroup(group) {
        this.createMembership({ group: group });
        return true;
      },

      save: ensureHasAccess(function () {
        if (this.createMembership({ state: 'saved' })) {
          this.send('track', 'Saved exploree');
        } else {
          this.send('track', 'Tried to save Exploree without an ExploreeGroup selected');
        }
      }),

      hide: ensureHasAccess(function () {
        if (this.createMembership({ state: 'hidden' })) {
          this.send('track', 'Hid exploree');
        } else {
          this.send('track', 'Tried to hide Exploree without an ExploreeGroup selected');
        }
      }),

      maybe: ensureHasAccess(function () {
        if (this.createMembership({ state: 'interested' })) {
          this.send('track', 'Maybed exploree');
        } else {
          this.send('track', 'Tried to maybe Exploree without an ExploreeGroup selected');
        }
      }),

      unsave: function unsave() {
        var _this2 = this;

        this.get('membership').destroyRecord().then(function () {
          _this2.get('group').reload();
        });
        this.send('track', 'Unsaved exploree');
      },

      unhide: function unhide() {
        this.get('membership').destroyRecord();
        this.send('track', 'Unhide exploree');
      },

      unmaybe: function unmaybe() {
        this.get('membership').destroyRecord();
        this.send('track', 'Unmaybe exploree');
      }

    }

  });
});