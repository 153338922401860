define('laterally/models/concerns/dirty-trackable', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Mixin.create({
    didChange: function didChange(keys) {
      var model = this;

      var changedAttributes = this.changedAttributes();
      var changedAttribute;
      var changedRelation;

      changedAttribute = keys.find(function (key) {
        return !!changedAttributes[key];
      });

      this.eachRelationship(function (name, relationship) {
        var key = relationship.key;
        var relation;

        if (keys.indexOf(relationship.key) !== -1) {
          relation = get(model, key);

          if (relationship.kind === 'belongsTo') {
            // TODO
          } else if (relationship.kind === 'hasMany') {
              if (relation.anyBy('isDirty') || get(relation, 'canonicalState').any(function (canonicalRecord) {
                return get(canonicalRecord, 'isDeleted') && get(canonicalRecord, 'isDirty');
              })) {
                changedRelation = relation;
              }
            }
        }
      });

      return changedAttribute || changedRelation;
    }
  });
});