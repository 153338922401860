define('laterally/models/invite-token', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    token: _emberData['default'].attr('string'),

    firmUser: _emberData['default'].belongsTo('firmUser'),
    locations: _emberData['default'].hasMany('location'),

    used: _emberData['default'].attr('boolean')

  });
});