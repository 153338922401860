define('laterally/routes/concerns/modal', ['exports', 'ember'], function (exports, _ember) {

  var on = _ember['default'].on;

  exports['default'] = _ember['default'].Mixin.create({

    renderTemplate: function renderTemplate() {
      this.render({
        outlet: 'modal',
        into: 'application'
      });
    },

    disconnectModal: on('deactivate', function () {
      this.disconnectOutlet({
        outlet: 'modal',
        parentView: 'application'
      });
    })

  });
});