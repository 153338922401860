define('laterally/components/donut-chart/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var c3 = window.c3;
  exports['default'] = Component.extend({
    didInsertElement: function didInsertElement() {
      var options = {
        bindto: '#' + get(this, 'elementId'),

        data: {
          columns: get(this, 'columns'),
          type: 'donut',
          colors: get(this, 'colors')
        },

        donut: {
          title: get(this, 'title'),
          label: {
            format: function format(value, ratio, id) {
              return d3.format('.0%')(ratio);
            }
          }
        },

        transition: {
          duration: 300
        }
      };
      if (get(this, 'width')) {
        options['donut']['width'] = get(this, 'width');
      }
      c3.generate(options);
    }
  });
});