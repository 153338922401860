define('laterally/controllers/firms', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;
  var equal = _ember['default'].computed.equal;
  var not = _ember['default'].computed.not;

  exports['default'] = _ember['default'].Controller.extend({

    needs: ['application'],

    userable: alias('session.userable'),

    selectedCount: alias('userable.jobSelections.length'),

    onSelected: equal('controllers.application.currentPath', 'authenticated.firms.selected'),

    showDirections: not('userable.steps.PENDING')

  });
});