define('laterally/utils/computed/stored-property', ['exports', 'ember'], function (exports, _ember) {

  var computed = _ember['default'].computed;
  var localStore = window.store;

  exports['default'] = function (key) {
    return computed(key, function (_, value) {
      if (arguments.length > 1) {
        if (value) {
          if (localStore.enabled) {
            localStore.set(key, value);
          } else {
            Cookies.set(key, value);
          }
        } else {
          if (localStore.enabled) {
            localStore.remove(key);
          } else {
            Cookies.remove(key);
          }
        }

        return value;
      } else {
        if (localStore.enabled) {
          return localStore.get(key);
        } else {
          return Cookies.get(key);
        }
      }
    });
  };
});