define('laterally/utils/track-property', ['exports', 'ember', 'laterally/utils/debounced-observer'], function (exports, _ember, _laterallyUtilsDebouncedObserver) {
  exports['default'] = trackProperty;

  var get = _ember['default'].get;

  function trackProperty(property, event) {
    return (0, _laterallyUtilsDebouncedObserver['default'])(property, 1000, function () {
      var value = get(this, property);
      if (get(this, 'trackingEnabled') && value) {
        this.analytics.track(event, { value: value });
      }
    });
  }
});