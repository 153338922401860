define('laterally/routes/concerns/editable', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    actions: {

      willTransition: function willTransition(transition) {
        // Only worry about a transition if it dumps this route.
        if (transition.targetName.indexOf(this.routeName) !== 0) {
          // call the editablecontroller mixin function
          // only if it's not being retried
          if (this.controller.get('retrying')) {
            this.controller.safeTransitionModal(transition);
          }

          // then just ensure retrying is false for future
          // calls
          this.controller.set('retrying', false);
        }
      }

    }

  });
});