define('laterally/components/form-submit/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var on = _ember['default'].on;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'button',

    classNames: ['form-for--submit'],

    attributeBindings: ['type', 'name', 'disabled'],
    type: 'submit',
    name: 'submit',

    // attrs
    form: null,
    text: 'Submit',
    disabled: false,

    sendSubmit: on('click', function (event) {
      event.preventDefault();
      if (!get(this, 'disabled')) {
        this.get('form').send('sendSubmit');
      }
    })

  });
});