define('laterally/helpers/diff', ['exports', 'ember'], function (exports, _ember) {
  exports.diff = diff;
  var get = _ember['default'].get;
  var _ = window._;

  function diff(obj) {
    var firstArray = obj[0].toArray();
    var secondArray = obj[1].toArray();
    if (firstArray.length != secondArray.length) {
      return true;
    }
    return get(_.difference(firstArray, secondArray), 'length') > 0;
  }

  exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(diff);
});