define('laterally/components/range-slider/component', ['exports', 'ember-cli-nouislider/components/range-slider', 'ember'], function (exports, _emberCliNouisliderComponentsRangeSlider, _ember) {
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var get = _ember['default'].get;
  exports['default'] = _emberCliNouisliderComponentsRangeSlider['default'].extend({
    classNames: ['noUi-extended'],

    scheduleOnInit: on('didInsertElement', function () {
      var _this = this;

      run.next('afterRender', function () {
        _this.sendAction('on-init', get(_this, 'slider').get());
      });
    })
  });
});