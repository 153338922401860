define('laterally/utils/computed/not-with-set', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = notWithSet;

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;

  function notWithSet(key) {
    return computed(key, function (_, value) {
      if (arguments.length === 2) {
        set(this, key, !value);
      }
      return !get(this, key);
    });
  }
});