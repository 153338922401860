define('laterally/routes/fee-agreement', ['exports', 'ember', 'laterally/routes/concerns/require-userable'], function (exports, _ember, _laterallyRoutesConcernsRequireUserable) {
  exports['default'] = _ember['default'].Route.extend(_laterallyRoutesConcernsRequireUserable['default'], {

    onMissingUser: function onMissingUser() {
      this.transitionTo("page", "terms-of-service");
    },

    model: function model() {
      var query = "fee-agreement";

      return this.store.find("page", query).then(function (page) {
        return page;
      }, function () {
        return { path: window.location.pathname };
      });
    },

    afterModel: function afterModel(model) {
      if (model.path) {
        this.transitionTo('landing');
      }
    }
  });
});