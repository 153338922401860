define('laterally/routes/connection', ['exports', 'ember', 'laterally/utils/deferred-receive-action', 'laterally/utils/deferred-send-action'], function (exports, _ember, _laterallyUtilsDeferredReceiveAction, _laterallyUtilsDeferredSendAction) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var RSVP = _ember['default'].RSVP;
  var moment = window.moment;
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('connection', params.id).then(function (connection) {
        return get(connection, 'job').then(function () {
          return connection;
        });
      });
    },

    afterModel: function afterModel(model) {
      if (!get(model, 'seenByLawyer')) {
        set(model, 'seenByLawyer', true);
        model.save();
      }

      if (get(model, 'conversation')) {
        get(model, 'conversation').then(function (conversation) {
          conversation.readLatest();
        });
      }
    },

    actions: {
      acceptConnection: (0, _laterallyUtilsDeferredReceiveAction['default'])(function (connection) {
        this.analytics.track('Connections: Clicked [Get Intro]');
        if (get(connection, 'acceptedByLawyer')) {
          return this.transitionTo('conversations.show', get(connection, 'conversation'));
        } else {
          return (0, _laterallyUtilsDeferredSendAction['default'])(this, 'claimUser', { connection: connection }).then(function (didClaim) {
            set(connection, 'acceptedByLawyer', true);

            return connection.save().then(function (connection) {
              return RSVP.resolve(didClaim, connection);
            });
          });
        }
      }),

      respondToConnection: (0, _laterallyUtilsDeferredReceiveAction['default'])(function (connection) {
        var _this = this;

        this.analytics.track('Connections: Clicked [Add a Message]');
        return (0, _laterallyUtilsDeferredSendAction['default'])(this, 'claimUser', { connection: connection }).then(function () {
          return _this.transitionTo('conversations.show', get(connection, 'conversation'));
        });
      }),

      requestInfo: function requestInfo(connection) {
        set(connection, 'requestedHelpAt', moment());
        connection.save();

        this.send('openIntercom');
      }
    }
  });
});