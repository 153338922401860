define('laterally/components/modal-dialog', ['exports', 'ember'], function (exports, _ember) {

  var $ = _ember['default'].$;
  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Component.extend({
    modalOpened: null,
    modalClosed: null,

    classNames: ['modal'],
    classNameBindings: ['fade'],

    // attrs
    closeable: true,
    size: 'md',
    fade: true,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var closeable = this.get('closeable');
      var modal = this.$().modal({
        backdrop: closeable ? true : 'static',
        keyboard: closeable,
        show: true
      });
      this.sendAction('modalOpened');
      modal.on('hidden.bs.modal', function () {
        _this.sendAction('modalClosed');
      });
    },

    willDestroyElement: function willDestroyElement() {
      $('body').removeClass('modal-open').find('.modal-backdrop').remove();
    },

    close: function close() {
      this.$().modal('hide');
    },

    actions: {

      close: function close() {
        this.close();
      },

      sendActionAndClose: function sendActionAndClose(action) {
        this.sendAction(action);
        this.close();
      }

    }

  });
});