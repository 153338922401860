define('laterally/components/animate-number/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var on = _ember['default'].on;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Component.extend({

    tagName: '',

    // attrs
    to: null,
    duration: 1000,

    number: null,
    start: null,

    animate: on('didInsertElement', function () {
      set(this, 'start', Date.now());
      this.update();
    }),

    update: function update() {
      var since = Date.now() - get(this, 'start');
      var delta = Math.min(1, since / get(this, 'duration'));
      set(this, 'number', Math.round(get(this, 'to') * delta));
      if (delta < 1) {
        run.later(this, 'update', 20);
      }
    }

  });
});