define('laterally/helpers/format-duration', ['exports', 'ember'], function (exports, _ember) {

  function formatDuration(start, end, options) {
    var format = options && options.hash && options.hash.format;
    start = window.moment(start).format(format);
    end = end ? window.moment(end).format(format) : 'present';
    return '%@-%@'.fmt(start, end);
  }

  exports.formatDuration = formatDuration;
  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(formatDuration);
});