define('laterally/routes/students', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel(transition) {
      this.controllerFor('landing').set('forStudents', true);
      this.controllerFor('onboarding').set('toStudents', true);

      transition.abort();
      this.transitionTo('landing-move-tracker');
    }
  });
});