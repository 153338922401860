define('laterally/helpers/moment-age', ['exports', 'ember'], function (exports, _ember) {

  // Returns string represnetation of a moment's age
  // ** in weeks or months **

  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(function (m) {
    var mom;
    var now = window.moment();
    var weeksDiff;

    if (m) {
      mom = window.moment(m).clone();
      weeksDiff = now.diff(mom, 'weeks');
      return [weeksDiff, weeksDiff === 1 ? 'week' : 'weeks'].join(' ');
    } else {
      return '';
    }
  });
});