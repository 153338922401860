define('laterally/components/server-error/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',

    error: (function () {
      var errors = get(this, 'model.serverErrors');
      var key = get(this, 'key');
      if (errors) {
        if (errors[key]) {
          return errors[key][0];
        }
      }
    }).property('model.serverErrors.[]')

  });
});