define('laterally/components/concerns/editable', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    classNames: ['editable'],
    classNameBindings: ['editable:active', 'valueEmpty:empty:non-empty', 'editing', 'isPulsing:pulse'],
    attributeBindings: ['data-property'],

    editable: true,
    editing: false,
    error: false,
    isPulsing: false,
    toggleEditModeOnStart: false,
    highlightField: false,

    valueEmpty: _ember['default'].computed.empty('value'),

    isVisible: (function () {
      return !!(this.get('value') || this.get('editable'));
    }).property('value', 'editable'),

    isPulsingDidChange: (function () {
      if (this.get('isPulsing')) {
        if (this.focusInput) {
          this.focusInput();
        }
      }
    }).observes('isPulsing'),

    setEditing: (function (event) {
      this.set('editing', event.type === 'focusin');
    }).on('focusIn', 'focusOut'),

    editingEvent: (function () {
      if (this.get('editing')) {
        if (this.get('toggleEditModeOnStart')) {
          this.set('editMode', true);
        }
      }
    }).observes('editing'),

    highlight: (function () {
      this.set('highlightField', true);
    }).on('mouseEnter'),

    unHighlight: (function () {
      this.set('highlightField', false);
    }).on('mouseLeave'),

    focusOnParentClick: function focusOnParentClick() {
      this.$().parent().click(this, function (e) {
        e.data.$('.input').focus();
      });
    }

  });
});