define('laterally/mixins/token-manageable', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Mixin.create({
    token: null,
    _tokenValue: null,

    _tokenDidChange: observer('token', function () {
      var token = get(this, 'token');

      if (token) {
        set(this, '_tokenValue', get(token, 'value'));
      } else {
        set(this, '_tokenValue', null);
      }
    }),

    _start: on('init', function () {
      var store = get(this, 'store');
      var tokenValue = get(this, '_tokenValue');
      var token;

      var typeKey = get(this, 'typeKey');
      var payload = {};
      payload[typeKey.underscore()] = tokenValue;

      if (tokenValue) {
        // store.push is acting funny (?) and this way
        // we can use the serializer to decode the JWT
        store.pushPayload(typeKey, payload);
        token = store.all(typeKey).findProperty('value', tokenValue);

        if (token.get('expired')) {
          store.unloadRecord(token);
          set(this, '_tokenValue', null);
        } else {
          set(this, 'token', token);
        }
      }
    })

  });
});