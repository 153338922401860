define('laterally/mixins/timeoutable', ['exports', 'ember'], function (exports, _ember) {
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var RSVP = _ember['default'].RSVP;

  var RouteMixin = _ember['default'].Mixin.create({
    session: inject.service(),

    _previousTransition: null,
    _previousURL: null,

    _currentPath: computed(function () {
      return this.controllerFor('application').get('currentPath');
    }).volatile(),

    _currentURL: computed(function () {
      return get(this.router, 'url');
    }).volatile(),

    activate: function activate() {
      var _this = this;

      RSVP.on('error', function (reason, label) {
        _this._handleRejection(reason, null, get(_this, '_currentURL'));
      });
    },

    _shouldReauthenticate: function _shouldReauthenticate(reason) {
      return reason.status === 401;
    },

    _handleRejection: function _handleRejection(reason) {
      var _this2 = this;

      var transition = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
      var url = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

      if (this._shouldReauthenticate(reason)) {
        var target = transition || this;

        set(this, '_previousTransition', transition);
        set(this, '_previousURL', url);

        target.send('logOut', function () {
          _this2.send('afterSessionExpiry');
        });

        return true;
      } else {
        return false;
      }
    },

    actions: {
      error: function error(reason, transition) {
        this._handleRejection(reason, transition);
      },

      willTransition: function willTransition(transition) {
        var currentPath = get(this, '_currentPath');
        var previousTransition = get(this, '_previousTransition');
        var previousURL = get(this, '_previousURL');

        if (currentPath === 'login' && (previousTransition || previousURL)) {
          transition.abort();

          if (previousTransition) {
            set(this, '_previousTransition', null);
            previousTransition.retry();
          } else {
            set(this, '_previousURL', null);
            this.transitionTo(previousURL);
          }

          return true;
        } else {
          return this._super(transition);
        }
      },

      afterSessionExpiry: function afterSessionExpiry() {
        return false;
      }
    }
  });

  exports.RouteMixin = RouteMixin;
});