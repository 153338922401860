define('laterally/components/hide-on-blur', ['exports', 'ember', 'laterally/utils/computed/not-with-set'], function (exports, _ember, _laterallyUtilsComputedNotWithSet) {

  var observer = _ember['default'].observer;

  exports['default'] = _ember['default'].Component.extend({

    // attrs
    show: (0, _laterallyUtilsComputedNotWithSet['default'])('hide'),
    hide: false,

    actionSender: observer('show', function () {
      if (this.get('show')) {
        this.sendAction('on-show');
      } else {
        this.sendAction('on-hide');
      }
    })

  });
});