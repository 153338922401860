define("laterally/utils/intersection", ["exports"], function (exports) {
  // http://stackoverflow.com/questions/1885557/simplest-code-for-array-intersection-in-javascript

  /* finds the intersection of 
   * two arrays in a simple fashion.  
   *
   * PARAMS
   *  a - first array, must already be sorted
   *  b - second array, must already be sorted
   *
   * NOTES
   *
   *  Should have O(n) operations, where n is 
   *    n = MIN(a.length(), b.length())
   */
  var intersection = function intersection(a, b) {
    var ai = 0,
        bi = 0;
    var result = [];

    while (ai < a.length && bi < b.length) {
      if (a[ai] < b[bi]) {
        ai++;
      } else if (a[ai] > b[bi]) {
        bi++;
      } else /* they're equal */
        {
          result.push(a[ai]);
          ai++;
          bi++;
        }
    }

    return result;
  };

  exports["default"] = intersection;
});