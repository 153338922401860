define('laterally/transforms/array', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(value) {
      return value;
    },

    serialize: function serialize(value) {
      return value;
    }

  });
});