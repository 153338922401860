define('laterally/routes/concerns/require-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      var sessionCtrl = this.session;
      var user = sessionCtrl.get('user');
      if (user) {
        this.trigger('requiredUser', user, transition);
      } else {
        this.trigger('missingUser', null, transition);
        if (this.onMissingUser) {
          this.onMissingUser();
        } else {
          sessionCtrl.set('nextTransition', transition);
          this.transitionTo('login');
        }
      }
    }

  });
});