define('laterally/models/bar-admission', ['exports', 'ember', 'ember-data', 'laterally/utils/computed/not-any', 'laterally/utils/computed/year'], function (exports, _ember, _emberData, _laterallyUtilsComputedNotAny, _laterallyUtilsComputedYear) {
  exports['default'] = _emberData['default'].Model.extend({

    state: _emberData['default'].attr('string'),
    number: _emberData['default'].attr('string'),
    year: _emberData['default'].attr('date'),
    admittee: _emberData['default'].belongsTo('admittee', { polymorphic: true }),

    yearOnly: (0, _laterallyUtilsComputedYear['default'])('year'),

    isBlank: (0, _laterallyUtilsComputedNotAny['default'])('state', 'number', 'year'),
    notBlank: _ember['default'].computed.not('isBlank')

  });
});