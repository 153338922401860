define('laterally/services/clock', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    every: 1000,
    until: -1,

    pulse: _ember['default'].computed.oneWay('_count').readOnly(),

    tick: (function () {
      var count = this.get('_count');
      var every = this.get('every');
      var until = this.get('until');

      var timer = _ember['default'].run.later(this, function () {
        if (until === -1 || count < until) {
          this.incrementProperty('_count', every);
          this.tick();
        }
      }, every);

      this.set('timer', timer);
    }).on('init'),

    willDestroy: function willDestroy() {
      _ember['default'].run.cancel(this.get('timer'));
    },

    _count: 0
  });
});