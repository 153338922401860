define('laterally/routes/candidates', ['exports', 'ember', 'laterally/routes/concerns/modal-on-step'], function (exports, _ember, _laterallyRoutesConcernsModalOnStep) {
  exports['default'] = _ember['default'].Route.extend(_laterallyRoutesConcernsModalOnStep['default'], {

    model: function model() {
      // Call findQuery, otherwise all the firmLocations in memory get shown,
      // which may not be firmLocations that belong to the current user.
      return this.store.findQuery('jobSelection', {}).then(function (jobSelections) {
        return jobSelections.mapBy('firmLocationJob.firmLocation').uniq().sortBy('location.name');
      });
    },

    afterModel: (function (model, transition) {
      this.showModal(model, transition, {
        modal: "application/termsOfServiceModal",
        state: "APPROVED"
      });
      this.showModal(model, transition, {
        modal: "application/firstTimeModal",
        state: "START"
      });
      this._super();
    }).track("Candidates")

  });
});