define('laterally/controllers/application/practice-areas', ['exports', 'ember', 'laterally/controllers/lawyer/index/selections'], function (exports, _ember, _laterallyControllersLawyerIndexSelections) {

  var get = _ember['default'].get;
  var alias = _ember['default'].computed.alias;
  var equal = _ember['default'].computed.equal;

  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Controller.extend(_laterallyControllersLawyerIndexSelections['default'], {

    selections: [],
    practiceAreaCategories: [],
    onSave: null,
    modalTitle: 'Select Practice Areas',
    match: null,
    max: null,
    reload: false,

    matchAll: equal('match', 'all'),

    actions: {
      saveAndReload: function saveAndReload() {
        var _this = this;

        var def = _ember['default'].RSVP.defer();

        this.onSave(get(this, 'newSelections'), this.get('match'), def);

        def.promise.then(function () {
          var reload = _this.get('reload');
          if (reload) {
            _this.send('reload');
          }
        });
        this.send('close');
      },

      setMatch: function setMatch(match) {
        this.set('match', match);
      }
    }
  });
});