define('laterally/services/referral-manager', ['exports', 'ember', 'laterally/mixins/token-manageable', 'laterally/utils/computed/stored-property'], function (exports, _ember, _laterallyMixinsTokenManageable, _laterallyUtilsComputedStoredProperty) {
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Object.extend(_laterallyMixinsTokenManageable['default'], {
    typeKey: 'referralToken',
    _tokenValue: (0, _laterallyUtilsComputedStoredProperty['default'])('referralToken')
  }, {
    referredBy: function referredBy(token) {
      set(this, 'token', token);
    }
  });
});