define('laterally/components/promising-button/component', ['exports', 'ember', 'laterally/utils/deferred-send-action'], function (exports, _ember, _laterallyUtilsDeferredSendAction) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    tagName: 'button',
    attributeBindings: ['disabled', 'title', 'data-toggle', 'data-placement'],

    spinWhileFulfilling: true,

    action: null,
    actionParams: null,

    isFulfilling: false,

    click: function click(e) {
      var _this = this;

      e.preventDefault();

      var args = [this, 'action'].concat(get(this, 'actionParams'));
      var ret = _laterallyUtilsDeferredSendAction['default'].apply(this, args);

      if (ret && ret.then) {
        set(this, 'isFulfilling', true);

        ret['finally'](function () {
          if (!get(_this, 'isDestroying')) {
            set(_this, 'isFulfilling', false);
          }
        });
      }
    }
  });
});