define('laterally/controllers/explorees/campaign', ['exports', 'ember', 'laterally/controllers/concerns/paged', 'laterally/controllers/concerns/ordered', 'laterally/utils/computed/store-find', 'laterally/models/exploree-group-membership'], function (exports, _ember, _laterallyControllersConcernsPaged, _laterallyControllersConcernsOrdered, _laterallyUtilsComputedStoreFind, _laterallyModelsExploreeGroupMembership) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var equal = computed.equal;
  var alias = computed.alias;
  var _ = window._;

  var filters = {
    filterSetId: null,
    state: 'all',
    keywords: '',
    orderDirection: 'asc',
    orderColumn: 'firms.name',
    practiceAreas: [],
    practiceAreasMatch: 'any',
    locations: [],
    positions: [],
    firms: [],
    schools: [],
    schoolRanks: [],
    firmRanks: [],
    lastMoveYear: null,
    lastMoveUnknown: false,
    hasClerkships: false,
    hasBio: false,
    graduationYearMin: null,
    graduationYearMax: null
  };

  exports['default'] = _ember['default'].ArrayController.extend(_laterallyControllersConcernsPaged['default'], _laterallyControllersConcernsOrdered['default'], _laterallyModelsExploreeGroupMembership.stateProperties, _ember['default'].$.extend(true, {}, filters), {
    defaultFilters: filters,

    itemController: 'explorees/exploree',

    firmUser: alias('session.userable'),

    showSaveAll: computed('state', function () {
      return get(this, 'state') === 'all' || get(this, 'state') === '';
    }),
    displayLastMove: false,

    queryParams: [{
      filterSetId: 'filters',
      state: 'state'
    }],

    filters: ['practiceAreas', 'practiceAreasMatch', 'locations', 'positions', 'firms', 'schools', 'schoolRanks', 'firmRanks', 'hasClerkships', 'hasBio', 'graduationYearMin', 'graduationYearMax', 'lastMoveYear', 'lastMoveUnknown', 'keywords'],

    practiceAreaCategories: (0, _laterallyUtilsComputedStoreFind['default'])('practiceAreaCategory', {
      with_explorees: true
    }),
    locationOptions: (0, _laterallyUtilsComputedStoreFind['default'])('location', {
      with_explorees: true
    }),
    firmOptions: (0, _laterallyUtilsComputedStoreFind['default'])('firm', {
      explore_filter: true,
      without_current_user_firm: true
    }),
    schoolOptions: (0, _laterallyUtilsComputedStoreFind['default'])('school', {
      explore: true
    }),
    positionOptions: [{ label: 'Associate', value: 'associate' }, { label: 'Counsel', value: 'counsel' }, { label: 'Partner', value: 'partner' }, { label: 'Unsure', value: 'indefinite' }],

    countedFirmOptions: (function () {
      return get(this, 'firmOptions').map(function (firm) {
        return {
          id: get(firm, 'id'),
          name: get(firm, 'name'),
          count: null,
          label: get(firm, 'name'),
          zeroed: false
        };
      });
    }).property(),

    sortedCountedFirmOptions: computed('countedFirmOptions.@each.count', function () {
      var chunks = _.groupBy(get(this, 'countedFirmOptions'), function (option) {
        return get(option, 'count') > 0;
      });
      return chunks[true].concat(chunks[false]);
    }),

    foo: (function () {
      if (!this.get('_entering')) {
        set(this, 'firms', get(this, 'firmSelections').compact().mapBy('name'));
      }
    }).observes('firmSelections.@each'),

    asdasdafdasfdsfwfsdfbdjkdsd: (function () {
      var counts = get(this, 'model.meta.firm_counts');

      get(this, 'countedFirmOptions').forEach(function (option) {
        var count = counts[get(option, 'id')] || 0;

        if (count) {
          set(option, 'label', get(option, 'name') + ' (' + count + ')');
        } else {
          set(option, 'label', get(option, 'name'));
        }

        set(option, 'count', count);
        set(option, 'zeroed', count === 0);
      });

      this.notifyPropertyChange('countedFirmOptions.[]');
    }).observes('model.meta'),

    schoolRankOptions: ['Top 1-3', '4-14', '15-30', 'Other'],
    firmRankOptions: ['Top 1-25', '26-50', '51-100', 'Notable', 'Other'],

    graduationYearEnd: (function () {
      return new Date().getFullYear() - 1;
    }).property(),
    graduationYearStart: (function () {
      return this.get('graduationYearEnd') - 50;
    }).property(),

    keywordsQuery: '',
    keywordsQuerySetter: (function () {
      this.set('keywordsQuery', this.get('keywords'));
    }).observes('keywords'),

    useFilters: function useFilters(filters) {
      var _this = this;

      this.set('_entering', true);
      this.setProperties(filters || {});
      this.set('firmSelections', get(this, 'firms').map(function (firmName) {
        return get(_this, 'countedFirmOptions').findBy('name', firmName);
      }));
      this.set('_entering', false);
    },

    // HACK: not sure of good way to tell when
    // we're done the transition into this controller
    filterDidChange: (function () {
      if (!this.get('_entering')) {
        this.send('filterDidChange');
      }
    }).observes('keywords', 'practiceAreas.[]', 'practiceAreasMatch', 'locations.[]', 'positions.[]', 'graduationYearMin', 'graduationYearMax', 'lastMoveYear', 'lastMoveUnknown', 'firms.[]', 'firmRanks.[]', 'schools.[]', 'schoolRanks.[]', 'hasClerkships', 'hasBio'),

    filterSetDidChange: (function () {
      set(this, 'filterSetId', get(this, 'filterSet.id'));
    }).observes('filterSet'),

    // This is automatically set in the route.
    group: null,

    matchAll: equal('practiceAreasMatch', 'all'),

    keywordsDebouncer: (function () {
      _ember['default'].run.debounce(this, 'updateKeywords', 500);
    }).observes('keywordsQuery'),

    updateKeywords: function updateKeywords() {
      this.set('keywords', this.get('keywordsQuery'));
    },

    trackFilters: (function () {
      var filters = this.getProperties(this.get('filters'));
      this.analytics.track('Viewed Explore Candidates', filters);
    }).observes('model.isLoaded'),

    scrollToTopObserver: (function () {
      if (!this.get('loading')) {
        _ember['default'].run.schedule('afterRender', function () {
          var results = _ember['default'].$('.explore-results');

          if (results.length) {
            var offset = results.offset().top;
            if (offset < _ember['default'].$(window).scrollTop()) {
              _ember['default'].$('html, body').animate({
                scrollTop: offset
              }, 500);
            }
          }
        });
      }
    }).observes('loading'),

    resetLastMove: (function () {
      if (!this.get('lastMoveYear')) {
        this.set('lastMoveUnknown', false);
        this.set('displayLastMove', false);
      }
    }).observes('lastMoveYear').on('init'),

    practiceAreaObjects: (function () {
      var _this2 = this;

      return this.get('practiceAreaCategories').then(function (categories) {
        var allPracticeAreas = [];
        categories.forEach(function (category) {
          var parentAreas = category.get('practiceAreaEntries').filterBy('type', null).mapBy('practiceArea');
          parentAreas = parentAreas.filter(function (n) {
            return n != null;
          });
          allPracticeAreas.pushObjects(parentAreas);

          parentAreas.forEach(function (area) {
            allPracticeAreas.pushObjects(area.get('children').toArray());
          });
        });
        return _this2.get("practiceAreas").map(function (area) {
          var match = allPracticeAreas.findBy('name', area);
          return match;
        });
      });
    }).property('practiceAreas.[]', 'practiceAreaCategories'),

    prevQueryParams: {},

    actions: {

      createFilterSet: function createFilterSet(name) {
        var _this3 = this;

        var filterSet = get(this, 'group.filterSets').createRecord({
          name: name || 'Saved filters #' + (get(this, 'group.filterSets.length') + 1),
          filters: getProperties(this, get(this, 'filters'))
        });

        filterSet.save().then(function (filterSet) {
          set(_this3, 'filterSet', filterSet);
        });
      },

      deleteFilterSet: function deleteFilterSet(filterSet) {
        filterSet.deleteRecord();
        filterSet.save();

        if (get(this, 'filterSet') === filterSet) {
          set(this, 'filterSet', null);
        }
      },

      toggleHasClerkships: function toggleHasClerkships() {
        this.toggleProperty('hasClerkships');
      },

      toggleHasBio: function toggleHasBio() {
        this.toggleProperty('hasBio');
      },

      clearKeywordsQuery: function clearKeywordsQuery() {
        this.set('keywordsQuery', '');
        this.send('focusKeywordSearch');
      },

      focusKeywordSearch: function focusKeywordSearch() {
        _ember['default'].$('.explore-sidebar-filter--search input').focus();
      },

      drawerOpened: function drawerOpened(component /*, animation, progress, remaining */) {
        var element = component.$().parent();
        var offset = element.offset().top;
        var scroll = _ember['default'].$(window).scrollTop();
        if (scroll > 100 && scroll > offset) {
          _ember['default'].$(window).scrollTop(offset);
        }
      },

      removeFilter: function removeFilter(filter, item) {
        this.get(filter).removeObject(item);
      },

      clearLastMoveYear: function clearLastMoveYear() {
        this.set('lastMoveYear', null);
      },

      clearLastMoveUnknown: function clearLastMoveUnknown() {
        this.set('lastMoveUnknown', false);
      },

      toggleDisplayLastMove: function toggleDisplayLastMove() {
        this.toggleProperty('displayLastMove');
      },

      editPracticeAreas: function editPracticeAreas() {
        var _this4 = this;

        this.get('practiceAreaObjects').then(function (pao) {
          _this4.send('openModal', 'application/practice-areas', {
            modalTitle: '',
            practiceAreaCategories: _this4.get('practiceAreaCategories'),
            selections: pao,
            match: _this4.get('practiceAreasMatch'),
            onSave: (function (selected, match, def) {
              this.set('practiceAreasMatch', match);
              var newSelectionNames = selected.mapBy('name');
              this.set('practiceAreas', newSelectionNames);
            }).bind(_this4)
          });
        });
      },

      editFirms: function editFirms() {
        this.send('openModal', 'selectable-firms', {
          model: this.get('firmOptions'),
          selections: this.get('firms').slice(),
          onSave: (function (selected) {
            this.set('firms', selected);
          }).bind(this)
        });
      },

      editSchools: function editSchools() {
        this.send('openModal', 'selectable-schools', {
          model: this.get('schoolOptions'),
          selections: this.get('schools').slice(),
          onSave: (function (selected) {
            this.set('schools', selected);
          }).bind(this)
        });
      },

      showJob: function showJob(exploreeGroup) {
        this.transitionToRoute('explore-jobs.edit', exploreeGroup.get('id'));
      },

      next: function next() {
        this.analytics.track('Clicked Next on Explore Step 1');
        this.send('showJob', this.get('group'));
      },

      goToConnect: function goToConnect() {
        this.transitionToRoute('explorees.connect', this.get('group.id'));
      },

      renameFilterSet: function renameFilterSet(filterSet) {
        filterSet.save();
      }

    }

  });
});