define('laterally/controllers/candidates/firm-location-job', ['exports', 'ember', 'laterally/utils/computed/array-controller'], function (exports, _ember, _laterallyUtilsComputedArrayController) {
  exports['default'] = _ember['default'].Controller.extend({

    visible: (function () {
      return this.get('jobSelections').anyBy('visible');
    }).property('jobSelections.@each.visible'),

    jobSelections: (0, _laterallyUtilsComputedArrayController['default'])('model.jobSelections', 'candidates/job_selection')

  });
});