define('laterally/initializers/select-collections', ['exports', 'ember', 'laterally/utils/collections'], function (exports, _ember, _laterallyUtilsCollections) {

		var get = _ember['default'].get;
		var computed = _ember['default'].computed;

		exports['default'] = {
				name: 'select-collections',
				initialize: function initialize() {
						_ember['default'].Select.reopen({
								collection: null,
								content: computed('collection', function () {
										return _laterallyUtilsCollections[get(this, 'collection')] || [];
								})
						});
				}
		};
});