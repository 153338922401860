define('laterally/controllers/explorees/message', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    defaultMessage: "Hey {{first_name}},\n\nI'd love to connect with you on Lateral.ly.",

    currentMessage: (function () {
      return this.get('model.message') || this.get('defaultMessage');
    }).property('model.message'),

    actions: {
      saveMessage: function saveMessage(group, message, defer) {
        group.set('message', message).save().then(defer.resolve)['catch'](defer.reject);
      }
    }
  });
});