define('laterally/controllers/lawyer/attachment', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var alias = _ember['default'].computed.alias;
  var titleize = _ember['default'].computed.titleize;

  exports['default'] = _ember['default'].Mixin.create({
    needs: ['lawyer'],
    showErrorsFor: alias('controllers.lawyer.showErrorsFor'),

    // attrs
    lawyer: null,
    canEdit: null,
    attachment: null,
    attachmentName: null,

    title: titleize('attachmentName'),

    uploadMessage: (function () {
      return 'Upload your ' + get(this, 'attachmentName').humanize();
    }).property('attachmentName'),

    actions: {

      deleteAttachment: function deleteAttachment() {
        var _this = this;

        get(this, 'lawyer').deleteAttachment(get(this, 'attachmentName'), get(this, 'session')).then(function () {
          _this.send('highlightProgressErrors');
        });
      },

      uploadedAttachment: function uploadedAttachment() {
        this.send('highlightProgressErrors');
      }

    }

  });
});