define('laterally/components/confirm-action/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['confirm-action'],

    // attrs
    action: null,

    // blockParams
    isConfirming: false,
    needsConfirm: true,
    // confirmer: this, // no need to define this

    actions: {

      confirm: function confirm() {
        if (get(this, 'needsConfirm')) {
          if (get(this, 'isConfirming')) {
            this.sendAction();
            this.send('cancel');
          } else {
            set(this, 'isConfirming', true);
          }
        } else {
          this.sendAction();
        }
      },

      cancel: function cancel() {
        set(this, 'isConfirming', false);
      }

    }

  });
});