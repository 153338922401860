define('laterally/routes/onboarding/employers', ['exports', 'ember', 'laterally/routes/concerns/attrs'], function (exports, _ember, _laterallyRoutesConcernsAttrs) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Route.extend(_laterallyRoutesConcernsAttrs['default'], {

    pageName: 'Employer Registration',

    beforeModel: function beforeModel(transition) {
      if (get(this, 'session.user.employerApplicationId')) {
        transition.send('next');
      }
    },

    attrs: function attrs() {
      var application = this.store.createRecord('employerApplication');
      return { application: application };
    },

    actions: {

      submit: function submit() {
        var _this = this;

        if (get(this, 'controller.agreed')) {
          get(this, 'controller.application').save().then(function () {
            _this.send('next');
          });
        }
      },

      next: function next() {
        this.transitionTo('applied');
      }

    }

  });
});