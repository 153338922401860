define('laterally/controllers/conversations/show/index', ['exports', 'ember', 'laterally/utils/computed/group'], function (exports, _ember, _laterallyUtilsComputedGroup) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var and = _ember['default'].computed.and;
  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({
    needs: ['conversations/show'],
    conversation: alias('controllers.conversations/show.model'),

    currentUserable: alias('session.user.userable'),

    sortedMessages: computed('model.messages.[]', function () {
      return get(this, 'model.messages').sortBy('createdAtUnix');
    }),

    // Group messages by user and 7-minute segments
    groupedMessages: (0, _laterallyUtilsComputedGroup['default'])('sortedMessages', function (groups, message) {
      var keyForItem = function keyForItem(item) {
        return [get(item, 'user.id'), get(item, 'id')].join('-');
      };

      if (groups.get('length')) {
        var lastGroup = get(groups, 'lastObject');
        var firstItem = get(lastGroup, 'firstObject');

        // If the previous message has the same user and isn't more than 7 minutes
        // older than this message, then it can be in the same group
        if (get(firstItem, 'isIntroduction') === get(message, 'isIntroduction')) {
          if (get(firstItem, 'sender') === get(message, 'sender')) {
            if (get(message, 'createdAt').diff(get(firstItem, 'createdAt'), 'minutes') < 7) {
              return keyForItem(firstItem);
            }
          }
        }
      }

      // No matches found - this item belongs in a new group
      return keyForItem(message);
    }),

    linkToLawyer: and('sender.user.isLawyer', 'session.user.isFirm'),

    sender: (function () {
      var userable = this.get('session.userable');
      if (userable.get('isExploree') && this.get('explorees.length')) {
        return userable.get('exploree');
      }
      return userable;
    }).property('session.userable.isExploree', 'explorees.length'),

    canDeleteConnection: (function () {
      var userable = get(this, 'session.userable');
      return get(userable, 'isFirm') && get(this, 'conversation.firmUsers').contains(userable);
    }).property('session.userable.isFirm', 'conversation.firmUsers.@each'),

    canDeleteAcceptance: (function () {
      var userable = get(this, 'session.userable');
      return get(userable, 'isLawyer') && get(this, 'conversation.lawyers').contains(userable);
    }).property('session.userable.isLawyer', 'conversation.lawyers.@each'),

    actions: {

      readLatest: function readLatest() {
        this.get('model').readLatest();
      },

      sendMessage: function sendMessage(body) {
        this.get('model.messages').createRecord({
          body: body,
          createdAt: window.moment(),
          sender: this.get('sender')
        }).save();
        var sender = this.get('sender');
        this.analytics.track('Sent message', {
          sender: {
            id: sender.get('id'),
            name: sender.get('name'),
            type: sender.constructor.typeKey
          },
          body: body
        });
      },

      deleteMessage: function deleteMessage(message) {
        if (window.confirm("Are you sure you want to delete this message?")) {
          var conversation = get(message, 'conversation');
          if (get(message, 'isIntroduction')) {
            this.store.find('connection', get(conversation, 'connectionId')).then(function (connection) {
              set(connection, 'introduction', null);
              connection.save();
            });
          }
          message.destroyRecord();
          this.send('messageDeletedFromConversation', conversation);
        }
      },

      deleteConnection: function deleteConnection(conversation) {
        var _this = this;

        if (window.confirm("Are you sure you want to delete this invitation? The job profile will no longer be shared with this lawyer.")) {
          this.store.find('connection', get(conversation, 'connectionId')).then(function (connection) {
            connection.destroyRecord().then(function () {
              _this.send('deleteConversationFromList', conversation);
              _this.transitionToRoute('conversations.index');
            });
          });
        }
      },

      deleteAcceptance: function deleteAcceptance(message) {
        if (window.confirm("Are you sure you want to un-accept the invitation?")) {
          message.destroyRecord();
          this.store.find('connection', get(message, 'conversation.connectionId')).then(function (connection) {
            set(connection, 'acceptedByLawyer', false);
            connection.save();
          });
        }
      }
    }

  });
});