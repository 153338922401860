define('laterally/components/editable-card-collection/component', ['exports', 'ember', 'laterally/utils/has-many-committer'], function (exports, _ember, _laterallyUtilsHasManyCommitter) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var observer = _ember['default'].observer;

  exports['default'] = _ember['default'].Component.extend(_laterallyUtilsHasManyCommitter['default'], {

    classNames: ['editable-card', 'editable-card-collection'],
    classNameBindings: ['isEditing'],

    // attrs
    records: [],

    canEdit: false,
    isSaving: false,
    isEditing: false,

    isEditingDidChange: observer('isEditing', function () {
      if (get(this, 'isEditing')) {
        this.sendAction('onEdit');
      }
    }),

    actions: {

      save: function save() {
        var _this = this;

        set(this, 'isSaving', true);
        set(this, 'isEditing', false);
        this.saveRecords()['finally'](function () {
          set(_this, 'isSaving', false);
          _this.sendAction('savedOrCanceled');
        });
      },

      cancel: function cancel() {
        this.rollbackRecords();
        set(this, 'isEditing', false);
        this.sendAction('savedOrCanceled');
      },

      edit: function edit() {
        if (get(this, 'canEdit')) {
          set(this, 'isEditing', true);
        }
      },

      add: function add() {
        if (!get(this, 'isEditing')) {
          set(this, 'isEditing', true);
        }

        var order = 0;
        if (get(this, 'records.length') > 0) {
          order = Math.max.apply(Math, _toConsumableArray(get(this, 'records').mapBy('order'))) + 1;
        }
        this.createRecord({ order: order });
      },

      remove: function remove(record) {
        this.removeRecord(record);
      }

    }

  });
});