define('laterally/controllers/application/header/conversations', ['exports', 'ember'], function (exports, _ember) {

  var computed = _ember['default'].computed;
  var alias = computed.alias;

  exports['default'] = _ember['default'].ArrayController.extend({

    sortProperties: ['messages.lastObject.createdAtUnix'],
    sortAscending: false,

    needs: ['conversations'],
    userable: alias('session.userable'),

    showConversations: computed('userable', function () {
      return this.get('userable.isAnySelecting') || this.get('userable.steps.APPROVED');
    }),

    model: alias('controllers.conversations.model'),

    // computed macros don't seem to work with itemController properties yet
    unreadConversations: (function () {
      return this.filterProperty('read', false);
    }).property('@each.read'),

    actions: {
      goToConversation: function goToConversation(conversation) {
        if (this.get('userable.isLawyer') && conversation.get('connectionId')) {
          this.transitionToRoute('connection', conversation.get('connectionId'));
        } else {
          this.transitionToRoute('conversations.show', conversation);
        }
      }
    }

  });
});