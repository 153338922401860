define('laterally/routes/concerns/paged', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    queryParams: {
      page: { refreshModel: true }
    },

    actions: {

      queryParamsDidChange: function queryParamsDidChange(changed, all, removed) {
        if (this.controller && !changed.page) {
          this.controller.set('page', 1);
        }
        return this._super(changed, all, removed);
      }

    }

  });
});