define('laterally/views/application', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].View.extend({

    classNames: ['application'],
    classNameBindings: ['routeClass'],

    routeClass: computed('controller.currentRouteName', function () {
      var parts = get(this, 'controller.currentRouteName').split('.');
      var combos = parts.reduce(function (classes, part) {
        var prev = classes.last() || [];
        classes.push(prev.concat(part));
        return classes;
      }, []);

      if (parts[0] === 'landing-move-tracker') {
        combos.push(['landing']);
      }

      return combos.map(function (combo) {
        return 'route--' + combo.join('-');
      }).join(' ');
    })
  });
});