define('laterally/transitions', ['exports'], function (exports) {
  exports['default'] = function () {

    this.transition(this.inHelper('liquid-modal'), this.toModal('user-authenticator'), this.use('fade'));

    this.transition(this.inHelper('liquid-modal'), this.fromModal('user-authenticator'), this.use('fade', { duration: 150 }));

    this.transition(this.inHelper('liquid-modal'), this.toModal('our-pledge'), this.use('explode', {
      pick: '.lf-overlay',
      use: ['cross-fade', { maxOpacity: 0.5, duration: 250 }]
    }, {
      pick: '.lm-container',
      use: ['scale', { duration: 250 }]
    }));

    this.transition(this.inHelper('liquid-modal'), this.fromModal('our-pledge'), this.use('fade', { duration: 150 }));

    this.transition(this.inHelper('liquid-modal'), this.fromModal('practice-areas-selector'), this.use('fade', { duration: 1 }));

    this.transition(this.inHelper('liquid-modal'), this.toModal('practice-areas-selector'), this.use('fade', { duration: 1 }));

    this.transition(this.fromRoute('onboarding.lawyers'), this.toRoute('onboarding.employers'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('onboarding.lawyers'), this.toRoute('onboarding.students'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('onboarding.students'), this.toRoute('onboarding.employers'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.toValue(function (to, from) {
      return to && from && to > from;
    }), this.use('toLeft'));
    this.transition(this.toValue(function (to, from) {
      return to && from && to < from;
    }), this.use('toRight'));

    this.transition(this.hasClass('change-title-success'), this.toValue(false), this.use('fade', { duration: 500 }));
  };
});