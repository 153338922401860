define('laterally/components/pop-over', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['pop-over'],

    attributeBindings: ['placement:data-placement', 'content:data-content', 'title'],

    enabled: _ember['default'].computed.not('disabled'),
    disabled: false,

    _initializedPopover: false,

    triggers: 'click',
    html: false,

    initializePopover: (function () {
      if (this.get('enabled') && !this.get('_initializedPopover')) {
        this.set('_initializedPopover', true);
        this.$().popover({
          container: 'body',
          trigger: this.get('triggers'),
          html: this.get('html')
        });
      }
    }).observes('enabled').on('didInsertElement'),

    destroyPopover: (function () {
      if (!this.get('enabled') && this.get('_initializedPopover')) {
        this.set('_initializedPopover', false);
        this.$().popover('destroy');
      }
    }).observes('enabled').on('willDestroyElement')

  });
});