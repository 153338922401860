define('laterally/views/concerns/modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    layoutName: 'concerns/modal',

    classNames: ['modal', 'fade'],

    modalSize: 'md',

    modalSizeClass: (function () {
      return 'modal-' + this.get('modalSize');
    }).property('modalSize'),

    closable: (function () {
      return this.get('controller.closable') !== false;
    }).property(),

    initializeModal: (function () {
      var closable = this.get('closable');
      var route = this.container.lookup('route:application');
      route.set('lastModalView', this);
      this.$().modal({
        backdrop: closable ? true : 'static',
        keyboard: closable,
        show: true
      }).on('hidden.bs.modal', (function () {
        if (!route.get('isDestroyed')) {
          route.set('lastModalView', null);
        }

        if (this.get('controller')) {
          this.get('controller').send('disconnectModal');
        }
      }).bind(this));
    }).on('didInsertElement'),

    actions: {

      close: function close() {
        this.$().modal('hide');
      }

    }

  });
});