define('laterally/models/school-attendance', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    graduation: _emberData['default'].attr('date'),
    degree: _emberData['default'].attr('string'),
    honors: _emberData['default'].attr('string'),
    concentration: _emberData['default'].attr('string'),
    activityA: _emberData['default'].attr('string'),
    activityB: _emberData['default'].attr('string'),
    activityC: _emberData['default'].attr('string'),

    school: _emberData['default'].belongsTo('school'),
    lawyer: _emberData['default'].belongsTo('lawyer'),

    graduationYear: (function () {
      if (this.get('graduation')) {
        return this.get('graduation').getUTCFullYear();
      }
    }).property('graduation'),

    isJD: (function () {
      return this.get('degree') === 'J.D.';
    }).property('degree')

  });
});