define('laterally/models/practice-area-entry', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    order: _emberData['default'].attr('number'),
    type: _emberData['default'].attr('string'),
    content: _emberData['default'].attr('string'),
    hideChildren: _emberData['default'].attr('boolean'),

    practiceArea: _emberData['default'].belongsTo('practiceArea'),
    practiceAreaCategory: _emberData['default'].belongsTo('practiceAreaCategory'),

    practiceAreaProxy: (function () {
      if (this.get('practiceArea')) {
        return this.get('practiceArea');
      } else {
        return {};
      }
    }).property('practiceArea'),

    isBlank: (function () {
      return this.get('type') == 'blank';
    }).property('type'),

    isText: (function () {
      return this.get('type') == 'text';
    }).property('type'),

    isNormal: (function () {
      return !this.get('isBlank') && !this.get('isText');
    }).property('isBlank', 'isText')

  });
});