define('laterally/components/form-for/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var on = _ember['default'].on;
  var observer = _ember['default'].observer;
  var equal = _ember['default'].computed.equal;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'form',

    classNames: ['form-for'],
    classNameBindings: ['model.isSaving', 'hasLabelTag', 'hasLabelPlaceholder', 'hasSubmitted'],

    attributeBindings: ['novalidate'],
    novalidate: true,

    // attrs
    model: null,
    'on-submit': 'submit',
    labels: 'tag',

    hasLabelTag: equal('labels', 'tag'),
    hasLabelPlaceholder: equal('labels', 'placeholder'),

    hasSubmitted: false,

    sendSubmit: on('submit', function (event) {
      event.preventDefault();
      this.send('sendSubmit');
    }),

    firstErrorFocuser: observer('model.errors.[]', function () {
      if (get(this, 'hasLabelPlaceholder')) {
        _ember['default'].run.scheduleOnce('afterRender', this, 'focusFirstError');
      }
    }),

    focusFirstError: function focusFirstError() {
      if (this.$('input:focus').length === 0) {
        this.$('.has-error :input').first().focus();
      }
    },

    actions: {

      sendSubmit: function sendSubmit() {
        var model = this.get('model');
        set(this, 'hasSubmitted', true);
        this.sendAction('on-submit', model);

        if (get(this, 'on-submitted-target')) {
          get(this, 'on-submitted-target').send(get(this, 'on-submitted'));
        }
      }

    }

  });
});