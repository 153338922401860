define('laterally/routes/onboarding/students', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var hash = _ember['default'].RSVP.hash;

  exports['default'] = _ember['default'].Route.extend({

    pageName: 'Law Student Registration',

    beforeModel: function beforeModel(transition) {
      if (get(this, 'session.userable')) {
        transition.send('next');
      }
    },

    model: function model() {
      return this.store.createRecord('lawyer');
    },

    afterModel: function afterModel() {
      var application = this.controllerFor('application');
      var controller = this.controllerFor('onboarding/students');

      var firms = get(application, 'firms');
      var schools = get(application, 'schools');

      hash({ firms: firms, schools: schools }).then(function (props) {
        controller.setProperties(props);
      });

      return null; // don't wait for resolution
    },

    actions: {

      next: function next() {
        this.transitionTo('move-tracker');
      }

    }

  });
});