define('laterally/controllers/conversations', ['exports', 'ember'], function (exports, _ember) {
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;

  var alias = computed.alias;

  exports['default'] = _ember['default'].ArrayController.extend({

    sortProperties: ['dateForSorting'],
    sortAscending: false,

    currentUser: alias('session.user'),
    currentUserable: alias('currentUser.userable'),

    fromCampaign: false,

    actions: {

      enableFromCampaign: function enableFromCampaign() {
        set(this, 'fromCampaign', true);
      },

      disableFromCampaign: function disableFromCampaign() {
        set(this, 'fromCampaign', false);
      }
    }

  });
});