define('laterally/components/tree-list/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['tree-list'],

    // attrs
    content: [],
    childrenPath: null,
    parentPath: null,

    tree: computed('content.[]', function () {
      var childrenPath = get(this, 'childrenPath');
      var parentPath = get(this, 'parentPath');
      return get(this, 'content').map(function (container) {
        var item = container;
        if (parentPath) {
          item = get(container, parentPath);
        }
        return {
          item: item,
          children: get(item, childrenPath),
          container: container
        };
      });
    })

  });
});