define('laterally/controllers/conversations/index', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].ArrayController.extend({

    currentUser: alias('session.user'),
    isLawyer: alias('currentUser.isLawyer'),
    isFirm: alias('currentUser.isFirm')

  });
});