define('laterally/components/our-pledge/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['our-pledge'],

    dismis: 'dismiss',

    actions: {
      outsideClick: function outsideClick() {
        this.sendAction('dismiss');
      },

      escape: function escape() {
        this.sendAction('dismiss');
      },

      dismiss: function dismiss() {
        this.sendAction('dismiss');
      }
    }
  });
});