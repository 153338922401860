define('laterally/controllers/selectable-school', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({
    needs: ['selectable-schools'],
    selectableSchools: alias('controllers.selectable-schools'),

    checked: (function (key, value) {
      var selections = this.get('selectableSchools.selections');
      var school = this.get('model.name');
      var checked = selections.contains(school);
      if (arguments.length === 2 && checked ^ value) {
        if (value) {
          selections.addObject(school);
        } else {
          selections.removeObject(school);
        }
        return value;
      } else {
        return checked;
      }
    }).property('selectableSchools.selections.[]')

  });
});