define('laterally/adapters/application', ['exports', 'laterally/config/environment', 'ember', 'ember-data', 'laterally/utils/underscore-keys', 'laterally/utils/filter-falsy-keys'], function (exports, _laterallyConfigEnvironment, _ember, _emberData, _laterallyUtilsUnderscoreKeys, _laterallyUtilsFilterFalsyKeys) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  var inflector = _ember['default'].Inflector.inflector;
  inflector.irregular('child', 'children');
  inflector.uncountable('offspring');

  exports['default'] = _emberData['default'].ActiveModelAdapter.extend({
    host: _laterallyConfigEnvironment['default'].APP.API_HOST,
    namespace: 'api',

    findQuery: function findQuery(store, type, query) {
      query = (0, _laterallyUtilsUnderscoreKeys['default'])(query);
      query = (0, _laterallyUtilsFilterFalsyKeys['default'])(query);
      return this._super(store, type, query);
    },

    headers: computed('session.accessToken', function () {
      var accessToken = get(this.session, 'accessToken');

      if (accessToken) {
        return { 'Authorization': ['Bearer', accessToken].join(' ') };
      } else {
        return {};
      }
    })

  });
});