define('laterally/helpers/humanized-range', ['exports', 'ember'], function (exports, _ember) {
  var moment = window.moment;

  function humanizeRange(value) {
    var isEqual;

    if (value[0] instanceof moment && value[1] instanceof moment) {
      isEqual = value[0].isSame(value[1]);
    } else {
      isEqual = value[0] === value[1];
    }

    if (isEqual) {
      return value[0];
    } else {
      return value.join(' - ');
    }
  }

  exports.humanizeRange = humanizeRange;
  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(humanizeRange);
});