define('laterally/components/content-editable', ['exports', 'ember', 'laterally/components/concerns/editable'], function (exports, _ember, _laterallyComponentsConcernsEditable) {
  exports['default'] = _ember['default'].Component.extend(_laterallyComponentsConcernsEditable['default'], {

    classNames: ['content-editable'],
    classNameBindings: ['highlighted'],
    attributeBindings: ['onclick'],
    onclick: '',

    tagName: 'span',

    value: "",

    spellCheck: "false",
    highlighted: false,
    neverShowPencil: false,
    parentClickable: false,
    retainNewlines: false,

    showPencil: (function () {
      return !this.get('neverShowPencil') && !this.get('editMode') && this.get('editable');
    }).property('editMode', 'editable'),

    currentCount: (function () {
      var result = this.get("value") && this.get("value").length || 0;
      if (this.get("limit") && result > this.get("limit")) {
        result = this.get("limit");
      }
      return result;
    }).property("value"),

    contentEditable: (function () {
      return this.get('editable') ? 'true' : 'false';
    }).property('editable'),

    attachEventListeners: (function () {
      this.on('focusOut', this, this.doFocusOut);
      this.on('focusIn', this, this.saveOldValue);
      this.on('change', this, this.elementValueDidChange);
      this.on('paste', this, this.doPaste);
      this.on('cut', this, this.elementValueDidChange);
      this.on('input', this, this.elementValueDidChange);
      this.on('keyUp', this, this.elementValueDidChange);
      this.on('keyDown', this, this.preventInputOnLimit);
    }).on('init'),

    doFocusOut: function doFocusOut() {
      this.elementValueDidChange();
      this.trackOnFocusOut();
    },

    mouseEnter: function mouseEnter() {
      this.set('highlighted', true);
    },

    mouseLeave: function mouseLeave() {
      this.set('highlighted', false);
    },

    doPaste: function doPaste(e) {
      e.preventDefault();

      //from http://stackoverflow.com/a/17545260
      var text = '';
      var that = $(this);

      if (e.clipboardData) text = e.clipboardData.getData('text/plain');else if (window.clipboardData) text = window.clipboardData.getData('Text');else if (e.originalEvent.clipboardData) text = $('<div></div>').text(e.originalEvent.clipboardData.getData('text'));

      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertHTML', false, $(text).html());
        this.elementValueDidChange();
        return false;
      } else {
        // IE > 7
        that.find('*').each(function () {
          $(this).addClass('within');
        });

        setTimeout(function () {
          that.find('*').each(function () {
            $(this).not('.within').contents().unwrap();
            that.elementValueDidChange();
          });
        }, 1);
      }
    },

    preventInputOnLimit: function preventInputOnLimit(event) {
      var text = this.getTextOrHtml(this.$(".input"));
      var length = text.length;
      var limit = this.get("limit");
      var n = event.which;

      var keycodes = [8, // backspace
      9, // tab
      37, // arrow left
      38, // arrow up
      39, // arrow right
      40 // arrow down
      ];

      if (this.get("preventReturns")) {
        keycodes.push(13);
        if (n === 13) {
          event.preventDefault();
        }
      }

      if (limit && limit <= length) {
        if (keycodes.indexOf(n) === -1) {
          event.preventDefault();
        }
      }

      //Ember.run.later(this, 'updateElementValue', 100);
    },

    elementValueDidChange: function elementValueDidChange() {
      var limit = this.get("limit");
      var text = this.getTextOrHtml(this.$(".input"));
      if (!limit || limit >= text.length) {
        this.set('value', text);
      } else if (limit < text.length) {
        this.set('value', text.substring(0, limit - 1));
      }
    },

    getTextOrHtml: function getTextOrHtml(element) {
      return element[0].innerText;
    },

    setTextOrHtml: function setTextOrHtml(element, value) {
      if (this.get('retainNewlines')) {
        if (value) {
          value = value.replace(/\n/g, '<br>');
        }
        element.html(value);
      } else {
        element.text(value);
      }
    },

    trackOnFocusOut: function trackOnFocusOut() {
      var oldValue = this.get("oldValue");
      var newValue = this.get('value');
      if (oldValue !== newValue) {
        if (this.get('valueDidChange')) {
          this.sendAction('valueDidChange', newValue, oldValue);
        } else {
          var trackAs = this.get("trackAs");
          if (trackAs) {
            this.get('analytics').track(trackAs + " edited", {
              old_value: oldValue,
              new_value: newValue
            });
          }
        }
      }
    },

    saveOldValue: function saveOldValue() {
      this.set("oldValue", this.get("value"));
    },

    updateElementValue: (function () {
      var $input = this.$('.input');
      // If value is null or undefined, we don't want the strings "null" or
      // "undefined".
      var value = this.get('value') || '';
      var limit = this.get("limit");
      if (limit && limit < value.length) {
        value = this.getTextOrHtml($input).slice(0, limit);
      }
      if (this.getTextOrHtml($input) !== value) {
        this.setTextOrHtml($input, value);
      }
    }).observes('value').on('didInsertElement'),

    didInsertElement: function didInsertElement() {
      if (this.get('parentClickable')) {
        this.focusOnParentClick();
      }
    },

    focusInput: (function (e) {
      this.$('.input').focus();
    }).on('click')

  });
});