define('laterally/components/practice-areas-select/component', ['exports', 'ember', 'laterally/models/lawyer', 'laterally/utils/computed/store-locally'], function (exports, _ember, _laterallyModelsLawyer, _laterallyUtilsComputedStoreLocally) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var or = computed.or;
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['practice-areas-select'],

    // attrs
    selections: null,
    practiceAreaCategories: null,
    max: _laterallyModelsLawyer['default'].maxPracticeAreas,

    currentCategory: or('recentCategory', 'corporateCategory'),

    recentCategoryId: (0, _laterallyUtilsComputedStoreLocally['default'])('practice-areas-select.recentCategoryId'),
    recentCategory: computed('recentCategoryId', function () {
      return get(this, 'practiceAreaCategories').findBy('id', get(this, 'recentCategoryId'));
    }),

    corporateCategory: (function () {
      return this.get('practiceAreaCategories').findBy('name', 'Corporate');
    }).property('practiceAreaCategories'),

    actions: {
      practiceAreaCategorySelected: function practiceAreaCategorySelected(category) {
        set(this, 'recentCategoryId', get(category, 'id'));
      }
    }

  });
});