define('laterally/components/quill-text/component', ['exports', 'ember', 'laterally/components/concerns/editable'], function (exports, _ember, _laterallyComponentsConcernsEditable) {
  exports['default'] = _ember['default'].Component.extend({

    quill: null,
    value: null,
    isEditing: null,

    classNames: ['quill-wrapper'],

    didInsertElement: function didInsertElement() {
      var q = new Quill('#' + this.elementId + ' .editor', { theme: 'snow' });
      this.set('quill', q);
      q.addModule('toolbar', { container: '#' + this.elementId + ' .toolbar' });
      if (this.get('value') != null) {
        q.setHTML(this.get('value'));
      }
      this.on('mouseDown', this, this.focus);
      this.on('touchStart', this, this.focus);
      this.$().parent().click(this, function () {
        q.focus();
      });
    },

    focus: function focus() {
      this.set('isEditing', true);
    }
  });
});