define('laterally/controllers/connection/chat', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  exports['default'] = _ember['default'].Controller.extend({
    currentUser: alias('session.user'),
    currentUserable: alias('currentUser.userable'),

    actions: {
      readLatest: function readLatest() {
        get(this, 'conversation').readLatest();
      },

      sendMessage: function sendMessage(body) {
        var sender = this.get('currentUserable');

        get(get(this, 'model.conversation'), 'messages').createRecord({
          body: body,
          createdAt: window.moment(),
          sender: sender
        }).save();

        this.analytics.track('Sent message', {
          sender: {
            id: sender.get('id'),
            name: sender.get('name'),
            type: sender.constructor.typeKey
          },
          body: body
        });
      },

      deleteMessage: function deleteMessage(message) {
        if (window.confirm("Are you sure you want to delete this message?")) {
          var conversation = message.get('conversation');
          message.destroyRecord();

          if (get(conversation, 'messages.length') === 0) {
            conversation.deleteRecord();
            conversation.save();

            this.transitionTo('conversations');
          }
        }
      }
    }
  });
});