define('laterally/controllers/concerns/editable', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {

  var $ = _ember['default'].$;

  function callMethod(method) {
    return function () {
      this[method].apply(this, arguments);
    };
  }

  exports['default'] = _ember['default'].Mixin.create({

    editing: false,
    safeTransition: true,
    canEdit: true,

    edit: function edit() {
      if (this.get('canEdit')) {
        this.set('editing', true);
      }
    },

    save: function save() {
      this.set('editing', false);
      return this.get('content').save();
    },

    dirty: (function () {
      return this.get('model.isDirty');
    }).property('model.isDirty'),

    preventUnload: (function () {
      if (this.get('dirty') && !_laterallyConfigEnvironment['default'].APP.DISABLE_UNLOAD_CONFIRMATION) {
        $(window).on('beforeunload', helper.bind(this));
      } else {
        $(window).off('beforeunload', helper);
      }
      function helper() {
        var isLawyerRoute = /\/lawyer/ig.test(this.get('container').lookup('router:main').get('location.location.pathname'));
        if (isLawyerRoute && this.get('dirty')) {
          return "You have unsaved changes in your profile";
        }
      }
    }).observes('model.isDirty'),

    retrying: false,
    safeTransitionModal: function safeTransitionModal(transition) {
      if (this.get('safeTransition') && this.get('dirty')) {
        transition.send('openModal', 'application/unsavedChangesModal', {
          previousTransition: transition
        });
        transition.abort();
      }
    },

    cancel: function cancel() {
      this.set('editing', false);
      this.get('content').rollback();
    },

    actions: {

      edit: callMethod('edit'),
      save: callMethod('save'),
      cancel: callMethod('cancel')

    }

  });
});