define('laterally/components/count-by/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['count-by'],
    classNameBindings: ['count:is-non-zero:is-zero'],

    // attrs
    content: [],
    by: null,
    value: null,

    count: computed('content.[]', 'by', 'value', function () {
      var content = get(this, 'content');
      var by = get(this, 'by');
      var value = get(this, 'value');
      if (content && content.filterBy) {
        return get(content.filterBy(by, value), 'length');
      } else {
        return 0;
      }
    })

  });
});