define('laterally/services/pusher', ['exports', 'ember', 'laterally/config/environment'], function (exports, _ember, _laterallyConfigEnvironment) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Object.extend({
    key: _laterallyConfigEnvironment['default'].APP.PUSHER_KEY,

    prefix: null,
    client: null,

    open: function open(userId) {
      if (!userId) {
        throw new Error('A user ID must be provided');
      }

      var headers = {};
      var accessToken = get(this.session, 'token.value');

      if (accessToken) {
        headers['Authorization'] = ['Bearer', accessToken].join(' ');
      }

      this.client = new window.Pusher(this.key, {
        authEndpoint: [this.store.adapterFor('user').buildURL('user', userId), 'push_key'].join('/'),
        disableFlash: true,
        encrypted: true,
        auth: { headers: headers }
      });

      this.prefix = ['private-user', userId].join('-');
    },

    close: function close() {
      if (this.client) {
        this.client.disconnect();
        this.client = null;
        this.prefix = null;
      }
    },

    subscribe: function subscribe(typeKey) {
      if (!this.client) {
        return;
      }

      if (!this._subscriptionsMap[typeKey]) {
        this._subscriptionsMap[typeKey] = 0;

        var service = this;
        var channel = this.client.subscribe(this._channelForType(typeKey));

        channel.bind_all(function (eventName, data) {
          if (['created', 'updated', 'deleted'].contains(eventName)) {
            _ember['default'].run(service, function () {
              service['_recordWas' + eventName.capitalize()](typeKey, data);
            });
          }
        });
      }

      this._subscriptionsMap[typeKey]++;
    },

    unsubscribe: function unsubscribe(typeKey) {
      if (this.client) {
        if (this._subscriptionsMap[typeKey] === 1) {
          this.client.unsubscribe(this._channelForType(typeKey));
        }

        this._subscriptionsMap[typeKey]--;
      }
    },

    _recordWasCreated: function _recordWasCreated(typeKey, data) {
      if (typeof data === 'string') {
        this.store.find(typeKey, data);
      } else {
        this.store.pushPayload(this.store, data);
      }
    },

    _recordWasUpdated: function _recordWasUpdated(typeKey, data) {
      if (typeof data === 'string') {
        this.store.find(typeKey, data);
      } else {
        this.store.pushPayload(this.store, data);
      }
    },

    _recordWasDeleted: function _recordWasDeleted(typeKey, data) {
      var id;
      var record;

      if (typeof data === 'string') {
        id = data;
      } else {
        id = data[typeKey]['id'];
      }

      record = this.store.recordForId(typeKey, id);

      record.deleteRecord();
      record.transitionTo('saved');
    },

    _channelForType: function _channelForType(typeKey) {
      return [this.get('prefix'), _ember['default'].String.pluralize(typeKey)].join('-');
    },

    // Pusher doesn't allow multiple subscriptions
    // to the same channel, so we'll maintain a count
    // of each type in case the app tries to subscribe
    // multiple times
    _subscriptionsMap: {}
  });
});