define('laterally/components/conversations-list/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    conversations: null,
    profile: null,

    conversationObjects: computed('conversations.@each', function () {
      var profile = get(this, 'profile');

      return get(this, 'conversations').map(function (conversation) {
        var otherMember;
        var lastMessageByOtherMember;

        if (get(profile, 'isFirm')) {
          otherMember = get(conversation, 'lawyers.firstObject') || get(conversation, 'explorees.firstObject');
        } else {
          otherMember = get(conversation, 'firmUsers.firstObject');
        }

        var otherMemberMessages = get(conversation, 'messages').filterProperty('sender', otherMember).sortBy('id');
        lastMessageByOtherMember = get(otherMemberMessages, 'lastObject');

        return {
          conversation: conversation,
          otherMember: otherMember,
          lastMessageByOtherMember: lastMessageByOtherMember
        };
      });
    })
  });
});