define('laterally/components/move-tracker/percent-stat/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    percent: null,

    visible: (function () {
      return get(this, 'percent') !== null;
    }).property('percent'),

    prettyPercentage: (function () {
      if (get(this, 'percent') < 1) {
        return '< 1';
      } else {
        return Math.round(get(this, 'percent'));
      }
    }).property('percent')
  });
});