define('laterally/components/scroll-to', ['exports', 'ember'], function (exports, _ember) {

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'a',

    attributeBindings: ['hash:href'],

    duration: 500,

    hash: (function () {
      return '#' + this.get('anchor');
    }).property('anchor'),

    scrollTo: (function (event) {
      event.preventDefault();
      var hash = this.get('hash');
      var duration = this.get('duration');
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, duration, function () {
        window.location.hash = hash;
      });
    }).on('click')

  });
});