define('laterally/components/x-connection/component', ['exports', 'ember', 'laterally/utils/deferred-send-action'], function (exports, _ember, _laterallyUtilsDeferredSendAction) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['x-connection'],

    connection: null,

    gettingIntro: false,
    gotIntro: false,

    getIntroTooltip: computed('connection.{job,acceptedByLawyer,introducer}', function () {
      if (!get(this, 'connection.acceptedByLawyer')) {
        if (get(this, 'connection.isAssisted')) {
          return "Connect with " + get(this, 'connection.introducer.fullName') + " and start a chat";
        }

        return "You'll be connected with hiring contacts at " + get(this, 'connection.job.firm.name') + " and they'll be able to start a conversation with you on Laterally";
      }
    }),

    actions: {
      getIntro: function getIntro() {
        var _this = this;

        set(this, 'gettingIntro', true);

        (0, _laterallyUtilsDeferredSendAction['default'])(this, 'getIntro', get(this, 'connection')).then(function () {
          set(_this, 'gettingIntro', false);
          set(_this, 'gotIntro', true);
        });
      },

      respondTo: function respondTo() {
        this.sendAction('respondTo', get(this, 'connection'));
      },

      requestInfo: function requestInfo() {
        this.sendAction('requestInfo', get(this, 'connection'));
      },

      acknowledgeIntro: function acknowledgeIntro() {
        set(this, 'gotIntro', false);
      }
    }
  });
});