define('laterally/routes/logout', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      if (!this.session.get('token')) {
        this.transitionTo('landing');
      }
    },

    // Desperately trying to avoid errors
    // like `currentUser.get` where `currentUser`
    // is null, so doing this in setupController
    // after the transition has completed?
    setupController: function setupController() {
      this.referrerManager.clearReferrer();
      var session = this.session;

      session.close().then(function () {
        var user = session.get('user');

        if (user) {
          user.unloadRecord();
        }
      });

      return this.transitionTo('landing');
    }

  });
});