define('laterally/components/saving-button/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var get = _ember['default'].get;
  var run = _ember['default'].run;
  exports['default'] = Component.extend({
    tagName: 'button',
    attributeBindings: ['disabled', 'title', 'data-toggle', 'data-placement'],

    isDisabled: false,
    isSaving: false,

    disabled: computed.or('isDisabled', 'isSaving'),

    titleDidChange: observer('title', function () {
      var _this = this;

      run.scheduleOnce('afterRender', function () {
        if (!get(_this, 'title')) {
          _this.$().tooltip('destroy');
        }
      });
    }),

    click: function click() {
      if (!get(this, 'isDisabled')) {
        this.sendAction('action');
      }
    }
  });
});