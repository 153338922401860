define("laterally/routes/offers/index", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({

    beforeModel: function beforeModel(transition) {
      if (this.session.get("user.userable.states.DEMOING")) {
        transition.send("openModal", "application/fullAccessRequiredModal");
        transition.abort();
      }
    },

    model: function model() {
      return this.store.find("offer");
    }

  });
});