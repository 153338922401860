define('laterally/routes/firms/index', ['exports', 'ember', 'laterally/routes/concerns/paged', 'laterally/routes/concerns/ordered', 'laterally/models/lawyer'], function (exports, _ember, _laterallyRoutesConcernsPaged, _laterallyRoutesConcernsOrdered, _laterallyModelsLawyer) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Route.extend(_laterallyRoutesConcernsPaged['default'], _laterallyRoutesConcernsOrdered['default'], {

    queryParams: {
      practiceAreaId: { refreshModel: true },
      locationId: { refreshModel: true },
      state: { refreshModel: true }
    },

    model: function model(params) {
      if (params.state === 'saved') {
        return [];
      }
      return this.store.find('firm', params);
    },

    actions: {

      reload: function reload() {
        var _this = this;

        this.store.find('firm', this.modelFor('firms/index').query).then(function (x) {
          _this.controllerFor('firms/index').set('model', x);
        });
      },

      finishedOnboarding: function finishedOnboarding(lawyer) {
        var _this2 = this;

        this.analytics.track('Finished Onboarding');
        var controller = this.controllerFor('firms/index');
        if (get(lawyer, 'isAnySelecting')) {
          controller.send('hideOnboardingModals');
          controller.fakeLoading().then(function () {
            return _this2.refresh();
          });
        }
      },

      openPracticeAreasModal: function openPracticeAreasModal() {
        var appController = this.controllerFor('application');
        this.send('openModal', 'application/practice-areas', {
          modalTitle: 'Practice Areas',
          max: _laterallyModelsLawyer['default'].maxPracticeAreas,
          selections: this.get('session.userable.desiredPracticeAreas'),
          practiceAreaCategories: appController.get('practiceAreaCategories'),
          reload: true,
          onSave: (function (selected, match, defer) {
            var selections = this.get('session.userable.desiredPracticeAreas');
            selections.setObjects(selected);
            this.get('session.userable').save().then(function () {
              defer.resolve();
            });
          }).bind(this)
        });
      },

      openLocationsModal: function openLocationsModal() {
        this.send('openModal', 'lawyer/index/locations');
      }

    }

  });
});