define('laterally/components/explorees/header-bar/component', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;
  var equal = _ember['default'].computed.equal;
  var get = _ember['default'].get;
  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Component.extend({

    state: null,
    group: null,
    createGroup: 'createGroup',

    newName: '',
    hasError: false,
    copying: false,
    editing: false,

    savedActive: equal('state', 'saved'),
    hiddenActive: equal('state', 'hidden'),
    interestedActive: equal('state', 'interested'),

    editingRename: equal('editing', 'rename'),
    editingCopy: equal('editing', 'copy'),
    editingDestroy: equal('editing', 'destroy'),
    editingClose: equal('editing', 'close'),

    savedCount: alias('group.savedMembershipsCount'),

    focusInput: function focusInput() {
      _ember['default'].$('.explore-header input').focus();
    },

    errorClearer: (function () {
      this.set('hasError', false);
    }).observes('newName'),

    rename: function rename(name) {
      var group = this.get('group');
      group.set('name', name);
      group.save();
      this.send('cancel');
    },

    copy: function copy(name) {
      this.set('copying', true);
      this.get('group').copy({ name: name }).then((function (group) {
        this.set('group', null);
        this.send('selectGroup', group);
        this.send('cancel');
        this.set('copying', false);
      }).bind(this));
    },

    close: function close() {
      var _this = this;

      var group = this.get('group');
      group.set('isClosed', true);
      group.save().then(function () {
        _this.sendAction('closedGroup');
        _this.send('cancel');
      });
    },

    destroyRecord: function destroyRecord() {
      var _this2 = this;

      var group = get(this, 'group');
      set(this, 'group', null);

      this.send('cancel');

      group.destroyRecord().then(function () {
        _this2.sendAction('destroyedGroup');
      });
    },

    track: function track(message, props) {
      if (!props) {
        props = {};
      }
      var group = this.get('group');
      props.exploreeGroup = group ? group.getProperties('id', 'name') : group;
      this.analytics.track(message, props);
    },

    actions: {

      selectGroup: function selectGroup(group) {
        var state = this.get('state');
        this.sendAction('selectGroupAndState', group, state);
      },

      createGroup: function createGroup(name, resolve, reject) {
        this.sendAction('createGroup', name, resolve, reject);
      },

      switchState: function switchState(state) {
        this.set('state', state);
      },

      save: function save() {
        var name = this.get('newName');
        if (name) {
          var groupId = this.get('group.id');

          if (this.get('editingRename')) {
            this.track('Renamed ExploreeGroup', {
              from: this.get('group.name'),
              to: name
            });
            this.rename(name);
          } else if (this.get('editingCopy')) {
            this.copy(name);
            this.track('Copied ExploreeGroup', {
              to: name
            });
          } else if (this.get('editingClose')) {
            this.close();
            this.track('Closed ExploreeGroup', groupId);
            this.sendAction('closedGroup', groupId);
          } else if (this.get('editingDestroy')) {
            this.destroyRecord();
            this.track('Deleted ExploreeGroup');
            this.sendAction('destroyedGroup', groupId);
          }
        } else {
          this.set('hasError', true);
          this.track('Attempted to rename/copy ExploreeGroup with blank name');
        }
      },

      copy: function copy() {
        this.send('edit', 'copy');
        this.track('Started ExploreeGroup copy');
      },

      rename: function rename() {
        this.send('edit', 'rename');
        this.track('Started ExploreeGroup rename');
      },

      edit: function edit(type) {
        this.set('editing', type);
        this.set('newName', this.get('group.name'));
        _ember['default'].run.scheduleOnce('afterRender', this, 'focusInput');
      },

      cancel: function cancel() {
        this.set('editing', false);
        this.set('newName', '');
      },

      destroy: function destroy() {
        this.send('edit', 'destroy');
        this.track('Started ExploreeGroup delete');
      },

      close: function close() {
        this.send('edit', 'close');
        this.track('Started ExploreeGroup close');
      },

      viewJobProfile: function viewJobProfile(exploreeGroup) {
        this.track('Clicked Job Profile');
        this.transitionToRoute('explore-jobs.edit', exploreeGroup.id);
      }
    }

  });
});