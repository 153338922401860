define('laterally/serializers/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].ActiveModelSerializer.reopen({

    // For some reason, 'attrs' is not merged by default. We need this to be
    // merged for things like serializers/concerns/userable which defines attrs
    // but is extended by serializers/lawyer which also defines attrs.
    mergedProperties: ['attrs'],

    attrs: {},

    // normalizeAttributes and normalizeRelationships have been overwritten for
    // the following reason:
    // When calling store.load, the passed-in json object contains only the fields
    // that are to be updated, they should be merged in with the data in memory
    // already. However, before calling store.update, you must extract the record
    // from the json object. The two normalizing methods make sure to include
    // every attribute on the model on the hash, regardless of whether they were
    // in the passed-in json object. So, nothing is merged, everything gets
    // overwritten. In the following code, it is ensured that the passed-in json
    // has the payloadKey before adding it to the resulting hash.

    applyTransforms: function applyTransforms(type, data) {
      type.eachTransformedAttribute(function (key, type) {
        if (data.hasOwnProperty(key)) {
          var transform = this.transformFor(type);
          data[key] = transform.deserialize(data[key]);
        }
      }, this);

      return data;
    },

    normalizeAttributes: function normalizeAttributes(type, hash) {
      var payloadKey;

      if (this.keyForAttribute) {
        type.eachAttribute(function (key) {
          payloadKey = this.keyForAttribute(key);
          if (key === payloadKey) {
            return;
          }
          if (hash.hasOwnProperty(payloadKey)) {
            hash[key] = hash[payloadKey];
            delete hash[payloadKey];
          }
        }, this);
      }
    },

    normalizeRelationships: function normalizeRelationships(type, hash) {
      var payloadKey;

      if (this.keyForRelationship) {
        type.eachRelationship(function (key, relationship) {
          payloadKey = this.keyForRelationship(key, relationship.kind);
          if (key === payloadKey) {
            return;
          }
          if (hash.hasOwnProperty(payloadKey)) {
            hash[key] = hash[payloadKey];
            delete hash[payloadKey];
          }
        }, this);
      }
    },

    serializePolymorphicType: function serializePolymorphicType(record, json, relationship) {
      var key = relationship.key;
      var belongsTo = record.belongsTo(key);
      if (belongsTo && (belongsTo.type || belongsTo.constructor)) {
        key = this.keyForAttribute(key);
        json[key + "_type"] = _ember['default'].String.capitalize(_ember['default'].String.camelize((belongsTo.type || belongsTo.constructor).typeKey));
      }
    },

    typeForRoot: function typeForRoot(root) {
      if (root === 'job_saves') {
        return 'jobSave';
      } else {
        return this._super(root);
      }
    }

  });
});