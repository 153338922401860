define('laterally/components/editable-content/component', ['exports', 'ember'], function (exports, _ember) {

  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Component.extend({

    tagName: '',
    isEditing: false,

    // attrs
    'on-save': 'save',
    'on-cancel': 'cancel',

    actions: {

      edit: function edit() {
        set(this, 'isEditing', true);
      },

      save: function save() {
        this.sendAction('on-save');
        set(this, 'isEditing', false);
      },

      cancel: function cancel() {
        this.sendAction('on-cancel');
        set(this, 'isEditing', false);
      }

    }
  });
});