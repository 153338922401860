define('laterally/components/connectable-explanation/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    classNames: ['connectable-explanation'],

    connectable: null,
    deferred: null,

    fulfilled: function fulfilled() {
      this.sendAction('dismiss');
      get(this, 'deferred').resolve(get(this, 'connectable'));
    },

    aborted: function aborted() {
      this.sendAction('dismiss');
      get(this, 'deferred').reject();
    },

    actions: {
      submit: function submit() {
        this.fulfilled();
      },

      outsideClick: function outsideClick() {
        this.aborted();
      },

      escape: function escape() {
        this.aborted();
      },

      dismiss: function dismiss() {
        this.aborted();
      }
    }
  });
});