define('laterally/components/affix-to/component', ['exports', 'ember', 'laterally/utils/scheduled'], function (exports, _ember, _laterallyUtilsScheduled) {

  var get = _ember['default'].get;
  var on = _ember['default'].on;
  var computed = _ember['default'].computed;
  var $ = _ember['default'].$;

  function eventNames(component) {
    return 'scroll.' + get(component, 'elementId');
  }

  exports['default'] = _ember['default'].Component.extend({

    // attrs
    bounds: 'parent',

    $bounds: computed('bounds', function () {
      var bounds = get(this, 'bounds');
      if (bounds === 'parent') {
        return this.$().parent();
      }
      return this.$().closest(bounds);
    }),

    initializeAffix: on('didInsertElement', (0, _laterallyUtilsScheduled['default'])('afterRender', function () {
      var _this = this;

      var offset = get(this, '$bounds').offset();
      this.$().affix({ offset: offset });
      // Remove `transform` from all parents because we can't use it with
      // `position: fixed` because it creates a new stacking context. Remove on
      // scroll because we don't need it until then.
      // TODO: fork liquid-fire and make sure there are no transforms active
      // unless necessary.
      $(window).on(eventNames(this), function () {
        _this.detachWindowListeners();
        _this.$().parents().each(function () {
          if ($(this).css('transform')) {
            $(this).css('transform', 'none');
          }
        });
      });
    })),

    detachWindowListeners: on('willDestroyElement', function () {
      $(window).off(eventNames(this));
    })

  });
});