define('laterally/services/session', ['exports', 'ember', 'laterally/mixins/token-manageable', 'laterally/utils/computed/stored-property'], function (exports, _ember, _laterallyMixinsTokenManageable, _laterallyUtilsComputedStoredProperty) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var RSVP = _ember['default'].RSVP;
  var alias = computed.alias;
  var bool = computed.bool;
  exports['default'] = _ember['default'].Object.extend(_laterallyMixinsTokenManageable['default'], {
    typeKey: 'accessToken',
    _tokenValue: (0, _laterallyUtilsComputedStoredProperty['default'])('accessToken')
  }, {
    accessToken: alias('_tokenValue'),

    // legacy getters

    user: computed('token', function () {
      var user;
      var token = get(this, 'token');

      if (get(token, 'possessedUserId')) {
        user = get(token, 'possessedUser');
      } else {
        user = get(token, 'user');
      }

      return user;
    }),
    userable: alias('user.userable'),

    possessed: bool('token.possessedUserId'),
    canPossess: alias('token.userIsAdmin'),

    //

    actingUser: computed('token', function () {
      var user;
      var token = get(this, 'token');

      if (get(token, 'possessedUserId')) {
        user = get(token, 'possessedUser');
      } else {
        user = get(token, 'user');
      }

      return user;
    }),

    possessedUser: computed('token', function () {
      var user;
      var token = get(this, 'token');

      if (get(token, 'possessedUserId')) {
        user = get(token, 'possessedUser');
      } else {
        user = null;
      }

      return user;
    }),

    possessingUserId: computed('token', function () {
      var id;
      var token = get(this, 'token');

      if (get(token, 'possessedUserId')) {
        id = get(token, 'userId');
      } else {
        id = null;
      }

      return id;
    }),

    prepareToken: function prepareToken(attrs) {
      return this.store.createRecord('accessToken', attrs || {});
    },

    openWithSingleAccessToken: function openWithSingleAccessToken(singleAccessToken) {
      return this.open(this.prepareToken({
        singleAccessToken: singleAccessToken
      }));
    },

    openWithCredentials: function openWithCredentials(credentials) {
      return this.open(this.prepareToken({
        userEmail: credentials.email,
        userPassword: credentials.password
      }));
    },

    openWithClaimToken: function openWithClaimToken(claimToken) {
      return this.open(this.prepareToken({
        exploreeClaimToken: claimToken
      }));
    },

    open: function open(token) {
      var _this = this;

      var promise = new RSVP.Promise(function (resolve, reject) {
        token.save().then(function (token) {
          set(_this, 'token', token);
          resolve(token);
        }, reject);
      });

      return promise;
    },

    useTokenValue: function useTokenValue(tokenValue) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve) {
        if (tokenValue) {
          set(_this2, '_tokenValue', tokenValue);
          _this2._start();
        }

        resolve();
      });
    },

    getOneTimeToken: function getOneTimeToken() {
      var _this3 = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this3.store.createRecord('oneTimeToken').save().then(resolve, reject);
      });
    },

    close: function close() {
      var _this4 = this;

      set(this, 'token', null);
      return new RSVP.Promise(function (resolve, reject) {
        _this4.bus.publish('token:removed');
        resolve();
      });
    }

  });
});