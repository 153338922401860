define('laterally/views/authenticated', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].View.extend({
    mouseDown: function mouseDown(event) {
      this.showClaimModal(event);
    },

    touchStart: function touchStart(event) {
      this.showClaimModal(event);
    },

    keyDown: function keyDown(event) {
      this.showClaimModal(event);
    },

    showClaimModal: function showClaimModal(event) {
      if (!get(this, 'controller.user.claimed') && !get(this, 'controller.preventLegacyClaimModal')) {
        event.preventDefault();
        event.stopPropagation();

        this.get('controller').send('openModal', 'application/claim-modal');
        return false;
      }
    }
  });
});