define('laterally/utils/xdomain-slaves', ['exports', 'laterally/config/environment'], function (exports, _laterallyConfigEnvironment) {

  var xdomain = window.xdomain;

  if (xdomain) {
    var slaves = {};
    slaves[_laterallyConfigEnvironment['default'].APP.API_HOST] = '/api_proxy.html';

    xdomain.slaves(slaves);
  }
});