define('laterally/components/session-expiry-warning/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['session-expiry-warning-component'],

    currentToken: null,
    currentSessionWillExpireSoon: false,

    didInsertElement: function didInsertElement() {
      this.currentTokenDidChange();
    },

    currentTokenDidChange: observer('currentToken', function () {
      var _this = this;

      var token = get(this, 'currentToken');

      if (token) {
        var now = new Date().getTime();
        var exp = get(token, 'exp') * 1000;

        if (exp > now) {
          var expiresAround = exp - 10 * 60 * 1000;

          run.later(function () {
            set(_this, 'currentSessionWillExpireSoon', true);
          }, expiresAround - now);
        }
      }

      set(this, 'currentSessionWillExpireSoon', false);
    }),

    currentSessionWillExpireSoonDidChange: observer('currentSessionWillExpireSoon', function () {
      if (get(this, 'currentSessionWillExpireSoon')) {
        this.analytics.track('Shown session expiry warning');
      }
    }),

    click: function click() {
      this.sendAction('willRenewSession');
    }
  });
});