define('laterally/components/renew-session/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Component.extend({
    token: null,
    renewSession: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.analytics.track('Opened session renewal modal');

      _ember['default'].run.later(function () {
        _this.$('form input:eq(1)').focus();
      }, 350);
    },

    actions: {
      submit: function submit() {
        var _this2 = this;

        this.sendAction('renewSession', get(this, 'token'), function () {
          _this2.analytics.track('Renewed session');
          _this2.sendAction('dismiss');
        }, function (reason) {
          _this2.analytics.track('Failed to renew session');

          if (reason.status === 401) {
            _this2.sendAction('dismiss');
            RSVP.rethrow(reason);
          }
        });
      }
    }
  });
});