define('laterally/models/lawyer', ['exports', 'ember', 'ember-data', 'laterally/models/concerns/userable', 'laterally/models/concerns/stateful', 'laterally/models/concerns/conversation-member', 'laterally/models/concerns/transactor', 'laterally/models/concerns/admittee', 'laterally/models/concerns/experiencer', 'laterally/models/concerns/attachment', 'laterally/utils/rest-action', 'laterally/models/concerns/dirty-trackable'], function (exports, _ember, _emberData, _laterallyModelsConcernsUserable, _laterallyModelsConcernsStateful, _laterallyModelsConcernsConversationMember, _laterallyModelsConcernsTransactor, _laterallyModelsConcernsAdmittee, _laterallyModelsConcernsExperiencer, _laterallyModelsConcernsAttachment, _laterallyUtilsRestAction, _laterallyModelsConcernsDirtyTrackable) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var bool = _ember['default'].computed.bool;
  var or = _ember['default'].computed.or;
  var and = _ember['default'].computed.and;

  var requirementKeys = ['currentLocation', 'resume', 'transcript', 'barAdmissions.length'];

  var extraRequirementKeys = ['profilePicture', 'dealSheet', 'questionBesidesResume'];

  exports['default'] = _emberData['default'].Model.extend(_laterallyModelsConcernsUserable['default'], _laterallyModelsConcernsStateful['default'], _laterallyModelsConcernsConversationMember['default'], _laterallyModelsConcernsTransactor['default'], _laterallyModelsConcernsAdmittee['default'], _laterallyModelsConcernsAttachment['default'], _laterallyModelsConcernsDirtyTrackable['default'], _laterallyModelsConcernsExperiencer['default'], {

    isLawyer: true,
    isExploree: bool('exploree.isExploree'),
    traits: (function () {
      return {}; // Sent to Segment via back-end
    }).property().volatile(),

    stage: _emberData['default'].attr('string'),

    coverLetter: _emberData['default'].attr('string'),
    resume: _emberData['default'].attr(),
    transcript: _emberData['default'].attr(),
    dealSheet: _emberData['default'].attr(),
    schoolName: _emberData['default'].attr('string'),
    firmName: _emberData['default'].attr('string'),
    firmId: _emberData['default'].attr('number'),
    hasFirmId: bool('firmId'),
    locationName: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    phone: _emberData['default'].attr('string'),
    contactNotes: _emberData['default'].attr('string'),
    lookingForBigLaw: _emberData['default'].attr('string'),
    lookingForMidSizedFirms: _emberData['default'].attr('string'),
    lookingForBoutiques: _emberData['default'].attr('string'),
    lookingForSmallFirms: _emberData['default'].attr('string'),
    lookingForInHouse: _emberData['default'].attr('string'),
    currentLocation: _emberData['default'].attr('string'),
    position: _emberData['default'].attr('string'),
    offersCount: _emberData['default'].attr('number'),
    viewsCount: _emberData['default'].attr('number'),
    questionMoving: _emberData['default'].attr('string'),
    questionLookingFor: _emberData['default'].attr('string'),
    questionProudest: _emberData['default'].attr('string'),
    questionOtherCareer: _emberData['default'].attr('string'),
    questionBesidesResume: _emberData['default'].attr('string'),
    graduationYear: _emberData['default'].attr('number'),
    // Don't make this `boolean` because it could be null.
    usedHeadhunter: _emberData['default'].attr(),
    timeframe: _emberData['default'].attr('string'),
    explore: _emberData['default'].attr('boolean'),
    bio: _emberData['default'].attr('string'),
    honors: _emberData['default'].attr('string'),
    startedOnboardingAt: _emberData['default'].attr('date'),
    onboarded: _emberData['default'].attr('boolean', { defaultValue: false }),
    doubleSubmitVerified: _emberData['default'].attr('boolean'),
    linkedinUrl: _emberData['default'].attr('string'),
    visibleToFirmsCount: _emberData['default'].attr('number'),
    student: _emberData['default'].attr('boolean'),
    schoolEmail: _emberData['default'].attr('string'),
    schoolAccessCode: _emberData['default'].attr('string'),

    exploree: _emberData['default'].belongsTo('exploree'),
    jobSelections: _emberData['default'].hasMany('jobSelection', { async: true }),
    desiredLocations: _emberData['default'].hasMany('location'),
    desiredPracticeAreas: _emberData['default'].hasMany('practiceArea'),

    // errors: null,

    firms: computed('jobSelections.[]', function () {
      return this.get('jobSelections').filterProperty('hiddenFromLawyers', false).mapBy('firmLocationJob.firmLocation.firm').uniq();
    }),

    firmsOfOpenJobs: computed('jobSelections.[]', function () {
      return this.get('jobSelections').filterProperty('hiddenFromLawyers', false).filterProperty('firmLocationJob.job.defunct', false).mapBy('firmLocationJob.firmLocation.firm').uniq();
    }),

    approved: _ember['default'].computed.equal('state', 'approved'),
    unapproved: _ember['default'].computed.not('approved'),

    submitForApproval: (0, _laterallyUtilsRestAction['default'])('submit_for_approval'),
    requestAccess: (0, _laterallyUtilsRestAction['default'])("request_access"),
    requestStageBump: (0, _laterallyUtilsRestAction['default'])("request_stage_bump"),
    resendConfirmationEmail: (0, _laterallyUtilsRestAction['default'])("resend_confirmation_email"),
    verifyStudent: (0, _laterallyUtilsRestAction['default'])("verify_student"),

    markDoubleSubmissions: (0, _laterallyUtilsRestAction['default'])('mark_double_submissions'),
    getHelp: (0, _laterallyUtilsRestAction['default'])('get_help'),

    isStarting: alias('states.START'),
    isEditing: alias('states.EDITING'),
    isSelecting: alias('states.SELECTING'),
    isPending: alias('states.PENDING'),
    isApproved: alias('states.APPROVED'),

    hasRequestedAccess: or('states.SELECTING', 'states.EDITING'),
    hasPublished: alias('states.PENDING'),

    hasProfileForPublishRequest: and.apply(undefined, requirementKeys),

    canPublish: and('hasProfileForPublishRequest', 'firms.length'),

    requirementKeys: requirementKeys,
    extraRequirementKeys: extraRequirementKeys,

    nonEmptyBarAdmissions: computed.filterProperty('barAdmissions', 'notBlank'),

    canViewConversations: _ember['default'].computed.bool('isAnySelecting'),

    isFullSelecting: computed('state', 'stage', function () {
      return get(this, 'steps.SELECTING') && (get(this, 'stage') === 'FULL' || get(this, 'stage') === 'TRIALING') || get(this, 'steps.PENDING');
    }),

    isAnySelecting: alias('states.SELECTING'),

    stageBumpRequested: _emberData['default'].attr('boolean')
  }).reopenClass({

    maxPracticeAreas: 15,
    minGraduationYearForTranscript: 2005,

    STEPS: { START: 0, EDITING: 1, SELECTING: 2, PENDING: 3, APPROVED: 4, REJECTED: 5 },
    STATES: ['START', 'EDITING', 'SELECTING', 'PENDING', 'APPROVED', 'REJECTED']

  });
});