define('laterally/initializers/select-option-disabled', ['exports', 'ember'], function (exports, _ember) {

  var equal = _ember['default'].computed.equal;

  var initialize = function initialize() {

    _ember['default'].SelectOption.reopen({
      attributeBindings: ['disabled'],
      // TODO: add functionality to set "optionDisabledPath" to the Select view.
      disabled: equal('content.disabled', true)
    });
  };

  exports.initialize = initialize;
  exports['default'] = {
    name: 'select-option',
    initialize: initialize
  };
});