define('laterally/routes/referred', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params, transition) {
      var _this = this;

      this.store.find('referralToken', params.referral_token).then(function (token) {
        get(_this, 'referralManager').referredBy(token);
        transition.send('renderReferredModal');
        _this.transitionTo('landing');
      }, function () {
        _this.transitionTo('landing');
      });
    }

  });
});