define('laterally/services/focus', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Object.extend({

    name: null,

    focusOn: function focusOn(target, route, params) {
      var _this = this;

      get(this, 'router').transitionTo(route, params).then(function () {
        run.schedule('afterRender', function () {
          set(_this, 'name', target);
          run.next(function () {
            return set(_this, 'name', null);
          });
        });
      });
    }

  });
});