define('laterally/components/hover-editable/component', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['isHighlighted:is-highlighted'],
    classNames: ['hover-editable-component'],

    isHighlighted: false,

    mouseEnter: function mouseEnter() {
      this.set('isHighlighted', true);
    },

    mouseLeave: function mouseLeave() {
      this.set('isHighlighted', false);
    }
  });
});