define('laterally/routes/page', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    activate: function activate() {
      window.scrollTo(0, 0);
    },

    model: function model(params) {
      var _this = this;

      return this.store.find("page", params.path)['catch'](function () {
        _this.transitionTo('landing');
      });
    }
  });
});