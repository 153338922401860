define('laterally/controllers/lawyer/index/selections', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Mixin.create({

    needs: ['application'],

    // attr
    selections: [],

    newSelections: computed('selections.[]', function () {
      var selections = get(this, 'selections');
      if (selections.slice) {
        selections = selections.slice();
      }
      return selections;
    }),

    actions: {

      save: function save(def) {
        var selections = get(this, 'selections');
        selections.setObjects(get(this, 'newSelections'));
        get(selections, 'record').save().then(function () {
          if (def) {
            def.resolve();
          }
        });
        this.send('close');
      },

      cancel: function cancel() {
        this.notifyPropertyChange('newSelections');
        this.send('close');
      },

      close: function close() {
        this.send('disconnectModal');
      }

    }

  });
});