define('laterally/models/concerns/conversation-member', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var hasMany = _emberData['default'].hasMany;

  exports['default'] = _ember['default'].Mixin.create({

    messages: hasMany('message', { inverse: 'sender' }),
    conversations: hasMany('conversation')

  });
});