define('laterally/transforms/moment', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return window.moment.utc(deserialized).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      } else {
        return null;
      }
    },
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return window.moment.utc(serialized);
      } else {
        return null;
      }
    }
  });
});