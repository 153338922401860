define('laterally/utils/scheduled', ['exports', 'ember'], function (exports, _ember) {
  var _slice = Array.prototype.slice;
  exports['default'] = scheduled;

  var run = _ember['default'].run;

  function scheduled(queue, fn) {
    return function () {
      return run.schedule.apply(run, [queue, this, fn].concat(_slice.call(arguments)));
    };
  }
});