define('laterally/components/explorees/group-message', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: ['exploree-group-message'],
    savedSuccessfully: false,
    inFlight: false,
    failedSave: false,

    savedMessageSuccess: function savedMessageSuccess() {
      var self = this;
      this.setProperties({ inFlight: false, failedSave: false, savedSuccessfully: true });
      _ember['default'].run.later(function () {
        self.setProperties({ savedSuccessfully: false });
      }, 1000);
    },

    savedMessageFailure: function savedMessageFailure() {
      var self = this;
      this.setProperties({ inFlight: false, savedSuccessfully: false, failedSave: true });
      _ember['default'].run.later(function () {
        self.setProperties({ failedSave: false });
      }, 1000);
    },

    actions: {
      save: function save() {
        var message = this.$('textarea').val(),
            group = this.get('group'),
            defer = _ember['default'].RSVP.defer(),
            self = this;
        this.setProperties({ inFlight: true });
        this.sendAction('save', group, message, defer);
        defer.promise.then(self.savedMessageSuccess.bind(self), self.savedMessageFailure.bind(self));
      }
    }

  });
});