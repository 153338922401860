define('laterally/models/exploree-group-membership', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  var equal = _ember['default'].computed.equal;

  var states = ['saved', 'hidden', 'interested'];

  exports.states = states;
  var stateProperties = states.reduce(function (props, state) {
    var propName = 'is' + state.capitalize();
    props[propName] = equal('state', state);
    return props;
  }, {});

  exports.stateProperties = stateProperties;
  exports['default'] = _emberData['default'].Model.extend(stateProperties, {

    state: attr('string'),
    exploreeGroup: belongsTo('exploreeGroup'),
    exploree: belongsTo('exploree')

  });
});