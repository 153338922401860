define('laterally/components/concerns/options-from-content', ['exports', 'ember'], function (exports, _ember) {
  exports.normalizePath = normalizePath;

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;

  function normalizePath(path) {
    return path ? path.replace(/^content\.?/, '') : '';
  }

  exports['default'] = _ember['default'].Mixin.create({

    // attrs
    content: [],
    optionLabelPath: null,
    optionValuePath: null,

    options: computed('content.[]', function () {
      var labelPath = normalizePath(get(this, 'optionLabelPath'));
      var valuePath = normalizePath(get(this, 'optionValuePath'));
      if (!labelPath && !valuePath) {
        return get(this, 'content').map(function (item) {
          return { label: item, value: item };
        });
      }
      return get(this, 'content').map(function (item) {
        return {
          label: get(item, labelPath),
          value: get(item, valuePath)
        };
      });
    })

  });
});