define("laterally/controllers/application/first-time-modal", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({

    closable: true,

    actions: {

      closeModal: function closeModal() {
        var firmUser = this.get("session.user.userable");
        firmUser.startDemo();
      }

    }

  });
});