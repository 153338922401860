define('laterally/utils/debounced-observer', ['exports', 'ember'], function (exports, _ember) {

  var observer = _ember['default'].observer;
  var run = _ember['default'].run;

  exports['default'] = function (key, time, func) {
    return observer(key, function () {
      run.debounce(this, func, time);
    });
  };
});