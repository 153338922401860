define('laterally/routes/move-tracker/in-house', ['exports', 'laterally/routes/move-tracker/index', 'ember'], function (exports, _laterallyRoutesMoveTrackerIndex, _ember) {
  var merge = _ember['default'].merge;
  exports['default'] = _laterallyRoutesMoveTrackerIndex['default'].extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.useInHouseVersion();
    },

    generateQuery: function generateQuery(params) {
      return merge(this._super(params), { type: 'in-house' });
    },

    actions: {
      willTransition: function willTransition() {
        this.controller.set('nowLoading', true);
      },

      didTransition: function didTransition() {
        this.controller.set('nowLoading', false);
      }
    }
  });
});