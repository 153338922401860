define('laterally/routes/conversations/show/job', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Route.extend({

    renderTemplate: function renderTemplate() {
      this.render('conversations.show.job', {
        into: 'application',
        outlet: 'main'
      });
    }

  });
});