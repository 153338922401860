define('laterally/models/message', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;

  exports['default'] = _emberData['default'].Model.extend({

    body: attr("string"),
    createdAt: attr('moment'),

    conversation: belongsTo('conversation'),
    sender: belongsTo('conversationMember', { polymorphic: true, inverse: 'messages' }),

    createdAtUnix: (function () {
      return this.get('createdAt').unix();
    }).property('createdAt'),

    deleteableUntil: (function () {
      return this.get('createdAt').clone().add(1, 'years');
    }).property('createdAt'),

    isIntroduction: attr('boolean')

  });
});