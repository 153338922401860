define('laterally/components/intersection-count/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var _ = window._;
  exports['default'] = Component.extend({
    tagName: '',

    count: computed('a.@each', 'b.@each', function () {
      return _.intersection((get(this, 'a') || []).toArray(), (get(this, 'b') || []).toArray()).length;
    })
  });
});