define('laterally/controllers/explorees/exploree', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({

    currentUser: alias('session.user'),

    hidden: false,

    hasAccess: alias('parentController.hasAccess'),

    practiceAreas: (function () {
      return this.get('model.practiceAreas').mapBy('name').join(', ');
    }).property(),

    barAdmissions: (function () {
      return this.get('model.barAdmissions').mapBy('state').join(', ');
    }).property(),

    experiences: (function () {
      return this.get('model.experiences').toArray().sort(function (a, b) {
        if (!a.get('dateEnd')) {
          return a.get('dateStart') ? -1 : 1;
        }
        return -_ember['default'].compare(a.get('dateEnd'), b.get('dateEnd'));
      });
    }).property(),

    experienceSorting: ['dateStart:desc'],
    sortedExperiences: _ember['default'].computed.sort('model.experiences', 'experienceSorting'),

    schoolAttendances: (function () {
      return this.get('model.schoolAttendances').sortBy('graduation').reverse();
    }).property(),

    lawSchoolAttendance: (function () {
      var attendances = this.get('schoolAttendances').filterBy('school.lawSchool');
      return attendances.findBy('isJD') || attendances.sortBy('graduation').get('firstObject');
    }).property(),

    actions: {

      hidExploree: function hidExploree() {
        this.set('hidden', true);
      },

      track: function track(action, props) {
        if (!props) {
          props = {};
        }
        props.exploree = this.get('model').getProperties(['id', 'name']);
        this.analytics.track(action, {
          exploree: props
        });
      },

      toggleOpen: function toggleOpen() {
        this.toggleProperty('isOpen');
        var action = this.get('isOpen') ? 'Opened' : 'Closed';
        this.send('track', action + ' Explore Candidate');
      }

    }

  });
});