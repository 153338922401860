define('laterally/controllers/onboarding/students', ['exports', 'ember', 'laterally/utils/track-property'], function (exports, _ember, _laterallyUtilsTrackProperty) {

  var mapBy = _ember['default'].computed.mapBy;

  exports['default'] = _ember['default'].Controller.extend({

    // attrs
    lawyer: null,
    schools: null,
    firms: null,

    futureYears: [2019, 2020, 2021, 2022, 2023, 2024],

    actions: {

      toggleAgreed: function toggleAgreed() {
        this.toggleProperty('agreed');
      },

      enteredUnknownFirmName: function enteredUnknownFirmName(firmName) {
        this.analytics.track('Entered unknown firm name', { firm: firmName });
      },

      enteredUnknownSchoolName: function enteredUnknownSchoolName(schoolName) {
        this.analytics.track('Entered unknown school name', { school: schoolName });
      }

    }

  });
});