define('laterally/routes/concerns/require-no-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.session.get('user')) {
        this.transitionTo('profile');
      }
    }

  });
});