define('laterally/mixins/inaccessible', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = Mixin.create({

    displayOnboardingForm: false,
    displayStudentOnboardingForm: false,

    interceptClicksForModal: true,

    mouseDown: function mouseDown(e) {
      // when mixed-in to a component, this is triggered automatically. when mixed into controllers, you must separately mixin inaccessible-view into the correspoding view
      if (!get(this, 'interceptClicksForModal')) return;
      if (get(this, 'session.userable.steps.SELECTING') && !get(this, 'session.userable.steps.REJECTED')) {
        return;
      };

      this.startOnboarding();
    },

    startOnboarding: function startOnboarding(source) {
      if (source) {
        this.analytics.track('Started Onboarding', source || {});
      }
      if (get(this, 'session.userable.student')) {
        set(this, 'displayStudentOnboardingForm', true);
      } else {
        set(this, 'displayOnboardingForm', true);
      }
    },

    actions: {
      hideOnboardingModals: function hideOnboardingModals() {
        set(this, 'displayOnboardingForm', false);
        set(this, 'displayStudentOnboardingForm', false);
      }
    }

  });
});