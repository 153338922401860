define('laterally/components/input-error/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;

  exports['default'] = _ember['default'].Component.extend({

    tagName: 'span',

    computeError: (function () {
      _ember['default'].run.next(this, function () {
        this.notifyPropertyChange('error');
      });
    }).observes('model.errors.[]'),

    error: (function () {
      var errors = get(this, 'model.errors');
      var key = get(this, 'key');
      if (errors) {
        return errors.errorsFor(key).get('firstObject.message');
      }
    }).property('model.errors')

  });
});