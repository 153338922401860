define('laterally/components/select-editable', ['exports', 'ember', 'laterally/components/concerns/editable', 'laterally/utils/collections'], function (exports, _ember, _laterallyComponentsConcernsEditable, _laterallyUtilsCollections) {
  exports['default'] = _ember['default'].Component.extend(_laterallyComponentsConcernsEditable['default'], {
    tagName: 'span',

    classNames: ['select-editable', 'editable'],
    classNameBindings: ['highlightField'],

    editable: true,

    optionLabelPath: 'content',
    optionValuePath: 'content',

    opened: null,

    valueLabel: (function () {
      var valuePath = this.get('optionValuePath').replace(/^content\.?/, '');
      var labelPath = this.get('optionLabelPath').replace(/^content\.?/, '');
      var value = this.get('content').findBy(valuePath, this.get('value'));
      return value ? _ember['default'].get(value, labelPath) : undefined;
    }).property('value', 'content.[]'),

    content: (function () {
      return _laterallyUtilsCollections[this.get('collection')] || [];
    }).property('collection'),

    mouseDown: function mouseDown(e) {
      this.sendAction('opened', e, this.get('label'));

      if (this.get('toggleEditModeOnStart')) {
        this.set('editMode', true);
      }
    },

    actions: {

      clear: function clear() {
        this.set('value', null);
      }

    }

  });
});