define('laterally/components/floating-content-editable', ['exports', 'ember', 'laterally/components/content-editable'], function (exports, _ember, _laterallyComponentsContentEditable) {

  // Used to prevent 'hopping' of labels when editabe is toggled
  // using visibility: hidden;
  exports['default'] = _laterallyComponentsContentEditable['default'].extend({
    layoutName: 'components/content-editable',
    isVisible: true,

    notInvisible: _ember['default'].computed.or('editable', 'value'),
    classNameBindings: ['notInvisible::invisible']
  });
});