define('laterally/utils/has-many-committer', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var all = _ember['default'].RSVP.all;

  exports['default'] = _ember['default'].Mixin.create({

    records: [],

    deletedRecords: computed(function () {
      return [];
    }),

    saveRecords: function saveRecords() {
      var records = get(this, 'records');
      var deletedRecords = get(this, 'deletedRecords');
      var saves = [];
      records.forEach(function (record) {
        // TODO: `notBlank` logic should be inside a controller?
        if (get(record, 'notBlank')) {
          if (get(record, 'isDirty')) {
            saves.push(record.save());
          }
        } else {
          _ember['default'].run.next(function () {
            return record.unloadRecord();
          });
        }
      });
      deletedRecords.filterBy('isNew', false).forEach(function (record) {
        saves.push(record.save());
      });
      return all(saves);
    },

    rollbackRecords: function rollbackRecords() {
      var records = get(this, 'records');
      var deletedRecords = get(this, 'deletedRecords');
      records.filterProperty('isDirty', true).forEach(function (record) {
        if (record.get('isNew')) {
          record.destroyRecord();
        } else {
          record.rollback();
        }
      });
      deletedRecords.forEach(function (record) {
        return record.rollback();
      });
    },

    createRecord: function createRecord(attrs) {
      var records = get(this, 'records');
      return records.createRecord(attrs);
    },

    removeRecord: function removeRecord(record) {
      get(this, 'deletedRecords').addObject(record);
      record.deleteRecord();
    }

  });
});