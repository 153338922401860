define('laterally/utils/computed/array-controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = function (collection, itemController /*, ...sortProperties */) {
    var sortProperties = null;
    if (arguments.length > 2) {
      sortProperties = [].slice.call(arguments, 2);
    }
    return (function () {
      return _ember['default'].ArrayController.create({
        sortProperties: sortProperties,
        target: this,
        container: this.container,
        content: this.get(collection),
        itemController: itemController,
        parentController: this
      });
    }).property(collection);
  };
});