define('laterally/controllers/application', ['exports', 'ember', 'ember-data', 'laterally/utils/computed/store-find'], function (exports, _ember, _emberData, _laterallyUtilsComputedStoreFind) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var PromiseArray = _emberData['default'].PromiseArray;

  exports['default'] = _ember['default'].Controller.extend({
    queryParams: { accessToken: 'access_token' },
    accessToken: null,

    currentUser: _ember['default'].computed.alias('session.user'),
    flashMessage: null,

    schools: (0, _laterallyUtilsComputedStoreFind['default'])('school'),
    firms: (0, _laterallyUtilsComputedStoreFind['default'])('firm', { autocomplete: 'true' }),
    practiceAreas: (0, _laterallyUtilsComputedStoreFind['default'])('practiceArea'),
    practiceAreaCategories: (0, _laterallyUtilsComputedStoreFind['default'])('practiceAreaCategory'),
    locations: (0, _laterallyUtilsComputedStoreFind['default'])('location'),

    regions: computed('locations', function () {
      return PromiseArray.create({
        promise: get(this, 'locations').then(function (locations) {
          return locations.mapBy('region').uniq().sortBy('name');
        })
      });
    }),

    focus: null

  });
});