define('laterally/components/page-nav', ['exports', 'ember'], function (exports, _ember) {

  var gt = _ember['default'].computed.gt;

  function range(start, end) {
    var arr = new Array(end - start + 1);
    for (var i = 0; i <= end - start; i++) {
      arr[i] = i + start;
    }
    return arr;
  }

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['page-nav'],

    isVisible: gt('pages', 1),

    // attrs
    page: 1,
    total: 0,
    size: 25,
    'on-page': 'goToPage',

    hasNextPage: (function () {
      return this.get('page') < this.get('pages');
    }).property('page', 'pages'),

    hasPrevPage: gt('page', 1),

    pages: (function () {
      return Math.ceil(this.get('total') / this.get('size'));
    }).property('total', 'size'),

    from: (function () {
      return Math.max(this.get('page') - 3, 1);
    }).property('page'),

    to: (function () {
      return Math.min(this.get('from') + 4, this.get('pages'));
    }).property('from', 'pages'),

    links: (function () {
      var page = this.get('page');
      return range(this.get('from'), this.get('to')).map(function (i) {
        return { number: i, current: i === page };
      });
    }).property('page', 'from', 'to'),

    nextOptions: (function () {
      return range(this.get('to') + 1, this.get('pages'));
    }).property('to', 'pages'),

    prevOptions: (function () {
      return range(1, this.get('from') - 1);
    }).property('from'),

    select: (function (event) {
      var val = _ember['default'].$(event.target).val();

      if (val) {
        var page = parseInt(val);
        this.send('goTo', page);
      }
    }).on('change'),

    actions: {

      goTo: function goTo(number) {
        this.sendAction('on-page', number);
      },

      next: function next() {
        if (this.get('hasNextPage')) {
          this.send('goTo', this.get('page') + 1);
        }
      },

      prev: function prev() {
        if (this.get('hasPrevPage')) {
          this.send('goTo', this.get('page') - 1);
        }
      }

    }

  });
});