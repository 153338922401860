define('laterally/components/bar-chart/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var c3 = window.c3;
  exports['default'] = Component.extend({
    didInsertElement: function didInsertElement() {
      var cfg = {
        bindto: '#' + get(this, 'elementId'),

        data: {
          columns: get(this, 'columns'),
          type: 'bar'
        },

        tooltip: {
          format: {
            value: get(this, 'tooltipValueFormatter')
          }
        }
      };

      if (get(this, 'xAxisCategories')) {
        cfg.axis = cfg.axis || {};
        cfg.axis.x = {
          type: 'category',
          categories: get(this, 'xAxisCategories')
        };
      }

      cfg.axis = cfg.axis || {};
      cfg.axis.y = {
        label: get(this, 'yAxisLabel'),
        format: function format(v) {
          return v + '%';
        },

        min: 0,
        max: 100,
        padding: {
          top: 0,
          bottom: 0
        },

        tick: {
          format: function format(v) {
            return v + '%';
          }
        }
      };

      c3.generate(cfg);
    }
  });
});