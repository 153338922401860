define('laterally/routes/conversations', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    pageName: 'Conversations',

    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);
      var userable = this.session.get('userable');
      if (userable) {
        if (transition.targetName === 'conversations.index') {
          if (model.find(function (m) {
            return m.get('exploreeGroup');
          })) {
            controller.set('fromCampaign', true);
          }
        }
      }
    },

    model: function model() {
      return this.store.find('conversation');
    },

    actions: {

      // Sent from the conversations/show route.
      didLoadConversation: function didLoadConversation(conversation) {
        this.controllerFor('conversations').set('fromCampaign', !!conversation.get('exploreeGroup'));
      },

      willTransition: function willTransition() {
        this.send('disconnectModal');
      }

    }

  });
});