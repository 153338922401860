define('laterally/initializers/analytics/track', ['exports'], function (exports) {
  var track = function track(name, trackData) {
    var func = this;

    return function () {
      var data = {};

      if (trackData && typeof trackData === 'function') {
        data = trackData.apply(this, arguments) || {};

        if (data.toJSON) {
          data = data.toJSON();
        }
      } else if (trackData && typeof trackData === 'object') {
        data = trackData;
      }

      this.get('analytics').track(name, data);

      return func.apply(this, arguments);
    };
  };

  exports.track = track;
  exports['default'] = {
    name: 'Function.prototype.track',
    initialize: function initialize() {
      Function.prototype.track = track;
    }
  };
});