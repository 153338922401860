define('laterally/initializers/referral-manager', ['exports'], function (exports) {
  exports['default'] = {
    name: 'referral-manager',

    initialize: function initialize(container) {
      container.injection('service:referral-manager', 'store', 'store:main');

      container.injection('route', 'referralManager', 'service:referral-manager');
      container.injection('component', 'referralManager', 'service:referral-manager');
    }

  };
});