define('laterally/components/order-by/component', ['exports', 'ember', 'laterally/components/radio-element'], function (exports, _ember, _laterallyComponentsRadioElement) {

  var get = _ember['default'].get;
  var alias = _ember['default'].computed.alias;
  var equal = _ember['default'].computed.equal;

  exports['default'] = _laterallyComponentsRadioElement['default'].extend({

    tagName: 'a',

    classNames: ['order-by'],
    classNameBindings: ['isOrderedDesc:is-ordered-desc:is-ordered-asc'],

    attributeBindings: ['href'],
    href: '',

    // attrs
    column: null,
    defaultDirection: 'asc',
    orderable: null,

    orderBy: 'orderBy',

    key: alias('orderable.orderColumn'),
    value: alias('column'),
    isOrderedDesc: equal('orderable.orderDirection', 'desc'),

    select: function select(event) {
      event.preventDefault();
      var direction = get(this, 'orderable.orderDirection');

      if (get(this, 'isSelected')) {
        direction = direction === 'asc' ? 'desc' : 'asc';
      } else {
        direction = get(this, 'defaultDirection');
      }

      this.sendAction('orderBy', get(this, 'value'), direction);
    }

  });
});