define('laterally/views/application/header-steps', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].View.extend({

    tagName: 'ol',

    classNames: ['header-steps'],

    templateName: 'application/header_steps'

  });
});