define("laterally/templates/components/range-editable", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      isHTMLBars: true,
      revision: "Ember@1.11.4",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "range-editable-inputs");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "range-editable-separator");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks,
            content = hooks.content,
            get = hooks.get,
            inline = hooks.inline;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [2]);
        var morph0 = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        var morph1 = dom.createMorphAt(element0, 1, 1);
        var morph2 = dom.createMorphAt(element0, 5, 5);
        content(env, morph0, context, "label");
        inline(env, morph1, context, "select-editable", [], { "value": get(env, context, "fromValue"), "content": get(env, context, "fromContent"), "label": get(env, context, "fromLabel"), "prompt": get(env, context, "prompt"), "optionValuePath": "content.value", "optionLabelPath": "content.value", "class": "range-editable-from" });
        inline(env, morph2, context, "select-editable", [], { "value": get(env, context, "toValue"), "content": get(env, context, "toContent"), "label": get(env, context, "toLabel"), "prompt": get(env, context, "prompt"), "optionValuePath": "content.value", "optionLabelPath": "content.value", "class": "range-editable-to" });
        return fragment;
      }
    };
  })());
});