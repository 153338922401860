define('laterally/components/recent-campaigns-dropdown/component', ['exports', 'ember', 'laterally/utils/deferred-send-action'], function (exports, _ember, _laterallyUtilsDeferredSendAction) {
  var Component = _ember['default'].Component;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var filterBy = computed.filterBy;
  exports['default'] = Component.extend({
    classNames: ['recent-campaigns-dropdown'],
    newCampaignName: null,

    campaigns: null,
    createCampaign: null,

    filteredCampaigns: filterBy('campaigns', 'isDeleted', false),

    actions: {
      addCampaign: function addCampaign() {
        var _this = this;

        (0, _laterallyUtilsDeferredSendAction['default'])(this, 'createCampaign', get(this, 'newCampaignName')).then(function () {
          set(_this, 'newCampaignName', null);
        });
      }
    }
  });
});