define('laterally/controllers/selectable-firm', ['exports', 'ember'], function (exports, _ember) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = _ember['default'].Controller.extend({
    needs: ['selectable-firms'],
    selectableFirms: alias('controllers.selectable-firms'),

    checked: (function (key, value) {
      var selections = this.get('selectableFirms.selections');
      var firm = this.get('model.name');
      var checked = selections.contains(firm);
      if (arguments.length === 2 && checked ^ value) {
        if (value) {
          selections.addObject(firm);
        } else {
          selections.removeObject(firm);
        }
        return value;
      } else {
        return checked;
      }
    }).property('selectableFirms.selections.[]')

  });
});