define('laterally/initializers/session', ['exports'], function (exports) {
  exports['default'] = {

    name: 'session',
    before: 'store',

    initialize: function initialize(container) {
      container.injection('service:session', 'store', 'store:main');

      container.injection('route', 'session', 'service:session');
      container.injection('controller', 'session', 'service:session');
      container.injection('component', 'session', 'service:session');

      container.injection('service:pusher', 'session', 'service:session');
      container.injection('service:error-logger', 'session', 'service:session');
    }

  };
});