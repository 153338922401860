define('laterally/components/autocomplete-input', ['exports', 'ember', 'laterally/components/concerns/autocomplete'], function (exports, _ember, _laterallyComponentsConcernsAutocomplete) {
  exports['default'] = _ember['default'].Component.extend(_laterallyComponentsConcernsAutocomplete['default'], {

    classNames: ['autocomplete-input'],

    editing: false,

    setEditing: (function (event) {
      this.set('editing', event.type === 'focusin');
    }).on('focusIn', 'focusOut')

  });
});