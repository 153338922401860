define('laterally/views/application/sortable', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var makeArray = _ember['default'].makeArray;
  var sort = _ember['default'].computed.sort;

  exports['default'] = _ember['default'].CollectionView.extend({

    tagName: "ul",

    classNames: ['sortable'],

    enabled: _ember['default'].computed.not('disabled'),
    disabled: false,

    _initializedSortable: false,

    sortByWrapped: (function () {
      return makeArray(get(this, 'sortBy'));
    }).property('sortBy'),

    content: sort('unsortedContent', 'sortByWrapped'),

    updateSortBy: function updateSortBy() {
      this.beginPropertyChanges();
      this.forEachChildView((function (view) {
        view.get('content').set(this.get('sortBy'), view.$().index());
      }).bind(this));
      this.endPropertyChanges();
    },

    initializeSortable: (function () {
      var handle = this.get('sortHandle') || '.sort-handle';

      if (this.get('enabled') && !this.get('_initializedSortable')) {
        this.set('_initializedSortable', true);
        var $this = this.$();
        $this.sortable({
          axis: 'y',
          addClasses: 'false',
          container: 'parent',
          handle: handle,
          tolerance: 'pointer',
          scroll: false,
          start: function start() {
            $this.disableSelection();
          },
          change: function change(event, ui) {
            ui.helper.css('margin-top', _ember['default'].$(window).scrollTop());
          },
          beforeStop: function beforeStop(event, ui) {
            ui.helper.css('margin-top', 0);
          },
          stop: (function () {
            $this.enableSelection();
            this.updateSortBy();
          }).bind(this)
        });
      }
    }).observes('enabled').on('didInsertElement'),

    destroySortable: (function () {
      if (!this.get('enabled') && this.get('_initializedSortable')) {
        this.set('_initializedSortable', false);
        this.$().sortable('destroy');
      }
    }).observes('enabled').on('willDestroyElement'),

    createChildView: function createChildView() {
      var view = this._super.apply(this, arguments);
      var content = view.get('content');
      if (content.isController) {
        view.set('controller', content);
      }
      return view;
    }

  });
});