define('laterally/models/concerns/userable', ['exports', 'ember', 'ember-data', 'laterally/models/concerns/profile-picture'], function (exports, _ember, _emberData, _laterallyModelsConcernsProfilePicture) {
  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create(_laterallyModelsConcernsProfilePicture['default'], {

    user: _emberData['default'].belongsTo('user', { inverse: 'userable' }),

    firstName: _emberData['default'].attr('string'),
    lastName: _emberData['default'].attr('string'),

    name: (function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }).property('firstName', 'lastName'),

    fullName: computed.alias('name'),

    updatedAt: _emberData['default'].attr("date"),

    isApproved: computed.bool('steps.APPROVED'),
    agreed: _emberData['default'].attr('boolean', { defaultValue: false })

  });
});