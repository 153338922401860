define('laterally/components/radio-element', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var on = _ember['default'].on;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;

  var UNDEFINED = {};

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['radio-element'],
    classNameBindings: ['isSelected:is-selected:is-deselected'],

    // attrs
    key: null,
    value: null,
    initial: false,
    'on-event': 'click',
    toggle: UNDEFINED,
    toggled: null,

    initValue: on('init', function () {
      if (get(this, 'initial')) {
        this.select();
      }
    }),

    isSelected: computed('key', 'value', function () {
      // Use double equality, not triple, because passing in a value of 'null'
      // for 'value' gets converted to 'undefined' for some reason.
      return get(this, 'key') == get(this, 'value'); // jshint ignore:line
    }),

    attachEventListener: on('init', observer('on-event', function () {
      var attachedEventKey = get(this, 'attachedEventKey');
      var onEvent = get(this, 'on-event');
      if (attachedEventKey) {
        this.off(attachedEventKey, this, this.select);
      }
      if (onEvent) {
        this.on(onEvent, this, this.select);
      }
      this.set('attachedEventKey', onEvent);
    })),

    select: function select() {
      if (get(this, 'isSelected')) {
        var toggle = get(this, 'toggle');
        if (toggle !== UNDEFINED) {
          set(this, 'key', toggle);
        }
      } else {
        this.set('key', get(this, 'value'));

        if (get(this, 'on-select')) {
          this.sendAction('on-select', get(this, 'value'));
        }
      }
    },

    mouseDown: function mouseDown(e) {
      this.sendAction('toggled', e, get(this, 'value'));
    },

    actions: {

      select: function select() {
        this.select();
      }

    }

  });
});