define('laterally/components/lawyer-profile-progress/component', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var gte = _ember['default'].computed.gte;
  var or = _ember['default'].computed.or;

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['lawyer-profile-progress'],
    classNameBindings: ['isCompleted'],

    // attrs
    lawyer: null,
    showErrorsFor: {},
    refreshProgressAt: null,
    getHelp: null,

    isCompleted: gte('requirementPercentage', 100),

    barWidth: computed('requirementPercentage', 'extraPercentage', function () {
      if (get(this, 'isCompleted')) {
        return Math.ceil(get(this, 'requirementPercentage') * 0.9 + get(this, 'extraPercentage') * 0.1);
      } else {
        return get(this, 'requirementPercentage');
      }
    }),

    textPercent: computed('requirementPercentage', 'extraPercentage', function () {
      if (get(this, 'isCompleted')) {
        return Math.ceil(get(this, 'requirementPercentage') + get(this, 'extraPercentage') / 10);
      } else {
        return get(this, 'requirementPercentage');
      }
    }),

    requirementPercentage: computed('refreshProgressAt', function () {
      var lawyer = get(this, 'lawyer');
      var requirements = get(lawyer, 'requirementKeys');
      var done = requirements.filter(function (key) {
        return get(lawyer, key);
      });
      return Math.ceil(done.length / requirements.length * 100);
    }),

    extraPercentage: computed('refreshProgressAt', function () {
      var lawyer = get(this, 'lawyer');
      var requirements = get(lawyer, 'extraRequirementKeys');
      var done = requirements.filter(function (key) {
        return get(lawyer, key);
      });
      return Math.ceil(done.length / requirements.length * 100);
    }),

    canGetHelp: or('lawyer.isAnySelecting', 'lawyer.isPending'),

    actions: {

      focusOn: function focusOn(target, route) {
        this.sendAction('focusOn', target, route, get(this, 'lawyer'));
      },

      getHelp: function getHelp() {
        this.sendAction('getHelp');
      }

    }

  });
});