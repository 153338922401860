define('laterally/utils/filter-falsy-keys', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = filterFalsyKeys;

  function filterFalsyKeys(obj) {
    var filtered = {};
    var value;
    for (var key in obj) {
      value = obj[key];
      if (_ember['default'].typeOf(value) === 'object') {
        value = filterFalsyKeys(value);
      }
      if (value) {
        filtered[key] = value;
      }
    }
    return filtered;
  }
});