define('laterally/routes/lawyer/resume', ['exports', 'ember'], function (exports, _ember) {

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      set(controller, 'model', model);
      set(controller, 'canEdit', model === get(this, 'session.userable'));
    },

    model: function model() {
      return this.modelFor('lawyer');
    },

    templateName: 'lawyer/attachment'
  });
});