define('laterally/components/grouped-objects/component', ['exports', 'ember', 'laterally/utils/computed/group'], function (exports, _ember, _laterallyUtilsComputedGroup) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  var addObserver = _ember['default'].addObserver;
  var removeObserver = _ember['default'].removeObserver;
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    objects: null,
    grouper: null,
    groupSorter: null,
    grouperDependentKeys: null,

    groups: (0, _laterallyUtilsComputedGroup['default'])('objects', function (groups, object) {
      return get(this, 'grouper')(object);
    }),

    sortedGroups: computed('groupSorter', 'groups', function () {
      var _this = this;

      return get(this, 'groups').sort(function (a, b) {
        var sorter = get(_this, 'groupSorter');

        if (sorter) {
          return sorter(get(a, 'key'), get(b, 'key'));
        } else {
          return 1;
        }
      });
    }),

    willInsertElement: function willInsertElement() {
      var _this2 = this;

      (get(this, 'grouperDependentKeys') || []).forEach(function (key) {
        addObserver(_this2, 'objects.@each.' + key, _this2, '_recomputeGroups');
      });
    },

    willDestroyElement: function willDestroyElement() {
      var _this3 = this;

      (get(this, 'grouperDependentKeys') || []).forEach(function (key) {
        removeObserver(_this3, 'objects.@each.' + key, _this3, '_recomputeGroups');
      });
    },

    _recomputeGroups: function _recomputeGroups() {
      this.notifyPropertyChange('groups');
    }
  });
});