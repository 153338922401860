define('laterally/models/firm-location', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    firm: _emberData['default'].belongsTo('firm'),
    location: _emberData['default'].belongsTo('location'),
    firmLocationUsers: _emberData['default'].hasMany('firmLocationUser'),
    practiceAreas: _emberData['default'].hasMany('practiceArea'),
    firmLocationJobs: _emberData['default'].hasMany('firmLocationJob')

  });
});