define('laterally/controllers/external-jobs/index', ['exports', 'ember', 'ember-cli-js-cookie', 'laterally/utils/computed/store-find', 'laterally/controllers/concerns/paged'], function (exports, _ember, _emberCliJsCookie, _laterallyUtilsComputedStoreFind, _laterallyControllersConcernsPaged) {
  var Controller = _ember['default'].Controller;
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var oneWay = computed.oneWay;
  var alias = computed.alias;
  exports['default'] = Controller.extend(_laterallyControllersConcernsPaged['default'], {
    needs: ['application'],

    kind: 'all',
    startExperience: oneWay('minExperience'),
    endExperience: oneWay('maxExperience'),
    locationIds: [],
    sort: '-jobs_count',
    page: 1,
    total: alias('model.jobs.meta.total'),
    pageSize: 50,
    keywords: null,
    saved: false,

    isLoading: false,

    minExperience: 1,
    maxExperience: 100,
    experienceThreshold: 10,

    regions: alias('controllers.application.regions'),
    practiceAreaCategories: alias('controllers.application.practiceAreaCategories'),

    allLocations: (0, _laterallyUtilsComputedStoreFind['default'])('location'),
    locationOptions: computed('allLocations.isFulfilled', function () {
      if (get(this, 'allLocations.isFulfilled')) {
        return get(this, 'allLocations').slice();
      } else {
        return [];
      }
    }),

    initLocations: function initLocations() {
      var locationIds = get(this, 'locationIds');

      set(this, 'locations', get(this, 'locationOptions').filter(function (location) {
        return locationIds.contains(get(location, 'id'));
      }));
    },

    useKeywords: function useKeywords(keywords) {
      set(this, 'keywords', keywords);
      this.cookieSet('keywords', keywords);
      this.resetPage();
      this.send('reloadModel');
    },

    useKind: function useKind(kind) {
      set(this, 'kind', kind);
      this.cookieSet('kind', kind);
      this.send('reloadModel');
    },

    useExperiences: function useExperiences(experiences) {
      set(this, 'startExperience', experiences[0]);
      set(this, 'endExperience', experiences[1]);
      this.resetPage();
      this.cookieSet('start-experience', experiences[0]);
      this.cookieSet('end-experience', experiences[1]);
      this.send('reloadModel');
    },

    useLocations: function useLocations(locations) {
      set(this, 'locations', locations);
      var ids = locations.mapBy('id');
      set(this, 'locationIds', ids);
      this.resetPage();
      this.cookieSet('location-ids', ids);
      this.send('reloadModel');
    },

    dropLocation: function dropLocation(location) {
      get(this, 'locations').removeObject(location);
      get(this, 'locationIds').removeObject(get(location, 'id'));
      this.resetPage();
      this.cookieSet('location-ids', get(this, 'locationIds'));
      this.send('reloadModel');
    },

    resetPage: function resetPage() {
      set(this, 'page', 1);
    },

    filterOptions: computed(function () {
      return RSVP.all([get(this, 'allLocations')]);
    }),

    cookieSet: function cookieSet(key, value) {
      _emberCliJsCookie['default'].set(key, value, { expires: 7, path: '' });
    },

    actions: {}
  });
});