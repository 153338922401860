define('laterally/components/sortable-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: '',

    // attrs
    content: [],
    sortBy: null,
    enabled: false

  });
});