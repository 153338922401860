define('laterally/utils/underscore-keys', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = underscoreKeys;

  function underscoreKeys(obj) {
    var underscored = {};
    var value;
    for (var key in obj) {
      value = obj[key];
      if (_ember['default'].typeOf(value) === 'object') {
        value = underscoreKeys(value);
      }
      underscored[key.underscore()] = value;
    }
    return underscored;
  }
});