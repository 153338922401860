define('laterally/components/move-breakdown/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = Component.extend({
    classNames: ['move-breakdown'],

    store: service(),
    breakdown: null,

    hasDepartureData: computed('breakdown.isFulfilled', function () {
      if (get(this, 'breakdown.isFulfilled')) {
        var value = get(this, 'departureDataColumns').reduce(function (acc, col) {
          return col[1] + acc;
        }, 0);

        return value > 0;
      }
    }),

    hasHomegrownDepartureData: computed('breakdown.isFulfilled', function () {
      if (get(this, 'breakdown.isFulfilled')) {
        var value = get(this, 'homegrownDepartureDataColumns').reduce(function (acc, col) {
          return col[1] + acc;
        }, 0);

        return value > 0;
      }
    }),

    hasLateralDepartureData: computed('breakdown.isFulfilled', function () {
      if (get(this, 'breakdown.isFulfilled')) {
        var value = get(this, 'lateralDepartureDataColumns').reduce(function (acc, col) {
          return col[1] + acc;
        }, 0);

        return value > 0;
      }
    }),

    departureDataColumns: computed('breakdown.isFulfilled', function () {
      if (get(this, 'breakdown.isFulfilled')) {
        return [['Firms', get(this, 'breakdown.departuresToFirm')], ['Government', get(this, 'breakdown.departuresToGovernment')], ['In-House', get(this, 'breakdown.departuresToInHouse')], ['Clerkships', get(this, 'breakdown.departuresToClerkship')], ['Other', get(this, 'breakdown.departuresToOther')], ['Unknown', get(this, 'breakdown.departuresToUnknown')]];
      }
    }),

    departureColors: computed(function () {
      return {
        'Firms': '#374558',
        'In-House': '#1dbb8e',
        'Government': '#ffab28',
        'Clerkships': '#D62728',
        'Other': '#888888',
        'Unknown': '#000000'
      };
    }),

    lateralDepartureDataColumns: computed('breakdown.isFulfilled', function () {
      if (get(this, 'breakdown.isFulfilled')) {
        return [['Firms', get(this, 'breakdown.lateralDeparturesToFirm')], ['Government', get(this, 'breakdown.lateralDeparturesToGovernment')], ['In-House', get(this, 'breakdown.lateralDeparturesToInHouse')], ['Clerkships', get(this, 'breakdown.lateralDeparturesToClerkship')], ['Other', get(this, 'breakdown.lateralDeparturesToOther')]];
      }
    }),

    homegrownDepartureDataColumns: computed('breakdown.isFulfilled', function () {
      if (get(this, 'breakdown.isFulfilled')) {
        return [['Firms', get(this, 'breakdown.nonLateralDeparturesToFirm')], ['Government', get(this, 'breakdown.nonLateralDeparturesToGovernment')], ['In-House', get(this, 'breakdown.nonLateralDeparturesToInHouse')], ['Clerkships', get(this, 'breakdown.nonLateralDeparturesToClerkship')], ['Other', get(this, 'breakdown.nonLateralDeparturesToOther')], ['Unknown', get(this, 'breakdown.nonLateralDeparturesToUnknown')]];
      }
    }),

    stayDataColumns: computed('breakdown.isFulfilled', function () {
      if (get(this, 'breakdown.isFulfilled')) {
        return [[get(this, 'breakdown.firms.firstObject.name'), get(this, 'breakdown.stayLikelihoods').objectAt(0), get(this, 'breakdown.stayLikelihoods').objectAt(1), get(this, 'breakdown.stayLikelihoods').objectAt(2)], ['Industry Average', get(this, 'breakdown.avgStayLikelihoods').objectAt(0), get(this, 'breakdown.avgStayLikelihoods').objectAt(1), get(this, 'breakdown.avgStayLikelihoods').objectAt(2)]];
      }
    }),

    homegrownStayDataColumns: computed('breakdown.isFulfilled', function () {
      if (get(this, 'breakdown.isFulfilled')) {
        return [[get(this, 'breakdown.firms.firstObject.name'), get(this, 'breakdown.homegrownStayLikelihoods').objectAt(0), get(this, 'breakdown.homegrownStayLikelihoods').objectAt(1), get(this, 'breakdown.homegrownStayLikelihoods').objectAt(2)], ['Industry Average', get(this, 'breakdown.avgHomegrownStayLikelihoods').objectAt(0), get(this, 'breakdown.avgHomegrownStayLikelihoods').objectAt(1), get(this, 'breakdown.avgHomegrownStayLikelihoods').objectAt(2)]];
      }
    }),

    lateralStayDataColumns: computed('breakdown.isFulfilled', function () {
      if (get(this, 'breakdown.isFulfilled')) {
        return [[get(this, 'breakdown.firms.firstObject.name'), get(this, 'breakdown.lateralStayLikelihoods').objectAt(0), get(this, 'breakdown.lateralStayLikelihoods').objectAt(1), get(this, 'breakdown.lateralStayLikelihoods').objectAt(2)], ['Industry Average', get(this, 'breakdown.avgLateralStayLikelihoods').objectAt(0), get(this, 'breakdown.avgLateralStayLikelihoods').objectAt(1), get(this, 'breakdown.avgLateralStayLikelihoods').objectAt(2)]];
      }
    }),

    tenureDataCategories: ['3 yrs', '5 yrs', '7 yrs'],

    tenureLabeller: function tenureLabeller(value) {
      return Math.round(value * 100) / 100 + '%';
    }
  });
});