define('laterally/components/affix-element', ['exports', 'ember'], function (exports, _ember) {

  var $ = _ember['default'].$;
  var equal = _ember['default'].computed.equal;

  exports['default'] = _ember['default'].Component.extend({

    classNameBindings: ['affix', 'affixTop', 'affixBottom'],

    affix: equal('state', 'affix'),
    affixTop: equal('state', 'affixTop'),
    affixBottom: equal('state', 'affixBottom'),

    initializeAffix: (function () {
      var $this = this.$();
      var $parent = $this.parents(this.get('parent'));
      $(window).on(this.get('scrollEventName'), (function () {
        var scroll = $(window).scrollTop();
        var offset = $parent.offset().top;
        if (scroll < offset) {
          this.set('state', 'affixTop');
        } else if (scroll < offset + $parent.height()) {
          this.set('state', 'affix');
        } else {
          this.set('state', 'affixBottom');
        }
      }).bind(this));
    }).on('didInsertElement'),

    removeAffix: (function () {
      $(window).off(this.get('scrollEventName'));
    }).on('willDestroyElement'),

    scrollEventName: (function () {
      return 'scroll.affix-element.' + this.get('elementId');
    }).property('elementId')

  });
});